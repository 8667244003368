@import url('https://fonts.googleapis.com/css2?family=League+Spartan');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast-container {
  width: fit-content;
}

/* BEING KYW styling */
html {
  min-height: 100%;
  background-image: linear-gradient(
    90deg,
    #27426b,
    #000000 70%
  );
  /* background-color: red; */
  /* background-image: linear-gradient(
    180deg,
    #27426b,
    #000000 100%
  ); */
}

/* This is used to set background color for whole app */
#root {
  background-image: linear-gradient(
    90deg,
    #27426b,
    #000000 70%
  );
  font-family: 'League Spartan';
  /* font-weight: bold; */
}
.nav-link {
  color: #ffffff !important;
}

.btn-kyw {
  color: var(--button_accent_color);
  background: #f2cb2e;
}

ul.image-bullet {
  /* list-style-image: url("./images/kyw_bullet-20x20.png"); */
}
.alert-kyw-success {
  color: #ffffff;
  background-color:  #222b36;
}

/* gradient for minting tile */
.tile {
  /* background-image: linear-gradient(
    180deg,
    #aeaeae,
    #000000 100%
  ); */
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* border-radius: 30px 30px 30px 30px; */
  /* background-color: #000000; */
  /* border-width: 4px 4px 4px 4px;
  border-color: #f2cb2e;
  border-style: solid;
  border-radius: 30px 30px 30px 30px;
  overflow: visible;
  box-shadow: 0px 0px 50px 0px #000000 inst;
  padding: 60px 30px 60px 30px; */
}

.fusion-flex-container .fusion-row .fusion-flex-column {
  display: flex;
}
.fusion-body .fusion-builder-column-8 {
  width: 50% !important;
  margin-top: 0px;
  margin-bottom: 20px;
}
.fusion-flex-container .fusion-flex-align-self-stretch {
  align-self: stretch;
}
.fusion-layout-column {
  position: relative;
  float: left;
  margin-bottom: 20px;
}

/********* Compiled CSS - Do not edit *********/
/* latin */

@font-face {
  font-family: "Abel";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/MwQ5bhbm2POE2V9BPQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRxC7mw9c.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRzS7mw9c.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRxi7mw9c.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRxy7mw9c.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRyS7m.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRxC7mw9c.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRzS7mw9c.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRxi7mw9c.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRxy7mw9c.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRyS7m.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRxC7mw9c.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRzS7mw9c.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRxi7mw9c.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRxy7mw9c.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUQjIg1_i6t8kCHKm459WxRyS7m.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "News Cycle";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/CSR64z1Qlv-GDxkbKVQ_fO4KTet_.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "News Cycle";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  /* src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/CSR64z1Qlv-GDxkbKVQ_fOAKTQ.woff2)
    format("woff2"); */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "News Cycle";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/CSR54z1Qlv-GDxkbKVQ_dFsvWNpeudwk.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "News Cycle";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  /* src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/CSR54z1Qlv-GDxkbKVQ_dFsvWNReuQ.woff2)
    format("woff2"); */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/jizaRExUiTo99u79D0-ExdGM.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/jizaRExUiTo99u79D0aExdGM.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/jizaRExUiTo99u79D0yExdGM.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://ethjets.io/wp-content/uploads/fusion-gfonts/jizaRExUiTo99u79D0KExQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
.fusion-faqs-wrapper {
  display: none;
}
.fusion-faq-post {
  position: relative;
}
.fusion-faq-shortcode
  .fusion-accordian
  .fusion-toggle-icon-unboxed
  .panel-title
  a
  .fa-fusion-box {
  background-color: transparent !important;
}
.fusion-faq-shortcode
  .fusion-accordian
  .fusion-toggle-icon-unboxed
  .panel-title
  a:hover
  .fa-fusion-box {
  background-color: transparent !important;
}
body .portfolio-grid {
  margin-bottom: 80px;
}
.fusion-portfolio-wrapper {
  display: block;
}
.fusion-portfolio-post {
  float: left;
}
.fusion-portfolio-post .fusion-image-wrapper {
  opacity: 0;
}
.fusion-portfolio-one .fusion-portfolio-post {
  overflow: hidden;
  float: none;
  width: 100%;
}
.fusion-portfolio-two .fusion-portfolio-post {
  width: 50%;
}
.fusion-portfolio-three .fusion-portfolio-post {
  width: 33.3333%;
  width: calc(100% / 3);
}
.fusion-portfolio-four .fusion-portfolio-post {
  width: 25%;
}
.fusion-portfolio-five .fusion-portfolio-post {
  width: 20%;
}
.fusion-portfolio-six .fusion-portfolio-post {
  width: 16.6666%;
  width: calc(100% / 6);
}
.fusion-portfolio-post > .fusion-separator {
  margin: 40px 0;
}
.fusion-portfolio-content {
  margin-top: 20px;
}
.fusion-portfolio-content h2 {
  margin: 0 0 1px !important;
  padding: 0;
}
.fusion-portfolio-content .fusion-portfolio-meta {
  margin: 0 0 10px;
  padding: 0;
  font-weight: 400;
  font-size: 13px;
}
.fusion-portfolio-content p {
  margin: 0 0 20px;
}
.fusion-portfolio-content p:last-child {
  margin-bottom: 0;
}
.fusion-portfolio .fusion-content-sep {
  margin-bottom: 20px;
}
.fusion-portfolio .fusion-separator {
  margin-top: 40px;
}
.fusion-portfolio #infscr-loading {
  bottom: -29px;
}
.fusion-portfolio .loading-container {
  display: none;
}
.fusion-portfolio-content-wrapper {
  opacity: 0;
}
.fusion-portfolio-content-wrapper .fusion-portfolio-content {
  margin-bottom: 20px;
}
.fusion-portfolio-content-wrapper .fusion-image-wrapper {
  opacity: 1;
}
.fusion-portfolio-boxed .fusion-portfolio-content-wrapper {
  padding: 0;
  border: 1px solid #e9eaee;
  border-bottom-width: 3px;
}
.fusion-portfolio-boxed .fusion-portfolio-content {
  margin: 0;
  padding: 25px;
}
.fusion-portfolio-one .fluid-width-video-wrapper {
  position: static;
}
.fusion-portfolio-one .fusion-image-wrapper {
  margin-bottom: 15px;
}
.fusion-portfolio-one .fusion-portfolio-content {
  margin-bottom: 0;
}
.fusion-portfolio-one .fusion-portfolio-buttons {
  margin: 20px -15px 0;
}
.fusion-portfolio-one .fusion-portfolio-buttons .fusion-button {
  margin: 0 15px 15px;
}
.fusion-portfolio-one .fusion-portfolio-buttons-full {
  margin-left: 0;
  margin-right: 0;
}
.fusion-portfolio-one .fusion-portfolio-buttons-full .fusion-button {
  margin: 5px 0;
}
.fusion-portfolio-text-floated .fusion-image-wrapper {
  display: inline-block;
  flex-shrink: 1;
  margin: 0;
  padding: 0 12.5px;
  max-width: 540px;
  width: 100%;
}
.fusion-portfolio-text-floated .fusion-portfolio-content {
  margin-top: 0;
}
.portfolio-one-text .portfolio-item .image {
  width: 100%;
  height: 100%;
  float: none;
  margin-right: 0;
}
.portfolio-one-sidebar .portfolio-item .image {
  float: none;
}
.portfolio-infinite .isotope {
  overflow: visible !important;
}
.fusion-portfolio-gallery-hidden {
  display: none;
}
.fusion-portfolio-equal-heights .fusion-portfolio-post {
  display: inline-flex;
}
.fusion-portfolio-equal-heights .fusion-portfolio-content-wrapper {
  overflow: hidden;
  width: 100%;
}
.fusion-project-details-tb h3,
.project-content h3 {
  font-size: 13px;
  line-height: 17px;
  color: #333;
  margin: 0 0 28px;
}
.fusion-project-details-tb .project-description,
.project-content .project-description {
  float: left;
  width: 74.4681%;
}
.fusion-project-details-tb .project-description p,
.project-content .project-description p {
  margin-top: 0;
}
.fusion-project-details-tb .project-info,
.project-content .project-info {
  float: right;
  width: 23.4043%;
}
.fusion-project-details-tb .project-info .project-info-box,
.project-content .project-info .project-info-box {
  border-bottom: 1px solid #e7e6e6;
  padding-bottom: 15px;
  margin-bottom: 15px;
  overflow: hidden;
}
.fusion-project-details-tb .project-info h4,
.project-content .project-info h4 {
  color: #333;
  margin: 0 5px 0 0;
  float: left;
  width: 110px;
}
.with-sidebar .fusion-project-details-tb .project-info h4,
.with-sidebar .project-content .project-info h4 {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}
.fusion-project-details-tb .project-info .project-terms,
.project-content .project-info .project-terms {
  float: left;
}
.fusion-project-details-tb::after {
  content: " ";
  clear: both;
  display: table;
}
.fusion-project-details-tb .project-info {
  float: right;
  width: 100%;
}
.portfolio-full.with-sidebar .project-info {
  float: left;
}
.portfolio-full.with-sidebar .project-description {
  float: right;
  width: 72.5%;
}
.portfolio-half .flexslider:not(.tfs-slider) {
  width: 65.9574%;
  float: left;
}
.portfolio-half .project-content {
  width: 31.9149%;
  float: right;
}
.portfolio-half .project-description {
  width: 100%;
  margin-bottom: 50px;
}
.portfolio-half .project-description .flexslider:not(.tfs-slider) {
  width: auto;
}
.portfolio-half .project-info {
  width: 100%;
}
.portfolio-sep {
  clear: both;
  display: block;
}
.portfolio-half .portfolio-sep {
  margin-bottom: 50px;
}
.project-info-box span {
  float: left;
}
.portfolio-full .flexslider {
  margin-bottom: 70px;
}
.portfolio-grid .portfolio-tabs {
  margin-top: 0;
}
.portfolio-grid.portfolio-one .portfolio-item:last-child {
  margin-bottom: 0;
}
.portfolio-grid.portfolio-one .image {
  width: 100%;
  float: none;
}
.fusion-portfolio-layout-masonry .fusion-portfolio-content-wrapper,
.fusion-portfolio.fusion-portfolio-masonry .fusion-portfolio-content-wrapper {
  position: relative;
}
.fusion-portfolio-layout-masonry.fusion-portfolio-two.fusion-masonry-has-vertical
  .fusion-element-landscape,
.fusion-portfolio.fusion-portfolio-masonry.fusion-portfolio-two.fusion-masonry-has-vertical
  .fusion-element-landscape {
  width: 100%;
}
.fusion-portfolio-layout-masonry.fusion-portfolio-three.fusion-masonry-has-vertical
  .fusion-element-landscape,
.fusion-portfolio.fusion-portfolio-masonry.fusion-portfolio-three.fusion-masonry-has-vertical
  .fusion-element-landscape {
  width: 66.6666%;
}
.fusion-portfolio-layout-masonry.fusion-portfolio-four.fusion-masonry-has-vertical
  .fusion-element-landscape,
.fusion-portfolio.fusion-portfolio-masonry.fusion-portfolio-four.fusion-masonry-has-vertical
  .fusion-element-landscape {
  width: 50%;
}
.fusion-portfolio-layout-masonry.fusion-portfolio-five.fusion-masonry-has-vertical
  .fusion-element-landscape,
.fusion-portfolio.fusion-portfolio-masonry.fusion-portfolio-five.fusion-masonry-has-vertical
  .fusion-element-landscape {
  width: 40%;
}
.fusion-portfolio-layout-masonry.fusion-portfolio-six.fusion-masonry-has-vertical
  .fusion-element-landscape,
.fusion-portfolio.fusion-portfolio-masonry.fusion-portfolio-six.fusion-masonry-has-vertical
  .fusion-element-landscape {
  width: 33.3333%;
}
.fusion-masonry-element-container > a > img,
.fusion-masonry-element-container > img {
  display: none;
}
.fusion-portfolio-text.fusion-portfolio-layout-masonry.fusion-portfolio-rollover
  .fusion-portfolio-content,
.fusion-portfolio-text.fusion-portfolio-masonry.fusion-portfolio-rollover
  .fusion-portfolio-content {
  transition: all 0.3s ease;
}
.fusion-portfolio-text.fusion-portfolio-layout-masonry.fusion-portfolio-rollover
  .fusion-portfolio-post
  .fusion-portfolio-content-wrapper,
.fusion-portfolio-text.fusion-portfolio-masonry.fusion-portfolio-rollover
  .fusion-portfolio-post
  .fusion-portfolio-content-wrapper {
  overflow: hidden;
}
.fusion-portfolio-text.fusion-portfolio-layout-masonry.fusion-portfolio-rollover
  .fusion-portfolio-post
  .fusion-portfolio-content-wrapper:hover
  .fusion-portfolio-content,
.fusion-portfolio-text.fusion-portfolio-masonry.fusion-portfolio-rollover
  .fusion-portfolio-post
  .fusion-portfolio-content-wrapper:hover
  .fusion-portfolio-content {
  transition: all 0.3s ease;
  transform: translateY(105%);
}
.fusion-portfolio-element .fusion-load-more-button.fusion-portfolio-button {
  background-color: var(--portfolio_element_load_more_posts_button_bg_color);
  color: var(--portfolio_element_load_more_posts_button_text_color);
}
.fusion-portfolio-element
  .fusion-load-more-button.fusion-portfolio-button:hover {
  background-color: var(
    --portfolio_element_load_more_posts_hover_button_bg_color
  );
  color: var(--portfolio_element_load_more_posts_hover_button_text_color);
}
.fusion-privacy-element .fusion-privacy-form ul {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
}
.fusion-privacy-element .fusion-privacy-form-floated ul li {
  display: inline-block;
  margin-right: 20px;
}
#comment-input:after {
  content: "";
  display: table;
  clear: both;
}
#comment-input input {
  float: left;
  margin-right: 1%;
  padding-left: 15px;
  padding-right: 15px;
  width: 32.666666%;
  min-width: 28%;
  font-size: 13px;
  color: #747474;
  border: 1px solid #d2d2d2;
}
#comment-input input:last-child {
  margin-right: 0;
}
#comment-textarea {
  margin-bottom: 10px;
}
#comment-textarea.fusion-contact-comment-below {
  margin-top: 10px;
  margin-bottom: 0;
}
#comment-textarea textarea {
  padding: 12px 15px;
  width: 100%;
  height: 150px;
  font-size: 13px;
  color: #747474;
  border: 1px solid #d2d2d2;
}
.fusion-contact-form {
  line-height: normal;
}
.fusion-contact-form #comment-submit-container {
  margin-top: 20px;
  margin-bottom: 0;
}
.fusion-contact-form .grecaptcha-badge {
  z-index: 100000;
}
.fusion-contact-form .fusion-hide-recaptcha-badge {
  display: none;
}
.fusion-contact-form .fusion-comment-privacy-checkbox-wrapper {
  display: flex;
  align-items: baseline;
  margin: 20px 0;
  font-size: 13px;
}
.fusion-contact-form .fusion-comment-privacy-checkbox {
  margin: 0 10px 0 0;
}
.fusion-contact-form #comment-recaptcha {
  margin-top: 13px;
}
[class*=" awb-icon-"],
[class^="awb-icon-"] {
  font-family: awb-icons !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.awb-icon-user2:before {
  content: "\e61b";
}
.awb-icon-check:before {
  content: "\f00c";
}
.awb-icon-tiktok:before {
  content: "\e906";
}
.awb-icon-discord:before {
  content: "\e903";
}
.awb-icon-FB_logo_black-solid-1:before {
  content: "\e902";
}
.awb-icon-map-marker-alt:before {
  content: "\e901";
}
.awb-icon-pen:before {
  content: "\e600";
}
.awb-icon-yahoo:before {
  content: "\e601";
}
.awb-icon-pinterest:before {
  content: "\e602";
}
.awb-icon-myspace:before {
  content: "\e603";
}
.awb-icon-facebook:before {
  content: "\e604";
}
.awb-icon-twitter:before {
  content: "\e605";
}
.awb-icon-feed:before {
  content: "\e606";
}
.awb-icon-rss:before {
  content: "\e606";
}
.awb-icon-vimeo:before {
  content: "\e607";
}
.awb-icon-flickr:before {
  content: "\e608";
}
.awb-icon-dribbble:before {
  content: "\e609";
}
.awb-icon-blogger:before {
  content: "\e60b";
}
.awb-icon-soundcloud:before {
  content: "\e60c";
}
.awb-icon-reddit:before {
  content: "\e60d";
}
.awb-icon-paypal:before {
  content: "\e60e";
}
.awb-icon-linkedin:before {
  content: "\e60f";
}
.awb-icon-digg:before {
  content: "\e610";
}
.awb-icon-dropbox:before {
  content: "\e611";
}
.awb-icon-tumblr:before {
  content: "\e613";
}
.awb-icon-grid:before {
  content: "\e614";
}
.awb-icon-mail:before {
  content: "\e616";
}
.awb-icon-forrst:before {
  content: "\e617";
}
.awb-icon-skype:before {
  content: "\e618";
}
.awb-icon-link:before {
  content: "\e619";
}
.awb-icon-user:before {
  content: "\e61a";
}
.awb-icon-minus:before {
  content: "\e61c";
}
.awb-icon-plus:before {
  content: "\e61d";
}
.awb-icon-arrow-left:before {
  content: "\e61e";
}
.awb-icon-arrow-down:before {
  content: "\e61f";
}
.awb-icon-uniE620:before {
  content: "\e620";
}
.awb-icon-arrow-down2:before {
  content: "\e621";
}
.awb-icon-youtube:before {
  content: "\e622";
}
.awb-icon-list:before {
  content: "\e623";
}
.awb-icon-image:before {
  content: "\e624";
}
.awb-icon-images:before {
  content: "\e625";
}
.awb-icon-quotes-left:before {
  content: "\e627";
}
.awb-icon-film:before {
  content: "\e628";
}
.awb-icon-headphones:before {
  content: "\e629";
}
.awb-icon-bubbles:before {
  content: "\e62a";
}
.awb-icon-search:before {
  content: "\f002";
}
.awb-icon-star:before {
  content: "\f005";
}
.awb-icon-star-o:before {
  content: "\f006";
}
.awb-icon-gear:before {
  content: "\f013";
}
.awb-icon-check-square-o:before {
  content: "\f046";
}
.awb-icon-check-circle-o:before {
  content: "\f05d";
}
.awb-icon-shopping-cart:before {
  content: "\f07a";
}
.awb-icon-bars:before {
  content: "\f0c9";
}
.awb-icon-caret-left:before {
  content: "\f0d9";
}
.awb-icon-caret-right:before {
  content: "\f0da";
}
.awb-icon-angle-left:before {
  content: "\f104";
}
.awb-icon-angle-right:before {
  content: "\f105";
}
.awb-icon-angle-up:before {
  content: "\f106";
}
.awb-icon-angle-down:before {
  content: "\f107";
}
.awb-icon-spinner:before {
  content: "\f110";
}
.awb-icon-vk:before {
  content: "\f189";
}
.awb-icon-instagram:before {
  content: "\ea92";
}
.awb-icon-deviantart:before {
  content: "\eaaa";
}
.awb-icon-yelp:before {
  content: "\ead7";
}
.awb-icon-xing:before {
  content: "\e4d8";
}
.awb-icon-spotify:before {
  content: "\ea94";
}
.awb-icon-whatsapp:before {
  content: "\f232";
}
.awb-icon-eye:before {
  content: "\f06e";
}
.awb-icon-twitch:before {
  content: "\ea9f";
}
.awb-icon-telegram:before {
  content: "\f1d8";
}
.awb-icon-teams:before {
  content: "\e900";
}
.awb-icon-question-circle:before {
  content: "\e908";
}
.awb-icon-buy-now-cart:before {
  content: "\e907";
}
.awb-icon-avada-logo:before {
  content: "\e971";
}
.awb-icon-wechat:before {
  content: "\e905";
}
.awb-icon-weixin:before {
  content: "\e905";
}
.awb-icon-phone:before {
  content: "\e942";
}
.awb-icon-close:before {
  content: "\e904";
}
.fusion-form-form-wrapper .fusion-form-field {
  position: relative;
  width: 100%;
  clear: both;
  margin: 1em 0;
}
.fusion-form-form-wrapper .fusion-form-field .fusion-form-input {
  width: 100%;
  padding: 0 1em;
  outline: 0;
  transition: all 0.2s ease;
}
.fusion-form-form-wrapper .fusion-form-field .fusion-form-input[type="file"] {
  border-color: transparent;
}
.fusion-form-form-wrapper
  .fusion-form-field
  .fusion-form-input:hover:not(:focus) {
  border-color: var(--form_focus_border_color-5a);
}
.fusion-form-form-wrapper .fusion-form-field .label,
.fusion-form-form-wrapper .fusion-form-field label {
  display: inline-block;
  line-height: normal;
}
.fusion-form-form-wrapper .fusion-form-field.fusion-form-label-above .label,
.fusion-form-form-wrapper .fusion-form-field.fusion-form-label-above label {
  margin-bottom: 0.65em;
}
.fusion-form-form-wrapper .fusion-form-field.fusion-form-label-below .label,
.fusion-form-form-wrapper .fusion-form-field.fusion-form-label-below label {
  margin-top: 0.65em;
}
.fusion-form-form-wrapper
  .fusion-form-field.fusion-form-label-below
  .select-arrow {
  top: 1px;
}
.fusion-form-form-wrapper
  .fusion-form-field.fusion-form-textarea-field
  .fusion-form-input-with-icon
  > i {
  line-height: 1.4 !important;
}
.fusion-form-form-wrapper
  .fusion-form-field.fusion-form-textarea-field
  .fusion-form-input-with-icon
  i {
  top: 1em;
  transform: inherit;
}
.fusion-form-form-wrapper .fusion-form-field textarea {
  width: 100%;
}
.fusion-form-form-wrapper .fusion-form-field textarea.fusion-form-input {
  padding: 1em;
  box-sizing: border-box;
  height: auto;
  border-style: solid;
}
.fusion-form-form-wrapper .fusion-form-field option[value=""][disabled] {
  display: none;
}
.fusion-form-form-wrapper .fusion-form-field option {
  color: var(--form_text_color);
}
.fusion-form-form-wrapper .fusion-form-field input[type="file"] {
  width: 100%;
}
.fusion-form-form-wrapper .fusion-form-field input[type="number"] {
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.fusion-form-form-wrapper .fusion-form-field input[type="color"] {
  display: block;
  width: 100%;
}
.fusion-form-form-wrapper .fusion-form-field input[type="date"] {
  display: block;
  width: 100%;
}
.fusion-form-form-wrapper .fusion-form-field.fusion-form-hidden-field {
  display: none;
}
.fusion-form-form-wrapper
  .fusion-form-field.fusion-form-recaptcha-field
  .fusion-form-hide-recaptcha-badge {
  display: none;
}
.fusion-form-form-wrapper .fusion-form-field .fusion-form-tooltip {
  display: inline-block;
  margin-left: 0.4em;
  cursor: pointer;
  position: relative;
}
.fusion-form-form-wrapper
  .fusion-form-field
  .fusion-form-tooltip
  .fusion-form-tooltip-content {
  opacity: 0;
  z-index: 9999;
  width: auto !important;
  white-space: nowrap;
  pointer-events: none;
  position: absolute;
  bottom: 2.2em;
  left: -1.35em;
  transform: translateY(10px);
  padding: 0.54em 0.65em;
  background: #333;
  color: #fff;
  border: 1px solid #333;
  border-radius: 3px;
  transition: all 0.2s ease 0.2s;
}
.fusion-form-form-wrapper
  .fusion-form-field
  .fusion-form-tooltip
  .fusion-form-tooltip-content::before {
  content: " ";
  position: absolute;
  top: 100%;
  left: calc(1.4em - 5px);
  margin-left: 0.2em;
  width: 0;
  border-top: 0.6em solid;
  border-right: 0.5em solid transparent;
  border-left: 0.5em solid transparent;
  border-top-color: inherit;
  font-size: inherit;
  line-height: 0;
}
.fusion-form-form-wrapper
  .fusion-form-field
  .fusion-form-tooltip:hover
  .fusion-form-tooltip-content {
  opacity: 1;
  transform: none;
}
.fusion-form-form-wrapper .fusion-form-field.error input,
.fusion-form-form-wrapper
  .fusion-form-field.error
  label:not(.fusion-form-rating-icon) {
  color: #e0284f !important;
}
.fusion-form-form-wrapper .fusion-form-field.error .fusion-form-input,
.fusion-form-form-wrapper .fusion-form-field.error .fusion-form-upload-field {
  border-color: #e0284f !important;
}
.fusion-form-form-wrapper .fusion-form-field.error .fusion-form-input {
  box-shadow: 0 0 1.5px 1px rgba(224, 40, 79, 0.65);
}
.fusion-form-form-wrapper .fusion-form-field .fusion-form-element-hidden {
  display: none;
}
.fusion-form-form-wrapper .fusion-form-field .fusion-form-input-with-icon {
  position: relative;
}
.fusion-form-form-wrapper .fusion-form-field .fusion-form-input-with-icon > i {
  position: absolute;
  text-align: center;
  z-index: 99;
  left: 1em;
  top: 50%;
  top: calc(
    50% + (var(--form_border_width-top) - var(--form_border_width-bottom)) / 2
  );
  transform: translateY(-50%);
  color: var(--form_text_color);
  font-size: var(--form_text_size);
}
.fusion-form-form-wrapper
  .fusion-form-field
  .fusion-form-input-with-icon
  .fusion-form-input {
  padding-left: 2.5em !important;
}
.fusion-form-form-wrapper .fusion-form-field .wp-editor-tabs {
  float: left;
}
.fusion-form-form-wrapper .fusion-form-field .wp-editor-tabs .switch-tmce {
  margin-left: 0;
}
.fusion-form-form-wrapper
  .fusion-form-field
  .fusion-form-section-wrapper
  .fusion-form-section-container
  .fusion-form-section-title-wrapper
  .fusion-form-section-title {
  margin: 0;
  line-height: 1.75 !important;
}
.fusion-form-form-wrapper
  .fusion-form-field
  .fusion-form-section-wrapper
  .fusion-form-section-container.fusion-alignright {
  width: 100%;
  text-align: right;
  margin-left: 0;
}
.fusion-form-form-wrapper .fusion-form-field-hidden {
  display: none;
}
.fusion-form-form-wrapper .fusion-layout-column {
  margin-bottom: 0;
}
.fusion-builder-live .fusion-form-form-wrapper button[type="submit"] {
  pointer-events: none;
}
.fusion-form .form-submission-notices .fusion-form-response {
  display: none;
}
.fusion-body .flatpickr-calendar.open {
  z-index: 100000;
}
.fusion-form-widget .fusion-form-form-wrapper .fusion-layout-column {
  width: 100%;
  min-width: 100%;
}
@keyframes move {
  0%,
  32% {
    margin-left: -2.74em;
  }
  33%,
  66% {
    margin-left: 0;
  }
  100%,
  67% {
    margin-left: 2.74em;
  }
}
@-moz-document url-prefix() {
  .fusion-form-form-wrapper .fusion-form-field select {
    text-indent: -0.2em;
  }
}
* {
  box-sizing: border-box;
}
.fusionicon-arrow-down2 {
  display: table-cell;
  vertical-align: middle;
}
.icomoon-up:before {
  display: inline-block;
  transform: rotate(180deg);
}
input[type="date"],
input[type="datetime-local"],
input[type="datetime"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="submit"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type="image"] {
  padding: 0;
  border: none;
}
html {
  overflow-x: hidden;
  overflow-y: scroll;
}
body {
  margin: 0;
  color: #747474;
  min-width: 320px;
  -webkit-text-size-adjust: 100%;
  font: 13px/20px PTSansRegular, Arial, Helvetica, sans-serif;
}
body.fusion-blank-page.admin-bar {
  top: 32px;
  height: calc(100vh - 32px);
}
body #wrapper .gomapMarker {
  color: #333;
}
body .fusion-content-widget-area .button {
  margin-left: 15px;
}
#wrapper {
  overflow: visible;
}
#wrapper .tp-bullets {
  transform: translateZ(0);
}
#wrapper .strip-html .fusion-dropcap {
  top: 0;
  float: none;
  margin: 0;
  padding: 0;
  background-color: inherit !important;
  box-shadow: none;
  line-height: inherit;
  text-shadow: none;
  color: inherit !important;
  font-size: inherit;
}
#wrapper .strip-html .fusion-highlight {
  background-color: inherit !important;
  color: inherit !important;
}
#wrapper .strip-html .fusion-tooltip {
  color: inherit;
  border-bottom: 1px dotted;
}
#wrapper .fusion-vertical-menu-widget .menu {
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
}
#wrapper .fusion-vertical-menu-widget .menu li a {
  border-bottom: 1px solid transparent;
  border-right-color: transparent;
  padding: 8px 0;
  display: block;
}
#wrapper .fusion-vertical-menu-widget .menu li a .arrow {
  display: none;
  vertical-align: middle;
}
#wrapper .fusion-vertical-menu-widget .menu li a .arrow:before {
  font-family: awb-icons;
  content: "\f105";
}
#wrapper
  .fusion-vertical-menu-widget
  .menu
  .menu-item-has-children
  > a
  > .arrow,
#wrapper
  .fusion-vertical-menu-widget
  .menu
  .page_item_has_children
  > a
  > .arrow {
  display: inline-block;
  padding: 0 5px;
}
#wrapper .fusion-vertical-menu-widget .menu li.current-menu-item > a,
#wrapper .fusion-vertical-menu-widget .menu li.current_page_item > a {
  border-right-color: #65bc7b;
  border-right: 3px solid;
}
#wrapper .fusion-vertical-menu-widget .menu li.menu-item-has-children ul,
#wrapper .fusion-vertical-menu-widget .menu li.page_item_has_children ul {
  display: none;
}
#wrapper .fusion-vertical-menu-widget.right .menu {
  border-left: 1px solid transparent;
  text-align: right;
  border-right: 0;
}
#wrapper .fusion-vertical-menu-widget.right .menu > li > a {
  padding-right: 20px;
}
#wrapper .fusion-vertical-menu-widget.right .menu li a {
  padding-left: 10px;
}
#wrapper .fusion-vertical-menu-widget.right .menu li a .arrow:before {
  content: "\f104";
}
#wrapper .fusion-vertical-menu-widget.right .menu li.current-menu-item > a,
#wrapper .fusion-vertical-menu-widget.right .menu li.current_page_item > a {
  border-left: 3px solid;
  border-right: 0;
}
#wrapper .fusion-vertical-menu-widget.right .menu .children,
#wrapper .fusion-vertical-menu-widget.right .menu .sub-menu {
  margin-right: 30px;
}
#wrapper .fusion-vertical-menu-widget.left .menu {
  text-align: left;
}
#wrapper .fusion-vertical-menu-widget.left .menu > li > a {
  padding-left: 20px;
}
#wrapper .fusion-vertical-menu-widget.left .menu li a {
  padding-right: 10px;
}
#wrapper .fusion-vertical-menu-widget.left .menu .children,
#wrapper .fusion-vertical-menu-widget.left .menu .sub-menu {
  margin-left: 30px;
}
#wrapper .fusion-vertical-menu-widget.no-border.left .menu li a .arrow {
  padding-right: 0;
  width: 40px;
  text-align: right;
}
#wrapper .fusion-vertical-menu-widget.no-border.right .menu li a .arrow {
  padding-left: 0;
  width: 40px;
  text-align: left;
}
.fusion-blank-page {
  display: table;
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  overflow-x: hidden;
}
.wrapper_blank {
  float: none;
  vertical-align: middle;
  width: 100%;
}
.wrapper_blank #main {
  background: 0 0;
  padding: 0;
}
.fusion-body #wrapper.wrapper_blank {
  display: table-cell;
  margin: 0;
  max-width: none;
}
.error404 .search-page-search-form {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}
.fusion-404-checklist .fusion-custom-menu-item {
  display: none;
}
img {
  border-style: none;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}
object {
  max-width: 100%;
}
a {
  text-decoration: none;
}
input,
select,
textarea {
  font-family: var(--body_typography-font-family, inherit);
  vertical-align: middle;
  color: #000;
}
select {
  border-style: solid;
}
fieldset,
form {
  margin: 0;
  padding: 0;
  border-style: none;
}
.wp-caption {
  max-width: 100%;
}
.search-table {
  display: table;
  width: 100%;
}
#fallback-slide {
  display: none;
}
#fallback-slide img {
  width: 100%;
}
.gallery-box {
  background: #3d3c3c;
  padding: 0 10px;
}
.gallery-box img {
  width: 100%;
}
#wrapper .product-category a h2.woocommerce-loop-category__title,
.fusion-main-menu > ul > li > a .fusion-menu-description,
a,
a:after,
a:before {
  transition-property: color, background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}
.fusion-accordian .panel-title a .fa-fusion-box,
.woocommerce .social-share li .fontawesome-icon {
  transition-property: color, background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}
.gallery {
  margin: 0 auto;
  max-width: 1400px;
}
#main {
  padding: 55px 10px 45px;
  clear: both;
}
#main .columns h2 {
  margin: 0;
  line-height: 35px;
}
#main .container h2 {
  margin: 0;
}
#main .post h2 {
  color: #333;
  padding-bottom: 0;
}
#main .post h2 a {
  color: #333;
}
#main .sidebar.fusion-sticky-sidebar {
  padding: 0;
}
.col .heading {
  overflow: hidden;
  margin: 0 0 15px;
}
.col .heading img {
  float: left;
  margin: 0 10px 0 0;
}
.col p {
  margin: 0 0 23px;
}
h2 {
  color: #333;
  margin: 0 0 20px;
}
h2.entry-title {
  margin-top: 0;
  margin-bottom: 28px;
}
h1.entry-title {
  margin-top: 0;
  margin-bottom: 28px;
}
.avada-myaccount-data h2 {
  margin-top: 0;
}
.content-boxes .more {
  margin-top: 18px;
}
.content-boxes .heading .fontawesome-icon {
  float: left;
}
.content-boxes .heading-and-icon h2 {
  padding-left: 45px;
}
.content-boxes-icon-boxed.columns-5 .col {
  width: 12.8%;
}
.content-boxes-icon-boxed.columns-4 .col {
  width: 18%;
}
.content-boxes-icon-boxed.columns-3 .col {
  width: 26.65%;
}
.content-boxes-icon-boxed.columns-2 .col {
  width: 44%;
}
.content-boxes-icon-boxed.columns-1 .col {
  width: 96%;
}
.container {
  overflow: hidden;
}
.container .columns .col {
  width: 31.42857%;
  padding: 0 2.85714% 0 0;
}
.container .columns .col.last {
  margin-right: 0;
}
.container .col img {
  width: 100%;
}
.container .holder {
  overflow: hidden;
}
.container h3 a.active {
  text-decoration: none;
  color: #65bc7b;
}
.slider {
  overflow: hidden;
}
.review {
  width: 100%;
}
.meta {
  list-style: none;
  margin: 0 0 13px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 14px;
  color: #333;
}
.meta li {
  float: left;
  padding: 0 8px 0 9px;
  border-left: 1px solid #333;
}
.meta li:first-child {
  padding-left: 0;
  border-left: 0;
}
.widget_recent_entries .post-date {
  display: block;
}
.fusion-content-widget-area > section:last-child {
  margin-bottom: 0;
}
.fusion-content-widget-area .widget {
  margin-bottom: 45px;
  position: relative;
}
.fusion-content-widget-area .widget.flickr {
  overflow: hidden;
}
.fusion-content-widget-area .widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fusion-content-widget-area .widget li {
  margin: 0;
  padding: 0;
  display: block;
}
.fusion-content-widget-area .widget li a {
  display: inline;
}
.fusion-content-widget-area .widget .recentcomments,
.fusion-content-widget-area .widget li a,
.fusion-content-widget-area .widget_categories li {
  color: #838383;
}
.fusion-content-widget-area .widget .recentcomments,
.fusion-content-widget-area .widget_categories li {
  display: block;
}
.fusion-content-widget-area .widget .recentcomments a {
  background: 0 0;
  border: none;
}
.fusion-content-widget-area .contact_info .address {
  margin: 0 0 15px;
}
.fusion-content-widget-area .flickr-photo a {
  background: 0 0;
  float: left;
  border: 0;
}
.fusion-content-widget-area .widget_categories li a {
  display: block;
  border: 0;
  background: 0 0;
  padding: 0;
}
.fusion-content-widget-area .jtwt .jtwt_tweet:before {
  color: #d8d8d8;
}
.fusion-content-widget-area #comment-input input,
.fusion-content-widget-area #s {
  width: 100%;
  margin-right: 0;
}
.fusion-content-widget-area .widget_recent_entries ul {
  padding: 0;
}
.fusion-content-widget-area ul.list-icon {
  margin-left: 25px;
}
.fusion-content-widget-area
  .fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  a:hover {
  color: #333;
}
#content {
  width: 71.1702128%;
  float: left;
  min-height: 1px;
}
#content.full-width {
  width: 100%;
}
#posts-container {
  position: relative;
}
.single .comments-container {
  margin-top: 55px;
  clear: both;
}
.post {
  margin-bottom: 65px;
}
.single-post .post {
  margin-bottom: 0;
}
.post .image {
  margin-bottom: 40px;
}
.avada_faq .meta-info,
.single .post .meta-info {
  margin-bottom: 55px;
}
.search input {
  border: 1px solid #e7e6e6;
  padding: 8px 15px;
  font-size: 13px;
  color: #333;
  display: block;
}
.sidebar {
  width: 23.4042553%;
  float: right;
}
.sidebar.fusion-sticky-sidebar
  .fusion-sidebar-inner-content
  > section:last-child {
  margin-bottom: 0 !important;
}
.sidebar .widget {
  margin-bottom: 45px;
  position: relative;
}
.sidebar .widget.flickr {
  overflow: hidden;
}
.sidebar .widget .widget_title {
  margin: 0;
  padding: 0;
  font-size: 13px;
  margin-bottom: 18px;
  color: #333;
}
.sidebar .widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar .widget li {
  margin: 0;
  padding: 0;
  display: block;
}
.fusion-content-widget-area .contact_info p,
.fusion-widget-area .contact_info p {
  margin: 0;
}
.fusion-content-widget-area .s {
  width: 100%;
}
.jtwt li {
  background: 0 0;
}
.fusion-content-widget-area .jtwt .jtwt_tweet,
.jtwt .jtwt_tweet {
  position: relative;
  padding: 0;
  border: 0;
  margin-bottom: 30px;
  font-size: 13px;
  line-height: 20px;
  padding-left: 45px;
  padding-top: 0;
}
.jtwt .jtwt_tweet:before {
  position: absolute;
  top: 6px;
  margin-left: -45px;
  content: "\e605";
  font-family: awb-icons;
  font-size: 30px;
}
.jtwt .jtwt_tweet a {
  background: 0 0;
  color: #bfbfbf;
  display: inline;
  padding: 0;
  border: 0;
}
.jtwt .jtwt_tweet a:hover {
  color: #65bc7b;
}
.jtwt .jtwt_tweet .jtwt_tweet_text {
  margin-bottom: 0;
  margin-top: 0;
}
.recent-works-items {
  overflow: hidden;
}
.recent-works-items a {
  display: inline-block;
  padding: 3px 1.5px;
}
.fusion-columns-4 .recent-works-items a,
.fusion-columns-5 .recent-works-items a,
.fusion-columns-6 .recent-works-items a {
  max-width: 60px;
}
.bbp-pagination .bbp-pagination-links,
.fusion-pagination,
.page-links,
.pagination,
.woocommerce-pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  font-size: 9px;
  line-height: normal;
}
.page-links {
  margin-bottom: 40px;
  margin-top: 30px;
}
.bbp-pagination .bbp-pagination-links .current,
.bbp-pagination .bbp-pagination-links .pagination-next,
.bbp-pagination .bbp-pagination-links .pagination-prev,
.bbp-pagination .bbp-pagination-links a.inactive,
.bbp-pagination-links span.dots,
.bbp-topic-pagination .page-numbers,
.fusion-pagination .current,
.fusion-pagination .next,
.fusion-pagination .page-numbers,
.fusion-pagination .prev,
.page-links a,
.page-links > .page-number,
.pagination .current,
.pagination .pagination-next,
.pagination .pagination-prev,
.pagination a.inactive,
.woocommerce-pagination .current,
.woocommerce-pagination .next,
.woocommerce-pagination .page-numbers,
.woocommerce-pagination .prev {
  display: inline-flex;
  position: relative;
  float: none;
  margin: 0 4px;
  padding: 0;
  border: 1px solid;
  align-items: center;
  justify-content: center;
}
.fusion-pagination-circle .bbp-pagination .bbp-pagination-links .current,
.fusion-pagination-circle
  .bbp-pagination
  .bbp-pagination-links
  .pagination-next,
.fusion-pagination-circle
  .bbp-pagination
  .bbp-pagination-links
  .pagination-prev,
.fusion-pagination-circle .bbp-pagination .bbp-pagination-links a.inactive,
.fusion-pagination-circle .bbp-pagination-links span.dots,
.fusion-pagination-circle .bbp-topic-pagination .page-numbers,
.fusion-pagination-circle .fusion-pagination .current,
.fusion-pagination-circle .fusion-pagination .next,
.fusion-pagination-circle .fusion-pagination .page-numbers,
.fusion-pagination-circle .fusion-pagination .prev,
.fusion-pagination-circle .page-links a,
.fusion-pagination-circle .page-links > .page-number,
.fusion-pagination-circle .pagination .current,
.fusion-pagination-circle .pagination .pagination-next,
.fusion-pagination-circle .pagination .pagination-prev,
.fusion-pagination-circle .pagination a.inactive,
.fusion-pagination-circle .woocommerce-pagination .current,
.fusion-pagination-circle .woocommerce-pagination .next,
.fusion-pagination-circle .woocommerce-pagination .page-numbers,
.fusion-pagination-circle .woocommerce-pagination .prev {
  border-radius: 50%;
}
.bbp-pagination .bbp-pagination-links .current,
.fusion-pagination .current,
.page-links > .page-number,
.pagination .current,
.woocommerce-pagination .current {
  color: #fff;
}
.pagination.page-links a:hover {
  border-color: inherit;
}
.pagination .pagination-dots {
  display: inline-block;
  text-align: center;
}
.fusion-pagination .next,
.fusion-pagination .pagination-next,
.fusion-pagination .pagination-prev,
.fusion-pagination .prev,
.woocommerce-pagination .next,
.woocommerce-pagination .pagination-next,
.woocommerce-pagination .pagination-prev,
.woocommerce-pagination .prev {
  padding-left: 0;
  padding-right: 0;
}
.fusion-pagination .next,
.fusion-pagination .next:hover,
.fusion-pagination .prev,
.fusion-pagination .prev:hover,
.woocommerce-pagination .next,
.woocommerce-pagination .next:hover,
.woocommerce-pagination .prev,
.woocommerce-pagination .prev:hover {
  border: 0;
}
.fusion-show-pagination-text
  .bbp-pagination
  .bbp-pagination-links
  .pagination-next,
.fusion-show-pagination-text
  .bbp-pagination
  .bbp-pagination-links
  .pagination-prev,
.fusion-show-pagination-text .fusion-pagination .next,
.fusion-show-pagination-text .fusion-pagination .prev,
.fusion-show-pagination-text .pagination .pagination-next,
.fusion-show-pagination-text .pagination .pagination-prev,
.fusion-show-pagination-text .woocommerce-pagination .next,
.fusion-show-pagination-text .woocommerce-pagination .prev {
  border: none;
}
.fusion-show-pagination-text .bbp-pagination-links,
.fusion-show-pagination-text .pagination {
  margin-left: 1em;
  margin-right: 1em;
}
.fusion-show-pagination-text .fusion-pagination .next,
.fusion-show-pagination-text .fusion-pagination .pagination-next,
.fusion-show-pagination-text .fusion-pagination .pagination-prev,
.fusion-show-pagination-text .fusion-pagination .prev,
.fusion-show-pagination-text .woocommerce-pagination .next,
.fusion-show-pagination-text .woocommerce-pagination .pagination-next,
.fusion-show-pagination-text .woocommerce-pagination .pagination-prev,
.fusion-show-pagination-text .woocommerce-pagination .prev {
  padding: 0;
}
body.fusion-hide-pagination-text .page-text {
  display: none;
}
body.fusion-hide-pagination-text .pagination-next,
body.fusion-hide-pagination-text .pagination-prev {
  border-style: solid;
}
body.fusion-hide-pagination-text .pagination-next:after {
  right: auto;
}
body.fusion-hide-pagination-text.ltr .fusion-blog-pagination .pagination-prev,
body.fusion-hide-pagination-text.ltr .pagination-prev {
  margin-left: 0;
}
body.fusion-hide-pagination-text.ltr .fusion-blog-pagination .pagination-next,
body.fusion-hide-pagination-text.ltr .pagination-next {
  margin-right: 0;
}
body.fusion-hide-pagination-text.ltr .fusion-pagination .prev,
body.fusion-hide-pagination-text.ltr .woocommerce-pagination .prev {
  margin-left: 0;
}
body.fusion-hide-pagination-text.ltr .fusion-pagination .next,
body.fusion-hide-pagination-text.ltr .woocommerce-pagination .next {
  margin-right: 0;
}
body.fusion-hide-pagination-text .pagination-next:after,
body.fusion-hide-pagination-text .pagination-prev:before {
  position: static;
  transform: none;
}
body.fusion-hide-pagination-text .fusion-pagination .next,
body.fusion-hide-pagination-text .fusion-pagination .prev,
body.fusion-hide-pagination-text .woocommerce-pagination .next,
body.fusion-hide-pagination-text .woocommerce-pagination .prev {
  border-style: solid;
}
body.fusion-hide-pagination-text .fusion-pagination .next:after,
body.fusion-hide-pagination-text .fusion-pagination .prev:before,
body.fusion-hide-pagination-text .woocommerce-pagination .next:after,
body.fusion-hide-pagination-text .woocommerce-pagination .prev:before {
  position: static;
  transform: none;
}
.img-list {
  overflow: hidden;
}
.img-list li {
  padding: 5px;
  background: 0 0;
  float: left;
}
.img-list a {
  background: 0 0;
  height: auto;
  width: auto;
  text-indent: 0;
  border: 0;
}
.about-author {
  margin-top: 55px;
  overflow: hidden;
  clear: both;
}
.about-author .title a {
  color: #65bc7b;
}
.about-author div.avatar {
  float: left;
  margin-right: 20px;
  margin-bottom: 5px;
}
.related-posts {
  margin-top: 55px;
}
.related-posts .image {
  width: 180px;
  height: 138px;
  margin-bottom: 0;
}
.single-related-posts {
  clear: both;
}
.commentlist {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 65px;
}
.commentlist .children {
  list-style: none outside none;
}
.commentlist li {
  margin: 0;
  padding: 0;
}
.commentlist .the-comment {
  border-bottom: 1px solid #e7e6e6;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.commentlist .the-comment .comment-text {
  margin-left: 69px;
}
.commentlist .the-comment .comment-text :last-child {
  margin-bottom: 0;
}
.commentlist div.avatar {
  float: left;
  margin-right: 15px;
}
.commentlist .comment-author strong {
  padding-right: 5px;
}
#respond {
  margin-top: 40px;
}
.comments-container #respond {
  margin-bottom: 40px;
}
#s,
.comment-form input {
  border: 1px solid #d2d2d2;
  min-width: 28%;
  font-size: 13px;
  color: #747474;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}
.comment-form #comment-submit {
  margin: 0;
  min-width: auto;
}
.comment-form input,
.s {
  float: none;
}
.comment-form-comment textarea {
  border: 1px solid #d2d2d2;
  width: 100%;
  height: 150px;
  font-size: 13px;
  color: #747474;
  padding: 12px 15px;
}
.comment-form .comment-form-cookies-consent {
  margin-top: 20px;
}
.comment-form .comment-form-cookies-consent input {
  min-width: auto;
}
.comment-form .form-submit {
  margin-top: 20px;
  margin-bottom: 0;
}
#ui-datepicker-div,
.ui-datepicker-div,
.ui-datepicker-inline {
  border-color: #e9eaee;
}
.ui-datepicker-header select,
table.ui-datepicker td a {
  background-color: #e9eaee;
}
.ui-datepicker-header select {
  border: 1px solid #d2d2d2;
}
#tribe-bar-form input[type="text"],
.input-text,
.tribe-bar-disabled #tribe-bar-form .tribe-bar-filters input[type="text"],
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="phone-number"],
input[type="text"],
input[type="time"] {
  border: 1px solid #d2d2d2;
  font-size: 13px;
  color: #747474;
  padding: 8px 15px;
  width: 100%;
  box-sizing: border-box;
}
.select2-container .select2-choice,
.select2-results .select2-result-label {
  padding-left: 15px;
  padding-right: 15px;
}
#tribe-bar-form input[type="text"]::-webkit-input-placeholder {
  font-style: normal;
}
#tribe-bar-form input[type="text"]:-moz-placeholder {
  font-style: normal;
}
#tribe-bar-form input[type="text"]::-moz-placeholder {
  font-style: normal;
}
#shipping_method input[type="radio"],
.input-radio {
  background: 0 0;
  -webkit-appearance: checkbox;
  appearance: checkbox;
}
.single-navigation {
  min-height: 34px;
  line-height: 34px;
  border-top: 1px solid #e7e6e6;
  border-bottom: 1px solid #e7e6e6;
  margin-bottom: 60px;
  text-align: right;
}
.single-navigation a {
  display: inline-block;
  position: relative;
}
.single-navigation a[rel="next"] {
  margin-left: 15px;
  margin-right: 1em;
}
.fusion-pagination .prev:before,
.pagination-prev:before,
.single-navigation a[rel="prev"]:before,
.woocommerce-pagination .prev:before {
  position: absolute;
  top: 50%;
  transform: translateY(-51%);
  left: -1em;
  font-family: awb-icons;
  content: "\f104";
}
.fusion-pagination .next:after,
.pagination-next:after,
.single-navigation a[rel="next"]:after,
.woocommerce-pagination .next:after {
  position: absolute;
  top: 50%;
  transform: translateY(-51%);
  right: -1em;
  font-family: awb-icons;
  content: "\f105";
}
.side-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #ededed;
  margin-bottom: 20px;
}
.side-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.side-nav li {
  margin: 0;
  padding: 0;
}
.side-nav li a {
  border-bottom: 1px solid #ededed;
  display: block;
  height: auto;
  font-weight: 400;
  font-size: 14px;
  color: #333;
  line-height: 1.69em;
  padding: 8px 0;
}
.side-nav li a:hover {
  background: 0 0;
}
.side-nav li.current_page_item > a {
  background: 0 0;
  color: #65bc7b;
}
.side-nav li.current_page_item li a {
  color: #333;
  border-bottom: 1px solid #ededed;
}
.side-nav .children {
  display: none;
  margin-left: 15px;
}
.side-nav .arrow {
  display: inline;
  margin-left: 0;
}
.side-nav .arrow:after {
  position: absolute;
  font-size: 14px;
  font-family: awb-icons;
}
.side-nav-left .side-nav {
  border-right: 1px solid #ededed;
}
.side-nav-left .side-nav li.current_page_item > a {
  border-right: 3px solid #65bc7b;
}
.side-nav-left .side-nav .arrow {
  margin-left: 10px;
}
.side-nav-left .side-nav .arrow:after {
  content: "\f105";
}
.side-nav-right .side-nav {
  border-left: 1px solid #ededed;
}
.side-nav-right .side-nav li.current_page_item > a {
  padding-left: 23px;
  border-left: 3px solid #65bc7b;
}
.side-nav-right .side-nav li a {
  padding-left: 25px;
}
.side-nav-right .side-nav .arrow {
  margin-right: 10px;
}
.side-nav-right .side-nav .arrow:after {
  content: "\f104";
}
.side-nav-right .side-nav .children {
  margin-left: 40px;
}
.side-nav-right .side-nav .children a {
  padding-left: 0;
}
.woocommerce-tabs .tabs a:hover .arrow,
.woocommerce-tabs .tabs li.active a .arrow {
  display: inline-block;
  margin-left: 10px;
  padding-right: 0;
}
.woocommerce-tabs .panel .panel {
  margin-left: auto;
  padding: 0;
}
h5.toggle {
  overflow: hidden;
  margin: 0;
}
h5.toggle a {
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  color: #333;
  border-bottom: 1px solid #e8e6e6;
  overflow: hidden;
  margin: 0;
  padding: 10px 0;
  float: left;
  width: 100%;
}
h5.toggle.active a {
  border-bottom: 0;
}
h5.toggle span.arrow {
  background-color: #333;
  display: block;
  width: 22px;
  height: 22px;
  float: left;
  margin-right: 18px;
}
h5.toggle.active span.arrow {
  background-color: #65bc7b;
}
h5.toggle span.toggle-title {
  float: left;
  width: 90%;
}
.toggle-content {
  padding-top: 10px;
  padding-bottom: 15px;
  position: relative;
}
h5.toggle.active + .toggle-content {
  border-bottom: 1px solid #e8e6e6;
}
.clearboth {
  clear: both;
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  width: 100%;
  overflow: hidden;
}
.shortcode-tabs {
  margin-bottom: 50px;
}
.shortcode-tabs .tab-hold .tabs li {
  border-right: 0;
  box-sizing: border-box;
}
.shortcode-tabs .tab-hold .tabs li a {
  display: block;
  width: 100%;
  padding: 0;
  text-indent: 15px;
}
.shortcode-tabs .tab-hold .tabs li.active a {
  background: 0 0;
}
.shortcode-tabs .tab_content {
  padding: 15px;
  overflow: hidden;
}
.shortcode-tabs .tab_content :last-child {
  margin-bottom: 0;
}
.fusion-post-content p,
.post-content p {
  margin: 0 0 20px;
}
.fusion-post-content ul.arrow,
.post-content ul.arrow {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fusion-post-content ul.arrow li,
.post-content ul.arrow li {
  line-height: 18px;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  padding-left: 25px;
}
.fusion-post-content ul.arrow li:before,
.post-content ul.arrow li:before {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #65bc7b;
  height: 18px;
  border-radius: 75px;
  width: 18px;
  position: absolute;
  font-size: 14px;
  content: "\f105";
  float: left;
  margin-right: 0;
  margin-left: -25px;
  font-family: awb-icons;
  text-align: center;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  line-height: 17px;
  text-indent: 1px;
}
.fusion-post-content blockquote,
.post-content blockquote {
  background: #f5f5f5;
  font: 13px/20px PTSansItalic, arial, helvetica, sans-serif;
  color: #444;
  border-left: 4px solid #65bc7b;
  padding: 15px;
  margin-top: 2em;
  margin-bottom: 2em;
}
.fusion-post-content blockquote :last-child,
.post-content blockquote :last-child {
  margin-bottom: 0;
}
.reading-box-container {
  margin: 0 0 84px;
}
.reading-box {
  padding: 28px 28px 27px 31px;
  border-left: 3px solid #65bc7b;
  background: #f2efef;
}
.continue {
  float: right;
}
.avada-google-map {
  height: 416px;
  margin-bottom: 50px;
  overflow: hidden;
}
#gmap img,
.shortcode-map img {
  max-width: none;
}
.flickr_badge_image img,
.pyre_tabs img,
.recent-works-items img {
  transition: all 0.2s;
}
.flickr_badge_image img:hover,
.pyre_tabs img:hover,
.recent-works-items img:hover {
  opacity: 0.5;
}
.error-page .useful-links {
  width: 30.66%;
  padding-left: 5%;
}
.error-page .oops {
  font-size: 117px;
  margin: 0 0 20px;
  line-height: 117px;
  font-weight: bolder;
  color: #f0f0f0;
  font-family: "PT Sans", Arial, Helvetica, sans-serif;
}
.has-sidebar .error-page .oops {
  font-size: 90px;
  line-height: 90px;
}
.error_page .oops h1 {
  font-family: "PT Sans", Arial, Helvetica, sans-serif;
}
.fontawesome-icon {
  display: inline-block;
  float: left;
}
.aligncenter .fontawesome-icon,
.fusion-alignleft .fontawesome-icon,
.fusion-alignright .fontawesome-icon {
  float: none;
}
.fontawesome-icon {
  margin-right: 10px;
}
.fontawesome-icon.circle-yes {
  border-radius: 75px;
  border-width: 1px;
  border-style: solid;
  background-color: #333;
  color: #fff;
  text-align: center;
  margin-top: 2px;
}
.fontawesome-icon.large {
  font-size: 40px;
}
.fontawesome-icon.large.circle-yes {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
.fontawesome-icon.medium {
  font-size: 18px;
}
.fontawesome-icon.medium.circle-yes {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.fontawesome-icon.small {
  font-size: 10px;
}
.fontawesome-icon.small.circle-yes {
  width: 18px;
  height: 18px;
  line-height: 19px;
}
.fontawesome-icon.circle-no.large {
  margin-top: 2px;
}
.fontawesome-icon.circle-no.medium {
  margin-top: 3px;
}
.fontawesome-icon.circle-no.small {
  margin-top: 5px;
}
.icon-heading .fontawesome-icon {
  margin-top: -6px;
}
.main-flex {
  overflow: hidden;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.widget_layered_nav li a:before,
.widget_product_categories li a:before,
.woocommerce-tabs .tabs a:hover .arrow:after,
.woocommerce-tabs .tabs li.active a .arrow:after {
  position: absolute;
  font-family: awb-icons;
  content: "\f105";
  margin-left: -12px;
}
.fusion-widget-area .widget .recentcomments:before,
.fusion-widget-area .widget_archive li a:before,
.fusion-widget-area .widget_categories li a:before,
.fusion-widget-area .widget_links li a:before,
.fusion-widget-area .widget_meta li a:before,
.fusion-widget-area .widget_nav_menu li a:before,
.fusion-widget-area .widget_pages li a:before,
.fusion-widget-area .widget_recent_entries li a:before,
.fusion-widget-area .widget_rss li a:before {
  position: absolute;
  font-family: awb-icons;
  content: "\f105";
  margin-left: -12px;
}
.fusion-widget-area .widget .recentcomments,
.fusion-widget-area .widget_archive li,
.fusion-widget-area .widget_categories li,
.fusion-widget-area .widget_layered_nav li,
.fusion-widget-area .widget_links li,
.fusion-widget-area .widget_meta li,
.fusion-widget-area .widget_nav_menu li,
.fusion-widget-area .widget_pages li,
.fusion-widget-area .widget_product_categories li,
.fusion-widget-area .widget_recent_entries li,
.fusion-widget-area .widget_rss li {
  display: block;
  padding: 10px 10px 10px 12px;
  border-bottom: 1px solid #e7e6e6;
  position: relative;
}
.fusion-widget-area .widget_categories li {
  position: relative;
}
.fusion-widget-area .widget_rss .rss-date {
  display: block;
  margin: 0.15em 0;
}
.fusion-widget-area .widget_categories li span {
  position: absolute;
}
.fusion-widget-area .widget_categories .children {
  margin-left: 5px;
  padding-top: 10px;
}
.fusion-widget-area .widget_categories li .children li:last-child,
.fusion-widget-area .widget_categories li .sub-menu li:last-child,
.fusion-widget-area .widget_nav_menu li .children li:last-child,
.fusion-widget-area .widget_nav_menu li .sub-menu li:last-child,
.fusion-widget-area .widget_pages li .children li:last-child,
.fusion-widget-area .widget_pages li .sub-menu li:last-child,
.fusion-widget-area .widget_product_categories li .children li:last-child,
.fusion-widget-area .widget_product_categories li .sub-menu li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.shortcode-tabs .tab_content ul.arrow {
  float: none;
}
.fusion-widget-area ul.arrow {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fusion-widget-area ul.arrow li:before {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #65bc7b;
  height: 18px;
  border-radius: 75px;
  width: 18px;
  content: " ";
  float: left;
  margin-right: 10px;
  margin-left: 0;
}
.fusion-widget-area ul.arrow li {
  line-height: 18px;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  padding-left: 0;
}
.fusion-widget-area .children,
.fusion-widget-area .sub-menu {
  margin-left: 15px;
}
.fusion-widget-area .widget_nav_menu .sub-menu {
  padding-top: 10px;
}
.fusion-widget-area .fusion-vertical-menu-widget .children,
.fusion-widget-area .fusion-vertical-menu-widget .sub-menu {
  margin-left: 0;
}
.widget_categories li {
  background: 0 0;
  padding: 0;
  border-bottom: 0;
}
.fusion-widget-area .widget > ul > li:first-child,
.fusion-widget-area .widget_nav_menu > div > ul > li:first-child {
  padding-top: 0;
}
.widget_wooslider_slideshow_slides li a {
  background: 0 0;
  border: 0;
  margin: 0;
  padding: 0;
}
.fusion-widget-area .flexslider .slides > li {
  padding-top: 0;
  padding-bottom: 0;
}
.chat-icon {
  display: inline-block;
  width: 26px;
  height: 19px;
  margin-top: 9px;
}
.blog-shortcode h3.timeline-title {
  color: #747474;
  font-size: 13px;
}
.reading-box-container {
  position: relative;
  z-index: 1;
}
.reading-box {
  position: relative;
}
.tagline-shadow:after,
.tagline-shadow:before {
  bottom: 15px;
  left: 10px;
  width: 50%;
  height: 20%;
  max-width: 300px;
  max-height: 100px;
  position: absolute;
  content: "";
  z-index: -1;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  transform: rotate(-3deg);
}
.tagline-shadow:after {
  right: 10px;
  left: auto;
  transform: rotate(3deg);
}
.tooltip-shortcode {
  position: relative;
  display: inline;
  cursor: pointer;
  color: #747474;
}
ul.list-icon {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 10px;
}
ul.list-icon li:before {
  display: inline-block;
  height: 18px;
  width: 18px;
  content: " ";
  float: left;
  margin-right: 0;
  margin-left: -25px;
  font-family: awb-icons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
}
ul.list-icon-arrow li:before {
  content: "\f105";
}
ul.circle-yes li:before {
  border-radius: 75px;
  text-align: center;
  font-size: 9px;
}
ul.list-icon-color-light li:before {
  color: #fff;
}
ul.list-icon li {
  line-height: 18px;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  padding-left: 25px;
}
.fusion-widget-area ul.list-icon a {
  border: medium none;
  padding: 0;
}
.fusion-post-content
  .wooslider
  .overlay-natural.layout-text-bottom
  .slide-excerpt {
  bottom: 15%;
}
.fusion-post-content
  .wooslider
  .overlay-natural.layout-text-top
  .slide-excerpt {
  top: 15%;
}
.fusion-post-content .wooslider .slide-excerpt .slide-title,
.fusion-post-content .wooslider .slide-excerpt .slide-title a {
  color: #fff;
}
.fusion-post-content .wooslider .overlay-full .slide-excerpt {
  padding: 50px;
  opacity: 0.7;
}
.fusion-post-content .wooslider .wooslider-control-thumbs {
  position: relative;
  width: auto;
  margin: 33px auto 0;
  text-align: center;
}
.fusion-post-content .wooslider .wooslider-control-thumbs li {
  float: none;
  width: 56px;
}
.fusion-post-content .wooslider .wooslider-next,
.fusion-post-content .wooslider .wooslider-prev {
  top: 50%;
  margin-top: -29px;
}
.fusion-post-content .wooslider-type-attachments .wooslider-next,
.fusion-post-content .wooslider-type-attachments .wooslider-prev {
  top: 50%;
  margin-top: -59px;
}
.fusion-post-content .wooslider .overlay-none .slide-excerpt {
  margin-top: 10px;
}
.fusion-post-content .wooslider .overlay-none .slide-excerpt .slide-title,
.fusion-post-content .wooslider .overlay-none .slide-excerpt .slide-title a {
  color: #333;
}
.tabs-vertical {
  border: 0;
}
.tabs-vertical .tabset {
  border-left: 1px solid #d3d3d3;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  width: 15.74468085%;
  float: left;
  height: auto;
  position: relative;
  left: 1px;
  z-index: 10;
}
.tabs-vertical .tab-hold .tabset li {
  float: none;
  width: auto;
  height: auto;
  border: 0;
  background: 0 0;
}
.tabs-vertical .tab-hold .tabset li a {
  width: auto;
  text-indent: 0;
  border-bottom: 1px solid #d3d3d3;
  height: auto;
  line-height: normal;
  padding: 10px;
}
.tabs-vertical .tab-hold .tabset li:last-child a {
  border-bottom: 0;
}
.tabs-vertical .tab-hold .tabset li.active a {
  background: #fff;
  border-width: 0;
  border-style: solid;
  border-left-width: 3px;
  height: auto;
  line-height: normal;
}
.tabs-vertical .tabs-container {
  width: 83.93617021%;
  float: left;
}
.tabs-vertical .tabs-container .tab_content {
  border: 1px solid #d3d3d3;
  padding: 30px;
}
.error-message {
  color: #f0f0f0;
  font-size: 170px;
  line-height: 1;
  font-weight: 700;
}
.mejs-container.mejs-video {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.mejs-overlay,
.mejs-poster {
  width: 100%;
  height: 100%;
}
.mejs-mediaelement video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.mejs-container,
.mejs-embed,
.mejs-embed body {
  background: 0 0;
}
.fusion-sliding-bar-area .widget_shopping_cart_content .buttons {
  background: rgba(0, 0, 0, 0.4);
}
.fusion-sliding-bar-area .products-slider li .image {
  width: auto;
  height: auto;
}
.fusion-widget-area .products a {
  padding: 0;
  border: 0;
  background: 0 0;
}
.fusion-widget-area .products .product-title {
  margin: 0 0 10px;
}
.fusion-widget-area .products-slider a {
  border: 0;
  background: 0 0;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  display: inline-block;
  width: auto;
}
.fusion-widget-area .image-extras-content h3 {
  text-transform: none;
  margin-bottom: 5px;
}
.fusion-widget-area .product-category mark {
  background-color: transparent;
  color: inherit;
}
.fusion-widget-area .product-category h3 {
  margin-bottom: 0;
}
.fullwidthbanner-container {
  overflow: visible;
}
.tfs-slider .flex-caption {
  width: 97%;
}
.tfs-slider .flex-control-nav {
  bottom: 20px !important;
}
.tfs-slider .buttons a {
  margin: 0;
  display: inline-block;
}
.tp-simpleresponsive .slotholder,
.tp-simpleresponsive .slotholder img {
  -webkit-backface-visibility: visible;
  -webkit-transform: none;
}
#learndash_lessons .learndash_topic_dots {
  float: none;
}
.video-background {
  position: relative;
  overflow: hidden;
}
.video-bg {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.video-bg embed,
.video-bg iframe,
.video-bg object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-content {
  position: absolute;
  top: 0;
  padding: 15px;
  width: 100%;
}
.bbp-arrow {
  margin-top: -1px;
}
.fusion-animated {
  position: relative;
  z-index: 2000;
}
.fusion-social-links-footer
  .fusion-social-networks.boxed-icons
  a.fusion-social-network-icon.awb-custom-image {
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.fusion-social-links-footer
  .fusion-social-networks.boxed-icons
  a.fusion-social-network-icon.awb-custom-image
  img {
  position: relative;
}
.avada-container h3,
.comment-form input[type="submit"],
.fusion-body .fusion-load-more-button,
.project-content .project-info h4,
.review blockquote div strong,
.ticket-selector-submit-btn[type="submit"] {
  font-weight: 700;
}
.meta .fusion-date,
.post-content blockquote,
.review blockquote q {
  font-style: italic;
}
#wrapper .fusion-events-shortcode .fusion-events-meta h4,
.fusion-carousel-meta,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-product-buttons
  a,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-categories,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-categories
  a,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-gallery:before,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-link:before,
.fusion-recent-posts .columns .column .meta,
.fusion-rollover-linebreak,
.fusion-single-line-meta,
.product-buttons a {
  line-height: 1.5;
}
.ua-mobile .avada-not-responsive #wrapper {
  width: 100% !important;
  overflow: hidden !important;
}
.layout-wide-mode #wrapper {
  width: 100%;
  max-width: none;
}
#sliders-container {
  position: relative;
}
.layout-boxed-mode #sliders-container .main-flex[data-parallax="1"] {
  position: fixed;
}
.avada-video-embed {
  position: relative;
}
.avada-video-embed .fluid-width-video-wrapper {
  padding-top: 55%;
}
.avada-video-embed .fluid-width-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fusion-privacy-placeholder {
  padding: 30px;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.fusion-privacy-placeholder .fusion-privacy-placeholder-content {
  text-align: center;
}
.fusion-privacy-placeholder .fusion-privacy-consent {
  margin-top: 15px;
}
.fluid-width-video-wrapper .fusion-privacy-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.fusion-privacy-bar {
  padding: 15px 30px;
  position: fixed;
  width: 100%;
  z-index: 99999;
  display: none;
  max-height: 100vh;
  overflow: auto;
}
.fusion-privacy-bar .fusion-privacy-bar-acceptance {
  width: auto;
}
.fusion-privacy-bar-bottom {
  bottom: 0;
}
.fusion-privacy-bar .fusion-privacy-bar-main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -15px;
}
.fusion-privacy-bar .fusion-privacy-bar-main .fusion-privacy-bar-acceptance,
.fusion-privacy-bar .fusion-privacy-bar-main > span {
  margin: 0 15px;
}
.fusion-privacy-bar .fusion-privacy-bar-full {
  text-align: center;
  display: none;
  line-height: 1.5;
}
.fusion-privacy-bar .fusion-privacy-bar-full .fusion-privacy-choices {
  list-style: none;
  margin: 0.7em 0 0;
  padding: 0;
}
.fusion-privacy-bar .fusion-privacy-bar-full .fusion-column {
  text-align: initial;
}
.fusion-privacy-bar .fusion-privacy-bar-full .fusion-column .column-title {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.7em;
}
.searchform .fusion-search-form-content {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
.searchform .fusion-search-form-content .fusion-search-field {
  flex-grow: 1;
}
.searchform .fusion-search-form-content .fusion-search-field input {
  background-color: #fff;
  border: 1px solid #d2d2d2;
  color: #747474;
  font-size: 13px;
  padding: 8px 15px;
  height: 33px;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
}
.searchform
  .fusion-search-form-content
  .fusion-search-button
  input[type="submit"] {
  background: #000;
  border: none;
  border-radius: 0;
  color: #fff;
  font-size: 1em;
  height: 33px;
  line-height: 33px;
  margin: 0;
  padding: 0;
  width: 33px;
  text-indent: 0;
  cursor: pointer;
  font-family: awb-icons;
  font-weight: 400;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  transition: all 0.2s;
}
.fusion-search-element
  .searchform.fusion-search-form-clean
  .fusion-search-form-content,
.fusion-search-form-clean
  .searchform:not(.fusion-search-form-classic)
  .fusion-search-form-content {
  position: relative;
}
.fusion-search-element
  .searchform.fusion-search-form-clean
  .fusion-search-button,
.fusion-search-form-clean
  .searchform:not(.fusion-search-form-classic)
  .fusion-search-button {
  position: absolute;
}
.fusion-search-element
  .searchform.fusion-search-form-clean
  .fusion-search-button
  input[type="submit"],
.fusion-search-form-clean
  .searchform:not(.fusion-search-form-classic)
  .fusion-search-button
  input[type="submit"] {
  background-color: transparent;
  color: #aaa9a9;
}
.search-page-search-form-top {
  padding-bottom: 60px;
  border-bottom: 1px solid #e6e5e6;
  margin-bottom: 60px;
}
.search-page-search-form-bottom {
  padding-top: 60px;
  border-top: 1px solid #e6e5e6;
  margin-top: 60px;
}
.search-page-search-form .fusion-search-form {
  text-align: right;
}
.search-page-search-form
  .fusion-search-form
  .fusion-search-form
  .fusion-search-field
  input {
  text-indent: 0;
}
.fusion-main-menu-search-overlay .fusion-main-menu > .fusion-menu {
  display: flex;
  flex-wrap: wrap;
  transition: opacity 0.8s cubic-bezier(0.8, 0, 0.25, 1),
    transform 0.8s cubic-bezier(0.8, 0, 0.25, 1);
}
.fusion-main-menu-search-overlay .fusion-main-menu > .fusion-overlay-search {
  display: flex;
  visibility: hidden;
  align-items: center;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -150%);
  width: 100%;
  transition: opacity 0.8s cubic-bezier(0.8, 0, 0.25, 1),
    transform 0.8s cubic-bezier(0.8, 0, 0.25, 1);
}
.fusion-main-menu-search-overlay
  .fusion-main-menu
  > .fusion-overlay-search
  .fusion-search-form {
  flex-grow: 2;
}
.fusion-main-menu-search-overlay
  .fusion-main-menu
  > .fusion-overlay-search
  .fusion-close-search {
  overflow: hidden;
  position: relative;
  display: inline-block;
}
.fusion-main-menu-search-overlay
  .fusion-main-menu
  > .fusion-overlay-search
  .fusion-close-search:after,
.fusion-main-menu-search-overlay
  .fusion-main-menu
  > .fusion-overlay-search
  .fusion-close-search:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  width: 100%;
  margin-top: -1px;
}
.fusion-main-menu-search-overlay
  .fusion-main-menu
  > .fusion-overlay-search
  .fusion-close-search:before {
  transform: rotate(45deg);
}
.fusion-main-menu-search-overlay
  .fusion-main-menu
  > .fusion-overlay-search
  .fusion-close-search:after {
  transform: rotate(-45deg);
}
.fusion-main-menu-search-overlay .fusion-main-menu.search-open {
  overflow: visible;
}
.fusion-main-menu-search-overlay .fusion-main-menu.search-open > .fusion-menu {
  opacity: 0;
  transform: translateY(100%);
  visibility: hidden;
}
.fusion-main-menu-search-overlay
  .fusion-main-menu.search-open
  > .fusion-overlay-search {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%);
}
.fusion-main-menu-search-overlay .fusion-main-menu.search-transition {
  overflow: hidden !important;
}
.fusion-main-menu-search-overlay
  .fusion-main-menu.search-transition
  .fusion-menu {
  visibility: visible !important;
}
.fusion-main-menu-search-overlay
  .fusion-main-menu.search-transition
  > .fusion-overlay-search {
  visibility: visible;
}
.fusion-main-menu-search-overlay
  .fusion-header-v4.fusion-header-menu-align-right
  .fusion-overlay-search,
.fusion-main-menu-search-overlay
  .fusion-header-v5.fusion-header-menu-align-right
  .fusion-overlay-search {
  right: 0;
  left: auto;
  transform: translate(0, -150%);
}
.fusion-main-menu-search-overlay
  .fusion-header-v4.fusion-header-menu-align-right
  .search-open
  .fusion-overlay-search,
.fusion-main-menu-search-overlay
  .fusion-header-v5.fusion-header-menu-align-right
  .search-open
  .fusion-overlay-search {
  transform: translate(0, -50%);
}
.fusion-main-menu-search-overlay
  .fusion-header-v4.fusion-header-menu-align-left
  .fusion-overlay-search,
.fusion-main-menu-search-overlay
  .fusion-header-v5.fusion-header-menu-align-left
  .fusion-overlay-search {
  left: 0;
  transform: translate(0, -150%);
}
.fusion-main-menu-search-overlay
  .fusion-header-v4.fusion-header-menu-align-left
  .search-open
  .fusion-overlay-search,
.fusion-main-menu-search-overlay
  .fusion-header-v5.fusion-header-menu-align-left
  .search-open
  .fusion-overlay-search {
  transform: translate(0, -50%);
}
.fusion-overlay-search {
  position: absolute;
  opacity: 0;
}
.fusion-header-has-flyout-menu .fusion-flyout-search .fusion-live-search {
  position: relative;
}
.fusion-header-has-flyout-menu
  .fusion-flyout-search
  .fusion-live-search
  .fusion-search-form-content {
  position: relative;
}
.fusion-header-has-flyout-menu
  .fusion-flyout-search
  .fusion-live-search
  .fusion-search-form-content
  .fusion-search-button {
  display: block;
  position: absolute;
  right: 0;
  background-color: transparent;
}
.fusion-header-has-flyout-menu
  .fusion-flyout-search
  .fusion-live-search
  .fusion-search-form-content
  .fusion-search-button
  .fusion-search-submit {
  display: none;
}
.fusion-header-has-flyout-menu
  .fusion-flyout-search
  .fusion-live-search
  .fusion-search-form-content
  .fusion-slider-loading {
  margin: -1.75em 0 0 -3.5em;
  width: 3.5em;
  height: 3.5em;
  border-top-width: 0.4em;
  border-right-width: 0.4em;
  border-bottom-width: 0.4em;
  border-left-width: 0.4em;
}
.fusion-live-search .fusion-search-field {
  z-index: 14;
}
.fusion-live-search .fusion-search-button {
  z-index: 14;
  position: relative;
}
.fusion-live-search .fusion-slider-loading {
  display: none;
  margin: -1.2em 0 0 -1.2em;
  width: 2.4em;
  height: 2.4em;
  border-top: 0.3em solid rgba(255, 255, 255, 0.35);
  border-right: 0.3em solid rgba(255, 255, 255, 0.35);
  border-bottom: 0.3em solid #fff;
  border-left: 0.3em solid rgba(255, 255, 255, 0.35);
  pointer-events: none;
}
.fusion-live-search .fusion-search-results-wrapper {
  position: relative;
}
.fusion-flyout-search .fusion-live-search .fusion-search-results-wrapper {
  position: absolute;
  width: calc(100% - 100px);
  margin-top: 62px;
}
.fusion-live-search .fusion-search-results {
  z-index: -1;
  visibility: hidden;
  overflow-y: auto;
  position: absolute;
  margin-top: 25px;
  width: 100%;
  max-height: 250px;
  opacity: 0;
  transition: 0.3s opacity, 0.3s margin;
  background-color: #fff;
  border-style: solid;
}
.fusion-live-search .fusion-search-results.suggestions-added {
  z-index: 2001;
  visibility: visible;
  margin-top: 0;
  opacity: 1;
}
.fusion-live-search
  .fusion-search-results.suggestions-empty
  .fusion-search-result {
  padding: 14px;
  cursor: default;
}
.fusion-live-search
  .fusion-search-results.suggestions-empty
  .fusion-search-result:hover {
  padding: 14px;
}
.fusion-live-search .fusion-search-results.suggestions-transition {
  visibility: visible;
}
.fusion-live-search.fusion-main-menu-search-dropdown
  .fusion-main-menu
  .fusion-search-results.suggestions-added {
  position: static;
}
.fusion-live-search .fusion-search-result {
  display: flex;
  align-items: center;
  padding: 14px 7px;
  line-height: 1.4;
  border-bottom-style: solid;
  border-top: none;
  text-align: initial;
  cursor: pointer;
  transition: 0.2s padding;
}
.fusion-body .fusion-live-search .fusion-search-result {
  font-family: inherit;
}
.fusion-body .fusion-live-search .fusion-search-result:after {
  content: "";
}
.fusion-live-search .fusion-search-result:hover {
  padding: 14px 0 14px 14px;
}
.fusion-live-search .fusion-search-result:last-child {
  border-bottom: none;
}
.fusion-live-search .fusion-search-content,
.fusion-live-search .fusion-search-image {
  padding: 0 7px;
}
.fusion-live-search .fusion-search-image {
  max-width: 45px;
  box-sizing: content-box;
}
.fusion-main-menu .fusion-live-search .fusion-search-image {
  max-width: 35px;
}
.fusion-live-search .fusion-search-post-type {
  margin-top: 0.2em;
  font-size: 0.9em;
}
.fusion-author-widget .fusion-author-widget-left {
  text-align: left;
}
.fusion-author-widget .fusion-author-widget-center {
  text-align: center;
}
.fusion-author-widget .fusion-author-widget-right {
  text-align: right;
}
.fusion-author-widget .fusion-author-widget-name {
  margin-top: 20px;
}
.fusion-author-widget .fusion-social-networks {
  margin: -7px 0;
}
.fusion-author-widget
  .fusion-social-networks
  .fusion-social-networks-wrapper
  .fusion-social-network-icon {
  margin: 7px 5.5px;
}
.fusion-author-widget .fusion-social-networks.boxed-icons {
  margin: -5px 0;
}
.fusion-author-widget
  .fusion-social-networks.boxed-icons
  .fusion-social-networks-wrapper
  .fusion-social-network-icon {
  margin: 5px 3.5px;
}
.fusion-author-widget
  .fusion-author-widget-separator
  .fusion-author-widget-sep {
  border-top: 1px solid;
}
.fusion-author-widget .fusion-author-widget-sep {
  margin: 20px 0;
  height: 0;
}
.fusion-tabs-widget .fusion-tabs-widget-wrapper {
  border: 1px solid #eaeaea;
}
.fusion-tabs-widget .fusion-tabs-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.fusion-tabs-widget .fusion-tabs-nav ul li {
  flex: 1;
  margin: 0;
  padding: 0;
  border-right: 1px solid;
}
.fusion-tabs-widget .fusion-tabs-nav ul li:last-child {
  border-right: none;
}
.fusion-tabs-widget .fusion-tabs-nav ul li a {
  display: block;
  padding: 11px 0;
  border-top: 3px solid transparent;
  transition: all 0.2s;
  background: #f1f2f2;
  color: #333;
  font-size: 13px;
  text-align: center;
}
.fusion-footer-widget-area
  .fusion-widget-area
  .fusion-column
  .fusion-tabs-widget
  .fusion-tabs-nav
  ul
  li
  a {
  padding: 11px 0;
}
.fusion-tabs-widget .fusion-tabs-nav ul li.active a {
  border-top: 3px solid #65bc7b;
}
.fusion-tabs-widget .fusion-tabs-nav ul li .fusionicon-bubbles {
  font-size: 18px;
}
.fusion-tabs-widget .fusion-tabs-image-circle .image img {
  border-radius: 50%;
}
.fusion-tabs-widget .fusion-tabs-image-square .image img {
  border-radius: 0;
}
.fusion-tabs-widget .fusion-tabs-widget-content .fusion-tabs-widget-items {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fusion-tabs-widget .fusion-tabs-widget-content .fusion-tabs-widget-items li {
  overflow: hidden;
  padding: 20px 15px;
  border-bottom: 1px solid #e2e2e2;
}
.fusion-tabs-widget
  .fusion-tabs-widget-content
  .fusion-tabs-widget-items
  li:last-child {
  border-bottom: 0;
}
.fusion-tabs-widget .fusion-tabs-widget-content .image {
  float: left;
  margin-right: 13px;
  width: 52px;
}
.fusion-tabs-widget .fusion-tabs-widget-content .image a {
  height: auto;
  width: auto;
  line-height: normal;
  text-indent: 0;
}
.fusion-tabs-widget .fusion-tabs-widget-content .fusion-date-box {
  float: left;
  margin-right: 13px;
  padding: 7px 6px;
  min-width: 64px;
  text-align: center;
}
.fusion-tabs-widget .fusion-tabs-widget-content .fusion-date-box .fusion-date {
  font-size: 1.3em;
  line-height: 1;
  font-weight: 700;
}
.fusion-tabs-widget
  .fusion-tabs-widget-content
  .fusion-date-box
  .fusion-month-year {
  margin-top: 0.5em;
  line-height: 1;
  font-size: 0.85em;
}
.fusion-tabs-widget .fusion-tabs-widget-content .post-holder a {
  display: block;
  overflow: hidden;
  line-height: normal;
}
.fusion-tabs-widget .fusion-tabs-widget-content .post-holder p {
  margin: 0 0 2px;
}
.fusion-tabs-widget .fusion-tabs-widget-content .post-holder .fusion-meta {
  margin-top: 2px;
}
.fusion-tabs-widget
  .fusion-tabs-widget-content
  .post-holder
  .fusion-meta
  p
  > a {
  display: inline;
}
.flickr_badge_image {
  display: inline-block;
  padding: 3px;
}
.flickr_badge_image a {
  display: inline-block;
}
.flickr_badge_image img {
  width: 66px;
  height: 66px;
}
.fusion-columns-4 .flickr_badge_image img,
.fusion-columns-5 .flickr_badge_image img,
.fusion-columns-6 .flickr_badge_image img {
  width: 60px;
  height: 60px;
}
.fusion-tabs-widget .fusion-tabs-clean.fusion-tabs-widget-wrapper {
  border: none;
}
.fusion-tabs-widget
  .fusion-tabs-clean.fusion-tabs-widget-wrapper
  .fusion-tabs-nav {
  margin-bottom: 28px;
}
.fusion-tabs-widget
  .fusion-tabs-clean.fusion-tabs-widget-wrapper
  .fusion-tabs-nav
  ul
  li
  a {
  padding: 14px 0;
  border-top: none;
}
.fusion-footer-widget-area
  .fusion-widget-area
  .fusion-column
  .fusion-tabs-widget
  .fusion-tabs-clean.fusion-tabs-widget-wrapper
  .fusion-tabs-nav
  ul
  li
  a {
  padding: 14px 0;
}
.fusion-tabs-widget
  .fusion-tabs-clean.fusion-tabs-widget-wrapper
  .fusion-tabs-nav
  ul
  li.active
  a {
  border-top: none;
}
.fusion-tabs-widget
  .fusion-tabs-clean.fusion-tabs-widget-wrapper
  .fusion-tabs-widget-content {
  background: 0 0;
}
.fusion-tabs-widget
  .fusion-tabs-clean.fusion-tabs-widget-wrapper
  .fusion-tabs-widget-content
  .fusion-tabs-widget-items
  li {
  display: flex;
  align-items: center;
  padding: 13px 0;
  border-bottom: none;
}
.fusion-tabs-widget
  .fusion-tabs-clean.fusion-tabs-widget-wrapper
  .fusion-tabs-widget-content
  .fusion-date-box,
.fusion-tabs-widget
  .fusion-tabs-clean.fusion-tabs-widget-wrapper
  .fusion-tabs-widget-content
  .image {
  flex-shrink: 0;
}
.fusion-sliding-bar-area .fusion-tabs-widget .fusion-tabs-nav ul li a {
  background: rgba(0, 0, 0, 0.2);
}
.fusion-sliding-bar-area .fusion-tabs-widget .fusion-tabs-nav ul li a:hover {
  background: 0 0;
}
.fusion-sliding-bar-area .fusion-tabs-widget .fusion-tabs-nav ul li.active a {
  background: 0 0;
}
.fusion-sliding-bar-area
  .fusion-tabs-widget
  .fusion-tabs-widget-content
  .fusion-date-box {
  background: rgba(0, 0, 0, 0.2);
}
.flex-container a:active,
.flex-container a:focus,
.flexslider a:active,
.flexslider a:focus {
  outline: 0;
}
.flex-control-nav,
.flex-direction-nav,
.flexslider .slides {
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1000;
}
.flexslider {
  margin: 0;
  padding: 0;
}
.flexslider .slides {
  overflow: hidden;
}
.flexslider .slides li {
  display: none;
  -webkit-backface-visibility: hidden;
}
.flexslider .slides img {
  width: 100%;
  max-width: 100%;
  display: block;
  -webkit-user-select: none;
  user-select: none;
}
.flexslider .slides .video-shortcode {
  margin-bottom: 0;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
.flexslider .slides:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}
.flex-control-nav,
.flex-direction-nav,
.flexslider .slides {
  z-index: 2;
}
.flexslider {
  margin: 0 0 60px;
  background-color: transparent;
  position: relative;
}
.flex-viewport {
  max-height: 2000px;
  transition: all 1s ease;
}
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  height: 0;
}
.flex-direction-nav a {
  font-family: awb-icons;
  width: 30px;
  height: 30px;
  margin: -20px 0 0;
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 100;
  overflow: hidden;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: none;
  color: #fff;
}
.flex-direction-nav a:hover {
  color: #fff;
}
.sidebar .widget .flex-direction-nav a,
.sidebar .widget .flex-direction-nav a:hover {
  color: #fff;
}
.no-opacity .flex-direction-nav a {
  display: none;
}
.no-opacity .flexslider:hover .flex-direction-nav a {
  display: block;
}
.flex-direction-nav .flex-next {
  right: 0;
  text-indent: 2px;
}
.flex-direction-nav .flex-prev {
  left: 0;
  text-indent: -2px;
}
.flexslider:hover .flex-next {
  opacity: 0.8;
  right: 0;
}
.flexslider:hover .flex-prev {
  opacity: 0.8;
  left: 0;
}
.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
  opacity: 1;
}
.flex-direction-nav .flex-disabled,
.main-flex:hover .flex-direction-nav .flex-disabled {
  opacity: 0;
  cursor: default;
  display: none;
  visibility: hidden;
}
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
}
.fusion-body .flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
}
.fusion-body .flex-control-paging li a {
  display: block;
}
.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}
.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}
.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}
.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}
.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.2s cubic-bezier(0.21, 0.6, 0.35, 1);
}
.flex-control-thumbs img:hover {
  opacity: 1;
}
.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}
.flex-caption {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 96%;
  padding: 2%;
  color: #fff;
  left: 0;
  bottom: 0;
  margin: 0;
}
.main-flex {
  margin-bottom: 0;
}
.col .flexslider {
  margin: 0;
}
.isotope {
  transition-duration: 0.8s;
  transition-property: height, width;
}
.isotope-item {
  z-index: 2;
}
.isotope-hidden.isotope-item {
  z-index: 1;
}
.isotope .isotope-item {
  transition-duration: 0.8s;
  transition-property: top, left, opacity;
}
.isotope .isotope-item.no-transition,
.isotope.no-transition,
.isotope.no-transition .isotope-item {
  transition-duration: 0s;
}
.fusion-post-content .wooslider {
  border: 0;
  background: 0 0;
  box-shadow: none;
  border-radius: 0;
  margin: 0 auto;
  max-width: 940px;
}
.wooslider .slide-content {
  margin: 0;
}
.wooslider .wooslider-pauseplay {
  position: absolute;
  margin: 0;
  top: auto;
  bottom: 15px;
}
.wooslider .wooslider-control-nav {
  position: absolute;
  margin: 0;
  top: auto;
  bottom: -10px;
}
.tfs-slider .flex-next,
.tfs-slider .flex-prev {
  font-size: 25px;
  -webkit-font-smoothing: antialiased;
}
.main-flex:hover .flex-next,
.main-flex:hover .flex-prev,
.wooslider:hover .wooslider-next,
.wooslider:hover .wooslider-prev {
  display: block;
}
.main-flex .flex-next,
.main-flex .flex-prev,
.wooslider .wooslider-next,
.wooslider .wooslider-prev {
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  width: 63px;
  height: 63px;
  display: none;
  position: absolute;
  top: 42%;
  margin: 0;
  color: #fff;
  font-family: awb-icons;
  text-align: center;
  line-height: 63px;
  font-size: 36px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
.main-flex .flex-prev,
.wooslider .wooslider-prev {
  left: 0;
  padding: 0;
  border: 0;
  text-indent: -5px;
}
.main-flex .flex-next,
.wooslider .wooslider-next {
  right: 0;
  padding: 0;
  border: 0;
  text-indent: 5px;
}
#sliders-container .wooslider .slide-content p {
  margin: 0;
}
.fusion-footer-widget-area .wooslider-control-nav li a,
.sidebar .wooslider-control-nav li a {
  padding: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  margin-right: 3px;
}
.fusion-footer-widget-area .wooslider-next,
.fusion-footer-widget-area .wooslider-prev,
.fusion-post-content .wooslider .wooslider-next,
.fusion-post-content .wooslider .wooslider-prev,
.sidebar .wooslider-next,
.sidebar .wooslider-prev {
  width: 29px;
  height: 29px;
}
.fusion-footer-widget-area .wooslider .wooslider-control-nav,
.sidebar .wooslider .wooslider-control-nav {
  bottom: 5px;
}
.fusion-footer-widget-area
  .widget_wooslider_slideshow_slides
  .wooslider-control-nav,
.sidebar .widget_wooslider_slideshow_slides .wooslider-control-nav {
  bottom: 12px;
}
.fusion-footer-widget-area .slide-excerpt {
  overflow: hidden;
  position: absolute;
  bottom: -50px;
  padding: 0;
  width: 100%;
  text-align: center;
}
.sidebar .slide-excerpt {
  bottom: 15%;
  width: 100%;
}
.sidebar .slide-excerpt .slide-title {
  float: none;
}
.sidebar .slide-excerpt .slide-title a {
  color: #fff;
  border: none;
}
.sidebar .slide-excerpt .slide-title a:hover {
  color: #fff;
}
.sidebar .widget .slides li div a {
  padding: 0;
}
.layout-boxed-mode.fusion-top-header.avada-has-boxed-modal-shadow-light
  .fusion-boxed-shadow,
.layout-boxed-mode.fusion-top-header.avada-has-boxed-modal-shadow-light:not(.avada-footer-fx-sticky)
  #wrapper {
  box-shadow: 0 0 50px 1px rgba(0, 0, 0, 0.22);
}
.layout-boxed-mode.fusion-top-header.avada-has-boxed-modal-shadow-medium
  .fusion-boxed-shadow,
.layout-boxed-mode.fusion-top-header.avada-has-boxed-modal-shadow-medium:not(.avada-footer-fx-sticky)
  #wrapper {
  box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.35);
}
.layout-boxed-mode.fusion-top-header.avada-has-boxed-modal-shadow-hard
  .fusion-boxed-shadow,
.layout-boxed-mode.fusion-top-header.avada-has-boxed-modal-shadow-hard:not(.avada-footer-fx-sticky)
  #wrapper {
  box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.55);
}
.layout-boxed-mode:not(.fusion-top-header).avada-has-boxed-modal-shadow-light
  #boxed-wrapper,
.layout-boxed-mode:not(.fusion-top-header).avada-has-boxed-modal-shadow-light
  .fusion-boxed-shadow {
  box-shadow: 0 0 50px 1px rgba(0, 0, 0, 0.22);
}
.layout-boxed-mode:not(.fusion-top-header).avada-has-boxed-modal-shadow-medium
  #boxed-wrapper,
.layout-boxed-mode:not(.fusion-top-header).avada-has-boxed-modal-shadow-medium
  .fusion-boxed-shadow {
  box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.35);
}
.layout-boxed-mode:not(.fusion-top-header).avada-has-boxed-modal-shadow-hard
  #boxed-wrapper,
.layout-boxed-mode:not(.fusion-top-header).avada-has-boxed-modal-shadow-hard
  .fusion-boxed-shadow {
  box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.55);
}
.layout-boxed-mode.side-header #boxed-wrapper,
.layout-boxed-mode.side-header #slidingbar-area .fusion-row,
.layout-boxed-mode.side-header .fusion-footer-parallax,
.layout-boxed-mode.side-header > #lang_sel_footer {
  margin-right: auto;
  margin-left: auto;
}
.fusion-row {
  margin: 0 auto;
}
.fusion-row:after,
.fusion-row:before {
  content: " ";
  display: table;
}
.fusion-row:after {
  clear: both;
}
.fusion-columns {
  margin: 0 -15px;
}
.fusion-columns-5 .col-lg-2,
.fusion-columns-5 .col-md-2,
.fusion-columns-5 .col-sm-2,
.fusion-columns-5 .col-xs-2 {
  width: 20%;
}
.fusion-block-element {
  display: block;
}
.fusion-hidden {
  display: none;
}
.fusion-responsive-img {
  margin: 0;
}
#wrapper #posts-container .fusion-grid-sizer,
#wrapper .fusion-grid-sizer {
  margin: 0;
  padding: 0;
  height: 0;
  min-height: 0;
  visibility: hidden;
}
.fusion-masonry-element-container {
  background-size: cover;
  background-position: center center;
}
.fusion-element-grid .fusion-masonry-element-container {
  padding-top: 80%;
}
.fusion-element-landscape .fusion-masonry-element-container {
  padding-top: 40%;
}
.fusion-element-portrait .fusion-masonry-element-container {
  padding-top: 160%;
}
.fusion-masonry-element-container .fusion-image-wrapper > img,
.fusion-masonry-element-container .fusion-placeholder-image,
.fusion-masonry-element-container > img {
  display: none;
}
bold,
strong {
  font-weight: bolder;
}
.fusion-video iframe,
iframe {
  border: none;
}
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
.screen-reader-text:focus {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  color: #333;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}
mark {
  background: 0 0;
  color: inherit;
}
.fusion-breadcrumb-prefix {
  padding-right: 5px;
}
.fusion-breadcrumb-sep {
  padding: 0 5px;
}
.fusion-breadcrumb-sep:last-child {
  display: none;
}
.post-password-form input[type="password"] {
  margin: 0 10px;
  padding: 8px 15px;
  width: 200px;
  border: 1px solid #d2d2d2;
  font-size: 13px;
  color: #747474;
  box-sizing: border-box;
}
.fusion-image-wrapper {
  overflow: hidden;
  z-index: 1;
  position: relative;
}
.fusion-image-wrapper:hover .fusion-rollover {
  opacity: 1;
  transform: translateX(0);
}
.no-csstransforms .fusion-image-wrapper:hover .fusion-rollover {
  display: block;
}
.fusion-image-wrapper.fusion-video {
  display: block;
}
.fusion-image-size-fixed {
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}
.fusion-rollover {
  display: flex;
  align-items: center;
  justify-content: space-around;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: normal;
  text-align: center;
  background-image: linear-gradient(to top, #aad75d, #d1e990);
  transform: translateX(-100%);
  transition: all 0.3s ease-in-out;
  -webkit-transform-style: preserve-3d;
}
.no-csstransforms .fusion-rollover {
  display: none;
}
.fusion-clean-product-image-wrapper .fusion-rollover-content .view-cart {
  position: relative;
  z-index: 9;
}
.fusion-woo-slider .fusion-carousel-title-below-image .fusion-product-buttons {
  position: relative;
}
.fusion-rollover-content {
  padding: 10px;
  width: 90%;
}
.fusion-rollover-content .fusion-rollover-categories,
.fusion-rollover-content .fusion-rollover-title,
.fusion-rollover-content .price {
  margin: 0;
  line-height: normal;
  font-size: 13px;
  color: #333;
}
.fusion-rollover-content .fusion-rollover-title {
  margin-bottom: 3px;
  margin-top: 10px;
}
.fusion-rollover-content .fusion-rollover-categories a {
  font-size: 11px;
}
.fusion-rollover-content .fusion-rollover-categories a:hover {
  color: #444;
}
.fusion-rollover-content .price {
  display: inline-block;
}
.fusion-rollover-content .fusion-product-buttons {
  z-index: 99;
  position: relative;
}
.fusion-rollover-content .fusion-product-buttons a {
  display: block;
  margin-top: 10px;
  padding-left: 16px;
  width: auto;
  line-height: normal;
  text-align: left;
  max-width: 49%;
  font-size: 12px;
  box-sizing: border-box;
  z-index: 99;
}
.fusion-carousel-title-below-image
  .fusion-rollover-content
  .fusion-product-buttons
  a {
  max-width: none;
}
.fusion-rollover-content .fusion-product-buttons a:first-child {
  float: left;
}
.fusion-rollover-content .fusion-product-buttons a:first-child:before {
  position: absolute;
  margin-left: -16px;
  content: "\f07a";
  font-family: awb-icons;
}
.fusion-rollover-content .fusion-product-buttons a:last-child {
  float: right;
}
.fusion-rollover-content .fusion-product-buttons a:last-child:before {
  position: absolute;
  margin-left: -16px;
  content: "\e623";
  font-family: awb-icons;
}
.fusion-rollover-content .fusion-product-buttons a.added_to_cart {
  display: none;
}
.fusion-rollover-sep {
  display: inline-block;
  vertical-align: middle;
  height: 36px;
  width: 5px;
}
.fusion-rollover-gallery,
.fusion-rollover-link {
  display: inline-block;
  opacity: 0.9;
  position: relative;
  vertical-align: middle;
  margin: 0;
  height: 36px;
  width: 35px;
  text-indent: -10000px;
  background-color: #333;
  border-radius: 50%;
  background-clip: padding-box;
}
.fusion-rollover-gallery:hover,
.fusion-rollover-link:hover {
  opacity: 1;
}
.fusion-rollover-gallery:before,
.fusion-rollover-link:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-indent: 0;
  line-height: normal;
  text-shadow: none;
  font-family: awb-icons;
  font-size: 15px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}
.fusion-rollover-link:before {
  content: "\e619";
}
.fusion-rollover-gallery:before {
  content: "\f002";
}
.fusion-placeholder-image {
  max-width: 100%;
  background-color: #f6f6f6;
}
.fusion-hidden-content {
  overflow: hidden;
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
}
.fusion-filters {
  display: none;
  overflow: hidden;
  list-style: none;
  margin-bottom: 55px;
  padding: 0;
  line-height: 34px;
  border-top: 1px solid #e7e6e6;
  border-bottom: 1px solid #e7e6e6;
}
.fusion-filter {
  float: left;
  margin-right: 30px;
}
.fusion-filter.fusion-active a {
  line-height: 31px;
  border-top: 3px solid #65bc7b;
  color: #65bc7b;
}
.fusion-filter a {
  display: block;
}
.fusion-load-more-button {
  display: block;
  margin-top: 20px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #e9eaee;
  cursor: pointer;
  text-align: center;
  font-size: inherit;
  text-transform: uppercase;
  border: none;
  transition: all 0.2s;
}
.fusion-load-more-button:hover {
  transition: all 0.2s;
}
.fusion-portfolio-wrapper .fusion-load-more-button {
  display: none;
}
.fusion-loading-container {
  margin-top: 20px;
}
.fusion-loading-container .fusion-loading-spinner {
  display: inline-block;
}
.fusion-loading-container .fusion-spinner-1,
.fusion-loading-container .fusion-spinner-2,
.fusion-loading-container .fusion-spinner-3 {
  width: 12px;
  height: 12px;
  background-color: #e2e0e0;
  border-radius: 100%;
  display: inline-block;
  animation: fusion-bounce-delay 1.4s infinite ease-in-out;
  animation-fill-mode: both;
}
.fusion-loading-container .fusion-spinner-1 {
  animation-delay: -0.32s;
}
.fusion-loading-container .fusion-spinner-2 {
  animation-delay: -0.16s;
}
.fusion-loading-container .fusion-loading-msg {
  display: inline-block;
  margin-left: 7px;
}
@keyframes fusion-bounce-delay {
  0%,
  100%,
  80% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.fusion-body .wpcf7-form .fusion-slider-loading,
.fusion-slider-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -3em 0 0 -3em;
  color: transparent;
  font-size: 10px;
  border-top: 0.5em solid rgba(0, 0, 0, 0.2);
  border-right: 0.5em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.5em solid #333;
  border-left: 0.5em solid rgba(0, 0, 0, 0.2);
  animation: fusion-rotate 0.8s infinite linear;
}
.fusion-body .wpcf7-form .fusion-slider-loading .fusion-slider-loading-text,
.fusion-slider-loading .fusion-slider-loading-text {
  position: absolute;
}
.no-cssanimations .fusion-body .wpcf7-form .fusion-slider-loading,
.no-cssanimations .fusion-slider-loading {
  padding-left: 5px;
  padding-top: 15px;
  color: #000;
}
.fusion-slider-loading,
.fusion-slider-loading:after {
  width: 6em;
  height: 6em;
  border-radius: 50%;
  background-clip: padding-box;
}
.fusion-body .wpcf7-form .fusion-slider-loading {
  display: none;
  position: static;
  margin: 0 10px;
  border-width: 0.35em;
  vertical-align: middle;
}
.fusion-body .wpcf7-form .fusion-slider-loading,
.fusion-body .wpcf7-form .fusion-slider-loading:after {
  width: 3em;
  height: 3em;
}
.fusion-body .wpcf7-form.submitting .fusion-slider-loading {
  display: inline-block;
}
@keyframes fusion-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fusion-inline-sep {
  padding: 0 5px;
}
.fusion-inline-sep:last-child {
  display: none;
}
.post-content p {
  margin-top: 0;
  margin-bottom: 20px;
}
blockquote {
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 15px;
  background: #f5f5f5;
  border-left: 4px solid #65bc7b;
  font: 13px/20px PTSansItalic, arial, helvetica, sans-serif;
  color: #444;
  font-style: italic;
}
blockquote :last-child {
  margin-bottom: 0;
}
.comment-text blockquote p:first-child {
  margin-top: 0;
}
.table-1,
.table-2,
.tkt-slctr-tbl-wrap-dv {
  display: block;
  overflow-x: auto;
}
.tagcloud a {
  display: inline-flex;
  align-items: center;
  margin: 2px 0;
  padding: 0 10px;
  min-height: 2.15em;
  line-height: 2.15em;
  font-size: 0.9em;
  border: 1px solid;
  text-shadow: none;
}
.fusion-body .widget .tagcloud a:hover {
  color: #fff;
}
.fusion-body .quantity {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid;
  overflow: hidden;
}
.fusion-body .quantity .minus,
.fusion-body .quantity .plus,
.fusion-body .quantity .qty {
  float: left;
  border: 0;
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.fusion-body .quantity .minus,
.fusion-body .quantity .plus {
  cursor: pointer;
}
.fusion-body .quantity .qty {
  background: 0 0;
  border-left: 1px solid;
  border-right: 1px solid;
}
.fusion-body .quantity .qty -webkit-inner-spin-button,
.fusion-body .quantity .qty -webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.select-arrow {
  width: 35px;
  height: 33px;
  line-height: 33px;
  border-left-width: 1px;
  border-left-style: solid;
  position: absolute;
  bottom: 1px;
  right: 1px;
  pointer-events: none;
  z-index: 10;
  font-family: awb-icons;
  font-size: 9px;
  text-align: center;
}
.avada-select-parent {
  position: relative;
  overflow: hidden;
  margin: 0;
}
.bbpress .avada-select-parent {
  overflow: hidden;
}
.avada-select-parent select {
  border: 1px solid #dadada;
  -webkit-appearance: none;
  text-overflow: "";
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 13px;
  outline: 0;
  height: auto;
  padding: 9px 15px;
  position: relative;
  text-indent: 0.01px;
  vertical-align: middle;
  z-index: 5;
  margin: 0;
  border-radius: 0;
  background-clip: padding-box;
  min-width: 180px;
}
.sidebar .avada-select-parent select {
  min-width: initial;
}
.widget_archive select,
.widget_categories select {
  width: 100%;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 15px;
  padding-right: 34px;
}
.select2-results__option,
.select2-search--dropdown {
  padding-left: 15px;
  padding-right: 15px;
}
.select2-search--dropdown .select2-search__field {
  margin-right: 0;
}
.select2-container--default .select2-selection--single {
  border-radius: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.select2-dropdown {
  border-radius: 0;
}
#submit,
.submit {
  cursor: pointer;
}
#slidingbar .widget_calendar table,
.sidebar .widget_calendar table {
  width: 100%;
}
.widget_calendar.fusion-widget-align-default th {
  text-align: left;
}
.widget_calendar #next {
  text-align: right;
}
.widget_calendar #prev {
  text-align: left;
}
.fusion-ad-125-125 .fusion-image-row {
  display: flex;
  flex-wrap: wrap;
}
.fusion-ad-125-125 .fusion-image-holder {
  display: inline-block;
  box-sizing: border-box;
  padding: 2px;
  width: 50%;
}
.fusion-ad-125-125 .fusion-image-holder a {
  display: inline-block;
}
.rich-snippet-hidden {
  display: none;
}
.fusion-avatar-circle .attachment-recent-works-thumbnail,
.fusion-avatar-circle .avatar {
  border-radius: 50%;
}
.fusion-author .fusion-edit-profile {
  font-size: 12px;
}
.fusion-author .fusion-social-networks {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  line-height: normal;
}
.fusion-author .fusion-social-networks .fusion-social-network-icon {
  margin-bottom: 0;
}
.fusion-author .fusion-social-networks.boxed-icons .fusion-social-network-icon {
  margin-bottom: 2px;
}
.fusion-author-avatar {
  float: left;
  margin-right: 20px;
  max-width: 20%;
}
.fusion-author-info {
  overflow: hidden;
}
.fusion-author-title {
  margin-top: 0;
  color: #333;
  font-weight: 400;
}
.fusion-author-social {
  display: table;
  margin: 20px 0 80px;
  padding: 5px 0;
  width: 100%;
  border-top: 1px solid #e0dede;
  border-bottom: 1px solid #e0dede;
}
.fusion-author-tagline {
  display: table-cell;
  vertical-align: middle;
  max-width: 70%;
  font-weight: 700;
}
.fusion-page-load-link {
  display: none;
}
#menu-appearance a[href="themes.php?page=optionsframework"] {
  display: none;
}
#wpadminbar {
  z-index: 999999999999999;
}
#wpadminbar > #wp-toolbar #wp-admin-bar-avada > .ab-item:before,
#wpadminbar > #wp-toolbar #wp-admin-bar-fb-edit > .ab-item:before {
  content: "\e971";
  font-family: awb-icons;
  top: 4px;
  font-size: 16px;
}
@media screen and (max-width: 782px) {
  #wpadminbar > #wp-toolbar #wp-admin-bar-avada > .ab-item:before,
  #wpadminbar > #wp-toolbar #wp-admin-bar-fb-edit > .ab-item:before {
    content: "\e971";
    font-family: awb-icons;
    top: 0;
    padding: 10px;
    font-size: 25px;
  }
}
.woocommerce-billing-fields .form-row .input-text {
  margin-right: 0;
}
.fusion-disable-outline input,
.fusion-disable-outline select,
.fusion-disable-outline textarea {
  outline: 0;
}
.fusion-disable-outline .woocommerce-billing-fields .form-row .input-text {
  outline: 0;
}
.select2-container .select2-selection {
  margin: 0;
  box-shadow: none !important;
  outline: 0 !important;
}
.select2-container .select2-selection .select2-selection__arrow {
  top: 0;
  transform: inherit;
  right: 0;
  width: 40px;
  border-left: 1px solid #d2d2d2;
}
.select2-container--default .select2-dropdown .select2-search {
  border-bottom: 1px solid #d2d2d2;
  padding: 10px 15px;
  border-top: 1px solid #d2d2d2;
  margin-top: -1px;
}
.select2-container--default .select2-dropdown .select2-search input {
  padding: 8px 10px;
  height: auto;
  background: #fff;
}
.fusion-post-slideshow .image {
  margin-bottom: 0;
}
.fusion-post-slideshow .fusion-image-size-fixed {
  display: block;
}
.fusion-flexslider.fusion-post-slideshow {
  overflow: visible;
}
.fusion-meta-info {
  clear: both;
  overflow: hidden;
  margin-top: 20px;
  padding: 5px 0;
  border-top: 1px solid #e7e6e6;
  border-bottom: 1px solid #e7e6e6;
}
.fusion-meta-info .fusion-alignleft {
  width: 80%;
}
.fusion-post-title-meta-wrap {
  margin-bottom: 28px;
}
.fusion-post-title-meta-wrap .entry-title {
  margin-bottom: 10px;
}
.fusion-post-title-meta-wrap .fusion-meta-info {
  margin-top: 0;
}
.fusion-single-line-meta {
  margin-bottom: 20px;
}
.fusion-content-sep {
  margin-top: 20px;
  height: 6px;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}
.fusion-date-and-formats {
  float: left;
  margin-right: 17px;
  width: 82px;
  text-align: center;
}
.fusion-date-and-formats .fusion-date-box {
  padding: 8px 0 5px;
  color: #fff;
}
.fusion-date-and-formats .fusion-date {
  display: block;
  line-height: 29px;
  font-size: 29px;
  font-weight: 700;
}
.fusion-date-and-formats .fusion-format-box {
  margin-top: 3px;
  background-color: #eef0f2;
}
.fusion-date-and-formats .fusion-format-box i {
  display: block;
  line-height: 55px;
  font-size: 30px;
}
.fusion-timeline-line {
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -1px;
  height: 100%;
  border-left: 1px solid #efeeee;
  border-right: 1px solid #ebeaea;
}
.fusion-timeline-icon {
  margin: 0 auto -6px;
  max-width: 1000px;
  font-size: 46px;
  height: 47px;
  line-height: 1;
  color: #ebeaea;
  text-align: center;
}
.fusion-timeline-icon .fusionicon-bubbles {
  cursor: pointer;
}
.fusion-timeline-circle {
  position: absolute;
  left: 50%;
  margin: 10px 0 0 -5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #efeeee;
}
.fusion-timeline-arrow {
  position: absolute;
  color: #e9eaee;
}
.fusion-timeline-arrow:before {
  font-family: awb-icons;
  font-size: 28px;
  position: absolute;
  top: 0;
  line-height: 30px;
}
.fusion-blog-archive .entry-title {
  margin-top: 0;
  margin-bottom: 5px;
}
.fusion-blog-layout-large .entry-title,
.fusion-blog-layout-medium .entry-title {
  margin-top: 0;
  margin-bottom: 25px;
}
.fusion-blog-layout-large .fusion-post-slideshow,
.fusion-blog-layout-large-alternate .fusion-post-slideshow,
.single-avada_faq .fusion-post-slideshow,
.single-post .fusion-post-slideshow,
.single-wpfc_sermon .fusion-post-slideshow {
  margin-bottom: 30px;
}
.fusion-blog-layout-large-alternate .entry-title,
.fusion-blog-layout-medium-alternate .entry-title {
  margin-top: 0;
  margin-bottom: 5px;
}
.fusion-blog-layout-large-alternate .fusion-post-content-container,
.fusion-blog-layout-medium-alternate .fusion-post-content-container {
  margin-top: 20px;
}
.fusion-blog-layout-large-alternate .fusion-meta-info,
.fusion-blog-layout-medium-alternate .fusion-meta-info {
  margin-top: 0;
  border-top: 0;
}
.fusion-blog-layout-medium .fusion-post-medium,
.fusion-blog-layout-medium .fusion-post-medium-alternate,
.fusion-blog-layout-medium-alternate .fusion-post-medium,
.fusion-blog-layout-medium-alternate .fusion-post-medium-alternate {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}
.fusion-blog-layout-medium .fusion-post-slideshow,
.fusion-blog-layout-medium-alternate .fusion-post-slideshow {
  margin-right: 25px;
  width: 320px;
}
.fusion-blog-layout-medium .fusion-post-content,
.fusion-blog-layout-medium-alternate .fusion-post-content {
  flex: 1 1 1px;
}
.fusion-blog-layout-medium .fusion-meta-info,
.fusion-blog-layout-medium-alternate .fusion-meta-info {
  width: 100%;
}
.fusion-blog-layout-grid .fusion-post-slideshow,
.fusion-blog-layout-timeline .fusion-post-slideshow {
  margin-bottom: 0;
  border-bottom: 1px solid #eaeaea;
}
.fusion-blog-layout-grid .fusion-post-content-wrapper,
.fusion-blog-layout-timeline .fusion-post-content-wrapper {
  padding: 30px 25px 20px;
}
.fusion-blog-layout-grid .fusion-single-line-meta,
.fusion-blog-layout-timeline .fusion-single-line-meta {
  margin-bottom: 0;
}
.fusion-blog-layout-grid .fusion-post-content-container,
.fusion-blog-layout-timeline .fusion-post-content-container {
  margin-top: 20px;
}
.fusion-blog-layout-grid .fusion-post-content-container p:last-child,
.fusion-blog-layout-timeline .fusion-post-content-container p:last-child {
  margin-bottom: 0;
}
.fusion-blog-layout-grid .fusion-meta-info,
.fusion-blog-layout-timeline .fusion-meta-info {
  border: 0;
}
.fusion-blog-layout-grid .fusion-meta-info .fusion-alignleft,
.fusion-blog-layout-timeline .fusion-meta-info .fusion-alignleft {
  width: 50%;
}
.fusion-blog-layout-grid .fusion-password,
.fusion-blog-layout-timeline .fusion-password {
  width: auto;
  max-width: 100%;
}
.fusion-blog-layout-large-alternate .fusion-post-large-alternate {
  display: flex;
  flex-wrap: wrap;
}
.fusion-blog-layout-large-alternate .fusion-post-content {
  flex: 1;
}
.fusion-blog-layout-large-alternate .fusion-meta-info,
.fusion-blog-layout-large-alternate .fusion-post-slideshow {
  width: 100%;
}
.fusion-blog-layout-medium-alternate .fusion-post-slideshow {
  display: none;
}
.fusion-blog-layout-medium-alternate
  .has-post-thumbnail
  .fusion-post-slideshow {
  display: block;
  margin-right: 17px;
}
.fusion-blog-layout-grid .fusion-post-grid {
  margin-bottom: 0;
}
.fusion-blog-layout-grid.fusion-no-meta-info .fusion-post-content-container {
  margin-top: 0;
}
.fusion-blog-layout-grid
  .fusion-post-wrapper:not(.fusion-masonary-is-transparent) {
  border: 1px solid #eaeaea;
  border-bottom-width: 3px;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-2 .post {
  width: 50%;
  display: inline-block;
  float: left;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-2.fusion-blog-layout-masonry-has-vertical
  .post.fusion-element-landscape {
  width: 100%;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-3 .post {
  width: 33.3333%;
  display: inline-block;
  float: left;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-3.fusion-blog-layout-masonry-has-vertical
  .post.fusion-element-landscape {
  width: 66.6666%;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-4 .post {
  width: 25%;
  display: inline-block;
  float: left;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-4.fusion-blog-layout-masonry-has-vertical
  .post.fusion-element-landscape {
  width: 50%;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-5 .post {
  width: 20%;
  display: inline-block;
  float: left;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-5.fusion-blog-layout-masonry-has-vertical
  .post.fusion-element-landscape {
  width: 40%;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-6 .post {
  width: 16.6666%;
  display: inline-block;
  float: left;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-6.fusion-blog-layout-masonry-has-vertical
  .post.fusion-element-landscape {
  width: 33.3333%;
}
.fusion-blog-layout-grid .fusion-post-content,
.fusion-blog-layout-grid .post-content {
  word-wrap: break-word;
}
.fusion-blog-layout-grid.fusion-blog-equal-heights .fusion-post-grid {
  display: inline-flex;
}
.fusion-blog-layout-grid.fusion-blog-equal-heights .fusion-post-wrapper {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.fusion-blog-layout-grid.fusion-blog-equal-heights
  .fusion-post-wrapper
  .fusion-post-content-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.fusion-blog-layout-grid.fusion-blog-equal-heights
  .fusion-post-wrapper
  .fusion-post-content.post-content {
  margin-bottom: 20px;
}
.fusion-blog-layout-grid.fusion-blog-equal-heights
  .fusion-post-wrapper
  .fusion-meta-info {
  margin-top: auto;
}
.fusion-blog-layout-timeline {
  position: relative;
  margin: 0 auto;
  padding-top: 30px;
  max-width: 1000px;
}
.fusion-blog-layout-timeline .fusion-post-timeline {
  position: static;
  margin-bottom: 40px;
  border: 1px solid #eaeaea;
  border-bottom-width: 3px;
}
.fusion-no-meta-info
  .fusion-blog-layout-timeline
  .fusion-post-content-container {
  margin-top: 0;
}
.fusion-blog-layout-timeline .fusion-left-column {
  float: left;
  clear: left;
  width: 40%;
}
.fusion-blog-layout-timeline .fusion-right-column {
  float: right;
  clear: right;
  width: 40%;
}
.fusion-blog-layout-timeline .fusion-timeline-date {
  position: relative;
  clear: both;
  margin: 0 auto 2px;
  padding: 0.35em 0.45em;
  width: auto;
  max-width: 8em;
  line-height: 1.35em;
  background: #efeeee;
  text-align: center;
  cursor: pointer;
}
.fusion-blog-layout-timeline .fusion-left-column .fusion-timeline-arrow {
  left: 40%;
  margin-left: -0.5px;
}
.fusion-blog-layout-timeline .fusion-left-column .fusion-timeline-arrow:before {
  content: "\f0da";
  left: 100%;
}
.fusion-blog-layout-timeline .fusion-right-column .fusion-timeline-arrow {
  right: 40%;
  margin-right: -0.5px;
}
.fusion-blog-layout-timeline
  .fusion-right-column
  .fusion-timeline-arrow:before {
  content: "\f0d9";
  right: 100%;
}
.fusion-blog-layout-masonry .fusion-post-wrapper {
  overflow: hidden;
  position: relative;
}
.fusion-blog-layout-masonry .fusion-post-content-wrapper {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.fusion-blog-layout-masonry.fusion-blog-rollover
  .fusion-post-wrapper:hover
  .fusion-post-content-wrapper {
  transition: all 0.3s ease;
  transform: translateY(105%);
}
.fusion-blog-layout-masonry.fusion-blog-rollover .fusion-post-content-wrapper {
  transition: all 0.3s ease;
}
.fusion-blog-infinite .pagination {
  display: none;
}
.fusion-blog-infinite .fusion-load-more-button {
  margin-bottom: 40px;
}
.fusion-blog-layout-center .fusion-post-content {
  text-align: center;
}
.fusion-blog-layout-left .fusion-post-content {
  text-align: left;
}
.fusion-blog-layout-right .fusion-post-content {
  text-align: right;
}
.fusion-footer-parallax {
  z-index: 0;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
}
.fusion-footer-parallax.awb-footer-fixed {
  position: fixed;
}
.avada-footer-fx-no-height-parallax-effect #fusion-gmap-container,
.avada-footer-fx-no-height-parallax-effect #main,
.avada-footer-fx-no-height-parallax-effect #sliders-container,
.avada-footer-fx-no-height-parallax-effect .fusion-page-title-bar,
.avada-footer-fx-parallax-effect #fusion-gmap-container,
.avada-footer-fx-parallax-effect #main,
.avada-footer-fx-parallax-effect #sliders-container,
.avada-footer-fx-parallax-effect .fusion-page-title-bar {
  position: relative;
  z-index: 1;
}
.avada-footer-fx-bg-parallax .fusion-footer-widget-area,
.avada-footer-fx-no-height-bg-parallax .fusion-footer-widget-area,
.avada-footer-fx-no-height-sticky-with-parallax-bg-image
  .fusion-footer-widget-area,
.avada-footer-fx-sticky-with-parallax-bg-image .fusion-footer-widget-area {
  background-attachment: fixed;
  background-position: top center;
}
.avada-footer-fx-sticky #boxed-wrapper,
.avada-footer-fx-sticky #wrapper,
.avada-footer-fx-sticky-with-parallax-bg-image #boxed-wrapper,
.avada-footer-fx-sticky-with-parallax-bg-image #wrapper,
.avada-footer-fx-sticky-with-parallax-bg-image.layout-boxed-mode:not(.fusion-top-header)
  #boxed-wrapper,
.avada-footer-fx-sticky.layout-boxed-mode:not(.fusion-top-header)
  #boxed-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.avada-footer-fx-sticky-with-parallax-bg-image.layout-boxed-mode:not(.fusion-top-header)
  #boxed-wrapper
  #wrapper,
.avada-footer-fx-sticky.layout-boxed-mode:not(.fusion-top-header)
  #boxed-wrapper
  #wrapper {
  width: 100%;
}
.avada-footer-fx-sticky #main,
.avada-footer-fx-sticky-with-parallax-bg-image #main {
  flex-grow: 1;
}
.wp-audio-shortcode {
  max-height: 150px;
}
.tkt-slctr-tbl-wrap-dv table {
  margin-bottom: 10px;
}
#fancybox-overlay {
  z-index: 100049 !important;
}
#fancybox-wrap {
  z-index: 100050 !important;
}
div#shShutter {
  z-index: 100049;
}
div#shDisplay {
  z-index: 100050;
}
#cboxOverlay {
  z-index: 100049 !important;
}
#colorbox {
  z-index: 100050 !important;
}
.fusion-main-menu .lang-item > a img,
.fusion-mobile-nav-holder .lang-item > a img,
.fusion-secondary-menu .lang-item > a img {
  vertical-align: middle;
  padding: 0 2px;
}
.qtranxs-lang-menu-item > a img,
.qtranxs-lang-menu > a img {
  vertical-align: middle;
  padding: 0 3px;
}
#wpfc_sermon_sorting {
  overflow: hidden;
  margin: 0 -10px;
}
#wpfc_sermon_sorting > span {
  display: block;
  float: left;
  width: 25%;
  padding: 0 10px;
}
#wpfc_sermon_sorting .avada-select-parent select {
  width: 100%;
}
.fusion-ubermenu-support .fusion-header-v1 .fusion-header .fusion-row,
.fusion-ubermenu-support .fusion-header-v2 .fusion-header .fusion-row,
.fusion-ubermenu-support .fusion-header-v3 .fusion-header .fusion-row,
.fusion-ubermenu-support .fusion-header-v7 .fusion-header .fusion-row {
  position: relative;
}
.fusion-ubermenu-support .fusion-secondary-main-menu .fusion-row {
  position: relative;
}
.fusion-ubermenu {
  overflow: visible;
  position: static;
}
.cp-module .cp-slidein {
  z-index: 9999;
}
.fusion-body .product-buttons .wc-gzd-additional-info {
  margin: 0.5em 0 0.5em 0;
}
.fusion-body .product-buttons .wc-gzd-additional-info a {
  display: inline;
  float: none;
  padding: inherit;
  font-size: inherit;
}
.fusion-body .product-buttons .wc-gzd-additional-info + a {
  float: left;
}
.fusion-body .product-buttons .wc-gzd-additional-info + a + a,
.fusion-body .product-buttons .wc-gzd-additional-info + a + a + a,
.fusion-body .product-buttons .wc-gzd-additional-info + a + a + a + a {
  float: right;
}
.fusion-body .product-buttons .wc-gzd-additional-info:last-of-type {
  margin-bottom: 1.5em;
}
.fusion-body .fusion-carousel-meta .wc-gzd-additional-info {
  margin-bottom: 0.5em;
}
.fusion-body .fusion-carousel-meta .wc-gzd-additional-info:first-of-type {
  margin-top: 1.5em;
}
.fusion-body .fusion-carousel-meta .wc-gzd-additional-info:last-of-type {
  margin-bottom: 1.5em;
}
.bg-parallax-parent.video {
  position: relative;
}
.bg-parallax.video,
.fusion-bg-parallax.video {
  position: absolute;
  pointer-events: none !important;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.bg-parallax.video iframe,
.fusion-bg-parallax.video iframe {
  margin-bottom: 0 !important;
  display: block !important;
  max-width: none !important;
  max-height: none !important;
}
.parallax-inner {
  min-height: 150px;
}
.fusion-bg-parallax[data-bg-size-adjust="dont-scale"]
  + .bg-parallax-parent
  .parallax-inner {
  background-size: auto !important;
}
.datepicker.dropdown-menu {
  border-radius: 0;
  border: 0;
  padding: 15px;
}
.datepicker.dropdown-menu:before {
  border-bottom: 0;
}
.datepicker.dropdown-menu thead tr:nth-child(2) {
  font-size: 16px;
}
.datepicker.dropdown-menu td,
.datepicker.dropdown-menu th {
  border-radius: 0;
  border: 0;
  padding: 9px 10px;
}
.datepicker.dropdown-menu td.active,
.datepicker.dropdown-menu td.active:hover {
  border: 0;
  text-shadow: none;
}
.datepicker.dropdown-menu table tr td.new,
.datepicker.dropdown-menu table tr td.old {
  background-color: #fff !important;
  color: #999 !important;
}
.datepicker.dropdown-menu tbody td {
  border: 1px solid #fff;
}
.datepicker.dropdown-menu .datepicker-months td {
  padding: 15px 0 0;
}
.datepicker.dropdown-menu .datepicker-months span {
  text-shadow: none;
}
.datepicker.dropdown-menu .datepicker-months span:hover {
  text-shadow: none;
}
.crossfade-images {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.crossfade-images .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.crossfade-images:hover img:not(.hover-image) {
  opacity: 0;
}
.crossfade-images:hover .hover-image {
  opacity: 1 !important;
}
.crossfade-images img {
  transition: opacity 0.5s ease-in-out;
  position: relative;
  z-index: 50;
  min-width: 100%;
  vertical-align: middle;
}
.crossfade-images .hover-image {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
}
.avada-html-layout-boxed,
.avada-html-layout-boxed body,
.avada-html-layout-boxed body.custom-background,
html:not(.avada-html-layout-boxed):not(.avada-html-layout-framed),
html:not(.avada-html-layout-boxed):not(.avada-html-layout-framed) body {
  background-color: var(--bg_color);
  background-blend-mode: var(--bg-color-overlay);
}
.avada-html-has-bg-image
  .avada-has-bg-image-full.layout-boxed-mode.layout-scroll-offset-framed
  .fusion-bottom-frame,
.avada-html-has-bg-image
  .avada-has-bg-image-full.layout-boxed-mode.layout-scroll-offset-framed
  .fusion-sides-frame,
.avada-html-has-bg-image
  .avada-has-bg-image-full.layout-boxed-mode.layout-scroll-offset-framed
  .fusion-top-frame,
.avada-html-has-bg-image .avada-has-bg-image-full:not(.layout-boxed-mode),
.avada-html-has-bg-image
  .avada-has-bg-image-full:not(.layout-scroll-offset-framed) {
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}
.layout-boxed-mode.layout-scroll-offset-framed .fusion-bottom-frame,
.layout-boxed-mode.layout-scroll-offset-framed .fusion-sides-frame,
.layout-boxed-mode.layout-scroll-offset-framed .fusion-top-frame {
  background-color: var(--bg_color);
  background-blend-mode: var(--bg-color-overlay);
  background-image: var(--bg_image);
}
.avada-html-has-bg-image
  .layout-boxed-mode.layout-scroll-offset-framed.avada-has-bg-image-full
  .fusion-bottom-frame {
  background-position: 0
    calc((100vh - var(--margin_offset-bottom-no-percent)) * -1);
}
.avada-html-has-bg-image
  .layout-boxed-mode.layout-scroll-offset-framed.avada-has-bg-image-full.admin-bar
  .fusion-bottom-frame {
  background-position: 0
    calc((100vh - var(--margin_offset-bottom-no-percent) - 32px) * -1);
}
@media screen and (max-width: 782px) {
  .avada-html-has-bg-image
    .layout-boxed-mode.layout-scroll-offset-framed.avada-has-bg-image-full.admin-bar
    .fusion-bottom-frame {
    background-position: 0
      calc((100vh - var(--margin_offset-bottom-no-percent) - 46px) * -1);
  }
}
body {
  background-image: var(--bg_image);
  background-repeat: var(--bg_repeat);
}
#main,
.layout-boxed-mode #main,
.layout-boxed-mode.avada-footer-fx-sticky .above-footer-wrapper,
.layout-boxed-mode.avada-footer-fx-sticky-with-parallax-bg-image
  .above-footer-wrapper,
.layout-wide-mode #main,
.layout-wide-mode #wrapper,
body,
html,
html body.custom-background {
  background-color: var(--content_bg_color);
}
.quicktags-toolbar input {
  background: linear-gradient(
      to top,
      var(--content_bg_color),
      var(--form_bg_color)
    )
    #3e3e3e;
}
#main,
.wrapper_blank #main {
  background-image: var(--content_bg_image);
  background-repeat: var(--content_bg_repeat);
}
#main.full-bg,
.wrapper_blank #main.full-bg {
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}
.avada-content-bg-not-opaque #main,
.avada-content-bg-not-opaque #wrapper,
.avada-content-bg-not-opaque .bbp-arrow,
.avada-content-bg-not-opaque .fusion-separator .icon-wrapper {
  background-blend-mode: overlay;
}
html.avada-has-page-background-pattern {
  background-image: var(--bg_pattern);
  background-repeat: repeat;
}
html.avada-has-page-background-pattern .layout-boxed-mode .fusion-bottom-frame,
html.avada-has-page-background-pattern .layout-boxed-mode .fusion-sides-frame,
html.avada-has-page-background-pattern .layout-boxed-mode .fusion-top-frame,
html.avada-has-page-background-pattern body {
  background-image: var(--bg_pattern);
  background-repeat: repeat;
}
.fusion-load-more-button.fusion-blog-button {
  background-color: var(--blog_load_more_posts_button_bg_color);
  color: var(--blog_load_more_posts_button_text_color);
}
.fusion-load-more-button.fusion-blog-button:hover {
  background-color: var(--blog_load_more_posts_hover_button_bg_color);
  color: var(--blog_load_more_posts_hover_button_text_color);
}
body:not(.search-results) #posts-container .fusion-blog-layout-grid {
  margin-top: calc((var(--blog_archive_grid_column_spacing)) / -2);
  margin-right: calc((var(--blog_archive_grid_column_spacing)) / -2);
  margin-left: calc((var(--blog_archive_grid_column_spacing)) / -2);
  margin-bottom: 0;
}
body:not(.search-results)
  #posts-container
  .fusion-blog-layout-grid
  .fusion-post-grid {
  padding: calc((var(--blog_archive_grid_column_spacing)) / 2);
}
.avada-blog-archive-layout-grid
  .fusion-blog-archive
  .fusion-blog-layout-grid
  .post
  .fusion-post-content-wrapper,
.avada-blog-archive-layout-masonry
  .fusion-blog-archive
  .fusion-blog-layout-masonry
  .post
  .fusion-post-content-wrapper,
.avada-blog-archive-layout-timeline
  .fusion-blog-archive
  .fusion-blog-layout-timeline
  .post
  .fusion-post-content-wrapper,
.avada-blog-layout-grid
  .fusion-blog-archive
  .fusion-blog-layout-grid
  .post
  .fusion-post-content-wrapper,
.avada-blog-layout-masonry
  .fusion-blog-archive
  .fusion-blog-layout-masonry
  .post
  .fusion-post-content-wrapper,
.avada-blog-layout-timeline
  .fusion-blog-archive
  .fusion-blog-layout-timeline
  .post
  .fusion-post-content-wrapper {
  padding: var(--blog_archive_grid_padding-top)
    var(--blog_archive_grid_padding-right)
    var(--blog_archive_grid_padding-bottom)
    var(--blog_archive_grid_padding-left);
}
#wrapper .fusion-events-shortcode .fusion-events-meta h4,
.fusion-carousel-meta,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-categories,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-categories
  a,
.fusion-meta,
.fusion-meta-info,
.fusion-recent-posts .columns .column .meta,
.fusion-single-line-meta,
.post .single-line-meta,
.widget_recent_entries .post-date {
  font-size: var(--meta_font_size);
}
#main .about-author .title a:hover,
#main .post h2 a:hover,
#wrapper .fusion-vertical-menu-widget .menu li.current-menu-item > a,
#wrapper .fusion-vertical-menu-widget .menu li.current_page_ancestor > a,
#wrapper .fusion-widget-area .current-menu-item > a,
#wrapper .fusion-widget-area .current-menu-item > a:before,
#wrapper .fusion-widget-area .current_page_item > a,
#wrapper .fusion-widget-area .current_page_item > a:before,
#wrapper .jtwt .jtwt_tweet a:hover,
.content-box-percentage,
.fusion-content-widget-area .widget .recentcomments:hover:before,
.fusion-content-widget-area .widget li a:hover,
.fusion-content-widget-area .widget li a:hover:before,
.fusion-content-widget-area .widget_archive li a:hover:before,
.fusion-content-widget-area .widget_categories li a:hover,
.fusion-content-widget-area .widget_categories li a:hover:before,
.fusion-content-widget-area .widget_links li a:hover:before,
.fusion-content-widget-area .widget_nav_menu li a:hover:before,
.fusion-content-widget-area .widget_pages li a:hover:before,
.fusion-content-widget-area .widget_recent_entries li a:hover:before,
.fusion-date-and-formats .fusion-format-box i,
.fusion-open-submenu:hover,
.fusion-pagination .next:hover,
.fusion-pagination .next:hover:after,
.fusion-pagination .prev:hover,
.fusion-pagination .prev:hover:before,
.fusion-read-more:hover:after,
.more a:hover:after,
.pagination .pagination-next:hover:after,
.pagination .pagination-prev:hover:before,
.pagination-next:hover:after,
.pagination-prev:hover:before,
.price ins .amount,
.price > .amount,
.project-content .project-info .project-info-box a:hover,
.rtl .fusion-read-more:hover:before,
.rtl .more a:hover:before,
.side-nav .arrow:hover:after,
.side-nav li.current_page_ancestor > a,
.side-nav ul > li.current_page_item > a,
.single-navigation a[rel="next"]:hover:after,
.single-navigation a[rel="prev"]:hover:before,
.star-rating span:before,
.star-rating:before,
a:hover,
h5.toggle:hover a {
  color: var(--primary_color);
}
#nav ul li > a:hover,
#sticky-nav ul li > a:hover,
#wrapper .post-content blockquote,
#wrapper .tagcloud a:hover,
.comment-text blockquote,
.fusion-body blockquote,
.fusion-hide-pagination-text .pagination-next:hover,
.fusion-hide-pagination-text .pagination-prev:hover,
.fusion-pagination .current,
.fusion-pagination .page-numbers.current,
.fusion-pagination .page-numbers:hover,
.pagination .current,
.pagination a.inactive:hover,
.progress-bar-content {
  border-color: var(--primary_color);
}
#toTop:hover,
.circle-yes ul li:before,
.fusion-date-and-formats .fusion-date-box,
.fusion-pagination .current,
.fusion-search-element
  .searchform.fusion-search-form-classic
  .fusion-search-form-content
  .fusion-search-button
  input[type="submit"]:hover,
.fusion-search-form-classic
  .searchform:not(.fusion-search-form-clean)
  .fusion-search-form-content
  .fusion-search-button
  input[type="submit"]:hover,
.pagination .current,
.progress-bar-content,
.table-2 table thead,
.tagcloud a:hover,
ul.arrow li:before,
ul.circle-yes li:before {
  background-color: var(--primary_color);
}
#wrapper .fusion-vertical-menu-widget .menu li.current_page_item > a,
#wrapper .side-nav li.current_page_item a {
  border-right-color: var(--primary_color);
  border-left-color: var(--primary_color);
}
.fusion-content-widget-area
  .fusion-tabs-widget
  .fusion-tabs-nav
  ul
  li.active
  a {
  border-top-color: var(--primary_color);
}
#toTop {
  background-color: #333;
  border-radius: 4px 4px 0 0;
  bottom: 0;
  height: 35px;
  position: fixed;
  text-align: center;
  width: 48px;
  opacity: 0;
  z-index: 100000;
  transition: opacity 0.4s ease-in-out, background 0.2s ease-in-out;
  line-height: 1;
  pointer-events: none;
}
#toTop:before {
  line-height: 35px;
  content: "\f106";
  -webkit-font-smoothing: antialiased;
  font-family: awb-icons;
  font-size: 22px;
  color: #fff;
}
#toTop:active,
#toTop:focus {
  outline: 0;
  background-color: #333;
}
#toTop.fusion-to-top-active {
  pointer-events: auto;
  opacity: 0.9;
  transition: opacity 0.4s ease-in-out, background 0.2s ease-in-out;
}
.to-top-container #toTop {
  border-radius: var(--totop_border_radius) var(--totop_border_radius) 0 0;
}
.to-top-floating #toTop {
  bottom: 30px;
  height: 44px;
  width: 44px;
  border-radius: var(--totop_border_radius) var(--totop_border_radius)
    var(--totop_border_radius) var(--totop_border_radius);
}
.to-top-floating #toTop:before {
  line-height: 42px;
}
.to-top-right #toTop {
  right: 75px;
}
.to-top-left #toTop {
  left: 75px;
}
.fusion-slider-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.tfs-slider {
  transform: translate(0, 0);
  left: 0;
}
.tfs-slider.fixed-width-slider.flexslider .slide-content-container {
  padding-left: 10%;
  padding-right: 10%;
}
.tfs-slider li.flex-active-slide {
  position: relative;
}
.tfs-slider .slides li {
  -webkit-backface-visibility: visible;
}
.tfs-slider .slide-content-container {
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 0;
  z-index: 5;
  transform: translateY(-50%);
}
.tfs-slider .slide-content-container .slide-content {
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: 940px;
}
.tfs-slider .slide-content-container .slide-content .fusion-li-item,
.tfs-slider .slide-content-container .slide-content li {
  display: list-item;
  text-align: initial;
}
.tfs-slider .slide-content-container .caption,
.tfs-slider .slide-content-container .heading {
  display: block;
}
.tfs-slider .slide-content-container h2,
.tfs-slider .slide-content-container h3 {
  margin: 0;
  font-weight: 400;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  padding: 0;
}
.tfs-slider .slide-content-container .with-bg .fusion-title-sc-wrapper {
  padding: 3px 15px;
}
.tfs-slider .slide-content-container .fusion-title-sc-wrapper {
  display: inline-block;
  padding: 0;
  line-height: 1;
}
.tfs-slider .slide-content-container .fusion-title {
  overflow: visible;
}
.tfs-slider .slide-content-container .fusion-block-element {
  display: block;
}
.tfs-slider .slide-content-container .heading {
  margin: 0 0 14px;
}
.tfs-slider .slide-content-container h2 {
  font-size: 60px;
  line-height: 80px;
  margin-bottom: 10px;
}
.tfs-slider .slide-content-container h3 {
  font-size: 24px;
  line-height: 38px;
}
.tfs-slider .slide-content-container .fusion-sep-none {
  display: inline-block;
}
.tfs-slider .slide-content-container .fusion-sep-none h2,
.tfs-slider .slide-content-container .fusion-sep-none h3 {
  display: inline-block;
}
.tfs-slider .slide-content-container .fusion-title-size-two {
  margin: 0;
}
.tfs-slider .slide-content-container .fusion-title-size-two h2 {
  margin: 0;
}
.tfs-slider .slide-content-container .fusion-title-size-three {
  margin: 0;
}
.tfs-slider .slide-content-container .fusion-title-size-three h3 {
  margin: 0;
}
.tfs-slider .slide-content-container .fusion-title .title-sep,
.tfs-slider .slide-content-container .fusion-title.sep-underline {
  border-color: #fff;
}
.tfs-slider .slide-content-container.slide-content-left .buttons,
.tfs-slider .slide-content-container.slide-content-right .buttons {
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.tfs-slider .slide-content-container.slide-content-left {
  text-align: left;
}
.tfs-slider .slide-content-container.slide-content-right {
  text-align: right;
}
.tfs-slider .slide-content-container .buttons {
  overflow: visible;
}
.tfs-slider .slide-content-container .buttons a {
  -webkit-backface-visibility: hidden;
}
.tfs-slider .slide-content-container .tfs-button-1,
.tfs-slider .slide-content-container .tfs-button-2 {
  margin-top: 3.25%;
  padding: 0 7.5px;
  display: inline-block;
}
.tfs-slider .tfs-scroll-down-indicator {
  display: inline-block;
  z-index: 10000;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 40px;
  line-height: 1;
  cursor: pointer;
  font-family: awb-icons;
  color: #fff;
}
.tfs-slider .tfs-scroll-down-indicator:after {
  content: "\e61f";
  color: inherit;
}
.tfs-slider .slide-content-center {
  text-align: center;
}
.tfs-slider .slide-content-right {
  text-align: right;
}
.ua-mobile .tfs-slider .self-hosted-video-bg {
  display: none;
}
.tfs-slider .background {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 2;
  max-width: 100%;
}
.tfs-slider .background-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.tfs-slider video {
  width: 100%;
  height: auto;
  visibility: visible;
  position: static;
  top: 0;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.tfs-slider .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
}
.tfs-slider .mobile_video_image {
  display: none;
}
.ua-mobile .tfs-slider .mobile_video_image {
  width: 100%;
  height: 100%;
  display: block;
  z-index: 4;
  background-size: cover;
  background-position: center center;
}
.tfs-slider .overlay-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 5;
}
.tfs-slider .flex-direction-nav a {
  top: 50%;
  transform: translateY(-50%);
}
.tfs-slider .background iframe {
  position: relative;
  z-index: 1 !important;
  margin: 0 !important;
}
.rtl .tfs-slider[data-animation="slide"] .flex-viewport {
  direction: ltr;
}
.rtl .tfs-slider[data-animation="slide"] .flex-viewport .slides li {
  direction: rtl;
}
.fusion-body .avada-google-map {
  margin-top: var(--gmap_topmargin);
}
.avada-image-rollover-no .fusion-rollover {
  display: none;
}
.avada-image-rollover-no
  .fusion-masonry-element-container.fusion-image-wrapper
  > a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.fusion-image-wrapper .fusion-rollover {
  background-image: linear-gradient(
    to top,
    var(--image_gradient_bottom_color) 0,
    var(--image_gradient_top_color) 100%
  );
}
.avada-image-rollover-direction-fade .fusion-image-wrapper .fusion-rollover {
  transform: translateY(0);
  transition: opacity 0.5s ease-in-out;
}
.avada-image-rollover-direction-right .fusion-image-wrapper .fusion-rollover {
  transform: translateX(100%);
}
.avada-image-rollover-direction-bottom .fusion-image-wrapper .fusion-rollover {
  transform: translateY(100%);
}
.avada-image-rollover-direction-top .fusion-image-wrapper .fusion-rollover {
  transform: translateY(-100%);
}
.avada-image-rollover-direction-center_horiz
  .fusion-image-wrapper
  .fusion-rollover {
  transform: scaleX(0);
}
.avada-image-rollover-direction-center_vertical
  .fusion-image-wrapper
  .fusion-rollover {
  transform: scaleY(0);
}
.fusion-image-wrapper .fusion-rollover .fusion-rollover-gallery,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-link {
  width: calc((var(--image_rollover_icon_size)) * 1.5);
  height: calc((var(--image_rollover_icon_size)) * 1.5);
  background-color: transparent;
}
.avada-image-rollover-circle-yes
  .fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-gallery,
.avada-image-rollover-circle-yes
  .fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-link {
  width: calc((var(--image_rollover_icon_size)) * 2.41);
  height: calc((var(--image_rollover_icon_size)) * 2.41);
  background-color: var(--image_rollover_text_color);
  line-height: 2.41;
}
.fusion-image-wrapper .fusion-rollover .fusion-rollover-gallery:before,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-link:before {
  font-size: var(--image_rollover_icon_size);
  color: var(--image_rollover_icon_color);
}
.fusion-image-wrapper.hover .fusion-rollover {
  opacity: 1;
}
.avada-image-rollover-direction-fade
  .fusion-image-wrapper.hover
  .fusion-rollover,
.avada-image-rollover-direction-fade
  .fusion-image-wrapper:hover
  .fusion-rollover,
.avada-image-rollover-direction-right
  .fusion-image-wrapper.hover
  .fusion-rollover,
.avada-image-rollover-direction-right
  .fusion-image-wrapper:hover
  .fusion-rollover {
  transform: translateX(0);
}
.avada-image-rollover-direction-bottom
  .fusion-image-wrapper.hover
  .fusion-rollover,
.avada-image-rollover-direction-bottom
  .fusion-image-wrapper:hover
  .fusion-rollover,
.avada-image-rollover-direction-top
  .fusion-image-wrapper.hover
  .fusion-rollover,
.avada-image-rollover-direction-top
  .fusion-image-wrapper:hover
  .fusion-rollover {
  transform: translateY(0);
}
.avada-image-rollover-direction-center_horiz
  .fusion-image-wrapper.hover
  .fusion-rollover,
.avada-image-rollover-direction-center_horiz
  .fusion-image-wrapper:hover
  .fusion-rollover {
  transform: scaleX(1);
}
.avada-image-rollover-direction-center_vertical
  .fusion-image-wrapper.hover
  .fusion-rollover,
.avada-image-rollover-direction-center_vertical
  .fusion-image-wrapper:hover
  .fusion-rollover {
  transform: scaleY(1);
}
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-product-buttons
  a:before,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-categories,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-categories
  a,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-title
  a,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-content .price,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-content .price *,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-content a,
.fusion-rollover .fusion-rollover-content .fusion-rollover-title {
  color: var(--image_rollover_text_color);
}
.fusion-hide-pagination-text .fusion-pagination .next,
.fusion-hide-pagination-text .fusion-pagination .prev,
.fusion-hide-pagination-text .pagination .pagination-next,
.fusion-hide-pagination-text .pagination .pagination-prev,
.fusion-pagination .current,
.fusion-pagination .page-numbers:not(.prev):not(.next),
.page-links a,
.page-links > .page-number:not(.prev):not(.next),
.pagination .current,
.pagination .pagination-next,
.pagination a.inactive {
  border-radius: var(--pagination_border_radius);
  border-width: var(--pagination_border_width);
}
body:not(.fusion-hide-pagination-text) .fusion-pagination .next,
body:not(.fusion-hide-pagination-text) .fusion-pagination .prev,
body:not(.fusion-hide-pagination-text) .pagination .pagination-next,
body:not(.fusion-hide-pagination-text) .pagination .pagination-prev {
  line-height: var(--pagination_width_height);
}
body:not(.avada-has-pagination-padding) .fusion-pagination .current,
body:not(.avada-has-pagination-padding)
  .fusion-pagination
  .page-numbers:not(.prev):not(.next),
body:not(.avada-has-pagination-padding) .page-links a,
body:not(.avada-has-pagination-padding)
  .page-links
  > .page-number:not(.prev):not(.next),
body:not(.avada-has-pagination-padding) .pagination .current,
body:not(.avada-has-pagination-padding) .pagination a.inactive,
body:not(.avada-has-pagination-padding).fusion-hide-pagination-text
  .fusion-pagination
  .next,
body:not(.avada-has-pagination-padding).fusion-hide-pagination-text
  .fusion-pagination
  .prev,
body:not(.avada-has-pagination-padding).fusion-hide-pagination-text
  .pagination
  .pagination-next,
body:not(.avada-has-pagination-padding).fusion-hide-pagination-text
  .pagination
  .pagination-prev {
  width: var(--pagination_width_height);
  height: var(--pagination_width_height);
  margin-left: calc((var(--pagination_width_height)) / 10);
  margin-right: calc((var(--pagination_width_height)) / 10);
}
body:not(.avada-has-pagination-padding) .pagination .pagination-next,
body:not(.avada-has-pagination-padding) .pagination .pagination-prev {
  margin-left: calc((var(--pagination_width_height)) / 10);
  margin-right: calc((var(--pagination_width_height)) / 10);
}
body:not(.avada-has-pagination-padding) .pagination .pagination-dots {
  width: calc((var(--pagination_width_height)) / 2);
  margin-left: calc((var(--pagination_width_height)) / 10);
  margin-right: calc((var(--pagination_width_height)) / 10);
}
.avada-has-pagination-padding .fusion-pagination .current,
.avada-has-pagination-padding
  .fusion-pagination
  .page-numbers:not(.prev):not(.next),
.avada-has-pagination-padding .page-links a,
.avada-has-pagination-padding .page-links > .page-number:not(.prev):not(.next),
.avada-has-pagination-padding .pagination .current,
.avada-has-pagination-padding .pagination a.inactive,
.avada-has-pagination-padding.fusion-hide-pagination-text
  .fusion-pagination
  .next,
.avada-has-pagination-padding.fusion-hide-pagination-text
  .fusion-pagination
  .prev,
.avada-has-pagination-padding.fusion-hide-pagination-text
  .pagination
  .pagination-next,
.avada-has-pagination-padding.fusion-hide-pagination-text
  .pagination
  .pagination-prev {
  padding: var(--pagination_box_padding-height)
    var(--pagination_box_padding-width) var(--pagination_box_padding-height)
    var(--pagination_box_padding-width);
}
.fusion-pagination .next,
.fusion-pagination .page-numbers,
.fusion-pagination .prev,
.page-links,
.pagination,
.pagination .pagination-next,
.pagination .pagination-prev {
  font-size: var(--pagination_font_size);
}

.avada-select .select2-container .select2-choice .select2-arrow,
.avada-select .select2-container .select2-choice2 .select2-arrow,
.searchform
  .fusion-search-form-content
  .fusion-search-button
  input[type="submit"] {
  width: var(--form_input_height);
}
.avada-select .select2-container .select2-choice .select2-arrow,
.avada-select .select2-container .select2-choice2 .select2-arrow,
.searchform
  .fusion-search-form-content
  .fusion-search-button
  input[type="submit"],
.select2-container .select2-choice > .select2-chosen,
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: var(--form_input_height);
}
.fusion-main-menu .fusion-main-menu-search .fusion-custom-menu-item-contents {
  width: var(--form_input_height-main-menu-search-width);
}
#comment-input input,
#comment-textarea textarea,
.avada-select .select2-container .select2-choice,
.avada-select .select2-container .select2-choice2,
.chzn-container .chzn-drop,
.chzn-container-single .chzn-single,
.comment-form-comment textarea,
.fusion-body .avada-select-parent select,
.input-text,
.main-nav-search-form input,
.post-password-form label input[type="password"],
.search-page-search-form input,
input.s,
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="phone-number"],
input[type="text"],
input[type="time"],
select,
textarea {
  background-color: var(--form_bg_color);
  font-size: var(--form_text_size);
  color: var(--form_text_color);
}
#comment-input input:focus,
#comment-textarea textarea:focus,
.avada-select .select2-container .select2-choice2:focus,
.avada-select .select2-container .select2-choice:focus,
.chzn-container .chzn-drop:focus,
.chzn-container-single .chzn-single:focus,
.comment-form-comment textarea:focus,
.fusion-body .avada-select-parent select:focus,
.input-text:focus,
.main-nav-search-form input:focus,
.post-password-form label input[type="password"]:focus,
.search-page-search-form input:focus,
input.s:focus,
input[type="date"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="phone-number"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
select:focus,
textarea:focus {
  border-color: var(--form_focus_border_color);
}
.searchform .fusion-search-form-content .fusion-search-field input,
.select2-container--default .select2-selection--single {
  background-color: var(--form_bg_color);
  font-size: var(--form_text_size);
}
#wrapper .select-arrow,
.avada-select-parent .select-arrow,
.fusion-modal-content .select-arrow,
.select2-dropdown {
  background-color: var(--form_bg_color);
}
#calc_shipping_state_field .select2-selection__placeholder,
#comment-input .placeholder,
#comment-textarea .placeholder,
.fusion-search-element
  .searchform.fusion-search-form-clean
  .fusion-search-form-content
  .fusion-search-button
  input[type="submit"],
.fusion-search-form-clean
  .searchform:not(.fusion-search-form-classic)
  .fusion-search-form-content
  .fusion-search-button
  input[type="submit"],
.select2-results__option,
input.s .placeholder {
  font-size: var(--form_text_size);
  color: var(--form_text_color);
}
#calc_shipping_state_field .select2-selection__placeholder:focus,
#comment-input .placeholder:focus,
#comment-textarea .placeholder:focus,
.fusion-search-element
  .searchform.fusion-search-form-clean
  .fusion-search-form-content
  .fusion-search-button
  input[type="submit"]:focus,
.fusion-search-form-clean
  .searchform:not(.fusion-search-form-classic)
  .fusion-search-form-content
  .fusion-search-button
  input[type="submit"]:focus,
.select2-results__option:focus,
input.s .placeholder:focus {
  border-color: var(--form_focus_border_color);
}
.select2-selection__rendered {
  font-size: var(--form_text_size);
}
.catalog-ordering a,
.order-dropdown ul li a,
.order-dropdown > li:after,
.searchform .fusion-search-form-content .fusion-search-field input,
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: var(--form_text_color);
}
.catalog-ordering a:focus,
.order-dropdown ul li a:focus,
.order-dropdown > li:after:focus,
.searchform .fusion-search-form-content .fusion-search-field input:focus,
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered:focus {
  border-color: var(--form_focus_border_color);
}
.quicktags-toolbar {
  background: linear-gradient(
      to top,
      var(--content_bg_color),
      var(--form_bg_color)
    )
    #3e3e3e;
}
.quicktags-toolbar input {
  font-size: var(--form_text_size);
  color: var(--form_text_color);
}
.quicktags-toolbar input:hover {
  background: var(--form_bg_color);
}
.quicktags-toolbar input:focus {
  border-color: var(--form_focus_border_color);
}
.fusion-live-search .fusion-search-results {
  background-color: var(--form_bg_color);
  font-size: var(--form_text_size);
  color: var(--form_text_color);
  border-radius: var(--form_border_radius);
}
.fusion-live-search .fusion-search-results:focus {
  border-color: var(--form_focus_border_color);
}
.fusion-live-search .fusion-search-results.suggestions-added {
  margin-top: var(--form_border_width-top);
}
.fusion-live-search .fusion-search-result,
.fusion-live-search .fusion-search-results {
  border-color: var(--form_focus_border_color);
  border-width: var(--form_border_width-top) var(--form_border_width-right)
    var(--form_border_width-bottom) var(--form_border_width-left);
}
#wrapper .select-arrow,
.avada-select-parent .select-arrow,
.fusion-modal-content .select-arrow,
.gravity-select-parent .select-arrow,
.select-arrow,
.wpcf7-select-parent .select-arrow {
  font-size: calc((var(--form_text_size)) * 0.75);
}
#comment-input input::-moz-placeholder,
#comment-textarea textarea::-moz-placeholder,
.comment-form-comment textarea::-moz-placeholder,
.input-text::-moz-placeholder,
.post-password-form label input[type="password"]::-moz-placeholder,
.searchform .s::-moz-placeholder,
input#s::-moz-placeholder,
input::-moz-placeholder,
textarea.fusion-form-input::-moz-placeholder {
  color: var(--form_text_color);
}
#comment-input input::-webkit-input-placeholder,
#comment-textarea textarea::-webkit-input-placeholder,
.comment-form-comment textarea::-webkit-input-placeholder,
.input-text::-webkit-input-placeholder,
.post-password-form label input[type="password"]::-webkit-input-placeholder,
.searchform .s::-webkit-input-placeholder,
input#s::-webkit-input-placeholder,
input::-webkit-input-placeholder,
textarea.fusion-form-input::-webkit-input-placeholder {
  color: var(--form_text_color);
}
.fusion-body select:focus + .select-arrow,
.fusion-body select:focus + .select2-arrow {
  border-color: var(--form_focus_border_color);
  color: var(--form_focus_border_color);
}
.select2-container--default.select2-container--open .select2-dropdown,
.select2-container--default.select2-container--open
  .select2-dropdown
  .select2-search,
.select2-container--default.select2-container--open
  .select2-search--dropdown
  .select2-search__field,
.select2-container--default.select2-container--open .select2-selection--single,
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow,
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__rendered {
  border-color: var(--form_focus_border_color);
}
.select2-container--default.select2-container--open
  .select2-selection--single:focus
  .select2-selection__arrow
  b {
  border-bottom-color: var(--form_focus_border_color);
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-bottom-color: var(--form_border_color);
}
.select2-container--default
  .select2-container
  .select2-selection:focus
  .select2-selection__arrow {
  border-color: var(--form_focus_border_color);
}
.select2-container--default
  .select2-selection--single:focus
  .select2-selection__arrow
  b {
  border-top-color: var(--form_focus_border_color);
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-top-color: var(--form_border_color);
}
.fusion-search-form-clean .fusion-live-search .fusion-slider-loading {
  border-bottom-color: var(--form_text_color);
  border-top-color: var(--form_text_color-35a);
  border-right-color: var(--form_text_color-35a);
  border-left-color: var(--form_text_color-35a);
}
#comment-input input,
#comment-textarea textarea,
.avada-select .select2-container .select2-choice,
.avada-select .select2-container .select2-choice .select2-arrow,
.avada-select .select2-container .select2-choice2 .select2-arrow,
.chzn-container .chzn-drop,
.chzn-container-single .chzn-single,
.comment-form-comment textarea,
.fusion-body .avada-select-parent .select-arrow,
.fusion-body .avada-select-parent select,
.gravity-select-parent .select-arrow,
.input-text,
.main-nav-search-form input,
.post-password-form label input[type="password"],
.search-page-search-form input,
.searchform .fusion-search-form-content .fusion-search-field input,
.select-arrow,
.select2-container .select2-selection .select2-selection__arrow,
.select2-container--default .select2-dropdown .select2-search,
.select2-container--default .select2-search--dropdown .select2-search__field,
.select2-container--default .select2-selection--single,
.select2-dropdown,
input.s,
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="phone-number"],
input[type="text"],
input[type="time"],
select,
textarea {
  border-width: var(--form_border_width-top) var(--form_border_width-right)
    var(--form_border_width-bottom) var(--form_border_width-left);
  border-color: var(--form_border_color);
  border-radius: var(--form_border_radius);
}
.select-arrow,
.select2-arrow {
  color: var(--form_border_color);
}
.quicktags-toolbar input {
  border: 1px solid var(--form_border_color);
}
.fusion-contact-form .grecaptcha-badge {
  box-shadow: 0 0 3px var(--form_border_color) !important;
}
.fusion-search-element
  .searchform.fusion-search-form-classic
  .fusion-search-form-content,
.fusion-search-form-classic
  .searchform:not(.fusion-search-form-clean)
  .fusion-search-form-content,
input[type="tel"] {
  border-radius: var(--form_border_radius);
  border-width: var(--form_border_width-top) var(--form_border_width-right)
    var(--form_border_width-bottom) var(--form_border_width-left);
}
.select2-container .select2-selection .select2-selection__arrow {
  border-width: var(--form_border_width-top) var(--form_border_width-right)
    var(--form_border_width-bottom) var(--form_border_width-left);
}
.avada-select-parent .select-arrow,
.gravity-select-parent .select-arrow,
.select-arrow,
.wpcf7-select-parent .select-arrow {
  bottom: var(--form_border_width-bottom);
}
.fusion-blog-layout-masonry .fusion-post-content-wrapper,
.fusion-portfolio-layout-masonry .fusion-portfolio-content {
  background-color: var(--timeline_bg_color-not-transparent);
}
.fusion-blog-layout-grid .post .fusion-post-wrapper,
.fusion-blog-layout-timeline .post,
.fusion-portfolio.fusion-portfolio-boxed .fusion-portfolio-content-wrapper {
  background-color: var(--timeline_bg_color);
}
.fusion-blog-layout-timeline .fusion-timeline-circle,
.fusion-blog-layout-timeline .fusion-timeline-date,
.fusion-blog-timeline-layout .fusion-timeline-circle,
.fusion-blog-timeline-layout .fusion-timeline-date {
  background-color: var(--timeline_color);
}
.fusion-blog-timeline-layout .fusion-timeline-arrow,
.fusion-blog-timeline-layout .fusion-timeline-icon,
.fusion-timeline-arrow,
.fusion-timeline-icon {
  color: var(--timeline_color);
}
.fusion-blog-layout-grid .post .flexslider,
.fusion-blog-layout-grid .post .fusion-post-wrapper,
.fusion-blog-layout-timeline .fusion-timeline-arrow,
.fusion-blog-layout-timeline .fusion-timeline-date,
.fusion-blog-layout-timeline .fusion-timeline-line,
.fusion-blog-layout-timeline .post,
.fusion-blog-layout-timeline .post .flexslider,
.fusion-blog-timeline-layout .post,
.fusion-blog-timeline-layout .post .flexslider,
.fusion-portfolio.fusion-portfolio-boxed .fusion-portfolio-content-wrapper {
  border-color: var(--timeline_color);
}
.avada-has-transparent-timeline_color
  .fusion-blog-layout-grid
  .post
  .flexslider,
.avada-has-transparent-timeline_color
  .fusion-blog-layout-grid
  .post
  .fusion-post-wrapper,
.avada-has-transparent-timeline_color
  .fusion-blog-layout-timeline
  .fusion-timeline-arrow,
.avada-has-transparent-timeline_color
  .fusion-blog-layout-timeline
  .fusion-timeline-date,
.avada-has-transparent-timeline_color
  .fusion-blog-layout-timeline
  .fusion-timeline-line,
.avada-has-transparent-timeline_color .fusion-blog-layout-timeline .post,
.avada-has-transparent-timeline_color
  .fusion-blog-layout-timeline
  .post
  .flexslider,
.avada-has-transparent-timeline_color .fusion-blog-timeline-layout .post,
.avada-has-transparent-timeline_color
  .fusion-blog-timeline-layout
  .post
  .flexslider,
.avada-has-transparent-timeline_color
  .fusion-portfolio.fusion-portfolio-boxed
  .fusion-portfolio-content-wrapper,
.avada-has-transparent-timeline_color.fusion-portfolio.fusion-portfolio-boxed
  .fusion-portfolio-content-wrapper {
  border: none;
}
.fusion-blog-layout-grid .post .fusion-content-sep,
.fusion-blog-layout-timeline .post .fusion-content-sep,
.fusion-blog-timeline-layout .post .fusion-content-sep,
.fusion-body .product .fusion-content-sep,
.fusion-portfolio.fusion-portfolio-boxed .fusion-content-sep {
  border-color: var(--grid_separator_color);
}
.fusion-blog-layout-grid .post .fusion-content-sep,
.fusion-layout-timeline .post .fusion-content-sep,
.fusion-portfolio.fusion-portfolio-boxed .fusion-content-sep {
  border-color: var(--grid_separator_color);
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .avada-footer-fx-bg-parallax.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper,
  .avada-footer-fx-no-height-bg-parallax.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper,
  .avada-footer-fx-no-height-parallax-effect.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper,
  .avada-footer-fx-no-height-sticky-with-parallax-bg-image.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper,
  .avada-footer-fx-parallax-effect.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper,
  .avada-footer-fx-sticky-with-parallax-bg-image.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper {
    background-color: transparent;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .avada-footer-fx-bg-parallax.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper,
  .avada-footer-fx-no-height-bg-parallax.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper,
  .avada-footer-fx-no-height-parallax-effect.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper,
  .avada-footer-fx-no-height-sticky-with-parallax-bg-image.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper,
  .avada-footer-fx-parallax-effect.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper,
  .avada-footer-fx-sticky-with-parallax-bg-image.avada-has-footer-widget-bg-image
    .fusion-body
    #wrapper {
    background-color: transparent;
  }
}
#slidingbar-area .widget-title,
#slidingbar-area h3,
.fusion-privacy-bar-full .column-title {
  font-family: var(--footer_headings_typography-font-family);
  font-size: var(--footer_headings_typography-font-size);
  font-weight: var(--footer_headings_typography-font-weight);
  line-height: var(--footer_headings_typography-line-height);
  letter-spacing: var(--footer_headings_typography-letter-spacing);
  font-style: var(--footer_headings_typography-font-style, normal);
  color: var(--footer_headings_typography-color);
}
.fusion-footer-widget-column .product-title {
  color: var(--footer_headings_typography-color);
}
:root {
  --adminbar-height: 32px;
}
@media screen and (max-width: 782px) {
  :root {
    --adminbar-height: 46px;
  }
}
.page-template-blank #boxed-wrapper {
  display: flex;
}
.page-template-blank #boxed-wrapper > div {
  flex-grow: 1;
  flex-basis: auto;
}
#boxed-wrapper {
  overflow: hidden;
}
#main .fusion-row,
#slidingbar-area .fusion-row,
.fusion-footer-widget-area .fusion-row,
.fusion-page-title-row,
.tfs-slider .slide-content-container .slide-content {
  max-width: var(--site_width);
}
.ua-mobile
  .avada-not-responsive
  #slidingbar-area.fusion-sliding-bar-position-bottom,
.ua-mobile
  .avada-not-responsive
  #slidingbar-area.fusion-sliding-bar-position-top {
  width: var(--site_width);
}
body.avada-not-responsive {
  min-width: var(--site_width);
}
html:not(.avada-has-site-width-percent) #main,
html:not(.avada-has-site-width-percent) .fusion-footer-copyright-area,
html:not(.avada-has-site-width-percent) .fusion-footer-widget-area,
html:not(.avada-has-site-width-percent)
  .fusion-sliding-bar-position-bottom
  .fusion-sliding-bar,
html:not(.avada-has-site-width-percent)
  .fusion-sliding-bar-position-top
  .fusion-sliding-bar,
html:not(.avada-has-site-width-percent) .tfs-slider .slide-content-container {
  padding-left: 30px;
  padding-right: 30px;
}
html:not(.avada-has-site-width-percent) #main.width-100 {
  padding-left: var(--hundredp_padding-fallback_to_zero);
  padding-right: var(--hundredp_padding-fallback_to_zero);
}
.fusion-page-title-bar.fusion-tb-page-title-bar {
  padding-left: 0;
  padding-right: 0;
  height: auto;
}
.fusion-footer.fusion-tb-footer .fusion-footer-widget-area {
  padding: 0;
}
body.layout-boxed-mode {
  margin-top: var(--margin_offset-top) !important;
  margin-bottom: var(--margin_offset-bottom) !important;
}
body.layout-boxed-mode #wrapper,
body.layout-boxed-mode .fusion-footer-parallax {
  width: var(--site_width);
  max-width: 100%;
  margin: 0 auto;
}
body.layout-boxed-mode #wrapper.wrapper_blank {
  display: block;
}
.has-sidebar .width-100 .fusion-fullwidth,
.has-sidebar .width-100 .nonhundred-percent-fullwidth {
  margin-left: 0;
  margin-right: 0;
}
.width-100 .fusion-fullwidth,
.width-100 .nonhundred-percent-fullwidth {
  margin-left: var(--hundredp_padding-hundred_percent_negative_margin);
  margin-right: var(--hundredp_padding-hundred_percent_negative_margin);
}
.width-100 .fusion-fullwidth .fusion-fullwidth,
.width-100 .fusion-fullwidth .nonhundred-percent-fullwidth {
  margin-left: 0;
  margin-right: 0;
}
.width-100 .fusion-fullwidth.fusion-absolute-container {
  width: calc(100% + 2 * (var(--hundredp_padding-fallback_to_zero_px)));
}
body.page-template-100-width.side-header
  .fusion-tb-footer
  .fusion-fullwidth.fusion-absolute-container {
  width: calc(100% - var(--side_header_width));
}
html.avada-has-site-width-percent body #main.width-100 > .fusion-row,
html.avada-has-site-width-percent
  body.avada-responsive
  #main.width-100
  > .fusion-row {
  padding-left: 0;
  padding-right: 0;
}
html.avada-has-site-width-percent .width-100 .fullwidth-box,
html.avada-has-site-width-percent
  .width-100
  .fullwidth-box
  .fusion-row
  .fusion-full-width-sep {
  margin-left: var(--hundredp_padding-hundred_percent_negative_margin);
  margin-right: var(--hundredp_padding-hundred_percent_negative_margin);
}
html.avada-has-site-width-percent .width-100 .fullwidth-box .fullwidth-box,
html.avada-has-site-width-percent
  .width-100
  .fullwidth-box
  .fullwidth-box
  .fusion-row
  .fusion-full-width-sep {
  margin-left: 0;
  margin-right: 0;
}
html.avada-has-site-width-percent.avada-has-site-width-100-percent
  #main
  .fusion-row,
html.avada-has-site-width-percent.avada-has-site-width-100-percent
  #sliders-container
  .tfs-slider
  .slide-content-container,
html.avada-has-site-width-percent.avada-has-site-width-100-percent
  #slidingbar
  .fusion-row,
html.avada-has-site-width-percent.avada-has-site-width-100-percent
  .fusion-footer-copyright-area,
html.avada-has-site-width-percent.avada-has-site-width-100-percent
  .fusion-footer-widget-area {
  padding-left: var(--hundredp_padding);
  padding-right: var(--hundredp_padding);
}
body.has-sidebar #main .sidebar {
  width: var(--sidebar_width);
}
body.has-sidebar #content {
  width: calc(100% - var(--sidebar_width) - var(--sidebar_gutter));
}
body.has-sidebar.double-sidebars #main #sidebar {
  width: var(--sidebar_2_1_width);
  margin-left: calc(
    var(--dual_sidebar_gutter) - 100% + var(--sidebar_2_2_width)
  );
}
body.has-sidebar.double-sidebars #main #sidebar-2 {
  width: var(--sidebar_2_2_width);
  margin-left: var(--dual_sidebar_gutter);
}
body.has-sidebar.double-sidebars #content {
  width: calc(
    100% - var(--sidebar_2_1_width) - var(--sidebar_2_2_width) -
      var(--dual_sidebar_gutter) - var(--dual_sidebar_gutter)
  );
  margin-left: calc(var(--sidebar_2_1_width) + var(--dual_sidebar_gutter));
}
#main {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: var(--main_padding-top);
  padding-bottom: var(--main_padding-bottom);
}
#main.width-100 {
  padding-left: var(--hundredp_padding-fallback_to_zero);
  padding-right: var(--hundredp_padding-fallback_to_zero);
}
html.avada-is-100-percent-template #main {
  padding-left: 0;
  padding-right: 0;
}
html.avada-is-100-percent-template #main.width-100 {
  padding-left: var(--hundredp_padding-fallback_to_zero);
  padding-right: var(--hundredp_padding-fallback_to_zero);
}
body.layout-boxed-mode.layout-scroll-offset-framed {
  margin-top: var(--margin_offset-top-no-percent) !important;
  margin-bottom: var(--margin_offset-bottom-no-percent) !important;
}
.fusion-bottom-frame,
.fusion-boxed-shadow,
.fusion-sides-frame,
.fusion-top-frame {
  display: none;
}
.layout-boxed-mode.layout-scroll-offset-framed .fusion-sides-frame {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
  top: 0;
  pointer-events: none;
}
.layout-boxed-mode.layout-scroll-offset-framed:not(.avada-has-zero-margin-offset-top)
  .fusion-bottom-frame,
.layout-boxed-mode.layout-scroll-offset-framed:not(.avada-has-zero-margin-offset-top)
  .fusion-top-frame {
  display: block;
  position: fixed;
  z-index: 99999;
  width: 100%;
}
.layout-boxed-mode.layout-scroll-offset-framed:not(.avada-has-zero-margin-offset-top)
  .fusion-sides-frame,
.layout-boxed-mode.layout-scroll-offset-framed:not(.avada-has-zero-margin-offset-top)
  .fusion-top-frame {
  top: 0;
}
.layout-boxed-mode.layout-scroll-offset-framed:not(.avada-has-zero-margin-offset-top).avada-has-boxed-modal-shadow-none
  .fusion-boxed-shadow {
  display: none;
}
.layout-boxed-mode.layout-scroll-offset-framed .fusion-top-frame {
  height: var(--margin_offset-top-no-percent);
}
.layout-boxed-mode.layout-scroll-offset-framed .fusion-bottom-frame {
  height: var(--margin_offset-bottom-no-percent);
}
.layout-boxed-mode.layout-scroll-offset-framed .fusion-boxed-shadow {
  width: var(--site_width);
  height: calc(
    100vh - var(--margin_offset-top-no-percent) -
      var(--margin_offset-bottom-no-percent)
  );
  top: var(--margin_offset-top-no-percent);
  left: calc((100% - var(--site_width)) / 2);
}
.layout-boxed-mode.layout-scroll-offset-framed.admin-bar .fusion-sides-frame,
.layout-boxed-mode.layout-scroll-offset-framed.admin-bar .fusion-top-frame {
  top: 32px;
}
.layout-boxed-mode.layout-scroll-offset-framed.admin-bar .fusion-boxed-shadow {
  height: calc(
    100vh - var(--margin_offset-top-no-percent) -
      var(--margin_offset-bottom-no-percent) - var(--adminbar-height)
  );
}
.layout-boxed-mode.layout-scroll-offset-framed.admin-bar .fusion-boxed-shadow {
  top: calc(var(--margin_offset-top-no-percent) + var(--adminbar-height));
}
html.avada-has-site-width-percent
  .avada-responsive.layout-boxed-mode
  #main
  .fusion-row,
html.avada-has-site-width-percent
  .avada-responsive.layout-boxed-mode
  #slidingbar-area
  .fusion-row,
html.avada-has-site-width-percent
  .avada-responsive.layout-boxed-mode
  #small-nav
  .fusion-row,
html.avada-has-site-width-percent
  .avada-responsive.layout-boxed-mode
  .fusion-footer-copyright-area
  .fusion-row,
html.avada-has-site-width-percent
  .avada-responsive.layout-boxed-mode
  .fusion-footer-widget-area
  .fusion-row,
html.avada-has-site-width-percent
  .avada-responsive.layout-boxed-mode
  .fusion-page-title-row {
  max-width: none;
  padding-left: 10px;
  padding-right: 10px;
}
html.avada-has-site-width-percent.avada-html-not-responsive {
  overflow-x: auto;
}
html.avada-has-site-width-percent.avada-html-not-responsive body {
  overflow-x: auto;
}
.layout-wide-mode #wrapper {
  width: 100%;
  max-width: none;
}
.avada-has-pagetitle-100-width.layout-wide-mode .fusion-page-title-row {
  max-width: 100%;
}
.fusion-tb-footer .fusion-fullwidth,
.fusion-tb-header .fusion-fullwidth,
.fusion-tb-page-title-bar .fusion-fullwidth {
  padding-left: var(--hundredp_padding);
  padding-right: var(--hundredp_padding);
}
.avada-footer-fx-parallax-effect .fusion-tb-header .fusion-fullwidth {
  z-index: 2;
}
.fusion-portfolio-boxed .fusion-portfolio-unboxed .fusion-portfolio-content {
  padding: 0;
  margin: 20px 0;
}
.fusion-portfolio-boxed
  .fusion-portfolio-unboxed
  .fusion-portfolio-content-wrapper {
  border: none;
}
.fusion-portfolio-layout-masonry .fusion-portfolio-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  margin: 0;
}
.fusion-portfolio-content-wrapper .fusion-portfolio-content {
  text-align: var(--portfolio_archive_text_alignment);
}
.fusion-portfolio-layout-masonry {
  margin: calc((var(--portfolio_archive_column_spacing)) / -2);
}
.fusion-portfolio-layout-masonry .fusion-portfolio-content {
  padding: var(--portfolio_archive_text_layout-padding);
}
article.fusion-portfolio-post.post.fusion-col-spacing {
  padding: calc((var(--portfolio_archive_column_spacing)) / 2);
}
.fusion-portfolio-boxed .fusion-portfolio-content {
  padding-top: var(--portfolio_archive_layout_padding-top);
  padding-bottom: var(--portfolio_archive_layout_padding-bottom);
  padding-left: var(--portfolio_archive_layout_padding-left);
  padding-right: var(--portfolio_archive_layout_padding-right);
}
.fusion-portfolio-archive .fusion-load-more-button.fusion-portfolio-button {
  background-color: var(--portfolio_archive_load_more_posts_button_bg_color);
  color: var(--portfolio_archive_load_more_posts_button_text_color);
}
.fusion-portfolio-archive
  .fusion-load-more-button.fusion-portfolio-button:hover {
  background-color: var(
    --portfolio_archive_load_more_posts_hover_button_bg_color
  );
  color: var(--portfolio_archive_load_more_posts_hover_button_text_color);
}
.fusion-portfolio-content .fusion-portfolio-meta {
  font-size: var(--portfolio_meta_font_size);
}
.fusion-live-search .fusion-search-results {
  max-height: var(--live_search_results_height);
}
.search #posts-container .fusion-blog-layout-grid,
.search-results #posts-container .fusion-blog-layout-grid {
  margin-top: calc((var(--search_grid_column_spacing)) / -2);
  margin-right: calc((var(--search_grid_column_spacing)) / -2);
  margin-bottom: 0;
  margin-left: calc((var(--search_grid_column_spacing)) / -2);
}
.search #posts-container .fusion-blog-layout-grid .fusion-post-grid,
.search-results #posts-container .fusion-blog-layout-grid .fusion-post-grid {
  padding: calc((var(--search_grid_column_spacing)) / 2);
}
.fusion-main-menu-search-overlay .fusion-overlay-search .fusion-search-spacer {
  width: calc((var(--form_input_height)) * 0.4);
}
.fusion-main-menu-search-overlay .fusion-overlay-search .fusion-close-search {
  width: var(--form_text_size);
  height: var(--form_text_size);
}
.fusion-main-menu-search-overlay
  .fusion-overlay-search
  .fusion-close-search:after,
.fusion-main-menu-search-overlay
  .fusion-overlay-search
  .fusion-close-search:before {
  background-color: var(--nav_typography-color);
}
.fusion-main-menu-search-overlay
  .fusion-overlay-search
  .fusion-close-search:hover:after,
.fusion-main-menu-search-overlay
  .fusion-overlay-search
  .fusion-close-search:hover:before {
  background-color: var(--menu_hover_first_color);
}
#main .fusion-sticky-sidebar .fusion-sidebar-inner-content,
#main .sidebar:not(.fusion-sticky-sidebar) {
  background-color: var(--sidebar_bg_color);
}
#main .sidebar {
  padding: var(--sidebar_padding);
}
#main .sidebar.fusion-sticky-sidebar .fusion-sidebar-inner-content {
  padding: var(--sidebar_padding-percent_to_vw);
}
.sidebar .widget .heading .widget-title,
.sidebar .widget .widget-title {
  background-color: var(--sidebar_widget_bg_color);
  padding: var(--sidebar_widget_bg_color-opaque-padding);
}
.sidebar .widget .widget-title {
  font-size: var(--sidew_font_size);
}
.sidebar .widget .heading h4,
.sidebar .widget .widget-title {
  color: var(--sidebar_heading_color);
}
.fusion-body .fusion-flexslider .flex-direction-nav a,
.fusion-body .fusion-flexslider.flexslider-attachments .flex-direction-nav a,
.fusion-body .fusion-flexslider.flexslider-posts .flex-direction-nav a,
.fusion-body
  .fusion-flexslider.flexslider-posts-with-excerpt
  .flex-direction-nav
  a,
.fusion-body .fusion-slider-sc .flex-direction-nav a,
.fusion-body .woocommerce-product-gallery .flex-direction-nav a {
  width: var(--slider_nav_box_dimensions-width);
  height: var(--slider_nav_box_dimensions-height);
  line-height: var(--slider_nav_box_dimensions-height);
  font-size: var(--slider_arrow_size);
}
.fusion-body .fusion-carousel .fusion-carousel-nav .fusion-nav-next,
.fusion-body .fusion-carousel .fusion-carousel-nav .fusion-nav-prev {
  width: var(--slider_nav_box_dimensions-width);
  height: var(--slider_nav_box_dimensions-height);
  margin-top: calc((var(--slider_nav_box_dimensions-height)) / -2);
}
.fusion-body .fusion-carousel .fusion-carousel-nav .fusion-nav-next:before,
.fusion-body .fusion-carousel .fusion-carousel-nav .fusion-nav-prev:before {
  line-height: var(--slider_nav_box_dimensions-height);
  font-size: var(--slider_arrow_size);
}
.fusion-social-links-footer .fusion-social-networks a {
  font-size: var(--footer_social_links_font_size);
}
.fusion-social-links-footer .fusion-social-networks a.custom {
  max-height: var(--footer_social_links_boxed_padding);
}
.fusion-social-links-footer .fusion-social-networks a.custom img {
  max-height: var(--footer_social_links_font_size);
}
.fusion-social-links-footer .fusion-social-networks.boxed-icons a {
  width: calc(
    var(--footer_social_links_font_size) +
      (2 * (var(--footer_social_links_boxed_padding))) + 2px
  );
  padding: var(--footer_social_links_boxed_padding);
}
.fusion-social-links-footer .fusion-social-networks.boxed-icons a.custom {
  top: calc((var(--footer_social_links_boxed_padding)) * -1);
}
.fusion-social-links-footer .fusion-social-networks.boxed-icons a.custom img {
  max-height: calc(
    var(--footer_social_links_font_size) +
      (2 * (var(--footer_social_links_boxed_padding))) + 2px
  );
}
.fusion-social-links-footer
  .fusion-social-networks.boxed-icons
  .fusion-social-network-icon {
  color: var(--footer_social_links_icon_color);
}
.fusion-social-links-footer
  .fusion-social-networks.boxed-icons
  .fusion-social-network-icon:not(.custom) {
  background-color: var(--footer_social_links_box_color);
  border-color: var(--footer_social_links_box_color);
  border-radius: var(--footer_social_links_boxed_radius);
}
.fusion-social-links-footer
  .fusion-social-networks
  .fusion-social-network-icon {
  color: var(--footer_social_links_icon_color);
}
.fusion-social-networks.boxed-icons .fusion-social-network-icon:not(.custom) {
  background-color: var(--footer_social_links_box_color);
  border-color: var(--footer_social_links_box_color);
}
.fusion-single-sharing-box {
  background-color: var(--social_bg_color);
  padding: var(--social_bg_color-0-transparent, 20px);
}
.fusion-footer-widget-column {
  margin-bottom: 50px;
}
.fusion-footer-widget-column:last-child {
  margin-bottom: 0;
}
.fusion-footer-widget-column .row {
  margin-left: 0;
  margin-right: 0;
}
.fusion-footer-widget-column .flexslider a {
  border: 0;
  padding: 0;
}
.fusion-footer footer .fusion-columns .fusion-column {
  padding-left: var(--footer_widgets_padding);
  padding-right: var(--footer_widgets_padding);
}
.fusion-body .fusion-footer-widget-area-center .fusion-columns .fusion-column {
  text-align: center;
}
.fusion-body .fusion-footer-copyright-center .fusion-social-links-footer {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.fusion-footer footer .fusion-columns {
  margin: 0 calc((var(--footer_widgets_padding)) * -1);
}
.fusion-footer footer .fusion-columns .fusion-column.fusion-has-widgets {
  border-color: var(--footer_divider_color);
}
.ltr .fusion-footer footer .fusion-columns .fusion-column.fusion-has-widgets {
  border-right-width: var(--footer_divider_line_size);
  border-right-style: var(--footer_divider_line_style);
}
.rtl .fusion-footer footer .fusion-columns .fusion-column.fusion-has-widgets {
  border-left-width: var(--footer_divider_line_size);
  border-left-style: var(--footer_divider_line_style);
}
.fusion-footer footer .fusion-row .fusion-columns {
  display: var(--footer_divider_line-flex);
  flex-flow: wrap;
}
.rtl
  .fusion-footer
  footer
  .fusion-row
  .fusion-columns
  .fusion-column.fusion-column-last {
  border-left: none;
}
.ltr
  .fusion-footer
  footer
  .fusion-row
  .fusion-columns
  .fusion-column.fusion-column-last {
  border-right: none;
}
.fusion-footer-widget-area {
  position: relative;
  padding: var(--footer_area_padding-top) 10px var(--footer_area_padding-bottom);
  border-top: var(--footer_border_size) solid var(--footer_border_color);
  background-image: var(--footerw_bg_image);
  background-repeat: var(--footerw_bg_repeat);
  background-color: var(--footer_bg_color);
  background-size: var(--footerw_bg_full-size);
  background-position: var(--footerw_bg_full-position);
  color: var(--footer_text_color);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.fusion-footer-widget-area .widget_shopping_cart_content .buttons {
  background: rgba(0, 0, 0, 0.4);
}
.fusion-footer-widget-area .products-slider li .image {
  width: auto;
  height: auto;
}
.fusion-footer-widget-area h3 {
  font-family: var(--footer_headings_typography-font-family);
  font-size: var(--footer_headings_typography-font-size);
  font-weight: var(--footer_headings_typography-font-weight);
  line-height: var(--footer_headings_typography-line-height);
  letter-spacing: var(--footer_headings_typography-letter-spacing);
  font-style: var(--footer_headings_typography-font-style, normal);
  color: var(--footer_headings_typography-color);
}
.fusion-footer-widget-area .fusion-footer-widget-column .fusion-columns {
  margin-left: 0;
  margin-right: 0;
}
.fusion-footer-widget-area .fusion-footer-widget-column .fusion-column {
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
}
.fusion-footer-widget-area
  .fusion-footer-widget-column:not(.widget_icl_lang_sel_widget)
  ul:first-child
  > li:first-child
  > a,
.fusion-footer-widget-area
  .fusion-footer-widget-column:not(.widget_icl_lang_sel_widget)
  > ul
  > li:first-child
  > a {
  background-image: none;
  padding-top: 0;
}
.fusion-footer-widget-area ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
}
.fusion-footer-widget-area ul.fusion-carousel-holder li a,
.fusion-footer-widget-area ul.fusion-checklist li a {
  border: 0;
  padding: 0;
}
.fusion-footer-widget-area ul#recentcomments li.recentcomments:first-child {
  padding-top: 0;
  background: 0 0;
}
.fusion-footer-widget-area ul li {
  border-color: var(--footer_divider_color);
}
.fusion-footer-widget-area ul li a {
  display: block;
  position: relative;
}
.fusion-footer-widget-area ul li a:hover {
  color: var(--footer_link_color_hover);
}
.fusion-footer-widget-area li.recentcomments {
  padding: 12px 0;
  border-bottom: 1px solid #282a2b;
  display: block;
}
.fusion-footer-widget-area li.recentcomments a {
  border: none;
}
.fusion-footer-widget-area .widget_recent_entries li {
  border-bottom: 1px solid;
}
.fusion-footer-widget-area .widget_recent_entries a {
  border-bottom: 0;
  padding-bottom: 0;
}
.fusion-footer-widget-area .contact_info p {
  margin: 0;
}
.fusion-footer-parallax .fusion-footer-widget-area {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}
.fusion-footer-widget-area .widget-title {
  margin: 0 0 28px;
  text-transform: uppercase;
  font-family: var(--footer_headings_typography-font-family);
  font-size: var(--footer_headings_typography-font-size);
  font-weight: var(--footer_headings_typography-font-weight);
  line-height: var(--footer_headings_typography-line-height);
  letter-spacing: var(--footer_headings_typography-letter-spacing);
  font-style: var(--footer_headings_typography-font-style, normal);
  color: var(--footer_headings_typography-color);
}
.fusion-footer-widget-area a {
  color: var(--footer_link_color);
}
.fusion-footer-widget-area a:hover {
  color: var(--primary_color);
}
.fusion-footer-widget-area .widget > ul > li:first-child {
  padding-top: 0;
}
.fusion-footer-widget-area .widget li a:before {
  color: var(--footer_link_color);
}
.fusion-footer-widget-area .widget li.recentcomments {
  border-color: var(--footer_divider_color);
  color: var(--footer_text_color);
}
.fusion-footer-widget-area .widget li a:hover:before,
.fusion-footer-widget-area .widget li.recentcomments:hover:before {
  color: var(--footer_link_color_hover);
}
.fusion-footer-widget-area .widget a:hover {
  color: var(--footer_link_color_hover);
}
.fusion-footer-widget-area .widget_nav_menu > div > ul > li:first-child {
  padding-top: 0;
}
.fusion-footer-widget-area .widget_nav_menu ul ul li a {
  padding-top: 12px;
}
.fusion-footer-widget-area .holder-block img {
  width: 100%;
}
.fusion-footer-widget-area .widget .recentcomments:before,
.fusion-footer-widget-area .widget_archive li a:before,
.fusion-footer-widget-area .widget_categories li a:before,
.fusion-footer-widget-area .widget_links li a:before,
.fusion-footer-widget-area .widget_meta li a:before,
.fusion-footer-widget-area .widget_nav_menu li a:before,
.fusion-footer-widget-area .widget_pages li a:before,
.fusion-footer-widget-area .widget_recent_entries li a:before,
.fusion-footer-widget-area .widget_rss li a:before {
  position: absolute;
  font-family: awb-icons;
  content: "\f105";
  margin-left: -12px;
}
.fusion-footer-widget-area .widget_calendar table {
  width: 100%;
}
.fusion-footer-widget-area .fusion-tabs-widget .fusion-tabs-nav ul li {
  border-color: var(--footer_bg_color);
}
.fusion-footer-widget-area .fusion-tabs-widget .fusion-tabs-nav ul li a {
  background: rgba(0, 0, 0, 0.2);
  color: var(--footer_link_color);
}
.fusion-footer-widget-area .fusion-tabs-widget .fusion-tabs-nav ul li a:hover {
  background: 0 0;
}
.fusion-footer-widget-area .fusion-tabs-widget .fusion-tabs-nav ul li.active a {
  background: 0 0;
  border-color: var(--footer_link_color_hover);
}
.fusion-footer-widget-area
  .fusion-tabs-widget
  .fusion-tabs-widget-content
  .fusion-date-box {
  background: rgba(0, 0, 0, 0.2);
  color: var(--footer_link_color);
}
.fusion-footer-widget-area
  .fusion-tabs-widget
  .fusion-tabs-widget-content
  .post-holder
  a {
  color: var(--footer_link_color);
}
.fusion-footer-widget-area
  .fusion-tabs-widget
  .fusion-tabs-widget-content
  .post-holder
  a:hover {
  color: var(--footer_link_color_hover);
}
.fusion-footer-widget-area
  .fusion-tabs-widget
  .fusion-tabs-widget-content
  .fusion-tabs-widget-items
  li {
  border-color: var(--footer_divider_color);
}
.fusion-footer-widget-area
  .fusion-tabs-widget
  .fusion-tabs-clean
  .fusion-tabs-nav
  ul {
  border-color: var(--footer_divider_color);
}
.fusion-footer-widget-area
  .fusion-tabs-widget
  .fusion-tabs-clean
  .fusion-tabs-nav
  ul
  li {
  border-color: var(--footer_divider_color);
}
.fusion-footer-widget-area .fusion-tabs-widget .fusion-tabs-classic {
  border-color: var(--footer_divider_color);
}
.fusion-footer-widget-area .fusion-vertical-menu-widget ul.menu li a {
  color: var(--footer_link_color);
}
.fusion-footer-widget-area .fusion-vertical-menu-widget ul.menu li a:hover {
  color: var(--primary_color);
}
.fusion-footer-widget-area .fusion-tabs-clean .fusion-tabs-nav ul {
  border: 1px solid var(--footer_divider_color);
}
.fusion-footer-widget-area .widget_archive li,
.fusion-footer-widget-area .widget_categories li,
.fusion-footer-widget-area .widget_layered_nav li,
.fusion-footer-widget-area .widget_links li,
.fusion-footer-widget-area .widget_meta li,
.fusion-footer-widget-area .widget_nav_menu li,
.fusion-footer-widget-area .widget_pages li,
.fusion-footer-widget-area .widget_product_categories li,
.fusion-footer-widget-area .widget_recent_entries li {
  border-color: var(--footer_divider_color);
  color: var(--footer_text_color);
}
.fusion-footer-widget-area .product_list_widget li {
  border-color: var(--footer_divider_color);
}
.fusion-footer-widget-area .jtwt,
.fusion-footer-widget-area .widget_layered_nav li,
.fusion-footer-widget-area article.col {
  color: var(--footer_text_color);
}
.fusion-footer-widget-area .jtwt .jtwt_tweet {
  color: var(--footer_text_color);
}
.fusion-footer-widget-area .jtwt .jtwt_tweet a {
  color: var(--footer_link_color);
}
.fusion-footer-widget-area > .fusion-row {
  padding-left: var(--footer_area_padding-left);
  padding-right: var(--footer_area_padding-right);
}
.fusion-footer-widget-area .tagcloud a {
  border-color: var(--footer_divider_color);
}
.fusion-footer-widget-area .tagcloud a:hover {
  background-color: var(--footer_link_color_hover);
}
.fusion-footer-widget-area .fusion-accordian .panel-title a {
  color: var(--footer_link_color);
}
.fusion-footer-widget-area .fusion-accordian .panel-title a:hover {
  color: var(--footer_link_color_hover);
}
.fusion-footer-widget-area
  .fusion-accordian
  .panel-title
  a:hover
  .fa-fusion-box {
  background-color: var(--footer_link_color_hover) !important;
}
.fusion-footer-widget-area .fusion-accordian .fusion-panel {
  border-color: var(--footer_divider_color);
}
.layout-wide-mode.avada-has-100-footer
  .fusion-footer-widget-area
  > .fusion-row {
  max-width: 100% !important;
}
#wrapper .fusion-footer-widget-area .current-menu-item > a,
#wrapper .fusion-footer-widget-area .current-menu-item > a:before,
#wrapper .fusion-footer-widget-area .current_page_item > a,
#wrapper .fusion-footer-widget-area .current_page_item > a:before,
#wrapper
  .fusion-footer-widget-area
  .fusion-vertical-menu-widget
  .menu
  li.current-menu-item
  > a,
#wrapper
  .fusion-footer-widget-area
  .fusion-vertical-menu-widget
  .menu
  li.current_page_ancestor
  > a {
  color: var(--footer_link_color_hover);
}
#wrapper .fusion-footer-widget-area .jtwt .jtwt_tweet a:hover {
  color: var(--footer_link_color_hover);
}
#wrapper
  .fusion-footer-widget-area
  .fusion-tabs.classic
  .nav-tabs
  > li.active
  .tab-link,
#wrapper
  .fusion-footer-widget-area
  .fusion-tabs.classic
  .nav-tabs
  > li.active
  .tab-link:focus,
#wrapper
  .fusion-footer-widget-area
  .fusion-tabs.classic
  .nav-tabs
  > li.active
  .tab-link:hover,
#wrapper
  .fusion-footer-widget-area
  .fusion-tabs.vertical-tabs.classic
  .nav-tabs
  > li.active
  .tab-link {
  border-color: var(--footer_link_color_hover);
}
.fusion-footer-copyright-area {
  z-index: 10;
  position: relative;
  padding: var(--copyright_padding-top) 10px var(--copyright_padding-bottom);
  border-top: var(--copyright_border_size) solid var(--copyright_border_color);
  background-color: var(--copyright_bg_color);
}
.fusion-footer-copyright-area .fusion-row {
  max-width: var(--site_width);
}
.fusion-footer-copyright-area > .fusion-row {
  padding-left: var(--footer_area_padding-left);
  padding-right: var(--footer_area_padding-right);
}
.fusion-copyright-notice a {
  color: var(--copyright_link_color);
}
.fusion-footer-copyright-area a:hover {
  color: var(--copyright_link_color_hover);
}
.fusion-copyright-content {
  display: table;
  width: 100%;
}
.fusion-copyright-notice {
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  color: var(--copyright_text_color);
  font-size: var(--copyright_font_size);
}
.fusion-body .fusion-footer-copyright-center .fusion-copyright-content {
  display: block;
  text-align: center;
}
.fusion-body .fusion-footer-copyright-center .fusion-copyright-notice {
  display: block;
  width: 100%;
  text-align: center;
}
.layout-wide-mode.avada-has-100-footer
  .fusion-footer-copyright-area
  > .fusion-row {
  max-width: 100% !important;
}
#nav ul li ul li a,
#sticky-nav ul li ul li a,
#wrapper #nav ul li ul li > a,
#wrapper #sticky-nav ul li ul li > a,
.avada-container h3,
.comment-form input[type="submit"],
.ei-title h3,
.fusion-blog-shortcode .fusion-timeline-date,
.fusion-body #main .tribe-common .tribe-events-c-day-marker__date,
.fusion-body #main .tribe-events .datepicker,
.fusion-body .fusion-wrapper #main .tribe-common .tribe-common-h6--min-medium,
.fusion-body .tribe-common .tribe-common-b2,
.fusion-body .tribe-common .tribe-common-b3,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-categories,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-title,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-content .price,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-content a,
.fusion-load-more-button,
.fusion-main-menu .sub-menu,
.fusion-main-menu .sub-menu li a,
.fusion-megamenu-widgets-container,
.fusion-megamenu-wrapper .fusion-megamenu-submenu > a:hover,
.fusion-megamenu-wrapper li .fusion-megamenu-title-disabled,
.fusion-page-title-bar h3,
.gform_page_footer input[type="button"],
.meta .fusion-date,
.more,
.post-content blockquote,
.review blockquote div strong,
.review blockquote q,
.ticket-selector-submit-btn[type="submit"],
body {
  font-family: var(--body_typography-font-family);
  font-weight: var(--body_typography-font-weight);
  letter-spacing: var(--body_typography-letter-spacing);
  font-style: var(--body_typography-font-style, normal);
}
#slidingbar-area .slide-excerpt h2,
#slidingbar-area ul,
.counter-box-content,
.fusion-alert,
.fusion-blog-layout-timeline .fusion-timeline-date,
.fusion-body #main .tribe-events .datepicker,
.fusion-body .tribe-common .tribe-common-b2,
.fusion-body .tribe-common .tribe-common-b3,
.fusion-footer-widget-area .slide-excerpt h2,
.fusion-footer-widget-area ul,
.fusion-progressbar .progress-title,
.fusion-project-details-tb .project-info h4,
.gform_wrapper .gfield_description,
.gform_wrapper label,
.jtwt .jtwt_tweet,
.post-content blockquote,
.project-content .project-info h4,
.review blockquote q,
.sidebar .jtwt .jtwt_tweet,
.sidebar .slide-excerpt h2,
body {
  font-size: var(--body_typography-font-size);
}
#nav ul li ul li a,
#side-header .fusion-contact-info,
#side-header .header-social .top-menu,
#sticky-nav ul li ul li a,
#wrapper #nav ul li ul li > a,
#wrapper #sticky-nav ul li ul li > a,
.avada-container h3,
.comment-form input[type="submit"],
.ei-title h3,
.fusion-accordian .panel-body,
.fusion-body #main .tribe-common .tribe-events-c-day-marker__date,
.fusion-body .fusion-wrapper #main .tribe-common .tribe-common-h6--min-medium,
.fusion-body .tribe-common .tribe-common-b2,
.fusion-body .tribe-common .tribe-common-b3,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-categories,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-title,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-content .price,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-content a,
.fusion-main-menu .sub-menu,
.fusion-main-menu .sub-menu li a,
.fusion-megamenu-widgets-container,
.fusion-megamenu-wrapper .fusion-megamenu-submenu > a:hover,
.fusion-megamenu-wrapper li .fusion-megamenu-title-disabled,
.fusion-page-title-bar h3,
.gform_page_footer input[type="button"],
.meta .fusion-date,
.more,
.post-content blockquote,
.review blockquote div strong,
.review blockquote q,
.single-tribe_events #tribe-events-content .tribe-events-event-meta dt,
.ticket-selector-submit-btn[type="submit"],
body {
  line-height: var(--body_typography-line-height);
}
#wrapper .meta,
.fusion-blog-timeline-layout .fusion-timeline-date,
.fusion-body #main .tribe-common .tribe-events-c-day-marker__date,
.fusion-body .fusion-wrapper #main .tribe-common .tribe-common-h6--min-medium,
.fusion-body .tribe-block__tickets__registration__tickets__header,
.fusion-body .tribe-common .tribe-common-b2,
.fusion-body .tribe-common .tribe-common-b3,
.fusion-main-menu .fusion-custom-menu-item-contents,
.fusion-project-details-tb .project-info h4,
.fusion-rollover .price .amount,
.post .post-content,
.post-content blockquote,
.project-content .project-info h4,
.review blockquote div,
.search input,
.sidebar .jtwt,
.sidebar .widget .recentcomments,
.sidebar .widget_archive li,
.sidebar .widget_categories li,
.sidebar .widget_layered_nav li,
.sidebar .widget_links li,
.sidebar .widget_meta li,
.sidebar .widget_nav_menu li,
.sidebar .widget_pages li,
.sidebar .widget_product_categories li,
.sidebar .widget_recent_entries li,
.title-row,
body {
  color: var(--body_typography-color);
}
.meta .fusion-date,
.post-content blockquote,
.review blockquote q {
  font-style: italic;
}
#main .post h2 a,
.about-author .title a,
.fusion-blog-pagination .pagination .pagination-next:after,
.fusion-blog-pagination .pagination .pagination-prev:before,
.fusion-content-widget-area .widget .recentcomments a,
.fusion-content-widget-area .widget li a,
.fusion-content-widget-area .widget_categories li,
.fusion-hide-pagination-text .pagination-next:after,
.fusion-hide-pagination-text .pagination-prev:before,
.fusion-load-more-button,
.fusion-rollover a,
.pagination .pagination-next:after,
.pagination .pagination-prev:before,
.project-content .project-info .project-info-box a,
.shop_attributes tr th,
.single-navigation a[rel="next"]:after,
.single-navigation a[rel="prev"]:before,
body a,
body a:after,
body a:before {
  color: var(--link_color);
}
.fusion-modal h1,
.fusion-page-title-bar h1,
.fusion-tb-footer h1,
.fusion-tb-page-title-bar h1,
.fusion-title h1,
.fusion-widget-area h1,
.post-content h1,
.search-page-search-form h1 {
  font-family: var(--h1_typography-font-family);
  font-weight: var(--h1_typography-font-weight);
  line-height: var(--h1_typography-line-height);
  letter-spacing: var(--h1_typography-letter-spacing);
  font-style: var(--h1_typography-font-style, normal);
}
.fusion-modal h1,
.fusion-tb-footer h1,
.fusion-tb-page-title-bar h1,
.fusion-widget-area h1,
.post-content h1,
.search-page-search-form h1 {
  font-size: var(--h1_typography-font-size);
}
.fusion-modal h1,
.fusion-post-content h1,
.fusion-tb-footer h1,
.fusion-tb-page-title-bar h1,
.fusion-widget-area h1,
.post-content h1,
.search-page-search-form h1,
.title h1 {
  color: var(--h1_typography-color);
}
.fusion-title-size-one,
h1 {
  margin-top: var(--h1_typography-margin-top);
  margin-bottom: var(--h1_typography-margin-bottom);
}
#main .post h2,
#main .reading-box h2,
#main h2,
.ei-title h2,
.fusion-modal h2,
.fusion-tb-footer h2,
.fusion-tb-page-title-bar h2,
.fusion-title h2,
.fusion-widget-area h2,
.main-flex .slide-content h2,
.post-content h2,
.title h2 {
  font-family: var(--h2_typography-font-family);
  font-weight: var(--h2_typography-font-weight);
  line-height: var(--h2_typography-line-height);
  letter-spacing: var(--h2_typography-letter-spacing);
  font-style: var(--h2_typography-font-style, normal);
}
#main .fusion-portfolio h2,
#main .post h2,
#wrapper #main .post h2,
#wrapper #main .post-content .fusion-title h2,
#wrapper #main .post-content .title h2,
#wrapper .fusion-title h2,
#wrapper .post-content h2,
#wrapper .title h2,
.fusion-modal h2,
.fusion-tb-footer h2,
.fusion-tb-page-title-bar h2,
.fusion-widget-area h2,
h2.entry-title {
  font-size: var(--h2_typography-font-size);
}
#main .post h2,
.fusion-modal h2,
.fusion-post-content h2,
.fusion-tb-footer h2,
.fusion-tb-page-title-bar h2,
.fusion-title h2,
.fusion-widget-area h2,
.post-content h2,
.search-page-search-form h2,
.title h2 {
  color: var(--h2_typography-color);
}
.fusion-title-size-two,
h2 {
  margin-top: var(--h2_typography-margin-top);
  margin-bottom: var(--h2_typography-margin-bottom);
}
.fusion-author .fusion-author-title,
.fusion-header-tagline,
.fusion-modal .modal-title,
.fusion-modal h3,
.fusion-pricing-table .pricing-row,
.fusion-pricing-table .title-row,
.fusion-tb-footer h3,
.fusion-tb-page-title-bar h3,
.fusion-title h3,
.fusion-widget-area h3,
.main-flex .slide-content h3,
.post-content h3,
.project-content h3,
.sidebar .widget h3 {
  font-family: var(--h3_typography-font-family);
  font-weight: var(--h3_typography-font-weight);
  line-height: var(--h3_typography-line-height);
  letter-spacing: var(--h3_typography-letter-spacing);
  font-style: var(--h3_typography-font-style, normal);
}
.fusion-author .fusion-author-title,
.fusion-modal .modal-title,
.fusion-modal h3,
.fusion-tb-footer h3,
.fusion-tb-page-title-bar h3,
.fusion-widget-area h3,
.post-content h3,
.project-content h3 {
  font-size: var(--h3_typography-font-size);
}
.fusion-author .fusion-author-title,
.fusion-modal h3,
.fusion-post-content h3,
.fusion-tb-footer h3,
.fusion-tb-page-title-bar h3,
.fusion-title h3,
.fusion-widget-area h3,
.person-author-wrapper span,
.post-content h3,
.project-content h3,
.sidebar .widget h3,
.title h3 {
  color: var(--h3_typography-color);
}
.fusion-title-size-three,
h3 {
  margin-top: var(--h3_typography-margin-top);
  margin-bottom: var(--h3_typography-margin-bottom);
}
.fusion-carousel-title,
.fusion-flip-boxes .fusion-flip-box .flip-box-heading-back,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-title,
.fusion-image-wrapper
  .fusion-rollover
  .fusion-rollover-content
  .fusion-rollover-title
  a,
.fusion-megamenu-title,
.fusion-modal h4,
.fusion-person .person-desc .person-author .person-author-wrapper,
.fusion-project-details-tb .project-info h4,
.fusion-tabs .nav-tabs li .fusion-tab-heading,
.fusion-tabs-widget .fusion-tabs-nav ul li a,
.fusion-tb-footer h4,
.fusion-tb-page-title-bar h4,
.fusion-theme-sharing-box h4,
.fusion-title h4,
.fusion-widget-area h4,
.popover .popover-title,
.post-content h4,
.project-content .project-info h4,
table th {
  font-family: var(--h4_typography-font-family);
  font-weight: var(--h4_typography-font-weight);
  line-height: var(--h4_typography-line-height);
  letter-spacing: var(--h4_typography-letter-spacing);
  font-style: var(--h4_typography-font-style, normal);
}
.fusion-project-details-tb .project-info .project-terms,
.fusion-tb-footer h4,
.fusion-tb-page-title-bar h4,
.project-content .project-info .project-terms,
.project-info-box span {
  line-height: var(--h4_typography-line-height);
}
.fusion-carousel-title,
.fusion-flip-boxes .fusion-flip-box .flip-box-heading-back,
.fusion-modal h4,
.fusion-person .person-author-wrapper .person-name,
.fusion-person .person-author-wrapper .person-title,
.fusion-rollover .fusion-rollover-content .fusion-rollover-title,
.fusion-sharing-box h4,
.fusion-tabs .nav-tabs > li .fusion-tab-heading,
.fusion-tabs-widget .fusion-tabs-nav ul li a,
.fusion-tb-footer h4,
.fusion-tb-page-title-bar h4,
.fusion-theme-sharing-box h4,
.fusion-widget-area h4,
.person-author-wrapper,
.popover .popover-title,
.post-content h4 {
  font-size: var(--h4_typography-font-size);
}
.fusion-carousel-title,
.fusion-content-widget-area .fusion-tabs-widget .fusion-tabs-nav ul li a,
.fusion-content-widget-area
  .fusion-tabs-widget
  .fusion-tabs-widget-content
  .fusion-date-box,
.fusion-modal h4,
.fusion-post-content h4,
.fusion-project-details-tb .project-info h4,
.fusion-tabs .nav-tabs > li .fusion-tab-heading,
.fusion-tb-footer h4,
.fusion-tb-page-title-bar h4,
.fusion-theme-sharing-box h4,
.fusion-title h4,
.fusion-widget-area h4,
.post-content h4,
.project-content .project-info h4,
.title h4 {
  color: var(--h4_typography-color);
}
.fusion-title-size-four,
h4 {
  margin-top: var(--h4_typography-margin-top);
  margin-bottom: var(--h4_typography-margin-bottom);
}
.fusion-modal h5,
.fusion-tb-footer h5,
.fusion-tb-page-title-bar h5,
.fusion-title h5,
.fusion-widget-area h5,
.post-content h5 {
  font-family: var(--h5_typography-font-family);
  font-weight: var(--h5_typography-font-weight);
  line-height: var(--h5_typography-line-height);
  letter-spacing: var(--h5_typography-letter-spacing);
  font-style: var(--h5_typography-font-style, normal);
}
.fusion-modal h5,
.fusion-tb-footer h5,
.fusion-tb-page-title-bar h5,
.fusion-widget-area h5,
.post-content h5 {
  font-size: var(--h5_typography-font-size);
}
.fusion-modal h5,
.fusion-post-content h5,
.fusion-tb-footer h5,
.fusion-tb-page-title-bar h5,
.fusion-title h5,
.fusion-widget-area h5,
.post-content h5,
.title h5 {
  color: var(--h5_typography-color);
}
.fusion-title-size-five,
h5 {
  margin-top: var(--h5_typography-margin-top);
  margin-bottom: var(--h5_typography-margin-bottom);
}
.fusion-modal h6,
.fusion-tb-footer h6,
.fusion-tb-page-title-bar h6,
.fusion-title h6,
.fusion-widget-area h6,
.post-content h6 {
  font-family: var(--h6_typography-font-family);
  font-weight: var(--h6_typography-font-weight);
  line-height: var(--h6_typography-line-height);
  letter-spacing: var(--h6_typography-letter-spacing);
  font-style: var(--h6_typography-font-style, normal);
}
.fusion-modal h6,
.fusion-tb-footer h6,
.fusion-tb-page-title-bar h6,
.fusion-widget-area h6,
.post-content h6 {
  font-size: var(--h6_typography-font-size);
}
.fusion-modal h6,
.fusion-post-content h6,
.fusion-tb-footer h6,
.fusion-tb-page-title-bar h6,
.fusion-title h6,
.fusion-widget-area h6,
.post-content h6,
.title h6 {
  color: var(--h6_typography-color);
}
.fusion-title-size-six,
h6 {
  margin-top: var(--h6_typography-margin-top);
  margin-bottom: var(--h6_typography-margin-bottom);
}
#main .fusion-woocommerce-quick-view-container .product_title,
#wrapper #main .fusion-portfolio-content > h1.fusion-post-title,
#wrapper #main .fusion-portfolio-content > h2.fusion-post-title,
#wrapper #main .fusion-post-content > .blog-shortcode-post-title,
#wrapper #main .fusion-post-content > h1.fusion-post-title,
#wrapper #main .fusion-post-content > h2.fusion-post-title,
#wrapper #main .post > .fusion-post-title-meta-wrap > h1.fusion-post-title,
#wrapper #main .post > .fusion-post-title-meta-wrap > h2.fusion-post-title,
#wrapper #main .post > h1.fusion-post-title,
#wrapper #main .post > h2.fusion-post-title,
.single-product #main .product h1.product_title,
.single-product #main .product h2.product_title {
  font-family: var(--post_title_typography-font-family);
  font-weight: var(--post_title_typography-font-weight);
  line-height: var(--post_title_typography-line-height);
  letter-spacing: var(--post_title_typography-letter-spacing);
  font-style: var(--post_title_typography-font-style, normal);
}
#main .fusion-woocommerce-quick-view-container .product_title,
#wrapper #main .fusion-portfolio-content > h1.fusion-post-title,
#wrapper #main .fusion-portfolio-content > h2.fusion-post-title,
#wrapper #main .fusion-post-content > .blog-shortcode-post-title,
#wrapper #main .fusion-post-content > h1.fusion-post-title,
#wrapper #main .fusion-post-content > h2.fusion-post-title,
#wrapper #main .post > .fusion-post-title-meta-wrap > h1.fusion-post-title,
#wrapper #main .post > .fusion-post-title-meta-wrap > h2.fusion-post-title,
#wrapper #main .post > h1.fusion-post-title,
#wrapper #main .post > h2.fusion-post-title,
.single-product #main .product h1.product_title,
.single-product #main .product h2.product_title {
  font-size: var(--post_title_typography-font-size);
}
#wrapper #main .fusion-portfolio-content > h1.fusion-post-title,
#wrapper #main .fusion-portfolio-content > h2.fusion-post-title,
#wrapper #main .fusion-post-content > .blog-shortcode-post-title,
#wrapper #main .fusion-post-content > h1.fusion-post-title,
#wrapper #main .fusion-post-content > h2.fusion-post-title,
#wrapper #main .post > .fusion-post-title-meta-wrap > h1.fusion-post-title,
#wrapper #main .post > .fusion-post-title-meta-wrap > h2.fusion-post-title,
#wrapper #main .post > h1.fusion-post-title,
#wrapper #main .post > h2.fusion-post-title {
  color: var(--post_title_typography-color);
}
#tab-reviews #reviews #comments > h2,
#wrapper #main #comments .fusion-title h2,
#wrapper #main #comments .fusion-title h3,
#wrapper #main #respond .fusion-title h2,
#wrapper #main #respond .fusion-title h3,
#wrapper #main .about-author .fusion-title h2,
#wrapper #main .about-author .fusion-title h3,
#wrapper #main .related-posts .fusion-title h2,
#wrapper #main .related-posts .fusion-title h3,
#wrapper #main .related.products .fusion-title h2,
#wrapper #main .related.products .fusion-title h3,
.single-product .woocommerce-tabs .fusion-woocommerce-tab-title,
.woocommerce-container .up-sells .fusion-title h3 {
  font-family: var(--post_titles_extras_typography-font-family);
  font-weight: var(--post_titles_extras_typography-font-weight);
  line-height: var(--post_titles_extras_typography-line-height);
  letter-spacing: var(--post_titles_extras_typography-letter-spacing);
  font-style: var(--post_titles_extras_typography-font-style, normal);
}
#tab-reviews #reviews #comments > h2,
#wrapper #main #comments .fusion-title h2,
#wrapper #main #comments .fusion-title h3,
#wrapper #main #respond .fusion-title h2,
#wrapper #main #respond .fusion-title h3,
#wrapper #main .about-author .fusion-title h2,
#wrapper #main .about-author .fusion-title h3,
#wrapper #main .related-posts .fusion-title h2,
#wrapper #main .related-posts .fusion-title h3,
#wrapper #main .related.products .fusion-title h2,
#wrapper #main .related.products .fusion-title h3,
.single-product .woocommerce-tabs .fusion-woocommerce-tab-title,
.woocommerce-container .up-sells .fusion-title h3 {
  font-size: var(--post_titles_extras_typography-font-size);
}
#tab-reviews #reviews #comments > h2,
#wrapper #main #comments .fusion-title h2,
#wrapper #main #comments .fusion-title h3,
#wrapper #main #respond .fusion-title h2,
#wrapper #main #respond .fusion-title h3,
#wrapper #main .about-author .fusion-title h2,
#wrapper #main .about-author .fusion-title h3,
#wrapper #main .related-posts .fusion-title h2,
#wrapper #main .related-posts .fusion-title h3,
#wrapper #main .related.products .fusion-title h2,
#wrapper #main .related.products .fusion-title h3,
.single-product .woocommerce-tabs .fusion-woocommerce-tab-title,
.woocommerce-container .up-sells .fusion-title h3 {
  color: var(--post_titles_extras_typography-color);
}
.avada-select-parent .select-arrow,
.gravity-select-parent .select-arrow,
.select-arrow,
.wpcf7-select-parent .select-arrow {
  right: var(--form_border_width-right);
}
.avada-select-parent .select-arrow,
.gravity-select-parent .select-arrow,
.select-arrow,
.wpcf7-select-parent .select-arrow {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fusion-search-element
  .searchform.fusion-search-form-clean
  .fusion-search-form-content
  .fusion-search-field
  input,
.fusion-search-form-clean #bbpress-forums .bbp-search-form #bbp_search,
.fusion-search-form-clean
  .searchform:not(.fusion-search-form-classic)
  .fusion-search-form-content
  .fusion-search-field
  input {
  padding-left: var(--form_input_height);
}
.fusion-search-element
  .searchform.fusion-search-form-classic
  .fusion-search-form-content
  .fusion-search-field
  input,
.fusion-search-form-classic #bbpress-forums .bbp-search-form #bbp_search,
.fusion-search-form-classic
  .searchform:not(.fusion-search-form-clean)
  .fusion-search-form-content
  .fusion-search-field
  input {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
#wrapper .fusion-date-and-formats .fusion-format-box,
.tribe-mini-calendar-event .list-date .list-dayname,
.widget .tribe-mini-calendar-event .list-date .list-dayname {
  background-color: var(--dates_box_color);
}
.fusion-menu-item-button .menu-text {
  border-color: var(--button_border_color);
}
.fusion-menu-item-button:hover .menu-text {
  border-color: var(--button_border_hover_color, var(--button_border_color));
}
.fusion-theme-sharing-box {
  justify-content: space-between;
  margin-top: 60px;
}
.avada-social-full-transparent .fusion-theme-sharing-box {
  padding-left: 0;
  padding-right: 0;
}
.fusion-theme-sharing-box h4 {
  margin: 0;
  color: var(--sharing_box_tagline_text_color, var(--h4_typography-color));
}
.fusion-theme-sharing-box .fusion-social-networks-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.fusion-theme-sharing-box
  .fusion-social-networks.boxed-icons
  .fusion-social-network-icon:not(.custom) {
  border-radius: var(--sharing_social_links_boxed_radius);
}
.awb-off-canvas-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: -1;
  transition: 0.25s;
}
.awb-off-canvas-wrap.awb-show {
  z-index: 99990;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.awb-off-canvas-wrap.overlay-disabled {
  background-color: transparent !important;
  pointer-events: none;
}
.awb-off-canvas-wrap .awb-off-canvas {
  position: relative;
  pointer-events: auto;
  width: 800px;
  max-width: 100vw;
  max-height: 100vh;
}
.awb-off-canvas-wrap .awb-off-canvas.oc-waiting-for-close {
  opacity: 0 !important;
}
.awb-off-canvas-wrap .awb-off-canvas .off-canvas-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  transition: 0.3s;
}
.awb-off-canvas-wrap .awb-off-canvas .off-canvas-close:hover {
  color: #d30404;
}
.awb-off-canvas-wrap .awb-off-canvas.is-empty {
  height: 300px;
}
.awb-off-canvas-wrap .awb-off-canvas-inner {
  background: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  display: flex;
  align-items: flex-start;
  border-style: solid;
  border-width: 0;
  overflow: hidden;
  z-index: 10;
}
.awb-off-canvas-wrap .awb-off-canvas-inner .off-canvas-content {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.awb-off-canvas-wrap
  .awb-off-canvas-inner.hidden-scrollbar
  .off-canvas-content {
  scrollbar-width: none;
}
.awb-off-canvas-wrap
  .awb-off-canvas-inner.hidden-scrollbar
  .off-canvas-content::-webkit-scrollbar {
  display: none;
}
.awb-off-canvas-wrap.type-sliding-bar .awb-off-canvas {
  width: 400px;
}
.awb-off-canvas-wrap .fusion-container-margin-bottom .fusion-spacing-tooltip {
  bottom: 100% !important;
  top: auto !important;
  transform: none !important;
  margin-left: 40px !important;
}
.awb-off-canvas-wrap
  .fusion-element-spacing.fusion-column-margin-bottom
  .fusion-spacing-tooltip,
.awb-off-canvas-wrap
  .fusion-element-spacing.fusion-column-padding-top
  .fusion-spacing-tooltip {
  bottom: 0;
  transform: none !important;
}
a[href^="#awb-oc__"] .awb-oc-close-icon {
  opacity: 0;
  font-size: 0 !important;
  font-family: awb-icons !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a[href^="#awb-oc__"] .awb-oc-close-icon:before {
  content: "\e904";
}
a[href^="#awb-oc__"].awb-oc-active
  .fusion-megamenu-icon:not(.awb-oc-close-icon) {
  opacity: 0;
  font-size: 0 !important;
}
a[href^="#awb-oc__"].awb-oc-active .awb-oc-close-icon {
  opacity: 1;
  font-size: inherit !important;
}
.fusion-alert:not(.fusion-live-alert) .close {
  display: inline;
}
.fusion-portfolio-boxed.fusion-portfolio-element .fusion-portfolio-content {
  padding-top: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
}
.fusion-alert.alert-info,
.tribe-events-notices {
  background-color: #ffffff;
  border-color: #4a4e57;
  color: #4a4e57;
}
.alert-danger {
  background-color: rgba(219, 75, 104, 0.1);
  border-color: #db4b68;
  color: #db4b68;
}
.alert-success {
  background-color: rgba(18, 184, 120, 0.1);
  border-color: #12b878;
  color: #12b878;
}
.alert-warning {
  background-color: rgba(241, 174, 42, 0.1);
  border-color: #f1ae2a;
  color: #f1ae2a;
}
.fusion-content-widget-area .fusion-tabs-widget .fusion-tabs-widget-content {
  background-color: var(--tabs_bg_color);
}
.fusion-content-widget-area .fusion-tabs-widget .fusion-tabs-nav ul li {
  border-right-color: var(--tabs_bg_color);
}
.fusion-content-widget-area
  .fusion-tabs-widget
  .fusion-tabs-clean
  .fusion-tabs-nav
  ul {
  border: 1px solid var(--tabs_border_color);
}
.fusion-content-widget-area
  .fusion-tabs-widget
  .fusion-tabs-clean
  .fusion-tabs-nav
  ul
  li {
  border-right-color: var(--tabs_border_color);
}
.fusion-content-widget-area .fusion-tabs-widget .fusion-tabs-nav ul li a {
  border-top-color: var(--tabs_inactive_color);
}
.fusion-content-widget-area .fusion-tabs-widget .fusion-tabs-nav ul li a,
.fusion-content-widget-area
  .fusion-tabs-widget
  .fusion-tabs-widget-content
  .fusion-date-box {
  background: var(--tabs_inactive_color);
}
.fusion-content-widget-area .fusion-tabs-widget .fusion-tabs-nav ul li a:hover {
  background: var(--tabs_bg_color);
  border-top-color: var(--tabs_bg_color);
}
.fusion-content-widget-area
  .fusion-tabs-widget
  .fusion-tabs-nav
  ul
  li.active
  a {
  background: var(--tabs_bg_color);
}
.fusion-content-widget-area .fusion-tabs-widget .fusion-tabs-classic,
.fusion-content-widget-area
  .fusion-tabs-widget
  .fusion-tabs-widget-content
  .fusion-tabs-widget-items
  li {
  border-color: var(--tabs_border_color);
}
.fusion-secondary-menu .fusion-menu-cart-item img {
  border-color: var(--sep_color);
}
.checkout .payment_methods .payment_box,
.fusion-body blockquote,
.post-content blockquote,
.review blockquote q {
  background-color: var(--testimonial_bg_color);
}
.review blockquote q {
  color: var(--testimonial_text_color);
}
.fontawesome-icon {
  color: var(--icon_color);
}
.fontawesome-icon.circle-yes {
  color: var(--icon_color);
  background-color: var(--icon_circle_color);
  border-color: var(--icon_border_color);
  border-radius: 50% 50% 50% 50%;
}
.fontawesome-icon:hover {
  color: var(--icon_color_hover);
}
.fontawesome-icon.circle-yes:hover {
  color: var(--icon_color_hover);
  background-color: var(--icon_circle_color_hover);
  border-color: var(--icon_border_color_hover);
}
#reviews li .comment-text,
#small-nav,
#wrapper .side-nav li a,
#wrapper .side-nav li.current_page_item li a,
.avada-skin-rev,
.chzn-container-single .chzn-single,
.chzn-container-single .chzn-single div,
.chzn-drop,
.commentlist .the-comment,
.es-carousel-wrapper.fusion-carousel-small .es-carousel ul li img,
.fusion-author .fusion-author-social,
.fusion-author-widget .fusion-author-widget-separator .fusion-author-widget-sep,
.fusion-content-widget-area .widget .recentcomments,
.fusion-content-widget-area .widget li a,
.fusion-content-widget-area .widget li a:before,
.fusion-content-widget-area .widget_archive li,
.fusion-content-widget-area .widget_categories li,
.fusion-content-widget-area .widget_links li,
.fusion-content-widget-area .widget_meta li,
.fusion-content-widget-area .widget_nav_menu li,
.fusion-content-widget-area .widget_pages li,
.fusion-content-widget-area .widget_recent_entries li,
.fusion-counters-box .fusion-counter-box .counter-box-border,
.fusion-filters,
.fusion-hide-pagination-text .pagination-next,
.fusion-hide-pagination-text .pagination-prev,
.fusion-pagination .page-numbers,
.fusion-project-details-tb .project-info .project-info-box,
.fusion-tabs.vertical-tabs.clean .nav-tabs li .tab-link,
.input-radio,
.ls-avada,
.page-links a,
.pagination a.inactive,
.panel.entry-content,
.post .fusion-meta-info,
.price_slider_wrapper,
.progress-bar,
.project-content .project-info .project-info-box,
.search-page-search-form,
.side-nav,
.side-nav li a,
.single-navigation,
.table,
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th,
.table-1 table,
.table-1 table th,
.table-1 tr td,
.table-2 table thead,
.table-2 tr td,
.tabs-vertical .tabs-container .tab_content,
.tabs-vertical .tabset,
.tagcloud a,
.tkt-slctr-tbl-wrap-dv table,
.tkt-slctr-tbl-wrap-dv tr td,
h5.toggle.active + .toggle-content,
tr td {
  border-color: var(--sep_color);
}
.price_slider_wrapper .ui-widget-content {
  background-color: var(--sep_color);
}
:root {
  --awb-custom10: #65bc7b;
  --awb-custom10-h: 135;
  --awb-custom10-s: 39%;
  --awb-custom10-l: 57%;
  --awb-custom10-a: 100%;
  --awb-custom11: rgba(255, 255, 255, 0.8);
  --awb-custom11-h: 0;
  --awb-custom11-s: 0%;
  --awb-custom11-l: 100%;
  --awb-custom11-a: 80%;
  --awb-custom12: #747474;
  --awb-custom12-h: 0;
  --awb-custom12-s: 0%;
  --awb-custom12-l: 45%;
  --awb-custom12-a: 100%;
  --awb-custom13: #e0dede;
  --awb-custom13-h: 0;
  --awb-custom13-s: 3%;
  --awb-custom13-l: 87%;
  --awb-custom13-a: 100%;
  --awb-custom14: rgba(242, 243, 245, 0.7);
  --awb-custom14-h: 220;
  --awb-custom14-s: 13%;
  --awb-custom14-l: 95%;
  --awb-custom14-a: 70%;
  --awb-custom15: #f2f3f5;
  --awb-custom15-h: 220;
  --awb-custom15-s: 13%;
  --awb-custom15-l: 95%;
  --awb-custom15-a: 100%;
  --awb-custom16: #1d242d;
  --awb-custom16-h: 214;
  --awb-custom16-s: 22%;
  --awb-custom16-l: 15%;
  --awb-custom16-a: 100%;
  --awb-custom17: #e5e5e5;
  --awb-custom17-h: 0;
  --awb-custom17-s: 0%;
  --awb-custom17-l: 90%;
  --awb-custom17-a: 100%;
  --awb-custom18: #e8e8e8;
  --awb-custom18-h: 0;
  --awb-custom18-s: 0%;
  --awb-custom18-l: 91%;
  --awb-custom18-a: 100%;
  --awb-typography1-font-family: Montserrat;
  --awb-typography1-font-size: 46px;
  --awb-typography1-font-weight: 600;
  --awb-typography1-font-style: normal;
  --awb-typography1-line-height: 1.2;
  --awb-typography1-letter-spacing: -0.015em;
  --awb-typography1-text-transform: none;
  --awb-typography2-font-family: Montserrat;
  --awb-typography2-font-size: 24px;
  --awb-typography2-font-weight: 600;
  --awb-typography2-font-style: normal;
  --awb-typography2-line-height: 1.1;
  --awb-typography2-letter-spacing: 0px;
  --awb-typography2-text-transform: none;
  --awb-typography3-font-family: Montserrat;
  --awb-typography3-font-size: 16px;
  --awb-typography3-font-weight: 500;
  --awb-typography3-font-style: normal;
  --awb-typography3-line-height: 1.2;
  --awb-typography3-letter-spacing: 0.015em;
  --awb-typography3-text-transform: none;
  --awb-typography4-font-family: Arial, Helvetica, sans-serif;
  --awb-typography4-font-size: 16px;
  --awb-typography4-font-weight: 400;
  --awb-typography4-font-style: normal;
  --awb-typography4-line-height: 1.72;
  --awb-typography4-letter-spacing: 0.015em;
  --awb-typography4-text-transform: none;
  --awb-typography5-font-family: Inter;
  --awb-typography5-font-size: 13px;
  --awb-typography5-font-weight: 400;
  --awb-typography5-font-style: normal;
  --awb-typography5-line-height: 1.72;
  --awb-typography5-letter-spacing: 0.015em;
  --awb-typography5-text-transform: none;
  --site_width: 1260px;
  --site_width-int: 1260;
  --margin_offset-top: 0px;
  --margin_offset-bottom: 0px;
  --margin_offset-top-no-percent: 0px;
  --margin_offset-bottom-no-percent: 0px;
  --main_padding-top: 0px;
  --main_padding-top-or-55px: 0px;
  --main_padding-bottom: 0px;
  --hundredp_padding: 30px;
  --hundredp_padding-fallback_to_zero: 30px;
  --hundredp_padding-fallback_to_zero_px: 30px;
  --hundredp_padding-hundred_percent_negative_margin: -30px;
  --sidebar_width: 24%;
  --sidebar_gutter: 6%;
  --sidebar_2_1_width: 20%;
  --sidebar_2_2_width: 20%;
  --dual_sidebar_gutter: 4%;
  --grid_main_break_point: 1000;
  --side_header_break_point: 800;
  --content_break_point: 800;
  --typography_sensitivity: 0.6;
  --typography_factor: 1.5;
  --primary_color: #00edc5;
  --primary_color-85a: rgba(0, 237, 197, 0.85);
  --primary_color-7a: rgba(0, 237, 197, 0.7);
  --primary_color-5a: rgba(0, 237, 197, 0.5);
  --primary_color-35a: rgba(0, 237, 197, 0.35);
  --primary_color-2a: rgba(0, 237, 197, 0.2);
  --header_bg_repeat: no-repeat;
  --side_header_width: 0;
  --side_header_width-int: 0;
  --header_padding-top: 0px;
  --header_padding-bottom: 0px;
  --header_padding-left: 0px;
  --header_padding-right: 0px;
  --menu_highlight_background: #65bc7b;
  --menu_arrow_size-width: 23px;
  --menu_arrow_size-height: 12px;
  --nav_highlight_border: 3px;
  --nav_padding: 48px;
  --nav_padding-no-zero: 48px;
  --dropdown_menu_top_border_size: 3px;
  --dropdown_menu_width: 200px;
  --mainmenu_dropdown_vertical_padding: 12px;
  --menu_sub_bg_color: #f2efef;
  --menu_bg_hover_color: #f8f8f8;
  --menu_sub_sep_color: #dcdadb;
  --menu_h45_bg_color: #ffffff;
  --nav_typography-font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
  --nav_typography-font-weight: 400;
  --nav_typography-font-size: 18px;
  --nav_typography-letter-spacing: 0px;
  --nav_typography-color: #333333;
  --nav_typography-color-65a: rgba(51, 51, 51, 0.65);
  --nav_typography-color-35a: rgba(51, 51, 51, 0.35);
  --menu_hover_first_color: #ff9600;
  --menu_hover_first_color-65a: rgba(255, 150, 0, 0.65);
  --menu_sub_color: #333333;
  --nav_dropdown_font_size: 18px;
  --flyout_menu_icon_font_size: 20px;
  --flyout_menu_icon_font_size_px: 20px;
  --snav_font_size: 12px;
  --sec_menu_lh: 48px;
  --header_top_menu_sub_color: #747474;
  --mobile_menu_icons_top_margin: 2px;
  --mobile_menu_nav_height: 42px;
  --mobile_header_bg_color: #ffffff;
  --mobile_menu_background_color: #f9f9f9;
  --mobile_menu_hover_color: #f6f6f6;
  --mobile_menu_border_color: #dadada;
  --mobile_menu_toggle_color: #9ea0a4;
  --mobile_menu_typography-font-family: "IBM Plex Sans", Arial, Helvetica,
    sans-serif;
  --mobile_menu_typography-font-size: 14px;
  --mobile_menu_typography-font-weight: 400;
  --mobile_menu_typography-line-height: 42px;
  --mobile_menu_typography-letter-spacing: 0px;
  --mobile_menu_typography-color: #333333;
  --mobile_menu_typography-font-size-30-or-24px: 24px;
  --mobile_menu_typography-font-size-open-submenu: var(
    --mobile_menu_typography-font-size,
    13px
  );
  --mobile_menu_font_hover_color: #333333;
  --menu_icon_size: 14px;
  --logo_alignment: left;
  --logo_background_color: #ffffff;
  --slidingbar_width: 300px;
  --slidingbar_width-percent_to_vw: 300px;
  --slidingbar_content_padding-top: 60px;
  --slidingbar_content_padding-bottom: 60px;
  --slidingbar_content_padding-left: 30px;
  --slidingbar_content_padding-right: 30px;
  --slidingbar_bg_color: #363839;
  --slidingbar_divider_color: #505152;
  --slidingbar_link_color: rgba(255, 255, 255, 0.86);
  --slidingbar_link_color_hover: #ffffff;
  --footer_divider_color: #ffffff;
  --footer_headings_typography-font-family: Abel;
  --footer_headings_typography-font-size: 18px;
  --footer_headings_typography-font-weight: 600;
  --footer_headings_typography-line-height: 1.5;
  --footer_headings_typography-letter-spacing: 0px;
  --footer_headings_typography-color: #ffffff;
  --footer_text_color: rgba(255, 255, 255, 0.6);
  --sidebar_bg_color: #ffffff;
  /* --bg_image: url("//ethjets.io/wp-content/uploads/2022/01/black-background-v3.png"); */
  --bg_repeat: no-repeat;
  --bg_color: #000000;
  --bg-color-overlay: normal;
  --bg_pattern: url("//ethjets.io/wp-content/themes/Avada/assets/images/patterns/pattern1.png");
  --content_bg_color: #000000;
  --content_bg_image: none;
  --content_bg_repeat: no-repeat;
  --body_typography-font-family: "News Cycle";
  --body_typography-font-size: 22px;
  --base-font-size: 22;
  --body_typography-font-weight: 400;
  --body_typography-line-height: 1.4;
  --body_typography-letter-spacing: 0.02em;
  --body_typography-color: #ffffff;
  --link_color: #f7964a;
  --link_color-8a: rgba(247, 150, 74, 0.8);
  --h1_typography-font-family: Montserrat, "Arial Black", Gadget, sans-serif;
  --h1_typography-font-weight: 700;
  --h1_typography-line-height: 1.3;
  --h1_typography-letter-spacing: 0px;
  --h1_typography-font-size: 72px;
  --h1_typography-color: #4af8b6;
  --h1_typography-margin-top: 0.67em;
  --h1_typography-margin-bottom: 0.67em;
  --h2_typography-font-family: Montserrat, Arial, Helvetica, sans-serif;
  --h2_typography-font-weight: 400;
  --h2_typography-line-height: 1.2;
  --h2_typography-letter-spacing: 0px;
  --h2_typography-font-size: 54px;
  --h2_typography-color: #ff914d;
  --h2_typography-margin-top: 0em;
  --h2_typography-margin-bottom: 1.1em;
  --h3_typography-font-family: Inter, Arial, Helvetica, sans-serif;
  --h3_typography-font-weight: 700;
  --h3_typography-line-height: 0.8;
  --h3_typography-letter-spacing: 0px;
  --h3_typography-font-size: 40px;
  --h3_typography-color: #ffffff;
  --h3_typography-margin-top: 1em;
  --h3_typography-margin-bottom: 1em;
  --h4_typography-font-family: Inter, Arial, Helvetica, sans-serif;
  --h4_typography-font-weight: 400;
  --h4_typography-line-height: 0.8;
  --h4_typography-letter-spacing: 0px;
  --h4_typography-font-size: 24px;
  --h4_typography-color: #ffffff;
  --h4_typography-margin-top: 1em;
  --h4_typography-margin-bottom: 1em;
  --h5_typography-font-family: Montserrat, Arial, Helvetica, sans-serif;
  --h5_typography-font-weight: 400;
  --h5_typography-line-height: 1.3em;
  --h5_typography-letter-spacing: 0px;
  --h5_typography-font-size: 24px;
  --h5_typography-color: #222222;
  --h5_typography-margin-top: 1.67em;
  --h5_typography-margin-bottom: 1.67em;
  --h6_typography-font-family: Montserrat, Arial, Helvetica, sans-serif;
  --h6_typography-font-weight: 500;
  --h6_typography-line-height: 1;
  --h6_typography-letter-spacing: 0px;
  --h6_typography-font-size: 18px;
  --h6_typography-color: #222222;
  --h6_typography-margin-top: 2.33em;
  --h6_typography-margin-bottom: 2.33em;
  --post_title_typography-font-family: Montserrat;
  --post_title_typography-font-weight: 500;
  --post_title_typography-line-height: 1.3em;
  --post_title_typography-letter-spacing: 0px;
  --post_title_typography-font-size: 30px;
  --post_title_typography-color: #222222;
  --post_titles_extras_typography-font-family: Montserrat;
  --post_titles_extras_typography-font-weight: 400;
  --post_titles_extras_typography-line-height: 1.3em;
  --post_titles_extras_typography-letter-spacing: 0px;
  --post_titles_extras_typography-font-size: 18px;
  --post_titles_extras_typography-color: #222222;
  --blog_archive_grid_column_spacing: 40px;
  --dates_box_color: #eef0f2;
  --meta_font_size: 14px;
  --portfolio_archive_column_spacing: 20px;
  --portfolio_meta_font_size: 13px;
  --social_bg_color: #f6f6f6;
  --sharing_social_links_icon_color: #bebdbd;
  --sharing_social_links_box_color: #e8e8e8;
  --sharing_social_links_boxed_padding: 8px;
  --slider_nav_box_dimensions-width: 30px;
  --slider_nav_box_dimensions-height: 30px;
  --slider_arrow_size: 14px;
  --es_caption_font_size: 20px;
  --form_input_height: 50px;
  --form_input_height-main-menu-search-width: calc(250px + 1.43 * 50px);
  --form_text_size: 16px;
  --form_bg_color: rgba(255, 255, 255, 0);
  --form_text_color: #222222;
  --form_text_color-35a: rgba(34, 34, 34, 0.35);
  --form_border_width-top: 0px;
  --form_border_width-bottom: 1px;
  --form_border_width-left: 0px;
  --form_border_width-right: 0px;
  --form_border_color: #dddddd;
  --form_focus_border_color: #222222;
  --form_focus_border_color-5a: rgba(34, 34, 34, 0.5);
  --form_border_radius: 0px;
  --privacy_bg_color: rgba(0, 0, 0, 0.1);
  --privacy_color: rgba(0, 0, 0, 0.3);
  --privacy_bar_padding-top: 15px;
  --privacy_bar_padding-bottom: 15px;
  --privacy_bar_padding-left: 30px;
  --privacy_bar_padding-right: 30px;
  --privacy_bar_bg_color: #363839;
  --privacy_bar_font_size: 13px;
  --privacy_bar_color: #8c8989;
  --privacy_bar_link_color: #bfbfbf;
  --privacy_bar_link_hover_color: #65bc7b;
  --privacy_bar_headings_color: #dddddd;
  --scrollbar_background: #f2f3f5;
  --scrollbar_handle: #65bc7b;
  --image_rollover_icon_size: 16px;
  --image_rollover_text_color: #ffffff;
  --pagination_width_height: 40px;
  --pagination_box_padding-width: 6px;
  --pagination_box_padding-height: 2px;
  --pagination_border_width: 1px;
  --pagination_border_radius: 0px;
  --pagination_font_size: 14px;
  --timeline_bg_color: rgba(255, 255, 255, 0);
  --timeline_bg_color-not-transparent: #ffffff;
  --timeline_color: #ebeaea;
  --grid_separator_color: #e2e2e2;
  --carousel_nav_color: #999999;
  --carousel_hover_color: hsla(
    var(--awb-color5-h),
    var(--awb-color5-s),
    var(--awb-color5-l),
    calc(var(--awb-color5-a) - 20%)
  );
  --button_padding-top: 17px;
  --button_padding-bottom: 17px;
  --button_padding-left: 40px;
  --button_padding-right: 40px;
  --button_typography-font-family: Abel, Arial, Helvetica, sans-serif;
  --button_typography-font-weight: 700;
  --button_typography-letter-spacing: 1px;
  --button_font_size: 20px;
  --button_line_height: 21px;
  --button_text_transform: uppercase;
  --button_gradient_top_color: #4af8b6;
  --button_gradient_bottom_color: #3bc671;
  --button_gradient_top_color_hover: #eeeeee;
  --button_gradient_bottom_color_hover: #eeeeee;
  --button_gradient_start: 0%;
  --button_gradient_end: 100%;
  --button_gradient_angle: 105deg;
  --button_radial_direction: center center;
  --button_accent_color: #222222;
  --button_accent_hover_color: #222222;
  --button_bevel_color: rgba(0, 0, 0, 0);
  --button_box_shadow: none;
  --button_bevel_color_hover: rgba(0, 0, 0, 0);
  --button_border_width-top: 0px;
  --button_border_width-right: 0px;
  --button_border_width-bottom: 0px;
  --button_border_width-left: 0px;
  --button_border_color: rgba(255, 255, 255, 0);
  --button_border_hover_color: rgba(255, 255, 255, 0);
  --checklist_icons_color: var(--awb-color1);
  --checklist_circle_color: var(--awb-color4);
  --checklist_text_color: var(--awb-color8);
  --checklist_item_padding-top: 0.35em;
  --checklist_item_padding-bottom: 0.35em;
  --checklist_item_padding-left: 0;
  --checklist_item_padding-right: 0;
  --checklist_divider_color: var(--awb-color3);
  --checklist_odd_row_bgcolor: rgba(255, 255, 255, 0);
  --checklist_even_row_bgcolor: rgba(255, 255, 255, 0);
  --countdown_background_color: var(--awb-color5);
  --countdown_counter_box_color: var(--awb-color7);
  --countdown_counter_text_color: var(--awb-color1);
  --countdown_label_color: var(--awb-color1);
  --countdown_heading_text_color: var(--awb-color1);
  --icon_color: var(--awb-color1);
  --icon_color_hover: var(--awb-color1);
  --icon_circle_color: var(--awb-color5);
  --icon_circle_color_hover: var(--awb-color4);
  --icon_border_size: #ffffff;
  --icon_border_color: var(--awb-color8);
  --icon_border_color_hover: var(--awb-color4);
  --pricing_box_color: var(--awb-color4);
  --sep_color: var(--awb-color3);
  --tabs_bg_color: var(--awb-color1);
  --tabs_inactive_color: var(--awb-color2);
  --tabs_border_color: var(--awb-color3);
  --testimonial_bg_color: var(--awb-color2);
  --testimonial_text_color: var(--awb-color8);
  --title_text_transform: none;
  --title_border_color: var(--awb-color3);
  --accordion_divider_color: var(--awb-color3);
  --accordion_divider_hover_color: var(--awb-color3);
  --accordian_inactive_color: #ffffff;
  --accordian_active_color: #65bc7b;
}
#side-header,
.fusion-header {
  --header_bg_color: #ffffff;
  --archive_header_bg_color: #ffffff;
}
.fusion-header-wrapper,
#side-header {
  --header_border_color: #e5e5e5;
}
.fusion-secondary-header {
  --header_top_bg_color: #29292a;
  --snav_color: #ffffff;
}
.fusion-header-tagline {
  --tagline_font_size: 16px;
  --tagline_font_color: #747474;
}
.fusion-arrow-svg,
.fusion-header-wrapper,
#side-header {
  --header_sticky_bg_color: #ffffff;
}
.fusion-main-menu {
  --header_sticky_menu_color: #333333;
  --header_sticky_nav_font_size: 14px;
  --nav_height: 94px;
  --mobile_nav_padding: 25px;
  --menu_text_align: center;
  --menu_thumbnail_size-width: 26px;
  --menu_thumbnail_size-height: 14px;
}
.fusion-main-menu,
.fusion-logo-background {
  --header_sticky_nav_padding: 35px;
}
.fusion-menu-highlight-label {
  --main_nav_highlight_radius: 2px;
}
.side-nav {
  --side_nav_font_size: 16px;
}
.fusion-flyout-menu-icons {
  --flyout_nav_icons_padding: 32px;
  --flyout_menu_icon_color: #333333;
  --flyout_menu_icon_hover_color: #65bc7b;
}
.fusion-flyout-menu-bg {
  --flyout_menu_background_color: rgba(255, 255, 255, 0.95);
}
.fusion-flyout-menu {
  --flyout_menu_item_padding: 32px;
}
.fusion-secondary-menu {
  --topmenu_dropwdown_width: 200px;
  --header_top_first_border_color: #e5e5e5;
  --header_top_sub_bg_color: #ffffff;
  --header_top_menu_bg_hover_color: #fafafa;
  --header_top_menu_sub_sep_color: #e5e5e5;
  --header_top_menu_sub_hover_color: #333333;
}
.fusion-header {
  --top-bar-height: calc(48px / 2);
}
.fusion-megamenu-submenu {
  --megamenu_item_vertical_padding: 7px;
}
.fusion-megamenu-icon {
  --menu_icon_color: #212934;
  --menu_icon_hover_color: #65bc7b;
}
.fusion-logo {
  --logo_margin-top: 34px;
  --logo_margin-bottom: 34px;
  --logo_margin-left: 0px;
  --logo_margin-right: 0px;
}
.fusion-page-title-bar {
  --page_title_height: 300px;
  --page_title_mobile_height: 240px;
  --page_title_bg_color: #f6f6f6;
  --page_title_border_color: #d2d3d4;
  --page_title_font_size: 54px;
  --page_title_color: #222222;
  --page_title_subheader_font_size: 18px;
  --page_title_subheader_color: #ffffff;
  --page_title_bg: none;
  --page_title_bg_retina: var(--page_title_bg);
  --breadcrumbs_font_size: 14px;
  --breadcrumbs_text_color: #333333;
  --breadcrumbs_text_hover_color: #65bc7b;
}
.fusion-sliding-bar {
  --slidingbar_content_align: left;
}
.fusion-sb-toggle-wrapper {
  --slidingbar_toggle_icon_color: #ffffff;
}
#slidingbar {
  --slidingbar_font_size: 14px;
}
#slidingbar-area {
  --slidingbar_headings_color: #ffffff;
  --slidingbar_text_color: rgba(255, 255, 255, 0.6);
}
.fusion-footer-widget-area {
  --footerw_bg_full-size: initial;
  --footerw_bg_full-position: var(--footerw_bg_pos);
  --footerw_bg_repeat: no-repeat;
  --footerw_bg_pos: center center;
  --footer_bg_color: #000000;
  --footer_border_size: 0px;
  --footer_border_color: #ffffff;
  --footer_link_color: rgba(255, 255, 255, 0.8);
  --footer_link_color_hover: #42e496;
}
.fusion-footer {
  --footer_area_padding-top: 60px;
  --footer_area_padding-bottom: 64px;
  --footer_area_padding-left: 0px;
  --footer_area_padding-right: 0px;
  --footer_divider_line-flex: block;
  --footer_divider_line_size: 1px;
  --footer_divider_line_style: solid;
  --footer_widgets_padding: 16px;
}
.fusion-footer-copyright-area {
  --copyright_padding-top: 20px;
  --copyright_padding-bottom: 20px;
  --copyright_bg_color: #000000;
  --copyright_border_size: 0px;
  --copyright_border_color: #4b4c4d;
  --copyright_link_color_hover: #42e496;
}
.fusion-copyright-notice {
  --copyright_text_color: #ffffff;
  --copyright_link_color: #ffffff;
  --copyright_font_size: 13px;
}
.sidebar {
  --sidebar_padding: 0px;
  --sidebar_padding-percent_to_vw: 0px;
  --sidebar_widget_bg_color: rgba(255, 255, 255, 0);
  --sidew_font_size: 18px;
  --sidebar_heading_color: #333333;
}
.fusion-load-more-button {
  --blog_load_more_posts_button_bg_color: rgba(242, 243, 245, 0.7);
  --blog_load_more_posts_button_text_color: #333333;
  --blog_load_more_posts_hover_button_bg_color: rgba(242, 243, 245, 0.8);
  --blog_load_more_posts_hover_button_text_color: #333333;
  --portfolio_archive_load_more_posts_button_bg_color: rgba(242, 243, 245, 0.7);
  --portfolio_archive_load_more_posts_button_text_color: #333333;
  --portfolio_archive_load_more_posts_hover_button_bg_color: rgba(
    242,
    243,
    245,
    0.8
  );
  --portfolio_archive_load_more_posts_hover_button_text_color: #333333;
  --blog_element_load_more_posts_button_bg_color: rgba(242, 243, 245, 0.7);
  --blog_element_load_more_posts_button_text_color: #212934;
  --blog_element_load_more_posts_hover_button_bg_color: #f2f3f5;
  --blog_element_load_more_posts_hover_button_text_color: #212934;
  --portfolio_element_load_more_posts_button_bg_color: var(--awb-color7);
  --portfolio_element_load_more_posts_button_text_color: var(--awb-color1);
  --portfolio_element_load_more_posts_hover_button_bg_color: var(--awb-color5);
  --portfolio_element_load_more_posts_hover_button_text_color: var(
    --awb-color1
  );
}
.fusion-post-content-wrapper {
  --blog_archive_grid_padding-top: 30px;
  --blog_archive_grid_padding-bottom: 20px;
  --blog_archive_grid_padding-left: 25px;
  --blog_archive_grid_padding-right: 25px;
}
.fusion-portfolio-content {
  --portfolio_archive_text_layout-padding: 20px 0;
  --portfolio_archive_layout_padding-top: 25px;
  --portfolio_archive_layout_padding-bottom: 25px;
  --portfolio_archive_layout_padding-left: 25px;
  --portfolio_archive_layout_padding-right: 25px;
}
.fusion-portfolio-content-wrapper {
  --portfolio_archive_text_alignment: left;
}
.fusion-social-networks {
  --footer_social_links_font_size: 16px;
  --footer_social_links_boxed_padding: 8px;
}
.fusion-social-network-icon {
  --footer_social_links_icon_color: rgba(255, 255, 255, 0.8);
  --footer_social_links_box_color: #222222;
  --footer_social_links_boxed_radius: 4px;
  --sharing_social_links_boxed_radius: 4px;
}
.fusion-theme-sharing-box {
  --sharing_box_tagline_text_color: #333333;
}
.fusion-sharing-box {
  --sharing_social_links_font_size: 16px;
}
.ei-slider {
  --tfes_dimensions-width: 100%;
  --tfes_dimensions-height: 400px;
  --es_title_font_size: 42px;
  --es_title_color: #222222;
  --es_caption_color: #4a4e57;
}
.avada-google-map {
  --gmap_topmargin: 55px;
}
.fusion-search-results {
  --live_search_results_height: 250px;
}
.fusion-blog-layout-grid {
  --search_grid_column_spacing: 40px;
}
#toTop {
  --totop_border_radius: 6px;
}
.fusion-image-wrapper {
  --image_gradient_top_color: rgba(0, 0, 0, 0.69);
}
.fusion-rollover {
  --image_gradient_bottom_color: #222222;
  --image_rollover_icon_color: #222222;
}
.shadow-left {
  --avada-rev-image-shadow-top: ;
}
.shadow-right {
  --avada-rev-image-shadow-bottom: ;
}
.fusion-alert.alert-info.general {
  --info_bg_color: #ffffff;
  --info_accent_color: #4a4e57;
}
.fusion-alert.alert-danger.error {
  --danger_bg_color: rgba(219, 75, 104, 0.1);
  --danger_accent_color: #db4b68;
}
.fusion-alert.alert-success.success {
  --success_bg_color: rgba(18, 184, 120, 0.1);
  --success_accent_color: #12b878;
}
.fusion-alert.alert-warning.notice {
  --warning_bg_color: rgba(241, 174, 42, 0.1);
  --warning_accent_color: #f1ae2a;
}
body .fusion-alert.alert:not(.fusion-live-alert) {
  --alert_border_size: 1px;
}
body {
  --fusion-audio-max-width-default: 100%;
  --fusion-audio-background-color-default: #1d242d;
  --fusion-audio-accent-color-default: #ffffff;
  --fusion-audio-border-size-default: 0;
  --fusion-audio-border-color-default: var(--awb-color1);
  --fusion-audio-border-top-left-radius-default: 0px;
  --fusion-audio-border-top-right-radius-default: 0px;
  --fusion-audio-border-bottom-right-radius-default: 0px;
  --fusion-audio-border-bottom-left-radius-default: 0px;
  --button-border-radius-top-left: 10px;
  --button-border-radius-top-right: 0px;
  --button-border-radius-bottom-right: 10px;
  --button-border-radius-bottom-left: 0px;
  --fusion-video-max-width-default: 100%;
}
.fusion-scroll-section-nav {
  --container_scroll_nav_bg_color: hsla(
    var(--awb-color8-h),
    var(--awb-color8-s),
    var(--awb-color8-l),
    calc(var(--awb-color8-a) - 80%)
  );
}
.fusion-scroll-section-link-bullet {
  --container_scroll_nav_bullet_color: var(--awb-color3);
}
.fusion-body .fusion-dropcap {
  --dropcap_color: var(--awb-color5);
  --dropcap_text_color: var(--awb-color1);
}
.popover {
  --popover_heading_bg_color: var(--awb-color2);
  --popover_content_bg_color: var(--awb-color1);
  --popover_border_color: var(--awb-color3);
  --popover_text_color: var(--awb-color8);
}
.fusion-social-links {
  --social_links_font_size: 16px;
  --social_links_boxed_padding: 8px;
}
.fusion-tb-header {
  --awb_header_bg_color: rgba(12, 53, 67, 0);
}
@media only screen and (max-width: 800px) {
  .has-sidebar #content {
    order: 1;
  }
  .has-sidebar #sidebar {
    order: 2;
    margin-top: 50px;
  }
  .has-sidebar #sidebar-2 {
    order: 3;
    margin-top: 50px;
  }
  #side-header .fusion-mobile-logo-1 .fusion-standard-logo,
  .fusion-mobile-logo-1 .fusion-standard-logo {
    display: none;
  }
  #side-header .fusion-mobile-logo,
  .fusion-mobile-logo {
    display: inline-block;
  }
}
@font-face {
  font-family: "awb-icons";
  src: url("//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/icomoon/awb-icons.woff")
      format("woff"),
    url("//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/icomoon/awb-icons.ttf")
      format("truetype"),
    url("//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/icomoon/awb-icons.svg#awb-icons")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: 0.75em;
}
.fa-sm {
  font-size: 0.875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fab.fa-pull-left,
.fal.fa-pull-left,
.far.fa-pull-left,
.fas.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fab.fa-pull-right,
.fal.fa-pull-right,
.far.fa-pull-right,
.fas.fa-pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-rotate-90 {
  -webkit-filter: none;
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  src: url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-brands-400.eot);
  src: url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-brands-400.eot?#iefix)
      format("embedded-opentype"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-brands-400.woff2)
      format("woff2"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-brands-400.woff)
      format("woff"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-brands-400.ttf)
      format("truetype"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-brands-400.svg#fontawesome)
      format("svg");
  font-display: swap;
}
.fab {
  font-family: "Font Awesome 5 Brands";
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  src: url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-regular-400.eot);
  src: url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-regular-400.eot?#iefix)
      format("embedded-opentype"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-regular-400.woff2)
      format("woff2"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-regular-400.woff)
      format("woff"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-regular-400.ttf)
      format("truetype"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-regular-400.svg#fontawesome)
      format("svg");
  font-display: swap;
}
.far {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  src: url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-solid-900.eot);
  src: url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-solid-900.eot?#iefix)
      format("embedded-opentype"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-solid-900.woff2)
      format("woff2"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-solid-900.woff)
      format("woff"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-solid-900.ttf)
      format("truetype"),
    url(//ethjets.io/wp-content/themes/Avada/includes/lib/assets/fonts/fontawesome/webfonts/fa-solid-900.svg#fontawesome)
      format("svg");
  font-display: swap;
}
.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.fa-500px:before {
  content: "\f26e";
}
.fa-accessible-icon:before {
  content: "\f368";
}
.fa-accusoft:before {
  content: "\f369";
}
.fa-acquisitions-incorporated:before {
  content: "\f6af";
}
.fa-ad:before {
  content: "\f641";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-card:before {
  content: "\f2bb";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-adn:before {
  content: "\f170";
}
.fa-adversal:before {
  content: "\f36a";
}
.fa-affiliatetheme:before {
  content: "\f36b";
}
.fa-air-freshener:before {
  content: "\f5d0";
}
.fa-airbnb:before {
  content: "\f834";
}
.fa-algolia:before {
  content: "\f36c";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-alipay:before {
  content: "\f642";
}
.fa-allergies:before {
  content: "\f461";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-amazon-pay:before {
  content: "\f42c";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-amilia:before {
  content: "\f36d";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-android:before {
  content: "\f17b";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angry:before {
  content: "\f556";
}
.fa-angrycreative:before {
  content: "\f36e";
}
.fa-angular:before {
  content: "\f420";
}
.fa-ankh:before {
  content: "\f644";
}
.fa-app-store:before {
  content: "\f36f";
}
.fa-app-store-ios:before {
  content: "\f370";
}
.fa-apper:before {
  content: "\f371";
}
.fa-apple:before {
  content: "\f179";
}
.fa-apple-alt:before {
  content: "\f5d1";
}
.fa-apple-pay:before {
  content: "\f415";
}
.fa-archive:before {
  content: "\f187";
}
.fa-archway:before {
  content: "\f557";
}
.fa-arrow-alt-circle-down:before {
  content: "\f358";
}
.fa-arrow-alt-circle-left:before {
  content: "\f359";
}
.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}
.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-arrows-alt-h:before {
  content: "\f337";
}
.fa-arrows-alt-v:before {
  content: "\f338";
}
.fa-artstation:before {
  content: "\f77a";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-asymmetrik:before {
  content: "\f372";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-atlas:before {
  content: "\f558";
}
.fa-atlassian:before {
  content: "\f77b";
}
.fa-atom:before {
  content: "\f5d2";
}
.fa-audible:before {
  content: "\f373";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-autoprefixer:before {
  content: "\f41c";
}
.fa-avianex:before {
  content: "\f374";
}
.fa-aviato:before {
  content: "\f421";
}
.fa-award:before {
  content: "\f559";
}
.fa-aws:before {
  content: "\f375";
}
.fa-baby:before {
  content: "\f77c";
}
.fa-baby-carriage:before {
  content: "\f77d";
}
.fa-backspace:before {
  content: "\f55a";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-bacon:before {
  content: "\f7e5";
}
.fa-bacteria:before {
  content: "\e059";
}
.fa-bacterium:before {
  content: "\e05a";
}
.fa-bahai:before {
  content: "\f666";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-balance-scale-left:before {
  content: "\f515";
}
.fa-balance-scale-right:before {
  content: "\f516";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-band-aid:before {
  content: "\f462";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-bars:before {
  content: "\f0c9";
}
.fa-baseball-ball:before {
  content: "\f433";
}
.fa-basketball-ball:before {
  content: "\f434";
}
.fa-bath:before {
  content: "\f2cd";
}
.fa-battery-empty:before {
  content: "\f244";
}
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-battle-net:before {
  content: "\f835";
}
.fa-bed:before {
  content: "\f236";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bezier-curve:before {
  content: "\f55b";
}
.fa-bible:before {
  content: "\f647";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-biking:before {
  content: "\f84a";
}
.fa-bimobject:before {
  content: "\f378";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-biohazard:before {
  content: "\f780";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitcoin:before {
  content: "\f379";
}
.fa-bity:before {
  content: "\f37a";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-blackberry:before {
  content: "\f37b";
}
.fa-blender:before {
  content: "\f517";
}
.fa-blender-phone:before {
  content: "\f6b6";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-blog:before {
  content: "\f781";
}
.fa-blogger:before {
  content: "\f37c";
}
.fa-blogger-b:before {
  content: "\f37d";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-bold:before {
  content: "\f032";
}
.fa-bolt:before {
  content: "\f0e7";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-bone:before {
  content: "\f5d7";
}
.fa-bong:before {
  content: "\f55c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-book-dead:before {
  content: "\f6b7";
}
.fa-book-medical:before {
  content: "\f7e6";
}
.fa-book-open:before {
  content: "\f518";
}
.fa-book-reader:before {
  content: "\f5da";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-bootstrap:before {
  content: "\f836";
}
.fa-border-all:before {
  content: "\f84c";
}
.fa-border-none:before {
  content: "\f850";
}
.fa-border-style:before {
  content: "\f853";
}
.fa-bowling-ball:before {
  content: "\f436";
}
.fa-box:before {
  content: "\f466";
}
.fa-box-open:before {
  content: "\f49e";
}
.fa-box-tissue:before {
  content: "\e05b";
}
.fa-boxes:before {
  content: "\f468";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-brain:before {
  content: "\f5dc";
}
.fa-bread-slice:before {
  content: "\f7ec";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-briefcase-medical:before {
  content: "\f469";
}
.fa-broadcast-tower:before {
  content: "\f519";
}
.fa-broom:before {
  content: "\f51a";
}
.fa-brush:before {
  content: "\f55d";
}
.fa-btc:before {
  content: "\f15a";
}
.fa-buffer:before {
  content: "\f837";
}
.fa-bug:before {
  content: "\f188";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-burn:before {
  content: "\f46a";
}
.fa-buromobelexperte:before {
  content: "\f37f";
}
.fa-bus:before {
  content: "\f207";
}
.fa-bus-alt:before {
  content: "\f55e";
}
.fa-business-time:before {
  content: "\f64a";
}
.fa-buy-n-large:before {
  content: "\f8a6";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-calendar:before {
  content: "\f133";
}
.fa-calendar-alt:before {
  content: "\f073";
}
.fa-calendar-check:before {
  content: "\f274";
}
.fa-calendar-day:before {
  content: "\f783";
}
.fa-calendar-minus:before {
  content: "\f272";
}
.fa-calendar-plus:before {
  content: "\f271";
}
.fa-calendar-times:before {
  content: "\f273";
}
.fa-calendar-week:before {
  content: "\f784";
}
.fa-camera:before {
  content: "\f030";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-campground:before {
  content: "\f6bb";
}
.fa-canadian-maple-leaf:before {
  content: "\f785";
}
.fa-candy-cane:before {
  content: "\f786";
}
.fa-cannabis:before {
  content: "\f55f";
}
.fa-capsules:before {
  content: "\f46b";
}
.fa-car:before {
  content: "\f1b9";
}
.fa-car-alt:before {
  content: "\f5de";
}
.fa-car-battery:before {
  content: "\f5df";
}
.fa-car-crash:before {
  content: "\f5e1";
}
.fa-car-side:before {
  content: "\f5e4";
}
.fa-caravan:before {
  content: "\f8ff";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-caret-square-down:before {
  content: "\f150";
}
.fa-caret-square-left:before {
  content: "\f191";
}
.fa-caret-square-right:before {
  content: "\f152";
}
.fa-caret-square-up:before {
  content: "\f151";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-carrot:before {
  content: "\f787";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cash-register:before {
  content: "\f788";
}
.fa-cat:before {
  content: "\f6be";
}
.fa-cc-amazon-pay:before {
  content: "\f42d";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-apple-pay:before {
  content: "\f416";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-centercode:before {
  content: "\f380";
}
.fa-centos:before {
  content: "\f789";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-chair:before {
  content: "\f6c0";
}
.fa-chalkboard:before {
  content: "\f51b";
}
.fa-chalkboard-teacher:before {
  content: "\f51c";
}
.fa-charging-station:before {
  content: "\f5e7";
}
.fa-chart-area:before {
  content: "\f1fe";
}
.fa-chart-bar:before {
  content: "\f080";
}
.fa-chart-line:before {
  content: "\f201";
}
.fa-chart-pie:before {
  content: "\f200";
}
.fa-check:before {
  content: "\f00c";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-check-double:before {
  content: "\f560";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-cheese:before {
  content: "\f7ef";
}
.fa-chess:before {
  content: "\f439";
}
.fa-chess-bishop:before {
  content: "\f43a";
}
.fa-chess-board:before {
  content: "\f43c";
}
.fa-chess-king:before {
  content: "\f43f";
}
.fa-chess-knight:before {
  content: "\f441";
}
.fa-chess-pawn:before {
  content: "\f443";
}
.fa-chess-queen:before {
  content: "\f445";
}
.fa-chess-rook:before {
  content: "\f447";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-chromecast:before {
  content: "\f838";
}
.fa-church:before {
  content: "\f51d";
}
.fa-circle:before {
  content: "\f111";
}
.fa-circle-notch:before {
  content: "\f1ce";
}
.fa-city:before {
  content: "\f64f";
}
.fa-clinic-medical:before {
  content: "\f7f2";
}
.fa-clipboard:before {
  content: "\f328";
}
.fa-clipboard-check:before {
  content: "\f46c";
}
.fa-clipboard-list:before {
  content: "\f46d";
}
.fa-clock:before {
  content: "\f017";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-closed-captioning:before {
  content: "\f20a";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-cloud-download-alt:before {
  content: "\f381";
}
.fa-cloud-meatball:before {
  content: "\f73b";
}
.fa-cloud-moon:before {
  content: "\f6c3";
}
.fa-cloud-moon-rain:before {
  content: "\f73c";
}
.fa-cloud-rain:before {
  content: "\f73d";
}
.fa-cloud-showers-heavy:before {
  content: "\f740";
}
.fa-cloud-sun:before {
  content: "\f6c4";
}
.fa-cloud-sun-rain:before {
  content: "\f743";
}
.fa-cloud-upload-alt:before {
  content: "\f382";
}
.fa-cloudflare:before {
  content: "\e07d";
}
.fa-cloudscale:before {
  content: "\f383";
}
.fa-cloudsmith:before {
  content: "\f384";
}
.fa-cloudversify:before {
  content: "\f385";
}
.fa-cocktail:before {
  content: "\f561";
}
.fa-code:before {
  content: "\f121";
}
.fa-code-branch:before {
  content: "\f126";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cog:before {
  content: "\f013";
}
.fa-cogs:before {
  content: "\f085";
}
.fa-coins:before {
  content: "\f51e";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-comment:before {
  content: "\f075";
}
.fa-comment-alt:before {
  content: "\f27a";
}
.fa-comment-dollar:before {
  content: "\f651";
}
.fa-comment-dots:before {
  content: "\f4ad";
}
.fa-comment-medical:before {
  content: "\f7f5";
}
.fa-comment-slash:before {
  content: "\f4b3";
}
.fa-comments:before {
  content: "\f086";
}
.fa-comments-dollar:before {
  content: "\f653";
}
.fa-compact-disc:before {
  content: "\f51f";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-compress:before {
  content: "\f066";
}
.fa-compress-alt:before {
  content: "\f422";
}
.fa-compress-arrows-alt:before {
  content: "\f78c";
}
.fa-concierge-bell:before {
  content: "\f562";
}
.fa-confluence:before {
  content: "\f78d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-cookie:before {
  content: "\f563";
}
.fa-cookie-bite:before {
  content: "\f564";
}
.fa-copy:before {
  content: "\f0c5";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-cotton-bureau:before {
  content: "\f89e";
}
.fa-couch:before {
  content: "\f4b8";
}
.fa-cpanel:before {
  content: "\f388";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-creative-commons-by:before {
  content: "\f4e7";
}
.fa-creative-commons-nc:before {
  content: "\f4e8";
}
.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}
.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}
.fa-creative-commons-nd:before {
  content: "\f4eb";
}
.fa-creative-commons-pd:before {
  content: "\f4ec";
}
.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}
.fa-creative-commons-remix:before {
  content: "\f4ee";
}
.fa-creative-commons-sa:before {
  content: "\f4ef";
}
.fa-creative-commons-sampling:before {
  content: "\f4f0";
}
.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}
.fa-creative-commons-share:before {
  content: "\f4f2";
}
.fa-creative-commons-zero:before {
  content: "\f4f3";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-critical-role:before {
  content: "\f6c9";
}
.fa-crop:before {
  content: "\f125";
}
.fa-crop-alt:before {
  content: "\f565";
}
.fa-cross:before {
  content: "\f654";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-crow:before {
  content: "\f520";
}
.fa-crown:before {
  content: "\f521";
}
.fa-crutch:before {
  content: "\f7f7";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-css3-alt:before {
  content: "\f38b";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-cut:before {
  content: "\f0c4";
}
.fa-cuttlefish:before {
  content: "\f38c";
}
.fa-d-and-d:before {
  content: "\f38d";
}
.fa-d-and-d-beyond:before {
  content: "\f6ca";
}
.fa-dailymotion:before {
  content: "\e052";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-deaf:before {
  content: "\f2a4";
}
.fa-deezer:before {
  content: "\e077";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-democrat:before {
  content: "\f747";
}
.fa-deploydog:before {
  content: "\f38e";
}
.fa-deskpro:before {
  content: "\f38f";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-dev:before {
  content: "\f6cc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-dharmachakra:before {
  content: "\f655";
}
.fa-dhl:before {
  content: "\f790";
}
.fa-diagnoses:before {
  content: "\f470";
}
.fa-diaspora:before {
  content: "\f791";
}
.fa-dice:before {
  content: "\f522";
}
.fa-dice-d20:before {
  content: "\f6cf";
}
.fa-dice-d6:before {
  content: "\f6d1";
}
.fa-dice-five:before {
  content: "\f523";
}
.fa-dice-four:before {
  content: "\f524";
}
.fa-dice-one:before {
  content: "\f525";
}
.fa-dice-six:before {
  content: "\f526";
}
.fa-dice-three:before {
  content: "\f527";
}
.fa-dice-two:before {
  content: "\f528";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-digital-ocean:before {
  content: "\f391";
}
.fa-digital-tachograph:before {
  content: "\f566";
}
.fa-directions:before {
  content: "\f5eb";
}
.fa-discord:before {
  content: "\f392";
}
.fa-discourse:before {
  content: "\f393";
}
.fa-disease:before {
  content: "\f7fa";
}
.fa-divide:before {
  content: "\f529";
}
.fa-dizzy:before {
  content: "\f567";
}
.fa-dna:before {
  content: "\f471";
}
.fa-dochub:before {
  content: "\f394";
}
.fa-docker:before {
  content: "\f395";
}
.fa-dog:before {
  content: "\f6d3";
}
.fa-dollar-sign:before {
  content: "\f155";
}
.fa-dolly:before {
  content: "\f472";
}
.fa-dolly-flatbed:before {
  content: "\f474";
}
.fa-donate:before {
  content: "\f4b9";
}
.fa-door-closed:before {
  content: "\f52a";
}
.fa-door-open:before {
  content: "\f52b";
}
.fa-dot-circle:before {
  content: "\f192";
}
.fa-dove:before {
  content: "\f4ba";
}
.fa-download:before {
  content: "\f019";
}
.fa-draft2digital:before {
  content: "\f396";
}
.fa-drafting-compass:before {
  content: "\f568";
}
.fa-dragon:before {
  content: "\f6d5";
}
.fa-draw-polygon:before {
  content: "\f5ee";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-dribbble-square:before {
  content: "\f397";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-drum:before {
  content: "\f569";
}
.fa-drum-steelpan:before {
  content: "\f56a";
}
.fa-drumstick-bite:before {
  content: "\f6d7";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-dumbbell:before {
  content: "\f44b";
}
.fa-dumpster:before {
  content: "\f793";
}
.fa-dumpster-fire:before {
  content: "\f794";
}
.fa-dungeon:before {
  content: "\f6d9";
}
.fa-dyalog:before {
  content: "\f399";
}
.fa-earlybirds:before {
  content: "\f39a";
}
.fa-ebay:before {
  content: "\f4f4";
}
.fa-edge:before {
  content: "\f282";
}
.fa-edge-legacy:before {
  content: "\e078";
}
.fa-edit:before {
  content: "\f044";
}
.fa-egg:before {
  content: "\f7fb";
}
.fa-eject:before {
  content: "\f052";
}
.fa-elementor:before {
  content: "\f430";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-ello:before {
  content: "\f5f1";
}
.fa-ember:before {
  content: "\f423";
}
.fa-empire:before {
  content: "\f1d1";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-open-text:before {
  content: "\f658";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-envira:before {
  content: "\f299";
}
.fa-equals:before {
  content: "\f52c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-erlang:before {
  content: "\f39d";
}
.fa-ethereum:before {
  content: "\f42e";
}
.fa-ethernet:before {
  content: "\f796";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-euro-sign:before {
  content: "\f153";
}
.fa-evernote:before {
  content: "\f839";
}
.fa-exchange-alt:before {
  content: "\f362";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-expand:before {
  content: "\f065";
}
.fa-expand-alt:before {
  content: "\f424";
}
.fa-expand-arrows-alt:before {
  content: "\f31e";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-external-link-alt:before {
  content: "\f35d";
}
.fa-external-link-square-alt:before {
  content: "\f360";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-dropper:before {
  content: "\f1fb";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-facebook-messenger:before {
  content: "\f39f";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-fan:before {
  content: "\f863";
}
.fa-fantasy-flight-games:before {
  content: "\f6dc";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-faucet:before {
  content: "\e005";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-feather:before {
  content: "\f52d";
}
.fa-feather-alt:before {
  content: "\f56b";
}
.fa-fedex:before {
  content: "\f797";
}
.fa-fedora:before {
  content: "\f798";
}
.fa-female:before {
  content: "\f182";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-figma:before {
  content: "\f799";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-alt:before {
  content: "\f15c";
}
.fa-file-archive:before {
  content: "\f1c6";
}
.fa-file-audio:before {
  content: "\f1c7";
}
.fa-file-code:before {
  content: "\f1c9";
}
.fa-file-contract:before {
  content: "\f56c";
}
.fa-file-csv:before {
  content: "\f6dd";
}
.fa-file-download:before {
  content: "\f56d";
}
.fa-file-excel:before {
  content: "\f1c3";
}
.fa-file-export:before {
  content: "\f56e";
}
.fa-file-image:before {
  content: "\f1c5";
}
.fa-file-import:before {
  content: "\f56f";
}
.fa-file-invoice:before {
  content: "\f570";
}
.fa-file-invoice-dollar:before {
  content: "\f571";
}
.fa-file-medical:before {
  content: "\f477";
}
.fa-file-medical-alt:before {
  content: "\f478";
}
.fa-file-pdf:before {
  content: "\f1c1";
}
.fa-file-powerpoint:before {
  content: "\f1c4";
}
.fa-file-prescription:before {
  content: "\f572";
}
.fa-file-signature:before {
  content: "\f573";
}
.fa-file-upload:before {
  content: "\f574";
}
.fa-file-video:before {
  content: "\f1c8";
}
.fa-file-word:before {
  content: "\f1c2";
}
.fa-fill:before {
  content: "\f575";
}
.fa-fill-drip:before {
  content: "\f576";
}
.fa-film:before {
  content: "\f008";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-fingerprint:before {
  content: "\f577";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-fire-alt:before {
  content: "\f7e4";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-firefox-browser:before {
  content: "\e007";
}
.fa-first-aid:before {
  content: "\f479";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-first-order-alt:before {
  content: "\f50a";
}
.fa-firstdraft:before {
  content: "\f3a1";
}
.fa-fish:before {
  content: "\f578";
}
.fa-fist-raised:before {
  content: "\f6de";
}
.fa-flag:before {
  content: "\f024";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-flag-usa:before {
  content: "\f74d";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-flipboard:before {
  content: "\f44d";
}
.fa-flushed:before {
  content: "\f579";
}
.fa-fly:before {
  content: "\f417";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-minus:before {
  content: "\f65d";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-folder-plus:before {
  content: "\f65e";
}
.fa-font:before {
  content: "\f031";
}
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-font-awesome-alt:before {
  content: "\f35c";
}
.fa-font-awesome-flag:before {
  content: "\f425";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-fonticons-fi:before {
  content: "\f3a2";
}
.fa-football-ball:before {
  content: "\f44e";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-fort-awesome-alt:before {
  content: "\f3a3";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-freebsd:before {
  content: "\f3a4";
}
.fa-frog:before {
  content: "\f52e";
}
.fa-frown:before {
  content: "\f119";
}
.fa-frown-open:before {
  content: "\f57a";
}
.fa-fulcrum:before {
  content: "\f50b";
}
.fa-funnel-dollar:before {
  content: "\f662";
}
.fa-futbol:before {
  content: "\f1e3";
}
.fa-galactic-republic:before {
  content: "\f50c";
}
.fa-galactic-senate:before {
  content: "\f50d";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-gas-pump:before {
  content: "\f52f";
}
.fa-gavel:before {
  content: "\f0e3";
}
.fa-gem:before {
  content: "\f3a5";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-ghost:before {
  content: "\f6e2";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-gifts:before {
  content: "\f79c";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-git-alt:before {
  content: "\f841";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-github:before {
  content: "\f09b";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-gitkraken:before {
  content: "\f3a6";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-gitter:before {
  content: "\f426";
}
.fa-glass-cheers:before {
  content: "\f79f";
}
.fa-glass-martini:before {
  content: "\f000";
}
.fa-glass-martini-alt:before {
  content: "\f57b";
}
.fa-glass-whiskey:before {
  content: "\f7a0";
}
.fa-glasses:before {
  content: "\f530";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-globe-africa:before {
  content: "\f57c";
}
.fa-globe-americas:before {
  content: "\f57d";
}
.fa-globe-asia:before {
  content: "\f57e";
}
.fa-globe-europe:before {
  content: "\f7a2";
}
.fa-gofore:before {
  content: "\f3a7";
}
.fa-golf-ball:before {
  content: "\f450";
}
.fa-goodreads:before {
  content: "\f3a8";
}
.fa-goodreads-g:before {
  content: "\f3a9";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-google-drive:before {
  content: "\f3aa";
}
.fa-google-pay:before {
  content: "\e079";
}
.fa-google-play:before {
  content: "\f3ab";
}
.fa-google-plus:before {
  content: "\f2b3";
}
.fa-google-plus-g:before {
  content: "\f0d5";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-gopuram:before {
  content: "\f664";
}
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-gratipay:before {
  content: "\f184";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-greater-than:before {
  content: "\f531";
}
.fa-greater-than-equal:before {
  content: "\f532";
}
.fa-grimace:before {
  content: "\f57f";
}
.fa-grin:before {
  content: "\f580";
}
.fa-grin-alt:before {
  content: "\f581";
}
.fa-grin-beam:before {
  content: "\f582";
}
.fa-grin-beam-sweat:before {
  content: "\f583";
}
.fa-grin-hearts:before {
  content: "\f584";
}
.fa-grin-squint:before {
  content: "\f585";
}
.fa-grin-squint-tears:before {
  content: "\f586";
}
.fa-grin-stars:before {
  content: "\f587";
}
.fa-grin-tears:before {
  content: "\f588";
}
.fa-grin-tongue:before {
  content: "\f589";
}
.fa-grin-tongue-squint:before {
  content: "\f58a";
}
.fa-grin-tongue-wink:before {
  content: "\f58b";
}
.fa-grin-wink:before {
  content: "\f58c";
}
.fa-grip-horizontal:before {
  content: "\f58d";
}
.fa-grip-lines:before {
  content: "\f7a4";
}
.fa-grip-lines-vertical:before {
  content: "\f7a5";
}
.fa-grip-vertical:before {
  content: "\f58e";
}
.fa-gripfire:before {
  content: "\f3ac";
}
.fa-grunt:before {
  content: "\f3ad";
}
.fa-guilded:before {
  content: "\e07e";
}
.fa-guitar:before {
  content: "\f7a6";
}
.fa-gulp:before {
  content: "\f3ae";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-hacker-news-square:before {
  content: "\f3af";
}
.fa-hackerrank:before {
  content: "\f5f7";
}
.fa-hamburger:before {
  content: "\f805";
}
.fa-hammer:before {
  content: "\f6e3";
}
.fa-hamsa:before {
  content: "\f665";
}
.fa-hand-holding:before {
  content: "\f4bd";
}
.fa-hand-holding-heart:before {
  content: "\f4be";
}
.fa-hand-holding-medical:before {
  content: "\e05c";
}
.fa-hand-holding-usd:before {
  content: "\f4c0";
}
.fa-hand-holding-water:before {
  content: "\f4c1";
}
.fa-hand-lizard:before {
  content: "\f258";
}
.fa-hand-middle-finger:before {
  content: "\f806";
}
.fa-hand-paper:before {
  content: "\f256";
}
.fa-hand-peace:before {
  content: "\f25b";
}
.fa-hand-point-down:before {
  content: "\f0a7";
}
.fa-hand-point-left:before {
  content: "\f0a5";
}
.fa-hand-point-right:before {
  content: "\f0a4";
}
.fa-hand-point-up:before {
  content: "\f0a6";
}
.fa-hand-pointer:before {
  content: "\f25a";
}
.fa-hand-rock:before {
  content: "\f255";
}
.fa-hand-scissors:before {
  content: "\f257";
}
.fa-hand-sparkles:before {
  content: "\e05d";
}
.fa-hand-spock:before {
  content: "\f259";
}
.fa-hands:before {
  content: "\f4c2";
}
.fa-hands-helping:before {
  content: "\f4c4";
}
.fa-hands-wash:before {
  content: "\e05e";
}
.fa-handshake:before {
  content: "\f2b5";
}
.fa-handshake-alt-slash:before {
  content: "\e05f";
}
.fa-handshake-slash:before {
  content: "\e060";
}
.fa-hanukiah:before {
  content: "\f6e6";
}
.fa-hard-hat:before {
  content: "\f807";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-hat-cowboy:before {
  content: "\f8c0";
}
.fa-hat-cowboy-side:before {
  content: "\f8c1";
}
.fa-hat-wizard:before {
  content: "\f6e8";
}
.fa-hdd:before {
  content: "\f0a0";
}
.fa-head-side-cough:before {
  content: "\e061";
}
.fa-head-side-cough-slash:before {
  content: "\e062";
}
.fa-head-side-mask:before {
  content: "\e063";
}
.fa-head-side-virus:before {
  content: "\e064";
}
.fa-heading:before {
  content: "\f1dc";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-headphones-alt:before {
  content: "\f58f";
}
.fa-headset:before {
  content: "\f590";
}
.fa-heart:before {
  content: "\f004";
}
.fa-heart-broken:before {
  content: "\f7a9";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-helicopter:before {
  content: "\f533";
}
.fa-highlighter:before {
  content: "\f591";
}
.fa-hiking:before {
  content: "\f6ec";
}
.fa-hippo:before {
  content: "\f6ed";
}
.fa-hips:before {
  content: "\f452";
}
.fa-hire-a-helper:before {
  content: "\f3b0";
}
.fa-history:before {
  content: "\f1da";
}
.fa-hive:before {
  content: "\e07f";
}
.fa-hockey-puck:before {
  content: "\f453";
}
.fa-holly-berry:before {
  content: "\f7aa";
}
.fa-home:before {
  content: "\f015";
}
.fa-hooli:before {
  content: "\f427";
}
.fa-hornbill:before {
  content: "\f592";
}
.fa-horse:before {
  content: "\f6f0";
}
.fa-horse-head:before {
  content: "\f7ab";
}
.fa-hospital:before {
  content: "\f0f8";
}
.fa-hospital-alt:before {
  content: "\f47d";
}
.fa-hospital-symbol:before {
  content: "\f47e";
}
.fa-hospital-user:before {
  content: "\f80d";
}
.fa-hot-tub:before {
  content: "\f593";
}
.fa-hotdog:before {
  content: "\f80f";
}
.fa-hotel:before {
  content: "\f594";
}
.fa-hotjar:before {
  content: "\f3b1";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-house-damage:before {
  content: "\f6f1";
}
.fa-house-user:before {
  content: "\e065";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-hryvnia:before {
  content: "\f6f2";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-hubspot:before {
  content: "\f3b2";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-ice-cream:before {
  content: "\f810";
}
.fa-icicles:before {
  content: "\f7ad";
}
.fa-icons:before {
  content: "\f86d";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-id-card:before {
  content: "\f2c2";
}
.fa-id-card-alt:before {
  content: "\f47f";
}
.fa-ideal:before {
  content: "\e013";
}
.fa-igloo:before {
  content: "\f7ae";
}
.fa-image:before {
  content: "\f03e";
}
.fa-images:before {
  content: "\f302";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-industry:before {
  content: "\f275";
}
.fa-infinity:before {
  content: "\f534";
}
.fa-info:before {
  content: "\f129";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-innosoft:before {
  content: "\e080";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-instagram-square:before {
  content: "\e055";
}
.fa-instalod:before {
  content: "\e081";
}
.fa-intercom:before {
  content: "\f7af";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-invision:before {
  content: "\f7b0";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-italic:before {
  content: "\f033";
}
.fa-itch-io:before {
  content: "\f83a";
}
.fa-itunes:before {
  content: "\f3b4";
}
.fa-itunes-note:before {
  content: "\f3b5";
}
.fa-java:before {
  content: "\f4e4";
}
.fa-jedi:before {
  content: "\f669";
}
.fa-jedi-order:before {
  content: "\f50e";
}
.fa-jenkins:before {
  content: "\f3b6";
}
.fa-jira:before {
  content: "\f7b1";
}
.fa-joget:before {
  content: "\f3b7";
}
.fa-joint:before {
  content: "\f595";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-journal-whills:before {
  content: "\f66a";
}
.fa-js:before {
  content: "\f3b8";
}
.fa-js-square:before {
  content: "\f3b9";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-kaaba:before {
  content: "\f66b";
}
.fa-kaggle:before {
  content: "\f5fa";
}
.fa-key:before {
  content: "\f084";
}
.fa-keybase:before {
  content: "\f4f5";
}
.fa-keyboard:before {
  content: "\f11c";
}
.fa-keycdn:before {
  content: "\f3ba";
}
.fa-khanda:before {
  content: "\f66d";
}
.fa-kickstarter:before {
  content: "\f3bb";
}
.fa-kickstarter-k:before {
  content: "\f3bc";
}
.fa-kiss:before {
  content: "\f596";
}
.fa-kiss-beam:before {
  content: "\f597";
}
.fa-kiss-wink-heart:before {
  content: "\f598";
}
.fa-kiwi-bird:before {
  content: "\f535";
}
.fa-korvue:before {
  content: "\f42f";
}
.fa-landmark:before {
  content: "\f66f";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-laptop-code:before {
  content: "\f5fc";
}
.fa-laptop-house:before {
  content: "\e066";
}
.fa-laptop-medical:before {
  content: "\f812";
}
.fa-laravel:before {
  content: "\f3bd";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-laugh:before {
  content: "\f599";
}
.fa-laugh-beam:before {
  content: "\f59a";
}
.fa-laugh-squint:before {
  content: "\f59b";
}
.fa-laugh-wink:before {
  content: "\f59c";
}
.fa-layer-group:before {
  content: "\f5fd";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-lemon:before {
  content: "\f094";
}
.fa-less:before {
  content: "\f41d";
}
.fa-less-than:before {
  content: "\f536";
}
.fa-less-than-equal:before {
  content: "\f537";
}
.fa-level-down-alt:before {
  content: "\f3be";
}
.fa-level-up-alt:before {
  content: "\f3bf";
}
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-lightbulb:before {
  content: "\f0eb";
}
.fa-line:before {
  content: "\f3c0";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-linkedin:before {
  content: "\f08c";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-lira-sign:before {
  content: "\f195";
}
.fa-list:before {
  content: "\f03a";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-lock:before {
  content: "\f023";
}
.fa-lock-open:before {
  content: "\f3c1";
}
.fa-long-arrow-alt-down:before {
  content: "\f309";
}
.fa-long-arrow-alt-left:before {
  content: "\f30a";
}
.fa-long-arrow-alt-right:before {
  content: "\f30b";
}
.fa-long-arrow-alt-up:before {
  content: "\f30c";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-luggage-cart:before {
  content: "\f59d";
}
.fa-lungs:before {
  content: "\f604";
}
.fa-lungs-virus:before {
  content: "\e067";
}
.fa-lyft:before {
  content: "\f3c3";
}
.fa-magento:before {
  content: "\f3c4";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-mail-bulk:before {
  content: "\f674";
}
.fa-mailchimp:before {
  content: "\f59e";
}
.fa-male:before {
  content: "\f183";
}
.fa-mandalorian:before {
  content: "\f50f";
}
.fa-map:before {
  content: "\f279";
}
.fa-map-marked:before {
  content: "\f59f";
}
.fa-map-marked-alt:before {
  content: "\f5a0";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-map-marker-alt:before {
  content: "\f3c5";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-markdown:before {
  content: "\f60f";
}
.fa-marker:before {
  content: "\f5a1";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mask:before {
  content: "\f6fa";
}
.fa-mastodon:before {
  content: "\f4f6";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-mdb:before {
  content: "\f8ca";
}
.fa-medal:before {
  content: "\f5a2";
}
.fa-medapps:before {
  content: "\f3c6";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-medium-m:before {
  content: "\f3c7";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-medrt:before {
  content: "\f3c8";
}
.fa-meetup:before {
  content: "\f2e0";
}
.fa-megaport:before {
  content: "\f5a3";
}
.fa-meh:before {
  content: "\f11a";
}
.fa-meh-blank:before {
  content: "\f5a4";
}
.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}
.fa-memory:before {
  content: "\f538";
}
.fa-mendeley:before {
  content: "\f7b3";
}
.fa-menorah:before {
  content: "\f676";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-meteor:before {
  content: "\f753";
}
.fa-microblog:before {
  content: "\e01a";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-alt:before {
  content: "\f3c9";
}
.fa-microphone-alt-slash:before {
  content: "\f539";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-microscope:before {
  content: "\f610";
}
.fa-microsoft:before {
  content: "\f3ca";
}
.fa-minus:before {
  content: "\f068";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-mitten:before {
  content: "\f7b5";
}
.fa-mix:before {
  content: "\f3cb";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-mixer:before {
  content: "\e056";
}
.fa-mizuni:before {
  content: "\f3cc";
}
.fa-mobile:before {
  content: "\f10b";
}
.fa-mobile-alt:before {
  content: "\f3cd";
}
.fa-modx:before {
  content: "\f285";
}
.fa-monero:before {
  content: "\f3d0";
}
.fa-money-bill:before {
  content: "\f0d6";
}
.fa-money-bill-alt:before {
  content: "\f3d1";
}
.fa-money-bill-wave:before {
  content: "\f53a";
}
.fa-money-bill-wave-alt:before {
  content: "\f53b";
}
.fa-money-check:before {
  content: "\f53c";
}
.fa-money-check-alt:before {
  content: "\f53d";
}
.fa-monument:before {
  content: "\f5a6";
}
.fa-moon:before {
  content: "\f186";
}
.fa-mortar-pestle:before {
  content: "\f5a7";
}
.fa-mosque:before {
  content: "\f678";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-mountain:before {
  content: "\f6fc";
}
.fa-mouse:before {
  content: "\f8cc";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-mug-hot:before {
  content: "\f7b6";
}
.fa-music:before {
  content: "\f001";
}
.fa-napster:before {
  content: "\f3d2";
}
.fa-neos:before {
  content: "\f612";
}
.fa-network-wired:before {
  content: "\f6ff";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-newspaper:before {
  content: "\f1ea";
}
.fa-nimblr:before {
  content: "\f5a8";
}
.fa-node:before {
  content: "\f419";
}
.fa-node-js:before {
  content: "\f3d3";
}
.fa-not-equal:before {
  content: "\f53e";
}
.fa-notes-medical:before {
  content: "\f481";
}
.fa-npm:before {
  content: "\f3d4";
}
.fa-ns8:before {
  content: "\f3d5";
}
.fa-nutritionix:before {
  content: "\f3d6";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-octopus-deploy:before {
  content: "\e082";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-oil-can:before {
  content: "\f613";
}
.fa-old-republic:before {
  content: "\f510";
}
.fa-om:before {
  content: "\f679";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-orcid:before {
  content: "\f8d2";
}
.fa-osi:before {
  content: "\f41a";
}
.fa-otter:before {
  content: "\f700";
}
.fa-outdent:before {
  content: "\f03b";
}
.fa-page4:before {
  content: "\f3d7";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-pager:before {
  content: "\f815";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-paint-roller:before {
  content: "\f5aa";
}
.fa-palette:before {
  content: "\f53f";
}
.fa-palfed:before {
  content: "\f3d8";
}
.fa-pallet:before {
  content: "\f482";
}
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-parachute-box:before {
  content: "\f4cd";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-parking:before {
  content: "\f540";
}
.fa-passport:before {
  content: "\f5ab";
}
.fa-pastafarianism:before {
  content: "\f67b";
}
.fa-paste:before {
  content: "\f0ea";
}
.fa-patreon:before {
  content: "\f3d9";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-peace:before {
  content: "\f67c";
}
.fa-pen:before {
  content: "\f304";
}
.fa-pen-alt:before {
  content: "\f305";
}
.fa-pen-fancy:before {
  content: "\f5ac";
}
.fa-pen-nib:before {
  content: "\f5ad";
}
.fa-pen-square:before {
  content: "\f14b";
}
.fa-pencil-alt:before {
  content: "\f303";
}
.fa-pencil-ruler:before {
  content: "\f5ae";
}
.fa-penny-arcade:before {
  content: "\f704";
}
.fa-people-arrows:before {
  content: "\e068";
}
.fa-people-carry:before {
  content: "\f4ce";
}
.fa-pepper-hot:before {
  content: "\f816";
}
.fa-perbyte:before {
  content: "\e083";
}
.fa-percent:before {
  content: "\f295";
}
.fa-percentage:before {
  content: "\f541";
}
.fa-periscope:before {
  content: "\f3da";
}
.fa-person-booth:before {
  content: "\f756";
}
.fa-phabricator:before {
  content: "\f3db";
}
.fa-phoenix-framework:before {
  content: "\f3dc";
}
.fa-phoenix-squadron:before {
  content: "\f511";
}
.fa-phone:before {
  content: "\f095";
}
.fa-phone-alt:before {
  content: "\f879";
}
.fa-phone-slash:before {
  content: "\f3dd";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-phone-square-alt:before {
  content: "\f87b";
}
.fa-phone-volume:before {
  content: "\f2a0";
}
.fa-photo-video:before {
  content: "\f87c";
}
.fa-php:before {
  content: "\f457";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-pied-piper-hat:before {
  content: "\f4e5";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-pied-piper-square:before {
  content: "\e01e";
}
.fa-piggy-bank:before {
  content: "\f4d3";
}
.fa-pills:before {
  content: "\f484";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-pizza-slice:before {
  content: "\f818";
}
.fa-place-of-worship:before {
  content: "\f67f";
}
.fa-plane:before {
  content: "\f072";
}
.fa-plane-arrival:before {
  content: "\f5af";
}
.fa-plane-departure:before {
  content: "\f5b0";
}
.fa-plane-slash:before {
  content: "\e069";
}
.fa-play:before {
  content: "\f04b";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-playstation:before {
  content: "\f3df";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-plus:before {
  content: "\f067";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-poll:before {
  content: "\f681";
}
.fa-poll-h:before {
  content: "\f682";
}
.fa-poo:before {
  content: "\f2fe";
}
.fa-poo-storm:before {
  content: "\f75a";
}
.fa-poop:before {
  content: "\f619";
}
.fa-portrait:before {
  content: "\f3e0";
}
.fa-pound-sign:before {
  content: "\f154";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-pray:before {
  content: "\f683";
}
.fa-praying-hands:before {
  content: "\f684";
}
.fa-prescription:before {
  content: "\f5b1";
}
.fa-prescription-bottle:before {
  content: "\f485";
}
.fa-prescription-bottle-alt:before {
  content: "\f486";
}
.fa-print:before {
  content: "\f02f";
}
.fa-procedures:before {
  content: "\f487";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-project-diagram:before {
  content: "\f542";
}
.fa-pump-medical:before {
  content: "\e06a";
}
.fa-pump-soap:before {
  content: "\e06b";
}
.fa-pushed:before {
  content: "\f3e1";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-python:before {
  content: "\f3e2";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-question:before {
  content: "\f128";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-quidditch:before {
  content: "\f458";
}
.fa-quinscape:before {
  content: "\f459";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-quran:before {
  content: "\f687";
}
.fa-r-project:before {
  content: "\f4f7";
}
.fa-radiation:before {
  content: "\f7b9";
}
.fa-radiation-alt:before {
  content: "\f7ba";
}
.fa-rainbow:before {
  content: "\f75b";
}
.fa-random:before {
  content: "\f074";
}
.fa-raspberry-pi:before {
  content: "\f7bb";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-react:before {
  content: "\f41b";
}
.fa-reacteurope:before {
  content: "\f75d";
}
.fa-readme:before {
  content: "\f4d5";
}
.fa-rebel:before {
  content: "\f1d0";
}
.fa-receipt:before {
  content: "\f543";
}
.fa-record-vinyl:before {
  content: "\f8d9";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-red-river:before {
  content: "\f3e3";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-redhat:before {
  content: "\f7bc";
}
.fa-redo:before {
  content: "\f01e";
}
.fa-redo-alt:before {
  content: "\f2f9";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-remove-format:before {
  content: "\f87d";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-reply:before {
  content: "\f3e5";
}
.fa-reply-all:before {
  content: "\f122";
}
.fa-replyd:before {
  content: "\f3e6";
}
.fa-republican:before {
  content: "\f75e";
}
.fa-researchgate:before {
  content: "\f4f8";
}
.fa-resolving:before {
  content: "\f3e7";
}
.fa-restroom:before {
  content: "\f7bd";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-rev:before {
  content: "\f5b2";
}
.fa-ribbon:before {
  content: "\f4d6";
}
.fa-ring:before {
  content: "\f70b";
}
.fa-road:before {
  content: "\f018";
}
.fa-robot:before {
  content: "\f544";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-rocketchat:before {
  content: "\f3e8";
}
.fa-rockrms:before {
  content: "\f3e9";
}
.fa-route:before {
  content: "\f4d7";
}
.fa-rss:before {
  content: "\f09e";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-ruble-sign:before {
  content: "\f158";
}
.fa-ruler:before {
  content: "\f545";
}
.fa-ruler-combined:before {
  content: "\f546";
}
.fa-ruler-horizontal:before {
  content: "\f547";
}
.fa-ruler-vertical:before {
  content: "\f548";
}
.fa-running:before {
  content: "\f70c";
}
.fa-rupee-sign:before {
  content: "\f156";
}
.fa-rust:before {
  content: "\e07a";
}
.fa-sad-cry:before {
  content: "\f5b3";
}
.fa-sad-tear:before {
  content: "\f5b4";
}
.fa-safari:before {
  content: "\f267";
}
.fa-salesforce:before {
  content: "\f83b";
}
.fa-sass:before {
  content: "\f41e";
}
.fa-satellite:before {
  content: "\f7bf";
}
.fa-satellite-dish:before {
  content: "\f7c0";
}
.fa-save:before {
  content: "\f0c7";
}
.fa-schlix:before {
  content: "\f3ea";
}
.fa-school:before {
  content: "\f549";
}
.fa-screwdriver:before {
  content: "\f54a";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-scroll:before {
  content: "\f70e";
}
.fa-sd-card:before {
  content: "\f7c2";
}
.fa-search:before {
  content: "\f002";
}
.fa-search-dollar:before {
  content: "\f688";
}
.fa-search-location:before {
  content: "\f689";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-searchengin:before {
  content: "\f3eb";
}
.fa-seedling:before {
  content: "\f4d8";
}
.fa-sellcast:before {
  content: "\f2da";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-server:before {
  content: "\f233";
}
.fa-servicestack:before {
  content: "\f3ec";
}
.fa-shapes:before {
  content: "\f61f";
}
.fa-share:before {
  content: "\f064";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-shekel-sign:before {
  content: "\f20b";
}
.fa-shield-alt:before {
  content: "\f3ed";
}
.fa-shield-virus:before {
  content: "\e06c";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-shipping-fast:before {
  content: "\f48b";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-shoe-prints:before {
  content: "\f54b";
}
.fa-shopify:before {
  content: "\e057";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-shopware:before {
  content: "\f5b5";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-shuttle-van:before {
  content: "\f5b6";
}
.fa-sign:before {
  content: "\f4d9";
}
.fa-sign-in-alt:before {
  content: "\f2f6";
}
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-sign-out-alt:before {
  content: "\f2f5";
}
.fa-signal:before {
  content: "\f012";
}
.fa-signature:before {
  content: "\f5b7";
}
.fa-sim-card:before {
  content: "\f7c4";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-sink:before {
  content: "\e06d";
}
.fa-sistrix:before {
  content: "\f3ee";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-sith:before {
  content: "\f512";
}
.fa-skating:before {
  content: "\f7c5";
}
.fa-sketch:before {
  content: "\f7c6";
}
.fa-skiing:before {
  content: "\f7c9";
}
.fa-skiing-nordic:before {
  content: "\f7ca";
}
.fa-skull:before {
  content: "\f54c";
}
.fa-skull-crossbones:before {
  content: "\f714";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-slack:before {
  content: "\f198";
}
.fa-slack-hash:before {
  content: "\f3ef";
}
.fa-slash:before {
  content: "\f715";
}
.fa-sleigh:before {
  content: "\f7cc";
}
.fa-sliders-h:before {
  content: "\f1de";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-smile:before {
  content: "\f118";
}
.fa-smile-beam:before {
  content: "\f5b8";
}
.fa-smile-wink:before {
  content: "\f4da";
}
.fa-smog:before {
  content: "\f75f";
}
.fa-smoking:before {
  content: "\f48d";
}
.fa-smoking-ban:before {
  content: "\f54d";
}
.fa-sms:before {
  content: "\f7cd";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-snowboarding:before {
  content: "\f7ce";
}
.fa-snowflake:before {
  content: "\f2dc";
}
.fa-snowman:before {
  content: "\f7d0";
}
.fa-snowplow:before {
  content: "\f7d2";
}
.fa-soap:before {
  content: "\e06e";
}
.fa-socks:before {
  content: "\f696";
}
.fa-solar-panel:before {
  content: "\f5ba";
}
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-alpha-down:before {
  content: "\f15d";
}
.fa-sort-alpha-down-alt:before {
  content: "\f881";
}
.fa-sort-alpha-up:before {
  content: "\f15e";
}
.fa-sort-alpha-up-alt:before {
  content: "\f882";
}
.fa-sort-amount-down:before {
  content: "\f160";
}
.fa-sort-amount-down-alt:before {
  content: "\f884";
}
.fa-sort-amount-up:before {
  content: "\f161";
}
.fa-sort-amount-up-alt:before {
  content: "\f885";
}
.fa-sort-down:before {
  content: "\f0dd";
}
.fa-sort-numeric-down:before {
  content: "\f162";
}
.fa-sort-numeric-down-alt:before {
  content: "\f886";
}
.fa-sort-numeric-up:before {
  content: "\f163";
}
.fa-sort-numeric-up-alt:before {
  content: "\f887";
}
.fa-sort-up:before {
  content: "\f0de";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-sourcetree:before {
  content: "\f7d3";
}
.fa-spa:before {
  content: "\f5bb";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-speakap:before {
  content: "\f3f3";
}
.fa-speaker-deck:before {
  content: "\f83c";
}
.fa-spell-check:before {
  content: "\f891";
}
.fa-spider:before {
  content: "\f717";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-splotch:before {
  content: "\f5bc";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-spray-can:before {
  content: "\f5bd";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-square-full:before {
  content: "\f45c";
}
.fa-square-root-alt:before {
  content: "\f698";
}
.fa-squarespace:before {
  content: "\f5be";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-stackpath:before {
  content: "\f842";
}
.fa-stamp:before {
  content: "\f5bf";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-and-crescent:before {
  content: "\f699";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-star-half-alt:before {
  content: "\f5c0";
}
.fa-star-of-david:before {
  content: "\f69a";
}
.fa-star-of-life:before {
  content: "\f621";
}
.fa-staylinked:before {
  content: "\f3f5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-steam-symbol:before {
  content: "\f3f6";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-sticker-mule:before {
  content: "\f3f7";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stopwatch:before {
  content: "\f2f2";
}
.fa-stopwatch-20:before {
  content: "\e06f";
}
.fa-store:before {
  content: "\f54e";
}
.fa-store-alt:before {
  content: "\f54f";
}
.fa-store-alt-slash:before {
  content: "\e070";
}
.fa-store-slash:before {
  content: "\e071";
}
.fa-strava:before {
  content: "\f428";
}
.fa-stream:before {
  content: "\f550";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-stripe:before {
  content: "\f429";
}
.fa-stripe-s:before {
  content: "\f42a";
}
.fa-stroopwafel:before {
  content: "\f551";
}
.fa-studiovinari:before {
  content: "\f3f8";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-subway:before {
  content: "\f239";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-suitcase-rolling:before {
  content: "\f5c1";
}
.fa-sun:before {
  content: "\f185";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-supple:before {
  content: "\f3f9";
}
.fa-surprise:before {
  content: "\f5c2";
}
.fa-suse:before {
  content: "\f7d6";
}
.fa-swatchbook:before {
  content: "\f5c3";
}
.fa-swift:before {
  content: "\f8e1";
}
.fa-swimmer:before {
  content: "\f5c4";
}
.fa-swimming-pool:before {
  content: "\f5c5";
}
.fa-symfony:before {
  content: "\f83d";
}
.fa-synagogue:before {
  content: "\f69b";
}
.fa-sync:before {
  content: "\f021";
}
.fa-sync-alt:before {
  content: "\f2f1";
}
.fa-syringe:before {
  content: "\f48e";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-table-tennis:before {
  content: "\f45d";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-tablet-alt:before {
  content: "\f3fa";
}
.fa-tablets:before {
  content: "\f490";
}
.fa-tachometer-alt:before {
  content: "\f3fd";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-tape:before {
  content: "\f4db";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-taxi:before {
  content: "\f1ba";
}
.fa-teamspeak:before {
  content: "\f4f9";
}
.fa-teeth:before {
  content: "\f62e";
}
.fa-teeth-open:before {
  content: "\f62f";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-telegram-plane:before {
  content: "\f3fe";
}
.fa-temperature-high:before {
  content: "\f769";
}
.fa-temperature-low:before {
  content: "\f76b";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-tenge:before {
  content: "\f7d7";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-the-red-yeti:before {
  content: "\f69d";
}
.fa-theater-masks:before {
  content: "\f630";
}
.fa-themeco:before {
  content: "\f5c6";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-thermometer:before {
  content: "\f491";
}
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-think-peaks:before {
  content: "\f731";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbtack:before {
  content: "\f08d";
}
.fa-ticket-alt:before {
  content: "\f3ff";
}
.fa-tiktok:before {
  content: "\e07b";
}
.fa-times:before {
  content: "\f00d";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-tint:before {
  content: "\f043";
}
.fa-tint-slash:before {
  content: "\f5c7";
}
.fa-tired:before {
  content: "\f5c8";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-toilet:before {
  content: "\f7d8";
}
.fa-toilet-paper:before {
  content: "\f71e";
}
.fa-toilet-paper-slash:before {
  content: "\e072";
}
.fa-toolbox:before {
  content: "\f552";
}
.fa-tools:before {
  content: "\f7d9";
}
.fa-tooth:before {
  content: "\f5c9";
}
.fa-torah:before {
  content: "\f6a0";
}
.fa-torii-gate:before {
  content: "\f6a1";
}
.fa-tractor:before {
  content: "\f722";
}
.fa-trade-federation:before {
  content: "\f513";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-traffic-light:before {
  content: "\f637";
}
.fa-trailer:before {
  content: "\e041";
}
.fa-train:before {
  content: "\f238";
}
.fa-tram:before {
  content: "\f7da";
}
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-trash-alt:before {
  content: "\f2ed";
}
.fa-trash-restore:before {
  content: "\f829";
}
.fa-trash-restore-alt:before {
  content: "\f82a";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-trello:before {
  content: "\f181";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-truck-loading:before {
  content: "\f4de";
}
.fa-truck-monster:before {
  content: "\f63b";
}
.fa-truck-moving:before {
  content: "\f4df";
}
.fa-truck-pickup:before {
  content: "\f63c";
}
.fa-tshirt:before {
  content: "\f553";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-tv:before {
  content: "\f26c";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-typo3:before {
  content: "\f42b";
}
.fa-uber:before {
  content: "\f402";
}
.fa-ubuntu:before {
  content: "\f7df";
}
.fa-uikit:before {
  content: "\f403";
}
.fa-umbraco:before {
  content: "\f8e8";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-umbrella-beach:before {
  content: "\f5ca";
}
.fa-uncharted:before {
  content: "\e084";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-undo:before {
  content: "\f0e2";
}
.fa-undo-alt:before {
  content: "\f2ea";
}
.fa-uniregistry:before {
  content: "\f404";
}
.fa-unity:before {
  content: "\e049";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-university:before {
  content: "\f19c";
}
.fa-unlink:before {
  content: "\f127";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-unsplash:before {
  content: "\e07c";
}
.fa-untappd:before {
  content: "\f405";
}
.fa-upload:before {
  content: "\f093";
}
.fa-ups:before {
  content: "\f7e0";
}
.fa-usb:before {
  content: "\f287";
}
.fa-user:before {
  content: "\f007";
}
.fa-user-alt:before {
  content: "\f406";
}
.fa-user-alt-slash:before {
  content: "\f4fa";
}
.fa-user-astronaut:before {
  content: "\f4fb";
}
.fa-user-check:before {
  content: "\f4fc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-clock:before {
  content: "\f4fd";
}
.fa-user-cog:before {
  content: "\f4fe";
}
.fa-user-edit:before {
  content: "\f4ff";
}
.fa-user-friends:before {
  content: "\f500";
}
.fa-user-graduate:before {
  content: "\f501";
}
.fa-user-injured:before {
  content: "\f728";
}
.fa-user-lock:before {
  content: "\f502";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-user-minus:before {
  content: "\f503";
}
.fa-user-ninja:before {
  content: "\f504";
}
.fa-user-nurse:before {
  content: "\f82f";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-user-shield:before {
  content: "\f505";
}
.fa-user-slash:before {
  content: "\f506";
}
.fa-user-tag:before {
  content: "\f507";
}
.fa-user-tie:before {
  content: "\f508";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-users-cog:before {
  content: "\f509";
}
.fa-users-slash:before {
  content: "\e073";
}
.fa-usps:before {
  content: "\f7e1";
}
.fa-ussunnah:before {
  content: "\f407";
}
.fa-utensil-spoon:before {
  content: "\f2e5";
}
.fa-utensils:before {
  content: "\f2e7";
}
.fa-vaadin:before {
  content: "\f408";
}
.fa-vector-square:before {
  content: "\f5cb";
}
.fa-venus:before {
  content: "\f221";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-vest:before {
  content: "\e085";
}
.fa-vest-patches:before {
  content: "\e086";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-vial:before {
  content: "\f492";
}
.fa-vials:before {
  content: "\f493";
}
.fa-viber:before {
  content: "\f409";
}
.fa-video:before {
  content: "\f03d";
}
.fa-video-slash:before {
  content: "\f4e2";
}
.fa-vihara:before {
  content: "\f6a7";
}
.fa-vimeo:before {
  content: "\f40a";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-vimeo-v:before {
  content: "\f27d";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-virus:before {
  content: "\e074";
}
.fa-virus-slash:before {
  content: "\e075";
}
.fa-viruses:before {
  content: "\e076";
}
.fa-vk:before {
  content: "\f189";
}
.fa-vnv:before {
  content: "\f40b";
}
.fa-voicemail:before {
  content: "\f897";
}
.fa-volleyball-ball:before {
  content: "\f45f";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-mute:before {
  content: "\f6a9";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-vote-yea:before {
  content: "\f772";
}
.fa-vr-cardboard:before {
  content: "\f729";
}
.fa-vuejs:before {
  content: "\f41f";
}
.fa-walking:before {
  content: "\f554";
}
.fa-wallet:before {
  content: "\f555";
}
.fa-warehouse:before {
  content: "\f494";
}
.fa-watchman-monitoring:before {
  content: "\e087";
}
.fa-water:before {
  content: "\f773";
}
.fa-wave-square:before {
  content: "\f83e";
}
.fa-waze:before {
  content: "\f83f";
}
.fa-weebly:before {
  content: "\f5cc";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-weight:before {
  content: "\f496";
}
.fa-weight-hanging:before {
  content: "\f5cd";
}
.fa-weixin:before {
  content: "\f1d7";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-whatsapp-square:before {
  content: "\f40c";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-whmcs:before {
  content: "\f40d";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-wind:before {
  content: "\f72e";
}
.fa-window-close:before {
  content: "\f410";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-wine-bottle:before {
  content: "\f72f";
}
.fa-wine-glass:before {
  content: "\f4e3";
}
.fa-wine-glass-alt:before {
  content: "\f5ce";
}
.fa-wix:before {
  content: "\f5cf";
}
.fa-wizards-of-the-coast:before {
  content: "\f730";
}
.fa-wodu:before {
  content: "\e088";
}
.fa-wolf-pack-battalion:before {
  content: "\f514";
}
.fa-won-sign:before {
  content: "\f159";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-wordpress-simple:before {
  content: "\f411";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-wpressr:before {
  content: "\f3e4";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-x-ray:before {
  content: "\f497";
}
.fa-xbox:before {
  content: "\f412";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-yammer:before {
  content: "\f840";
}
.fa-yandex:before {
  content: "\f413";
}
.fa-yandex-international:before {
  content: "\f414";
}
.fa-yarn:before {
  content: "\f7e3";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-yen-sign:before {
  content: "\f157";
}
.fa-yin-yang:before {
  content: "\f6ad";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-youtube-square:before {
  content: "\f431";
}
.fa-zhihu:before {
  content: "\f63f";
}

.fade.in {
  opacity: 1;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: 700;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}
.alert-dismissable {
  padding-right: 35px;
}
.alert-dismissable .close {
  position: relative;
  top: -2px;
  color: inherit;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #356635;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #2d6987;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #a47e3c;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #953b39;
}
button.close {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: 0 none;
  cursor: pointer;
  padding: 0;
}
.close {
  color: #000;
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  opacity: 0.2;
}
.fusion-alert,
.tribe-events-notices {
  clear: both;
  position: relative;
  padding: 25px;
  border-radius: 0;
  margin-bottom: 20px;
  text-shadow: none;
}
.fusion-alert.alert-shadow,
.tribe-events-notices.alert-shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.fusion-alert .close,
.tribe-events-notices .close {
  position: absolute;
  box-sizing: content-box;
  top: -1px;
  right: -1px;
  height: 25px;
  width: 25px;
  line-height: 25px;
  opacity: 1;
  border: 1px solid;
  border-color: inherit;
  color: inherit;
  font-size: 15px;
  transition: all 0.2s;
}
.fusion-alert .close:hover,
.tribe-events-notices .close:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.05);
}
.fusion-alert.alert-blank,
.tribe-events-notices.alert-blank {
  border: 1px solid #747474;
}
.fusion-alert.fusion-alert-capitalize,
.tribe-events-notices.fusion-alert-capitalize {
  text-transform: uppercase;
}
.fusion-alert.fusion-alert-center,
.tribe-events-notices.fusion-alert-center {
  text-align: center;
}
.fusion-alert.fusion-alert-center .alert-icon,
.tribe-events-notices.fusion-alert-center .alert-icon {
  margin-right: 10px;
}
.fusion-alert.fusion-alert-left .fusion-alert-content-wrapper,
.tribe-events-notices.fusion-alert-left .fusion-alert-content-wrapper {
  display: flex;
  text-align: left;
}
.fusion-alert.fusion-alert-left .fusion-alert-content-wrapper .alert-icon,
.tribe-events-notices.fusion-alert-left
  .fusion-alert-content-wrapper
  .alert-icon {
  margin-right: 1em;
}
.fusion-alert.fusion-alert-right .close,
.tribe-events-notices.fusion-alert-right .close {
  left: -1px;
  right: auto;
}
.fusion-alert.fusion-alert-right .fusion-alert-content-wrapper,
.tribe-events-notices.fusion-alert-right .fusion-alert-content-wrapper {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.fusion-alert.fusion-alert-right .fusion-alert-content-wrapper .alert-icon,
.tribe-events-notices.fusion-alert-right
  .fusion-alert-content-wrapper
  .alert-icon {
  order: 1;
  margin-left: 1em;
}
.gform_wrapper .gform_validation_errors {
  position: relative;
  padding: 25px;
  border-radius: 0;
  margin-bottom: 20px;
  text-shadow: none;
}
.tribe-events-notices:not(.fusion-alert) {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-color: #d7d6d6;
  color: inherit;
  text-shadow: none;
  text-align: center;
  text-transform: uppercase;
}
body .fusion-alert.alert {
  border-width: var(--alert_border_size);
}
.fusion-alert.alert-info.general {
  background: var(--info_bg_color);
  border-color: var(--info_accent_color);
  color: var(--info_accent_color);
}
.fusion-alert.alert-info.general .alert-icon {
  color: var(--info_accent_color);
}
.fusion-alert.alert-info.general .fusion-alert-content {
  color: var(--info_accent_color);
}
.fusion-alert.alert-danger.error {
  background: var(--danger_bg_color);
  border-color: var(--danger_accent_color);
  color: var(--danger_accent_color);
}
.fusion-alert.alert-danger.error .alert-icon {
  color: var(--danger_accent_color);
}
.fusion-alert.alert-danger.error .fusion-alert-content {
  color: var(--danger_accent_color);
}
.fusion-alert.alert-success.success {
  background: var(--success_bg_color);
  border-color: var(--success_accent_color);
  color: var(--success_accent_color);
}
.fusion-alert.alert-success.success .alert-icon {
  color: var(--success_accent_color);
}
.fusion-alert.alert-success.success .fusion-alert-content {
  color: var(--success_accent_color);
}
.fusion-alert.alert-warning.notice {
  background: var(--warning_bg_color);
  border-color: var(--warning_accent_color);
  color: var(--warning_accent_color);
}
.fusion-alert.alert-warning.notice .alert-icon {
  color: var(--warning_accent_color);
}
.fusion-alert.alert-warning.notice .fusion-alert-content {
  color: var(--warning_accent_color);
}
.fusion-audio.fusion-audio {
  max-width: var(
    --fusion-audio-max-width,
    var(--fusion-audio-max-width-default, 100%)
  );
}
.fusion-audio.fusion-audio.dark-controls .mejs-button > button {
  background-image: url("https://ethjets.io/wp-content/plugins/fusion-builder/assets/images/mejs-controls-dark.svg");
}
.fusion-audio.fusion-audio.dark-controls .mejs-controls > * {
  color: #000;
}
.fusion-audio.fusion-audio.dark-controls
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-total,
.fusion-audio.fusion-audio.dark-controls
  .mejs-controls
  .mejs-time-rail
  .mejs-time-total {
  background: rgba(0, 0, 0, 0.33);
}
.fusion-audio.fusion-audio.dark-controls
  .mejs-controls
  .mejs-horizontal-volume-slider
  .mejs-horizontal-volume-current,
.fusion-audio.fusion-audio.dark-controls
  .mejs-controls
  .mejs-time-rail
  .mejs-time-loaded {
  background: #000;
}
.fusion-audio.fusion-audio.dark-controls .mejs-time-handle-content {
  border: 4px solid hsla(0, 0%, 0%, 0.9);
}
.fusion-audio.fusion-audio.dark-controls .mejs-time-current,
.fusion-audio.fusion-audio.dark-controls .mejs-time-handle-content {
  background: hsla(0, 0%, 0%, 0.9);
}
.fusion-audio.fusion-audio .mejs-container {
  background-color: transparent;
  height: calc(
    40px + 2 *
      var(
        --fusion-audio-border-size,
        var(--fusion-audio-border-size-default, 0)
      ) * 1px
  ) !important;
}
.fusion-audio.fusion-audio .mejs-container .mejs-controls,
.fusion-audio.fusion-audio .mejs-embed,
.fusion-audio.fusion-audio .mejs-embed body {
  background-color: var(
    --fusion-audio-background-color,
    var(--fusion-audio-background-color-default, #222)
  );
}
.fusion-audio.fusion-audio .mejs-controls .mejs-time-rail .mejs-time-current {
  background: var(
    --fusion-audio-accent-color,
    var(--fusion-audio-accent-color-default, #0073aa)
  );
}
.fusion-audio.fusion-audio .mejs-controls {
  border-top-left-radius: var(
    --fusion-audio-border-top-left-radius,
    var(--fusion-audio-border-top-left-radius-default, 0)
  );
  border-top-right-radius: var(
    --fusion-audio-border-top-right-radius,
    var(--fusion-audio-border-top-right-radius-default, 0)
  );
  border-bottom-right-radius: var(
    --fusion-audio-border-bottom-right-radius,
    var(--fusion-audio-border-bottom-right-radius-default, 0)
  );
  border-bottom-left-radius: var(
    --fusion-audio-border-bottom-left-radius,
    var(--fusion-audio-border-bottom-left-radius-default, 0)
  );
  box-shadow: var(--fusion-audio-box-shadow);
  border: calc(
      var(
          --fusion-audio-border-size,
          var(--fusion-audio-border-size-default, 0)
        ) * 1px
    )
    solid
    var(
      --fusion-audio-border-color,
      var(--fusion-audio-border-color-default, #222)
    );
  height: calc(
    40px + 2 *
      var(
        --fusion-audio-border-size,
        var(--fusion-audio-border-size-default, 0)
      ) * 1px
  ) !important;
}
.fusion-blog-shortcode a {
  text-decoration: none;
  box-shadow: none !important;
}
.fusion-blog-shortcode .entry-title {
  clear: right;
}
.fusion-blog-shortcode .fusion-read-more {
  position: relative;
  margin-right: 10px;
  text-align: right;
}
.fusion-blog-shortcode .fusion-read-more:after {
  content: "\f105";
  top: 50%;
  right: -10px;
  padding-left: 5px;
  font-size: 14px;
  font-family: awb-icons;
  position: absolute;
  transform: translateY(-50%);
}
.fusion-blog-shortcode .fusion-inline-sep {
  padding: 0 5px;
}
.fusion-blog-shortcode .fusion-inline-sep:last-child {
  display: none;
}
.fusion-blog-shortcode .fusion-post-grid {
  margin-bottom: 65px;
}
.fusion-blog-shortcode .fusion-load-more-button.fusion-blog-button {
  background-color: var(--blog_element_load_more_posts_button_bg_color);
  color: var(--blog_element_load_more_posts_button_text_color);
}
.fusion-blog-shortcode .fusion-load-more-button.fusion-blog-button:hover {
  background-color: var(--blog_element_load_more_posts_hover_button_bg_color);
  color: var(--blog_element_load_more_posts_hover_button_text_color);
}
.fusion-post-slideshow .image {
  margin-bottom: 0;
}
.fusion-post-slideshow .fusion-image-size-fixed {
  display: block;
}
.fusion-flexslider.fusion-post-slideshow {
  overflow: visible;
}
.fusion-meta-info {
  clear: both;
  overflow: hidden;
  margin-top: 20px;
  padding: 5px 0;
  border-top: 1px solid #e7e6e6;
  border-bottom: 1px solid #e7e6e6;
}
.fusion-meta-info .fusion-alignleft {
  width: 80%;
}
.fusion-single-line-meta {
  margin-bottom: 20px;
}
.fusion-content-sep {
  margin-top: 20px;
  height: 6px;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}
.fusion-date-and-formats {
  float: left;
  margin-right: 17px;
  width: 82px;
  text-align: center;
}
.fusion-date-and-formats .fusion-date-box {
  padding: 8px 0 5px;
  color: #fff;
  display: block;
}
.fusion-date-and-formats .fusion-date {
  display: block;
  line-height: 29px;
  font-size: 29px;
  font-weight: 700;
}
.fusion-date-and-formats .fusion-format-box {
  margin-top: 3px;
  background-color: #eef0f2;
}
.fusion-date-and-formats .fusion-format-box i {
  display: block;
  line-height: 55px;
  font-size: 30px;
}
.fusion-timeline-line {
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -1px;
  height: 100%;
  border-left: 1px solid #efeeee;
  border-right: 1px solid #ebeaea;
}
.fusion-timeline-icon {
  margin: 0 auto -6px;
  max-width: 1000px;
  font-size: 46px;
  height: 47px;
  line-height: 1;
  color: #ebeaea;
  text-align: center;
}
.fusion-timeline-icon .fusionicon-bubbles {
  cursor: pointer;
}
.fusion-timeline-circle {
  position: absolute;
  left: 50%;
  margin: 10px 0 0 -5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #efeeee;
}
.fusion-timeline-arrow {
  position: absolute;
  color: #e9eaee;
}
.fusion-timeline-arrow:before {
  font-family: awb-icons;
  font-size: 28px;
  position: absolute;
  top: 0;
  line-height: 30px;
}
.fusion-blog-archive .entry-title {
  margin-top: 0;
  margin-bottom: 5px;
}
.fusion-blog-layout-large article,
.fusion-blog-layout-large-alternate article,
.fusion-blog-layout-medium article {
  margin-bottom: 65px;
}
.fusion-blog-layout-large .entry-title,
.fusion-blog-layout-medium .entry-title {
  margin-top: 0;
  margin-bottom: 25px;
}
.fusion-blog-layout-large .fusion-post-slideshow,
.fusion-blog-layout-large-alternate .fusion-post-slideshow,
.single-avada_faq .fusion-post-slideshow,
.single-post .fusion-post-slideshow,
.single-wpfc_sermon .fusion-post-slideshow {
  margin-bottom: 30px;
}
.fusion-blog-layout-large-alternate .entry-title,
.fusion-blog-layout-medium-alternate .entry-title {
  margin-top: 0;
  margin-bottom: 5px;
}
.fusion-blog-layout-large-alternate .fusion-post-content-container,
.fusion-blog-layout-medium-alternate .fusion-post-content-container {
  margin-top: 20px;
}
.fusion-blog-layout-large-alternate .fusion-meta-info,
.fusion-blog-layout-medium-alternate .fusion-meta-info {
  margin-top: 0;
  border-top: 0;
}
.fusion-blog-layout-medium .fusion-post-medium,
.fusion-blog-layout-medium .fusion-post-medium-alternate,
.fusion-blog-layout-medium-alternate .fusion-post-medium,
.fusion-blog-layout-medium-alternate .fusion-post-medium-alternate {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}
.fusion-blog-layout-medium .fusion-post-slideshow,
.fusion-blog-layout-medium-alternate .fusion-post-slideshow {
  margin-right: 25px;
  width: 320px;
  margin-bottom: 0;
}
.fusion-blog-layout-medium .fusion-post-content,
.fusion-blog-layout-medium-alternate .fusion-post-content {
  flex: 1 1 1px;
}
.fusion-blog-layout-medium .fusion-meta-info,
.fusion-blog-layout-medium-alternate .fusion-meta-info {
  width: 100%;
}
.fusion-blog-layout-grid .fusion-post-slideshow,
.fusion-blog-layout-timeline .fusion-post-slideshow {
  margin-bottom: 0;
  border-bottom: 1px solid #eaeaea;
}
.fusion-blog-layout-grid .fusion-post-content-wrapper,
.fusion-blog-layout-timeline .fusion-post-content-wrapper {
  padding: 30px 25px 20px;
}
.fusion-blog-layout-grid .fusion-single-line-meta,
.fusion-blog-layout-timeline .fusion-single-line-meta {
  margin-bottom: 0;
}
.fusion-blog-layout-grid .fusion-post-content-container,
.fusion-blog-layout-timeline .fusion-post-content-container {
  margin-top: 20px;
}
.fusion-blog-layout-grid .fusion-post-content-container p:last-child,
.fusion-blog-layout-timeline .fusion-post-content-container p:last-child {
  margin-bottom: 0;
}
.fusion-blog-layout-grid .fusion-meta-info,
.fusion-blog-layout-timeline .fusion-meta-info {
  border: 0;
}
.fusion-blog-layout-grid .fusion-meta-info .fusion-alignleft,
.fusion-blog-layout-timeline .fusion-meta-info .fusion-alignleft {
  width: 50%;
  display: inline-block;
  margin: 0;
}
.fusion-blog-layout-grid .fusion-meta-info .fusion-alignright,
.fusion-blog-layout-timeline .fusion-meta-info .fusion-alignright {
  width: 50%;
  display: inline-block;
  text-align: right;
  margin: 0;
}
.fusion-blog-layout-grid .fusion-password,
.fusion-blog-layout-timeline .fusion-password {
  width: auto;
  max-width: 100%;
}
.fusion-blog-layout-large-alternate .fusion-post-large-alternate {
  display: flex;
  flex-wrap: wrap;
}
.fusion-blog-layout-large-alternate .fusion-post-content {
  flex: 1;
}
.fusion-blog-layout-large-alternate .fusion-meta-info,
.fusion-blog-layout-large-alternate .fusion-post-slideshow {
  width: 100%;
}
.fusion-blog-layout-medium-alternate .fusion-post-slideshow {
  display: none;
}
.fusion-blog-layout-medium-alternate
  .has-post-thumbnail
  .fusion-post-slideshow {
  display: block;
  margin-right: 17px;
}
.fusion-blog-layout-grid .fusion-post-grid {
  margin-bottom: 0;
  box-sizing: border-box;
  border: none;
}
.fusion-blog-layout-grid.fusion-no-meta-info .fusion-post-content-container {
  margin-top: 0;
}
.fusion-blog-layout-grid .fusion-post-wrapper {
  border: 1px solid #eaeaea;
  border-bottom-width: 3px;
}
.fusion-blog-layout-grid .fusion-post-wrapper .fusion-image-wrapper img {
  box-shadow: none;
  border-radius: 0;
}
.fusion-blog-layout-grid .fusion-post-wrapper.fusion-masonary-is-transparent {
  border: none;
  border-bottom-width: 0;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-1.fusion-blog-layout-masonry
  article {
  width: 100%;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-2 .fusion-post-grid {
  width: 50%;
  display: inline-block;
  float: left;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-2.fusion-blog-layout-masonry-has-vertical
  .fusion-post-grid.fusion-element-landscape {
  width: 100%;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-3 .fusion-post-grid {
  width: 33.33333333%;
  display: inline-block;
  float: left;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-3.fusion-blog-layout-masonry-has-vertical
  .fusion-post-grid.fusion-element-landscape {
  width: 66.66666667%;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-4 .fusion-post-grid {
  width: 25%;
  display: inline-block;
  float: left;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-4.fusion-blog-layout-masonry-has-vertical
  .fusion-post-grid.fusion-element-landscape {
  width: 50%;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-5 .fusion-post-grid {
  width: 20%;
  display: inline-block;
  float: left;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-5.fusion-blog-layout-masonry-has-vertical
  .fusion-post-grid.fusion-element-landscape {
  width: 40%;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-6 .fusion-post-grid {
  width: 16.66666667%;
  display: inline-block;
  float: left;
}
.fusion-blog-layout-grid.fusion-blog-layout-grid-6.fusion-blog-layout-masonry-has-vertical
  .fusion-post-grid.fusion-element-landscape {
  width: 33.33333333%;
}
.fusion-blog-layout-grid .fusion-post-content,
.fusion-blog-layout-grid .fusion-post-grid-content {
  word-wrap: break-word;
}
.fusion-blog-layout-grid.fusion-blog-equal-heights .fusion-post-grid {
  display: inline-flex;
}
.fusion-blog-layout-grid.fusion-blog-equal-heights .fusion-post-wrapper {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.fusion-blog-layout-grid.fusion-blog-equal-heights
  .fusion-post-wrapper
  .fusion-post-content-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.fusion-blog-layout-grid.fusion-blog-equal-heights
  .fusion-post-wrapper
  .fusion-post-content.fusion-post-grid-content {
  margin-bottom: 20px;
}
.fusion-blog-layout-grid.fusion-blog-equal-heights
  .fusion-post-wrapper
  .fusion-meta-info {
  margin-top: auto;
}
.fusion-blog-layout-timeline {
  position: relative;
  margin: 0 auto;
  padding-top: 30px;
  max-width: 1000px;
}
.fusion-blog-layout-timeline .fusion-post-timeline {
  position: static;
  margin-bottom: 40px;
  border: 1px solid #eaeaea;
  border-bottom-width: 3px;
}
.fusion-no-meta-info
  .fusion-blog-layout-timeline
  .fusion-post-content-container {
  margin-top: 0;
}
.fusion-blog-layout-timeline .fusion-left-column {
  float: left;
  clear: left;
  width: 40%;
}
.fusion-blog-layout-timeline .fusion-right-column {
  float: right;
  clear: right;
  width: 40%;
}
.fusion-blog-layout-timeline .fusion-timeline-date {
  position: relative;
  clear: both;
  margin: 0 auto 2px;
  padding: 0.35em 0.45em;
  width: auto;
  max-width: 8em;
  line-height: 1.35em;
  background: #efeeee;
  text-align: center;
  cursor: pointer;
}
.fusion-blog-layout-timeline .fusion-left-column .fusion-timeline-arrow {
  left: 40%;
  margin-left: -0.5px;
}
.fusion-blog-layout-timeline .fusion-left-column .fusion-timeline-arrow:before {
  content: "\f0da";
  left: 100%;
}
.fusion-blog-layout-timeline .fusion-right-column .fusion-timeline-arrow {
  right: 40%;
  margin-right: -0.5px;
}
.fusion-blog-layout-timeline
  .fusion-right-column
  .fusion-timeline-arrow:before {
  content: "\f0d9";
  right: 100%;
}
.fusion-blog-layout-masonry .fusion-post-wrapper {
  overflow: hidden;
  position: relative;
}
.fusion-blog-layout-masonry .fusion-post-content-wrapper {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.fusion-blog-layout-masonry.fusion-blog-rollover
  .fusion-post-wrapper:hover
  .fusion-post-content-wrapper {
  transition: all 0.3s ease;
  transform: translateY(105%);
}
.fusion-blog-layout-masonry.fusion-blog-rollover .fusion-post-content-wrapper {
  transition: all 0.3s ease;
}
.fusion-blog-infinite .pagination {
  display: none;
}
.fusion-blog-infinite .fusion-load-more-button {
  margin-bottom: 40px;
}
.fusion-blog-pagination .pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 40px;
  line-height: normal;
}
.fusion-show-pagination-text .fusion-blog-pagination .pagination {
  margin-left: 1em;
  margin-right: 1em;
}
.fusion-blog-pagination .pagination a {
  text-decoration: none;
}
.fusion-blog-pagination .pagination a.inactive {
  margin: 0 4px;
}
.fusion-blog-pagination .pagination .current {
  color: #fff;
  margin: 0 4px;
}
.fusion-blog-pagination .pagination .pagination-prev {
  padding: 0;
  position: relative;
}
.fusion-blog-pagination .pagination .pagination-prev:before {
  font-family: awb-icons;
  content: "\f104";
}
.fusion-blog-pagination .pagination .pagination-next {
  padding: 0;
  position: relative;
}
.fusion-blog-pagination .pagination .pagination-next:after {
  font-family: awb-icons;
  content: "\f105";
}
.fusion-load-more-button {
  display: block;
  margin-top: 20px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #e9eaee;
  cursor: pointer;
  text-align: center;
  font-size: inherit;
  text-transform: uppercase;
  border: none;
  transition: all 0.2s;
}
.fusion-load-more-button:hover {
  transition: all 0.2s;
}
.fusion-load-more-button.fusion-blog-button {
  background-color: var(--blog_load_more_posts_button_bg_color);
  color: var(--blog_load_more_posts_button_text_color);
}
.fusion-load-more-button.fusion-blog-button:hover {
  background-color: var(--blog_load_more_posts_hover_button_bg_color);
  color: var(--blog_load_more_posts_hover_button_text_color);
}
.fusion-blog-layout-center .fusion-post-content {
  text-align: center;
}
.fusion-blog-layout-left .fusion-post-content {
  text-align: left;
}
.fusion-blog-layout-right .fusion-post-content {
  text-align: right;
}
.fusion-blog-layout--masonry .fusion-post-content-wrapper {
  background-color: var(--timeline_bg_color-not-transparent);
}
.fusion-blog-layout--grid .fusion-post-grid .fusion-post-wrapper {
  background-color: var(--timeline_bg_color);
}
.fusion-blog-layout--timeline .fusion-post-grid {
  background-color: var(--timeline_bg_color);
}
.fusion-blog-layout-grid .fusion-post-grid .flexslider,
.fusion-blog-layout-grid .fusion-post-grid .fusion-post-wrapper,
.fusion-blog-layout-timeline .fusion-post-grid,
.fusion-blog-layout-timeline .fusion-post-grid .flexslider,
.fusion-blog-layout-timeline .fusion-timeline-arrow,
.fusion-blog-layout-timeline .fusion-timeline-date,
.fusion-blog-layout-timeline .fusion-timeline-line,
.fusion-blog-timeline-layout .fusion-post-grid,
.fusion-blog-timeline-layout .fusion-post-grid .flexslider,
.fusion-blog-timeline-layout .fusion-post-grid .fusion-content-sep,
.fusion-events-shortcode .fusion-events-thumbnail,
.fusion-events-shortcode .fusion-layout-column,
.product-buttons,
.product-buttons-container,
.products li .fusion-product-wrapper {
  border-color: var(--timeline_color);
}
.avada-has-transparent-timeline_color
  .fusion-blog-layout-grid
  .fusion-post-grid
  .flexslider,
.avada-has-transparent-timeline_color
  .fusion-blog-layout-grid
  .fusion-post-grid
  .fusion-post-wrapper,
.avada-has-transparent-timeline_color
  .fusion-blog-layout-timeline
  .fusion-post-grid,
.avada-has-transparent-timeline_color
  .fusion-blog-layout-timeline
  .fusion-post-grid
  .flexslider,
.avada-has-transparent-timeline_color
  .fusion-blog-layout-timeline
  .fusion-timeline-arrow,
.avada-has-transparent-timeline_color
  .fusion-blog-layout-timeline
  .fusion-timeline-date,
.avada-has-transparent-timeline_color
  .fusion-blog-layout-timeline
  .fusion-timeline-line,
.avada-has-transparent-timeline_color
  .fusion-blog-timeline-layout
  .fusion-post-grid,
.avada-has-transparent-timeline_color
  .fusion-blog-timeline-layout
  .fusion-post-grid
  .flexslider,
.avada-has-transparent-timeline_color
  .fusion-blog-timeline-layout
  .fusion-post-grid
  .fusion-content-sep,
.avada-has-transparent-timeline_color
  .fusion-events-shortcode
  .fusion-events-thumbnail,
.avada-has-transparent-timeline_color
  .fusion-events-shortcode
  .fusion-layout-column,
.avada-has-transparent-timeline_color .product-buttons,
.avada-has-transparent-timeline_color .product-buttons-container,
.avada-has-transparent-timeline_color .products li .fusion-product-wrapper {
  border: none;
}
.fusion-body .product .fusion-content-sep {
  border-color: var(--grid_separator_color);
}
.avada-has-transparent-grid-sep-color.fusion-body .product .fusion-content-sep {
  border: none;
}
.fusion-blog-layout-timeline .fusion-timeline-circle,
.fusion-blog-layout-timeline .fusion-timeline-date,
.fusion-blog-timeline-layout .fusion-timeline-circle,
.fusion-blog-timeline-layout .fusion-timeline-date {
  background-color: var(--timeline_color);
}
.fusion-blog-timeline-layout .fusion-timeline-arrow,
.fusion-blog-timeline-layout .fusion-timeline-icon,
.fusion-timeline-arrow,
.fusion-timeline-icon {
  color: var(--timeline_color);
}
.fusion-image-wrapper .fusion-rollover .fusion-rollover-gallery:before,
.fusion-image-wrapper .fusion-rollover .fusion-rollover-link:before {
  line-height: 2.41;
}
.fusion-blog-pagination .pagination a.inactive,
.fusion-hide-pagination-text
  .fusion-blog-pagination
  .pagination
  .pagination-next,
.fusion-hide-pagination-text
  .fusion-blog-pagination
  .pagination
  .pagination-prev {
  border-color: var(--sep_color);
}
#main #comment-submit,
#main .comment-submit,
#reviews input#submit,
.button-default,
.button.default,
.comment-form input[type="submit"],
.fusion-button,
.fusion-button-default,
.fusion-footer-widget-area .fusion-privacy-placeholder .button-default,
.fusion-portfolio-one .fusion-button,
.post-password-form input[type="submit"],
.slidingbar-area .button-default,
.ticket-selector-submit-btn[type="submit"],
.tml-submit-wrap input[type="submit"],
input.button-default {
  display: inline-block;
  position: relative;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  font-family: var(--button_typography-font-family);
  font-weight: var(--button_typography-font-weight);
  font-style: var(--button_typography-font-style, normal);
  letter-spacing: var(--button_typography-letter-spacing);
  border-width: var(--button_border_width-top, 0)
    var(--button_border_width-right, 0) var(--button_border_width-bottom, 0)
    var(--button_border_width-left, 0);
  border-style: solid;
  border-radius: var(--button-border-radius-top-left, 0)
    var(--button-border-radius-top-right, 0)
    var(--button-border-radius-bottom-right, 0)
    var(--button-border-radius-bottom-left, 0);
  text-transform: var(--button_text_transform);
  transition: all 0.2s;
}
.fusion-body .fusion-button-default-size,
button.button {
  padding-top: var(--button_padding-top, 13px);
  padding-right: var(--button_padding-right, 29px);
  padding-bottom: var(--button_padding-bottom, 13px);
  padding-left: var(--button_padding-left, 29px);
  line-height: var(--button_line_height, 1);
  font-size: var(--button_font_size, 14px);
}
.fusion-body .fusion-button-default-size .fusion-button-text-left,
button.button .fusion-button-text-left {
  display: inline-block;
  padding-left: calc(
    var(--button_padding-left, 29px) / 2 + var(--button_font_size, 14px) + 1px
  );
}
.fusion-body .fusion-button-default-size .fusion-button-text-right,
button.button .fusion-button-text-right {
  display: inline-block;
  padding-right: calc(
    var(--button_padding-right, 29px) / 2 + var(--button_font_size, 14px) + 1px
  );
}
.fusion-body .fusion-button-default-size .button-icon-divider-left,
.fusion-body .fusion-button-default-size .button-icon-divider-right,
button.button .button-icon-divider-left,
button.button .button-icon-divider-right {
  padding: 0;
  width: calc(var(--button_padding-left, 29px) + var(--button_font_size, 14px));
  text-align: center;
}
.fusion-body .fusion-button-default-size .button-icon-divider-left i,
.fusion-body .fusion-button-default-size .button-icon-divider-left img,
.fusion-body .fusion-button-default-size .button-icon-divider-right i,
.fusion-body .fusion-button-default-size .button-icon-divider-right img,
button.button .button-icon-divider-left i,
button.button .button-icon-divider-left img,
button.button .button-icon-divider-right i,
button.button .button-icon-divider-right img {
  top: 50%;
  transform: translateY(-50%);
  display: block;
}
#main #comment-submit,
#main .comment-submit,
#reviews input#submit,
.button-default,
.button.default,
.comment-form input[type="submit"],
.fusion-button-default,
.fusion-footer-widget-area .fusion-privacy-placeholder .button-default,
.fusion-portfolio-one .fusion-button,
.post-password-form input[type="submit"],
.slidingbar-area .button-default,
.ticket-selector-submit-btn[type="submit"],
.tml-submit-wrap input[type="submit"],
input.button-default {
  background: var(--button_gradient_top_color);
  color: var(--button_accent_color);
  border-color: var(--button_border_color);
}
#main #comment-submit .fusion-button-text,
#main .comment-submit .fusion-button-text,
#reviews input#submit .fusion-button-text,
.button-default .fusion-button-text,
.button.default .fusion-button-text,
.comment-form input[type="submit"] .fusion-button-text,
.fusion-button-default .fusion-button-text,
.fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default
  .fusion-button-text,
.fusion-portfolio-one .fusion-button .fusion-button-text,
.post-password-form input[type="submit"] .fusion-button-text,
.slidingbar-area .button-default .fusion-button-text,
.ticket-selector-submit-btn[type="submit"] .fusion-button-text,
.tml-submit-wrap input[type="submit"] .fusion-button-text,
input.button-default .fusion-button-text {
  color: var(--button_accent_color);
}
#main #comment-submit:active .fusion-button-text,
#main .comment-submit:active .fusion-button-text,
#reviews input#submit:active .fusion-button-text,
.button-default:active .fusion-button-text,
.button.default:active .fusion-button-text,
.comment-form input[type="submit"]:active .fusion-button-text,
.fusion-button-default:active .fusion-button-text,
.fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:active
  .fusion-button-text,
.fusion-portfolio-one .fusion-button:active .fusion-button-text,
.post-password-form input[type="submit"]:active .fusion-button-text,
.slidingbar-area .button-default:active .fusion-button-text,
.ticket-selector-submit-btn[type="submit"]:active .fusion-button-text,
.tml-submit-wrap input[type="submit"]:active .fusion-button-text,
input.button-default:active .fusion-button-text,
#main #comment-submit:focus .fusion-button-text,
#main .comment-submit:focus .fusion-button-text,
#reviews input#submit:focus .fusion-button-text,
.button-default:focus .fusion-button-text,
.button.default:focus .fusion-button-text,
.comment-form input[type="submit"]:focus .fusion-button-text,
.fusion-button-default:focus .fusion-button-text,
.fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:focus
  .fusion-button-text,
.fusion-portfolio-one .fusion-button:focus .fusion-button-text,
.post-password-form input[type="submit"]:focus .fusion-button-text,
.slidingbar-area .button-default:focus .fusion-button-text,
.ticket-selector-submit-btn[type="submit"]:focus .fusion-button-text,
.tml-submit-wrap input[type="submit"]:focus .fusion-button-text,
input.button-default:focus .fusion-button-text,
#main #comment-submit:hover .fusion-button-text,
#main .comment-submit:hover .fusion-button-text,
#reviews input#submit:hover .fusion-button-text,
.button-default:hover .fusion-button-text,
.button.default:hover .fusion-button-text,
.comment-form input[type="submit"]:hover .fusion-button-text,
.fusion-button-default:hover .fusion-button-text,
.fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:hover
  .fusion-button-text,
.fusion-portfolio-one .fusion-button:hover .fusion-button-text,
.post-password-form input[type="submit"]:hover .fusion-button-text,
.slidingbar-area .button-default:hover .fusion-button-text,
.ticket-selector-submit-btn[type="submit"]:hover .fusion-button-text,
.tml-submit-wrap input[type="submit"]:hover .fusion-button-text,
input.button-default:hover .fusion-button-text {
  color: var(--button_accent_hover_color, var(--button_accent_color));
}
.fusion-has-button-gradient #main #comment-submit,
.fusion-has-button-gradient #main .comment-submit,
.fusion-has-button-gradient #reviews input#submit,
.fusion-has-button-gradient .button-default,
.fusion-has-button-gradient .button.default,
.fusion-has-button-gradient .comment-form input[type="submit"],
.fusion-has-button-gradient .fusion-button-default,
.fusion-has-button-gradient
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default,
.fusion-has-button-gradient .fusion-portfolio-one .fusion-button,
.fusion-has-button-gradient .post-password-form input[type="submit"],
.fusion-has-button-gradient .slidingbar-area .button-default,
.fusion-has-button-gradient .ticket-selector-submit-btn[type="submit"],
.fusion-has-button-gradient .tml-submit-wrap input[type="submit"],
.fusion-has-button-gradient input.button-default,
.fusion-has-button-gradient.fusion-button-default {
  background-image: linear-gradient(
    var(--button_gradient_angle, 180deg),
    var(--button_gradient_top_color) var(--button_gradient_start, 0),
    var(--button_gradient_bottom_color) var(--button_gradient_end, 100%)
  );
}
#main #comment-submit:active,
#main .comment-submit:active,
#reviews input#submit:active,
.button-default:active,
.button.default:active,
.comment-form input[type="submit"]:active,
.fusion-button-default:active,
.fusion-footer-widget-area .fusion-privacy-placeholder .button-default:active,
.fusion-portfolio-one .fusion-button:active,
.post-password-form input[type="submit"]:active,
.slidingbar-area .button-default:active,
.ticket-selector-submit-btn[type="submit"]:active,
.tml-submit-wrap input[type="submit"]:active,
input.button-default:active,
#main #comment-submit:focus,
#main .comment-submit:focus,
#reviews input#submit:focus,
.button-default:focus,
.button.default:focus,
.comment-form input[type="submit"]:focus,
.fusion-button-default:focus,
.fusion-footer-widget-area .fusion-privacy-placeholder .button-default:focus,
.fusion-portfolio-one .fusion-button:focus,
.post-password-form input[type="submit"]:focus,
.slidingbar-area .button-default:focus,
.ticket-selector-submit-btn[type="submit"]:focus,
.tml-submit-wrap input[type="submit"]:focus,
input.button-default:focus,
#main #comment-submit:hover,
#main .comment-submit:hover,
#reviews input#submit:hover,
.button-default:hover,
.button.default:hover,
.comment-form input[type="submit"]:hover,
.fusion-button-default:hover,
.fusion-footer-widget-area .fusion-privacy-placeholder .button-default:hover,
.fusion-portfolio-one .fusion-button:hover,
.post-password-form input[type="submit"]:hover,
.slidingbar-area .button-default:hover,
.ticket-selector-submit-btn[type="submit"]:hover,
.tml-submit-wrap input[type="submit"]:hover,
input.button-default:hover {
  background: var(--button_gradient_top_color_hover);
  color: var(--button_accent_hover_color, var(--button_accent_color));
  border-color: var(--button_border_hover_color);
}
.fusion-has-button-gradient #main #comment-submit:active,
.fusion-has-button-gradient #main .comment-submit:active,
.fusion-has-button-gradient #reviews input#submit:active,
.fusion-has-button-gradient .button-default:active,
.fusion-has-button-gradient .button.default:active,
.fusion-has-button-gradient .comment-form input[type="submit"]:active,
.fusion-has-button-gradient .fusion-button-default:active,
.fusion-has-button-gradient
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:active,
.fusion-has-button-gradient .fusion-portfolio-one .fusion-button:active,
.fusion-has-button-gradient .post-password-form input[type="submit"]:active,
.fusion-has-button-gradient .slidingbar-area .button-default:active,
.fusion-has-button-gradient .ticket-selector-submit-btn[type="submit"]:active,
.fusion-has-button-gradient .tml-submit-wrap input[type="submit"]:active,
.fusion-has-button-gradient input.button-default:active,
.fusion-has-button-gradient.fusion-button-default:active,
.fusion-has-button-gradient #main #comment-submit:focus,
.fusion-has-button-gradient #main .comment-submit:focus,
.fusion-has-button-gradient #reviews input#submit:focus,
.fusion-has-button-gradient .button-default:focus,
.fusion-has-button-gradient .button.default:focus,
.fusion-has-button-gradient .comment-form input[type="submit"]:focus,
.fusion-has-button-gradient .fusion-button-default:focus,
.fusion-has-button-gradient
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:focus,
.fusion-has-button-gradient .fusion-portfolio-one .fusion-button:focus,
.fusion-has-button-gradient .post-password-form input[type="submit"]:focus,
.fusion-has-button-gradient .slidingbar-area .button-default:focus,
.fusion-has-button-gradient .ticket-selector-submit-btn[type="submit"]:focus,
.fusion-has-button-gradient .tml-submit-wrap input[type="submit"]:focus,
.fusion-has-button-gradient input.button-default:focus,
.fusion-has-button-gradient.fusion-button-default:focus,
.fusion-has-button-gradient #main #comment-submit:hover,
.fusion-has-button-gradient #main .comment-submit:hover,
.fusion-has-button-gradient #reviews input#submit:hover,
.fusion-has-button-gradient .button-default:hover,
.fusion-has-button-gradient .button.default:hover,
.fusion-has-button-gradient .comment-form input[type="submit"]:hover,
.fusion-has-button-gradient .fusion-button-default:hover,
.fusion-has-button-gradient
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:hover,
.fusion-has-button-gradient .fusion-portfolio-one .fusion-button:hover,
.fusion-has-button-gradient .post-password-form input[type="submit"]:hover,
.fusion-has-button-gradient .slidingbar-area .button-default:hover,
.fusion-has-button-gradient .ticket-selector-submit-btn[type="submit"]:hover,
.fusion-has-button-gradient .tml-submit-wrap input[type="submit"]:hover,
.fusion-has-button-gradient input.button-default:hover,
.fusion-has-button-gradient.fusion-button-default:hover {
  background-image: linear-gradient(
    var(--button_gradient_angle, 180deg),
    var(--button_gradient_top_color_hover) var(--button_gradient_start, 0),
    var(--button_gradient_bottom_color_hover) var(--button_gradient_end, 100%)
  );
}
.fusion-button_gradient-radial #main #comment-submit,
.fusion-button_gradient-radial #main .comment-submit,
.fusion-button_gradient-radial #reviews input#submit,
.fusion-button_gradient-radial .button-default,
.fusion-button_gradient-radial .button.default,
.fusion-button_gradient-radial .comment-form input[type="submit"],
.fusion-button_gradient-radial .fusion-button-default,
.fusion-button_gradient-radial
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default,
.fusion-button_gradient-radial .fusion-portfolio-one .fusion-button,
.fusion-button_gradient-radial .post-password-form input[type="submit"],
.fusion-button_gradient-radial .slidingbar-area .button-default,
.fusion-button_gradient-radial .ticket-selector-submit-btn[type="submit"],
.fusion-button_gradient-radial .tml-submit-wrap input[type="submit"],
.fusion-button_gradient-radial input.button-default,
.fusion-button_gradient-radial.fusion-button-default {
  background-image: radial-gradient(
    circle at var(--button_radial_direction, center center),
    var(--button_gradient_top_color) var(--button_gradient_start, 0),
    var(--button_gradient_bottom_color) var(--button_gradient_end, 100%)
  );
}
.fusion-button_gradient-radial #main #comment-submit:active,
.fusion-button_gradient-radial #main .comment-submit:active,
.fusion-button_gradient-radial #reviews input#submit:active,
.fusion-button_gradient-radial .button-default:active,
.fusion-button_gradient-radial .button.default:active,
.fusion-button_gradient-radial .comment-form input[type="submit"]:active,
.fusion-button_gradient-radial .fusion-button-default:active,
.fusion-button_gradient-radial
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:active,
.fusion-button_gradient-radial .fusion-portfolio-one .fusion-button:active,
.fusion-button_gradient-radial .post-password-form input[type="submit"]:active,
.fusion-button_gradient-radial .slidingbar-area .button-default:active,
.fusion-button_gradient-radial
  .ticket-selector-submit-btn[type="submit"]:active,
.fusion-button_gradient-radial .tml-submit-wrap input[type="submit"]:active,
.fusion-button_gradient-radial input.button-default:active,
.fusion-button_gradient-radial.fusion-button-default:active,
.fusion-button_gradient-radial #main #comment-submit:focus,
.fusion-button_gradient-radial #main .comment-submit:focus,
.fusion-button_gradient-radial #reviews input#submit:focus,
.fusion-button_gradient-radial .button-default:focus,
.fusion-button_gradient-radial .button.default:focus,
.fusion-button_gradient-radial .comment-form input[type="submit"]:focus,
.fusion-button_gradient-radial .fusion-button-default:focus,
.fusion-button_gradient-radial
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:focus,
.fusion-button_gradient-radial .fusion-portfolio-one .fusion-button:focus,
.fusion-button_gradient-radial .post-password-form input[type="submit"]:focus,
.fusion-button_gradient-radial .slidingbar-area .button-default:focus,
.fusion-button_gradient-radial .ticket-selector-submit-btn[type="submit"]:focus,
.fusion-button_gradient-radial .tml-submit-wrap input[type="submit"]:focus,
.fusion-button_gradient-radial input.button-default:focus,
.fusion-button_gradient-radial.fusion-button-default:focus,
.fusion-button_gradient-radial #main #comment-submit:hover,
.fusion-button_gradient-radial #main .comment-submit:hover,
.fusion-button_gradient-radial #reviews input#submit:hover,
.fusion-button_gradient-radial .button-default:hover,
.fusion-button_gradient-radial .button.default:hover,
.fusion-button_gradient-radial .comment-form input[type="submit"]:hover,
.fusion-button_gradient-radial .fusion-button-default:hover,
.fusion-button_gradient-radial
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:hover,
.fusion-button_gradient-radial .fusion-portfolio-one .fusion-button:hover,
.fusion-button_gradient-radial .post-password-form input[type="submit"]:hover,
.fusion-button_gradient-radial .slidingbar-area .button-default:hover,
.fusion-button_gradient-radial .ticket-selector-submit-btn[type="submit"]:hover,
.fusion-button_gradient-radial .tml-submit-wrap input[type="submit"]:hover,
.fusion-button_gradient-radial input.button-default:hover,
.fusion-button_gradient-radial.fusion-button-default:hover {
  background-image: radial-gradient(
    circle at var(--button_radial_direction, center center),
    var(--button_gradient_top_color_hover) var(--button_gradient_start, 0),
    var(--button_gradient_bottom_color_hover) var(--button_gradient_end, 100%)
  );
}
#main #comment-submit:visited,
#main .comment-submit:visited,
#reviews input#submit:visited,
.button-default:visited,
.button.default:visited,
.comment-form input[type="submit"]:visited,
.fusion-button-default:visited,
.fusion-footer-widget-area .fusion-privacy-placeholder .button-default:visited,
.fusion-portfolio-one .fusion-button:visited,
.post-password-form input[type="submit"]:visited,
.slidingbar-area .button-default:visited,
.ticket-selector-submit-btn[type="submit"]:visited,
.tml-submit-wrap input[type="submit"]:visited,
input.button-default:visited {
  color: var(--button_accent_color);
}
.fusion-button_span-yes #main #comment-submit:not(.fusion-button-span-no),
.fusion-button_span-yes #main .comment-submit:not(.fusion-button-span-no),
.fusion-button_span-yes #reviews input#submit:not(.fusion-button-span-no),
.fusion-button_span-yes .button-default:not(.fusion-button-span-no),
.fusion-button_span-yes .button.default:not(.fusion-button-span-no),
.fusion-button_span-yes
  .comment-form
  input[type="submit"]:not(.fusion-button-span-no),
.fusion-button_span-yes .fusion-button-default:not(.fusion-button-span-no),
.fusion-button_span-yes
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:not(.fusion-button-span-no),
.fusion-button_span-yes
  .fusion-portfolio-one
  .fusion-button:not(.fusion-button-span-no),
.fusion-button_span-yes
  .post-password-form
  input[type="submit"]:not(.fusion-button-span-no),
.fusion-button_span-yes
  .slidingbar-area
  .button-default:not(.fusion-button-span-no),
.fusion-button_span-yes
  .ticket-selector-submit-btn[type="submit"]:not(.fusion-button-span-no),
.fusion-button_span-yes
  .tml-submit-wrap
  input[type="submit"]:not(.fusion-button-span-no),
.fusion-button_span-yes input.button-default:not(.fusion-button-span-no),
.fusion-button_span-yes.fusion-button-default:not(.fusion-button-span-no) {
  width: 100%;
}
.fusion-button.button-flat {
  box-shadow: none !important;
}
.fusion-button-wrapper {
  display: inline;
}
.fusion-button-wrapper.fusion-aligncenter {
  display: block;
  text-align: center;
}
.fusion-button-wrapper.fusion-align-block {
  display: block;
}
.fusion-disable-outline .bbp-submit-wrapper .button,
.fusion-disable-outline .button,
.fusion-disable-outline .fusion-button,
.fusion-disable-outline .gform_wrapper .button,
.fusion-disable-outline .gform_wrapper .gform_button,
.fusion-disable-outline .post-password-form input[type="submit"],
.fusion-disable-outline .single_add_to_cart_button,
.fusion-disable-outline .tml-submit-wrap input[type="submit"],
.fusion-disable-outline .tribe-events-tickets .button,
.fusion-disable-outline .tribe-tickets button,
.fusion-disable-outline .woocommerce .cart-collaterals .checkout-button,
.fusion-disable-outline .woocommerce .checkout #place_order,
.fusion-disable-outline .woocommerce.add_to_cart_inline .button,
.fusion-disable-outline .wpcf7-submit {
  outline: 0;
}
.fusion-disable-outline .bbp-submit-wrapper .button:focus,
.fusion-disable-outline .button:focus,
.fusion-disable-outline .fusion-button:focus,
.fusion-disable-outline .gform_wrapper .button:focus,
.fusion-disable-outline .gform_wrapper .gform_button:focus,
.fusion-disable-outline .post-password-form input[type="submit"]:focus,
.fusion-disable-outline .single_add_to_cart_button:focus,
.fusion-disable-outline .tml-submit-wrap input[type="submit"]:focus,
.fusion-disable-outline .tribe-events-tickets .button:focus,
.fusion-disable-outline .tribe-tickets button:focus,
.fusion-disable-outline .woocommerce .cart-collaterals .checkout-button:focus,
.fusion-disable-outline .woocommerce .checkout #place_order:focus,
.fusion-disable-outline .woocommerce.add_to_cart_inline .button:focus,
.fusion-disable-outline .wpcf7-submit:focus {
  outline: 0;
}
.fusion-button i,
.fusion-button-icon-divider,
.fusion-button-text {
  transition: all 0.2s;
}
#bbp_user_edit_submit,
.bbp-submit-wrapper .button,
.button-small,
.fusion-body .fusion-button-small,
.single_add_to_cart_button,
.wpcf7-form .wpcf7-submit.button-small,
.wpcf7-form .wpcf7-submit.fusion-button-small {
  padding: 9px 20px;
  line-height: 14px;
  font-size: 12px;
}
#bbp_user_edit_submit .fusion-button-text-left,
.bbp-submit-wrapper .button .fusion-button-text-left,
.button-small .fusion-button-text-left,
.fusion-body .fusion-button-small .fusion-button-text-left,
.single_add_to_cart_button .fusion-button-text-left,
.wpcf7-form .wpcf7-submit.button-small .fusion-button-text-left,
.wpcf7-form .wpcf7-submit.fusion-button-small .fusion-button-text-left {
  display: inline-block;
  padding-left: 25px;
}
#bbp_user_edit_submit .fusion-button-text-right,
.bbp-submit-wrapper .button .fusion-button-text-right,
.button-small .fusion-button-text-right,
.fusion-body .fusion-button-small .fusion-button-text-right,
.single_add_to_cart_button .fusion-button-text-right,
.wpcf7-form .wpcf7-submit.button-small .fusion-button-text-right,
.wpcf7-form .wpcf7-submit.fusion-button-small .fusion-button-text-right {
  display: inline-block;
  padding-right: 25px;
}
#bbp_user_edit_submit .button-icon-divider-left,
.bbp-submit-wrapper .button .button-icon-divider-left,
.button-small .button-icon-divider-left,
.fusion-body .fusion-button-small .button-icon-divider-left,
.single_add_to_cart_button .button-icon-divider-left,
.wpcf7-form .wpcf7-submit.button-small .button-icon-divider-left,
.wpcf7-form .wpcf7-submit.fusion-button-small .button-icon-divider-left {
  padding: 0 10px;
}
#bbp_user_edit_submit .button-icon-divider-left.fusion-megamenu-image,
.bbp-submit-wrapper .button .button-icon-divider-left.fusion-megamenu-image,
.button-small .button-icon-divider-left.fusion-megamenu-image,
.fusion-body
  .fusion-button-small
  .button-icon-divider-left.fusion-megamenu-image,
.single_add_to_cart_button .button-icon-divider-left.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.button-small
  .button-icon-divider-left.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.fusion-button-small
  .button-icon-divider-left.fusion-megamenu-image {
  padding: 0 5px;
}
#bbp_user_edit_submit .button-icon-divider-right,
.bbp-submit-wrapper .button .button-icon-divider-right,
.button-small .button-icon-divider-right,
.fusion-body .fusion-button-small .button-icon-divider-right,
.single_add_to_cart_button .button-icon-divider-right,
.wpcf7-form .wpcf7-submit.button-small .button-icon-divider-right,
.wpcf7-form .wpcf7-submit.fusion-button-small .button-icon-divider-right {
  padding: 0 10px;
}
#bbp_user_edit_submit .button-icon-divider-right.fusion-megamenu-image,
.bbp-submit-wrapper .button .button-icon-divider-right.fusion-megamenu-image,
.button-small .button-icon-divider-right.fusion-megamenu-image,
.fusion-body
  .fusion-button-small
  .button-icon-divider-right.fusion-megamenu-image,
.single_add_to_cart_button .button-icon-divider-right.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.button-small
  .button-icon-divider-right.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.fusion-button-small
  .button-icon-divider-right.fusion-megamenu-image {
  padding: 0 5px;
}
#bbp_user_edit_submit .button-icon-divider-left i,
#bbp_user_edit_submit .button-icon-divider-left img,
#bbp_user_edit_submit .button-icon-divider-right i,
#bbp_user_edit_submit .button-icon-divider-right img,
.bbp-submit-wrapper .button .button-icon-divider-left i,
.bbp-submit-wrapper .button .button-icon-divider-left img,
.bbp-submit-wrapper .button .button-icon-divider-right i,
.bbp-submit-wrapper .button .button-icon-divider-right img,
.button-small .button-icon-divider-left i,
.button-small .button-icon-divider-left img,
.button-small .button-icon-divider-right i,
.button-small .button-icon-divider-right img,
.fusion-body .fusion-button-small .button-icon-divider-left i,
.fusion-body .fusion-button-small .button-icon-divider-left img,
.fusion-body .fusion-button-small .button-icon-divider-right i,
.fusion-body .fusion-button-small .button-icon-divider-right img,
.single_add_to_cart_button .button-icon-divider-left i,
.single_add_to_cart_button .button-icon-divider-left img,
.single_add_to_cart_button .button-icon-divider-right i,
.single_add_to_cart_button .button-icon-divider-right img,
.wpcf7-form .wpcf7-submit.button-small .button-icon-divider-left i,
.wpcf7-form .wpcf7-submit.button-small .button-icon-divider-left img,
.wpcf7-form .wpcf7-submit.button-small .button-icon-divider-right i,
.wpcf7-form .wpcf7-submit.button-small .button-icon-divider-right img,
.wpcf7-form .wpcf7-submit.fusion-button-small .button-icon-divider-left i,
.wpcf7-form .wpcf7-submit.fusion-button-small .button-icon-divider-left img,
.wpcf7-form .wpcf7-submit.fusion-button-small .button-icon-divider-right i,
.wpcf7-form .wpcf7-submit.fusion-button-small .button-icon-divider-right img {
  top: 50%;
  transform: translateY(-50%);
  display: block;
}
.button-medium,
.fusion-body .fusion-button-medium,
.ticket-selector-submit-btn[type="submit"],
.wpcf7-form .wpcf7-submit.button-medium,
.wpcf7-form .wpcf7-submit.fusion-button-medium {
  padding: 11px 23px;
  line-height: 16px;
  font-size: 13px;
}
.button-medium .fusion-button-text-left,
.fusion-body .fusion-button-medium .fusion-button-text-left,
.ticket-selector-submit-btn[type="submit"] .fusion-button-text-left,
.wpcf7-form .wpcf7-submit.button-medium .fusion-button-text-left,
.wpcf7-form .wpcf7-submit.fusion-button-medium .fusion-button-text-left {
  display: inline-block;
  padding-left: 28px;
}
.button-medium .fusion-button-text-right,
.fusion-body .fusion-button-medium .fusion-button-text-right,
.ticket-selector-submit-btn[type="submit"] .fusion-button-text-right,
.wpcf7-form .wpcf7-submit.button-medium .fusion-button-text-right,
.wpcf7-form .wpcf7-submit.fusion-button-medium .fusion-button-text-right {
  display: inline-block;
  padding-right: 28px;
}
.button-medium .button-icon-divider-left,
.fusion-body .fusion-button-medium .button-icon-divider-left,
.ticket-selector-submit-btn[type="submit"] .button-icon-divider-left,
.wpcf7-form .wpcf7-submit.button-medium .button-icon-divider-left,
.wpcf7-form .wpcf7-submit.fusion-button-medium .button-icon-divider-left {
  padding: 0 12.5px;
}
.button-medium .button-icon-divider-left.fusion-megamenu-image,
.fusion-body
  .fusion-button-medium
  .button-icon-divider-left.fusion-megamenu-image,
.ticket-selector-submit-btn[type="submit"]
  .button-icon-divider-left.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.button-medium
  .button-icon-divider-left.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.fusion-button-medium
  .button-icon-divider-left.fusion-megamenu-image {
  padding: 0 6.25px;
}
.button-medium .button-icon-divider-right,
.fusion-body .fusion-button-medium .button-icon-divider-right,
.ticket-selector-submit-btn[type="submit"] .button-icon-divider-right,
.wpcf7-form .wpcf7-submit.button-medium .button-icon-divider-right,
.wpcf7-form .wpcf7-submit.fusion-button-medium .button-icon-divider-right {
  padding: 0 12.5px;
}
.button-medium .button-icon-divider-right.fusion-megamenu-image,
.fusion-body
  .fusion-button-medium
  .button-icon-divider-right.fusion-megamenu-image,
.ticket-selector-submit-btn[type="submit"]
  .button-icon-divider-right.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.button-medium
  .button-icon-divider-right.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.fusion-button-medium
  .button-icon-divider-right.fusion-megamenu-image {
  padding: 0 6.25px;
}
.button-medium .button-icon-divider-left i,
.button-medium .button-icon-divider-left img,
.button-medium .button-icon-divider-right i,
.button-medium .button-icon-divider-right img,
.fusion-body .fusion-button-medium .button-icon-divider-left i,
.fusion-body .fusion-button-medium .button-icon-divider-left img,
.fusion-body .fusion-button-medium .button-icon-divider-right i,
.fusion-body .fusion-button-medium .button-icon-divider-right img,
.ticket-selector-submit-btn[type="submit"] .button-icon-divider-left i,
.ticket-selector-submit-btn[type="submit"] .button-icon-divider-left img,
.ticket-selector-submit-btn[type="submit"] .button-icon-divider-right i,
.ticket-selector-submit-btn[type="submit"] .button-icon-divider-right img,
.wpcf7-form .wpcf7-submit.button-medium .button-icon-divider-left i,
.wpcf7-form .wpcf7-submit.button-medium .button-icon-divider-left img,
.wpcf7-form .wpcf7-submit.button-medium .button-icon-divider-right i,
.wpcf7-form .wpcf7-submit.button-medium .button-icon-divider-right img,
.wpcf7-form .wpcf7-submit.fusion-button-medium .button-icon-divider-left i,
.wpcf7-form .wpcf7-submit.fusion-button-medium .button-icon-divider-left img,
.wpcf7-form .wpcf7-submit.fusion-button-medium .button-icon-divider-right i,
.wpcf7-form .wpcf7-submit.fusion-button-medium .button-icon-divider-right img {
  top: 50%;
  transform: translateY(-50%);
  display: block;
}
#tribe-bar-form .tribe-bar-submit input[type="submit"],
.button-large,
.fusion-body .fusion-button-large,
.tml-submit-wrap input[type="submit"],
.wpcf7-form .wpcf7-submit.button-large,
.wpcf7-form .wpcf7-submit.fusion-button-large {
  padding: 13px 29px;
  line-height: 17px;
  font-size: 14px;
}
#tribe-bar-form .tribe-bar-submit input[type="submit"] .fusion-button-text-left,
.button-large .fusion-button-text-left,
.fusion-body .fusion-button-large .fusion-button-text-left,
.tml-submit-wrap input[type="submit"] .fusion-button-text-left,
.wpcf7-form .wpcf7-submit.button-large .fusion-button-text-left,
.wpcf7-form .wpcf7-submit.fusion-button-large .fusion-button-text-left {
  display: inline-block;
  padding-left: 34px;
}
#tribe-bar-form
  .tribe-bar-submit
  input[type="submit"]
  .fusion-button-text-right,
.button-large .fusion-button-text-right,
.fusion-body .fusion-button-large .fusion-button-text-right,
.tml-submit-wrap input[type="submit"] .fusion-button-text-right,
.wpcf7-form .wpcf7-submit.button-large .fusion-button-text-right,
.wpcf7-form .wpcf7-submit.fusion-button-large .fusion-button-text-right {
  display: inline-block;
  padding-right: 34px;
}
#tribe-bar-form
  .tribe-bar-submit
  input[type="submit"]
  .button-icon-divider-left,
.button-large .button-icon-divider-left,
.fusion-body .fusion-button-large .button-icon-divider-left,
.tml-submit-wrap input[type="submit"] .button-icon-divider-left,
.wpcf7-form .wpcf7-submit.button-large .button-icon-divider-left,
.wpcf7-form .wpcf7-submit.fusion-button-large .button-icon-divider-left {
  padding: 0 14.5px;
}
#tribe-bar-form
  .tribe-bar-submit
  input[type="submit"]
  .button-icon-divider-left.fusion-megamenu-image,
.button-large .button-icon-divider-left.fusion-megamenu-image,
.fusion-body
  .fusion-button-large
  .button-icon-divider-left.fusion-megamenu-image,
.tml-submit-wrap
  input[type="submit"]
  .button-icon-divider-left.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.button-large
  .button-icon-divider-left.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.fusion-button-large
  .button-icon-divider-left.fusion-megamenu-image {
  padding: 0 7.25px;
}
#tribe-bar-form
  .tribe-bar-submit
  input[type="submit"]
  .button-icon-divider-right,
.button-large .button-icon-divider-right,
.fusion-body .fusion-button-large .button-icon-divider-right,
.tml-submit-wrap input[type="submit"] .button-icon-divider-right,
.wpcf7-form .wpcf7-submit.button-large .button-icon-divider-right,
.wpcf7-form .wpcf7-submit.fusion-button-large .button-icon-divider-right {
  padding: 0 14.5px;
}
#tribe-bar-form
  .tribe-bar-submit
  input[type="submit"]
  .button-icon-divider-right.fusion-megamenu-image,
.button-large .button-icon-divider-right.fusion-megamenu-image,
.fusion-body
  .fusion-button-large
  .button-icon-divider-right.fusion-megamenu-image,
.tml-submit-wrap
  input[type="submit"]
  .button-icon-divider-right.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.button-large
  .button-icon-divider-right.fusion-megamenu-image,
.wpcf7-form
  .wpcf7-submit.fusion-button-large
  .button-icon-divider-right.fusion-megamenu-image {
  padding: 0 7.25px;
}
#tribe-bar-form
  .tribe-bar-submit
  input[type="submit"]
  .button-icon-divider-left
  i,
#tribe-bar-form
  .tribe-bar-submit
  input[type="submit"]
  .button-icon-divider-left
  img,
#tribe-bar-form
  .tribe-bar-submit
  input[type="submit"]
  .button-icon-divider-right
  i,
#tribe-bar-form
  .tribe-bar-submit
  input[type="submit"]
  .button-icon-divider-right
  img,
.button-large .button-icon-divider-left i,
.button-large .button-icon-divider-left img,
.button-large .button-icon-divider-right i,
.button-large .button-icon-divider-right img,
.fusion-body .fusion-button-large .button-icon-divider-left i,
.fusion-body .fusion-button-large .button-icon-divider-left img,
.fusion-body .fusion-button-large .button-icon-divider-right i,
.fusion-body .fusion-button-large .button-icon-divider-right img,
.tml-submit-wrap input[type="submit"] .button-icon-divider-left i,
.tml-submit-wrap input[type="submit"] .button-icon-divider-left img,
.tml-submit-wrap input[type="submit"] .button-icon-divider-right i,
.tml-submit-wrap input[type="submit"] .button-icon-divider-right img,
.wpcf7-form .wpcf7-submit.button-large .button-icon-divider-left i,
.wpcf7-form .wpcf7-submit.button-large .button-icon-divider-left img,
.wpcf7-form .wpcf7-submit.button-large .button-icon-divider-right i,
.wpcf7-form .wpcf7-submit.button-large .button-icon-divider-right img,
.wpcf7-form .wpcf7-submit.fusion-button-large .button-icon-divider-left i,
.wpcf7-form .wpcf7-submit.fusion-button-large .button-icon-divider-left img,
.wpcf7-form .wpcf7-submit.fusion-button-large .button-icon-divider-right i,
.wpcf7-form .wpcf7-submit.fusion-button-large .button-icon-divider-right img {
  top: 50%;
  transform: translateY(-50%);
  display: block;
}
.button-xlarge,
.fusion-body .fusion-button-xlarge,
.fusion-body .wpcf7-form .wpcf7-submit.button-xlarge,
.fusion-body .wpcf7-form .wpcf7-submit.fusion-button-xlarge {
  padding: 17px 40px;
  line-height: 21px;
  font-size: 18px;
}
.button-xlarge .fusion-button-text-left,
.fusion-body .fusion-button-xlarge .fusion-button-text-left,
.fusion-body .wpcf7-form .wpcf7-submit.button-xlarge .fusion-button-text-left,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.fusion-button-xlarge
  .fusion-button-text-left {
  display: inline-block;
  padding-left: 45px;
}
.button-xlarge .fusion-button-text-right,
.fusion-body .fusion-button-xlarge .fusion-button-text-right,
.fusion-body .wpcf7-form .wpcf7-submit.button-xlarge .fusion-button-text-right,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.fusion-button-xlarge
  .fusion-button-text-right {
  display: inline-block;
  padding-right: 45px;
}
.button-xlarge .button-icon-divider-left,
.fusion-body .fusion-button-xlarge .button-icon-divider-left,
.fusion-body .wpcf7-form .wpcf7-submit.button-xlarge .button-icon-divider-left,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.fusion-button-xlarge
  .button-icon-divider-left {
  padding: 0 18.5px;
}
.button-xlarge .button-icon-divider-left.fusion-megamenu-image,
.fusion-body
  .fusion-button-xlarge
  .button-icon-divider-left.fusion-megamenu-image,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.button-xlarge
  .button-icon-divider-left.fusion-megamenu-image,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.fusion-button-xlarge
  .button-icon-divider-left.fusion-megamenu-image {
  padding: 0 9.25px;
}
.button-xlarge .button-icon-divider-right,
.fusion-body .fusion-button-xlarge .button-icon-divider-right,
.fusion-body .wpcf7-form .wpcf7-submit.button-xlarge .button-icon-divider-right,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.fusion-button-xlarge
  .button-icon-divider-right {
  padding: 0 18.5px;
}
.button-xlarge .button-icon-divider-right.fusion-megamenu-image,
.fusion-body
  .fusion-button-xlarge
  .button-icon-divider-right.fusion-megamenu-image,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.button-xlarge
  .button-icon-divider-right.fusion-megamenu-image,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.fusion-button-xlarge
  .button-icon-divider-right.fusion-megamenu-image {
  padding: 0 9.25px;
}
.button-xlarge .button-icon-divider-left i,
.button-xlarge .button-icon-divider-left img,
.button-xlarge .button-icon-divider-right i,
.button-xlarge .button-icon-divider-right img,
.fusion-body .fusion-button-xlarge .button-icon-divider-left i,
.fusion-body .fusion-button-xlarge .button-icon-divider-left img,
.fusion-body .fusion-button-xlarge .button-icon-divider-right i,
.fusion-body .fusion-button-xlarge .button-icon-divider-right img,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.button-xlarge
  .button-icon-divider-left
  i,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.button-xlarge
  .button-icon-divider-left
  img,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.button-xlarge
  .button-icon-divider-right
  i,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.button-xlarge
  .button-icon-divider-right
  img,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.fusion-button-xlarge
  .button-icon-divider-left
  i,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.fusion-button-xlarge
  .button-icon-divider-left
  img,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.fusion-button-xlarge
  .button-icon-divider-right
  i,
.fusion-body
  .wpcf7-form
  .wpcf7-submit.fusion-button-xlarge
  .button-icon-divider-right
  img {
  top: 50%;
  transform: translateY(-50%);
  display: block;
}
.button span {
  line-height: normal;
}
.button-icon-left {
  margin-right: 0.7em;
}
.button-icon-right {
  margin-left: 0.7em;
}
.button-icon-divider-left,
.button-icon-divider-right {
  display: inline-block;
  position: absolute;
  top: 0;
  height: 100%;
}
.button-icon-divider-left i,
.button-icon-divider-right i {
  position: relative;
}
.button-icon-divider-left img,
.button-icon-divider-right img {
  position: relative;
}
.button-small .button-icon-divider-left img,
.button-small .button-icon-divider-right img {
  max-width: 22px;
}
.button-medium .button-icon-divider-left img,
.button-medium .button-icon-divider-right img {
  max-width: 23px;
}
.button-large .button-icon-divider-left img,
.button-large .button-icon-divider-right img {
  max-width: 24px;
}
.button-xlarge .button-icon-divider-left img,
.button-xlarge .button-icon-divider-right img {
  max-width: 25px;
}
.button-icon-divider-left {
  left: 0;
  border-right: 1px solid;
}
.button-icon-divider-right {
  right: 0;
  border-left: 1px solid;
}
#main #comment-submit,
#main .comment-submit,
#reviews input#submit,
.comment-form input[type="submit"],
.fusion-button-default-size,
.fusion-footer-widget-area .fusion-privacy-placeholder .button-default,
.fusion-portfolio-one .fusion-button,
.post-password-form input[type="submit"],
.slidingbar-area .button-default,
.ticket-selector-submit-btn[type="submit"],
.tml-submit-wrap input[type="submit"] {
  padding-top: var(--button_padding-top, 13px);
  padding-right: var(--button_padding-right, 29px);
  padding-bottom: var(--button_padding-bottom, 13px);
  padding-left: var(--button_padding-left, 29px);
  line-height: var(--button_line_height, 1);
  font-size: var(--button_font_size, 14px);
}
.fusion-button.button-3d:active {
  transform: translateY(calc(0.15em - 1px));
  box-shadow: inset 0 1px 0 #fff, 0 1px 0 var(--button_bevel_color),
    0.05em 0.1em 0.1em 0.07em rgba(0, 0, 0, 0.3);
}
.fusion-button.button-3d:hover {
  box-shadow: inset 0 1px 0 #fff, 0 0.15em 0 var(--button_bevel_color_hover),
    0.1em 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.3);
}
.fusion-button.button-3d:hover:active {
  box-shadow: inset 0 1px 0 #fff, 0 1px 0 var(--button_bevel_color_hover),
    0.05em 0.1em 0.1em 0.07em rgba(0, 0, 0, 0.3);
}
.fusion-button_type-3d #main #comment-submit:not(.button-flat),
.fusion-button_type-3d #main .comment-submit:not(.button-flat),
.fusion-button_type-3d #reviews input#submit:not(.button-flat),
.fusion-button_type-3d .button-default:not(.button-flat),
.fusion-button_type-3d .button.default:not(.button-flat),
.fusion-button_type-3d .comment-form input[type="submit"]:not(.button-flat),
.fusion-button_type-3d .fusion-button-default:not(.button-flat),
.fusion-button_type-3d
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:not(.button-flat),
.fusion-button_type-3d .fusion-portfolio-one .fusion-button:not(.button-flat),
.fusion-button_type-3d
  .post-password-form
  input[type="submit"]:not(.button-flat),
.fusion-button_type-3d .slidingbar-area .button-default:not(.button-flat),
.fusion-button_type-3d
  .ticket-selector-submit-btn[type="submit"]:not(.button-flat),
.fusion-button_type-3d .tml-submit-wrap input[type="submit"]:not(.button-flat),
.fusion-button_type-3d input.button-default:not(.button-flat),
.fusion-button_type-3d.fusion-button-default:not(.button-flat),
.fusion-button_type-3d.fusion-button:not(.button-flat) {
  box-shadow: inset 0 1px 0 #fff, 0 0.15em 0 var(--button_bevel_color),
    0.1em 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.3);
}
.fusion-button_type-3d #main #comment-submit:not(.button-flat):active,
.fusion-button_type-3d #main .comment-submit:not(.button-flat):active,
.fusion-button_type-3d #reviews input#submit:not(.button-flat):active,
.fusion-button_type-3d .button-default:not(.button-flat):active,
.fusion-button_type-3d .button.default:not(.button-flat):active,
.fusion-button_type-3d
  .comment-form
  input[type="submit"]:not(.button-flat):active,
.fusion-button_type-3d .fusion-button-default:not(.button-flat):active,
.fusion-button_type-3d
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:not(.button-flat):active,
.fusion-button_type-3d
  .fusion-portfolio-one
  .fusion-button:not(.button-flat):active,
.fusion-button_type-3d
  .post-password-form
  input[type="submit"]:not(.button-flat):active,
.fusion-button_type-3d
  .slidingbar-area
  .button-default:not(.button-flat):active,
.fusion-button_type-3d
  .ticket-selector-submit-btn[type="submit"]:not(.button-flat):active,
.fusion-button_type-3d
  .tml-submit-wrap
  input[type="submit"]:not(.button-flat):active,
.fusion-button_type-3d input.button-default:not(.button-flat):active,
.fusion-button_type-3d.fusion-button-default:not(.button-flat):active,
.fusion-button_type-3d.fusion-button:not(.button-flat):active {
  transform: translateY(calc(0.15em - 1px));
  box-shadow: inset 0 1px 0 #fff, 0 1px 0 var(--button_bevel_color),
    0.05em 0.1em 0.1em 0.07em rgba(0, 0, 0, 0.3);
}
.fusion-button_type-3d #main #comment-submit:not(.button-flat):hover,
.fusion-button_type-3d #main .comment-submit:not(.button-flat):hover,
.fusion-button_type-3d #reviews input#submit:not(.button-flat):hover,
.fusion-button_type-3d .button-default:not(.button-flat):hover,
.fusion-button_type-3d .button.default:not(.button-flat):hover,
.fusion-button_type-3d
  .comment-form
  input[type="submit"]:not(.button-flat):hover,
.fusion-button_type-3d .fusion-button-default:not(.button-flat):hover,
.fusion-button_type-3d
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:not(.button-flat):hover,
.fusion-button_type-3d
  .fusion-portfolio-one
  .fusion-button:not(.button-flat):hover,
.fusion-button_type-3d
  .post-password-form
  input[type="submit"]:not(.button-flat):hover,
.fusion-button_type-3d .slidingbar-area .button-default:not(.button-flat):hover,
.fusion-button_type-3d
  .ticket-selector-submit-btn[type="submit"]:not(.button-flat):hover,
.fusion-button_type-3d
  .tml-submit-wrap
  input[type="submit"]:not(.button-flat):hover,
.fusion-button_type-3d input.button-default:not(.button-flat):hover,
.fusion-button_type-3d.fusion-button-default:not(.button-flat):hover,
.fusion-button_type-3d.fusion-button:not(.button-flat):hover {
  box-shadow: inset 0 1px 0 #fff, 0 0.15em 0 var(--button_bevel_color_hover),
    0.1em 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.3);
}
.fusion-button_type-3d #main #comment-submit:not(.button-flat):hover:active,
.fusion-button_type-3d #main .comment-submit:not(.button-flat):hover:active,
.fusion-button_type-3d #reviews input#submit:not(.button-flat):hover:active,
.fusion-button_type-3d .button-default:not(.button-flat):hover:active,
.fusion-button_type-3d .button.default:not(.button-flat):hover:active,
.fusion-button_type-3d
  .comment-form
  input[type="submit"]:not(.button-flat):hover:active,
.fusion-button_type-3d .fusion-button-default:not(.button-flat):hover:active,
.fusion-button_type-3d
  .fusion-footer-widget-area
  .fusion-privacy-placeholder
  .button-default:not(.button-flat):hover:active,
.fusion-button_type-3d
  .fusion-portfolio-one
  .fusion-button:not(.button-flat):hover:active,
.fusion-button_type-3d
  .post-password-form
  input[type="submit"]:not(.button-flat):hover:active,
.fusion-button_type-3d
  .slidingbar-area
  .button-default:not(.button-flat):hover:active,
.fusion-button_type-3d
  .ticket-selector-submit-btn[type="submit"]:not(.button-flat):hover:active,
.fusion-button_type-3d
  .tml-submit-wrap
  input[type="submit"]:not(.button-flat):hover:active,
.fusion-button_type-3d input.button-default:not(.button-flat):hover:active,
.fusion-button_type-3d.fusion-button-default:not(.button-flat):hover:active,
.fusion-button_type-3d.fusion-button:not(.button-flat):hover:active {
  box-shadow: inset 0 1px 0 #fff, 0 1px 0 var(--button_bevel_color_hover),
    0.05em 0.1em 0.1em 0.07em rgba(0, 0, 0, 0.3);
}
.fusion-body #main .quantity .minus,
.fusion-body #main .quantity .plus,
.fusion-body #main .quantity .qty,
.fusion-body #main .quantity .tribe-ticket-quantity,
.fusion-button-quantity,
.single-product .product .summary .cart .quantity .minus,
.single-product .product .summary .cart .quantity .plus,
.single-product .product .summary .cart .quantity .qty,
.tribe-block__tickets__item__quantity button,
.tribe-events-tickets .woocommerce .quantity input {
  width: var(--qty_size-width, 38px);
}
.fusion-body #main .quantity .minus,
.fusion-body #main .quantity .plus,
.fusion-body #main .quantity .qty,
.fusion-body #main .quantity .tribe-ticket-quantity,
.fusion-button-quantity,
.single-product .product .summary .cart .quantity .minus,
.single-product .product .summary .cart .quantity .plus,
.single-product .product .summary .cart .quantity .qty,
.tribe-block__tickets__item__quantity button,
.tribe-events-tickets .woocommerce .quantity input {
  font-size: var(--qty_font_size, 14px);
  width: var(--qty_size-height, 36px);
  height: var(--qty_size-height, 36px);
  line-height: 1;
}
.fusion-body .quantity,
.single-product .product .summary .cart .quantity {
  width: calc(
    var(--qty_size-width, 38px) + var(--qty_size-height, 36px) +
      var(--qty_size-height, 36px)
  );
}
.fusion-button-span-yes {
  width: 100%;
}
body.fusion-button_span-yes .fusion-button-default-span {
  width: 100%;
}
.ls-container .button,
.ls-container .fusion-button {
  transition: none !important;
}
.ls-container .button:hover,
.ls-container .fusion-button:hover {
  transition: none !important;
}
@media only screen and (max-width: 800px) {
  .ls-container .button,
  .ls-container .fusion-button,
  .rev_slider .button,
  .rev_slider .fusion-button {
    padding: 0 20px;
    line-height: 30px;
    font-size: 12px;
    font-size: 10px !important;
    line-height: 30px !important;
    padding: 0 20px !important;
  }
  .ls-container .button .fusion-button-text-left,
  .ls-container .fusion-button .fusion-button-text-left,
  .rev_slider .button .fusion-button-text-left,
  .rev_slider .fusion-button .fusion-button-text-left {
    display: inline-block;
    padding-left: 25px;
  }
  .ls-container .button .fusion-button-text-right,
  .ls-container .fusion-button .fusion-button-text-right,
  .rev_slider .button .fusion-button-text-right,
  .rev_slider .fusion-button .fusion-button-text-right {
    display: inline-block;
    padding-right: 25px;
  }
  .ls-container .button .button-icon-divider-left,
  .ls-container .fusion-button .button-icon-divider-left,
  .rev_slider .button .button-icon-divider-left,
  .rev_slider .fusion-button .button-icon-divider-left {
    padding: 0 10px;
  }
  .ls-container .button .button-icon-divider-left.fusion-megamenu-image,
  .ls-container .fusion-button .button-icon-divider-left.fusion-megamenu-image,
  .rev_slider .button .button-icon-divider-left.fusion-megamenu-image,
  .rev_slider .fusion-button .button-icon-divider-left.fusion-megamenu-image {
    padding: 0 5px;
  }
  .ls-container .button .button-icon-divider-right,
  .ls-container .fusion-button .button-icon-divider-right,
  .rev_slider .button .button-icon-divider-right,
  .rev_slider .fusion-button .button-icon-divider-right {
    padding: 0 10px;
  }
  .ls-container .button .button-icon-divider-right.fusion-megamenu-image,
  .ls-container .fusion-button .button-icon-divider-right.fusion-megamenu-image,
  .rev_slider .button .button-icon-divider-right.fusion-megamenu-image,
  .rev_slider .fusion-button .button-icon-divider-right.fusion-megamenu-image {
    padding: 0 5px;
  }
  .ls-container .button .button-icon-divider-left i,
  .ls-container .button .button-icon-divider-left img,
  .ls-container .button .button-icon-divider-right i,
  .ls-container .button .button-icon-divider-right img,
  .ls-container .fusion-button .button-icon-divider-left i,
  .ls-container .fusion-button .button-icon-divider-left img,
  .ls-container .fusion-button .button-icon-divider-right i,
  .ls-container .fusion-button .button-icon-divider-right img,
  .rev_slider .button .button-icon-divider-left i,
  .rev_slider .button .button-icon-divider-left img,
  .rev_slider .button .button-icon-divider-right i,
  .rev_slider .button .button-icon-divider-right img,
  .rev_slider .fusion-button .button-icon-divider-left i,
  .rev_slider .fusion-button .button-icon-divider-left img,
  .rev_slider .fusion-button .button-icon-divider-right i,
  .rev_slider .fusion-button .button-icon-divider-right img {
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
  .ls-container .button.button-3d:active,
  .ls-container .fusion-button.button-3d:active,
  .rev_slider .button.button-3d:active,
  .rev_slider .fusion-button.button-3d:active {
    top: 2px !important;
  }
  .ls-container .button span,
  .ls-container .fusion-button span,
  .rev_slider .button span,
  .rev_slider .fusion-button span {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 640px) {
  .ls-container .button,
  .ls-container .fusion-button,
  .rev_slider .button,
  .rev_slider .fusion-button {
    padding: 0 20px;
    line-height: 20px;
    font-size: 10px;
    font-size: 10px !important;
    line-height: 20px !important;
    padding: 0 10px !important;
  }
  .ls-container .button .fusion-button-text-left,
  .ls-container .fusion-button .fusion-button-text-left,
  .rev_slider .button .fusion-button-text-left,
  .rev_slider .fusion-button .fusion-button-text-left {
    display: inline-block;
    padding-left: 25px;
  }
  .ls-container .button .fusion-button-text-right,
  .ls-container .fusion-button .fusion-button-text-right,
  .rev_slider .button .fusion-button-text-right,
  .rev_slider .fusion-button .fusion-button-text-right {
    display: inline-block;
    padding-right: 25px;
  }
  .ls-container .button .button-icon-divider-left,
  .ls-container .fusion-button .button-icon-divider-left,
  .rev_slider .button .button-icon-divider-left,
  .rev_slider .fusion-button .button-icon-divider-left {
    padding: 0 11px;
  }
  .ls-container .button .button-icon-divider-left.fusion-megamenu-image,
  .ls-container .fusion-button .button-icon-divider-left.fusion-megamenu-image,
  .rev_slider .button .button-icon-divider-left.fusion-megamenu-image,
  .rev_slider .fusion-button .button-icon-divider-left.fusion-megamenu-image {
    padding: 0 5.5px;
  }
  .ls-container .button .button-icon-divider-right,
  .ls-container .fusion-button .button-icon-divider-right,
  .rev_slider .button .button-icon-divider-right,
  .rev_slider .fusion-button .button-icon-divider-right {
    padding: 0 11px;
  }
  .ls-container .button .button-icon-divider-right.fusion-megamenu-image,
  .ls-container .fusion-button .button-icon-divider-right.fusion-megamenu-image,
  .rev_slider .button .button-icon-divider-right.fusion-megamenu-image,
  .rev_slider .fusion-button .button-icon-divider-right.fusion-megamenu-image {
    padding: 0 5.5px;
  }
  .ls-container .button .button-icon-divider-left i,
  .ls-container .button .button-icon-divider-left img,
  .ls-container .button .button-icon-divider-right i,
  .ls-container .button .button-icon-divider-right img,
  .ls-container .fusion-button .button-icon-divider-left i,
  .ls-container .fusion-button .button-icon-divider-left img,
  .ls-container .fusion-button .button-icon-divider-right i,
  .ls-container .fusion-button .button-icon-divider-right img,
  .rev_slider .button .button-icon-divider-left i,
  .rev_slider .button .button-icon-divider-left img,
  .rev_slider .button .button-icon-divider-right i,
  .rev_slider .button .button-icon-divider-right img,
  .rev_slider .fusion-button .button-icon-divider-left i,
  .rev_slider .fusion-button .button-icon-divider-left img,
  .rev_slider .fusion-button .button-icon-divider-right i,
  .rev_slider .fusion-button .button-icon-divider-right img {
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
  .ls-container .button.button-3d:active,
  .ls-container .fusion-button.button-3d:active,
  .rev_slider .button.button-3d:active,
  .rev_slider .fusion-button.button-3d:active {
    top: 2px !important;
  }
  .ls-container .button span,
  .ls-container .fusion-button span,
  .rev_slider .button span,
  .rev_slider .fusion-button span {
    font-size: 10px !important;
  }
}
.fusion-chart.legend-bottom .fusion-chart-inner,
.fusion-chart.legend-left .fusion-chart-inner,
.fusion-chart.legend-right .fusion-chart-inner,
.fusion-chart.legend-top .fusion-chart-inner {
  display: flex;
  flex-direction: row;
}
.fusion-chart.legend-bottom .fusion-chart-inner,
.fusion-chart.legend-top .fusion-chart-inner {
  flex-direction: column;
}
.fusion-chart.legend-bottom .fusion-chart-legend-wrap,
.fusion-chart.legend-top .fusion-chart-legend-wrap {
  width: 100%;
  text-align: center;
}
.fusion-chart.legend-top .fusion-chart-legend-wrap {
  padding-bottom: 20px;
  order: 1;
}
.fusion-chart.legend-top .fusion-chart-wrap {
  order: 2;
}
.fusion-chart.legend-bottom .fusion-chart-legend-wrap {
  padding-top: 20px;
  order: 2;
}
.fusion-chart.legend-bottom .fusion-chart-wrap {
  order: 1;
}
.fusion-chart.legend-left .fusion-chart-inner .fusion-chart-wrap,
.fusion-chart.legend-right .fusion-chart-inner .fusion-chart-wrap {
  flex: 15% 1 0;
}
.fusion-chart.legend-left .fusion-chart-inner .fusion-chart-legend-wrap,
.fusion-chart.legend-right .fusion-chart-inner .fusion-chart-legend-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 15% 0 0;
}
.fusion-chart.legend-left .fusion-chart-inner .fusion-chart-legend-wrap li,
.fusion-chart.legend-left .fusion-chart-inner .fusion-chart-legend-wrap span,
.fusion-chart.legend-right .fusion-chart-inner .fusion-chart-legend-wrap li,
.fusion-chart.legend-right .fusion-chart-inner .fusion-chart-legend-wrap span {
  display: block;
}
.fusion-chart.legend-right .fusion-chart-legend-wrap {
  padding-left: 5px;
  order: 2;
}
.fusion-chart.legend-right .fusion-chart-wrap {
  order: 1;
}
.fusion-chart.legend-left .fusion-chart-legend-wrap {
  padding-right: 5px;
  order: 1;
}
.fusion-chart.legend-left .fusion-chart-wrap {
  order: 2;
}
.fusion-chart .fusion-chart-legend-wrap {
  -webkit-user-select: none;
  user-select: none;
  direction: ltr;
}
.fusion-chart .fusion-chart-legend-wrap ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.fusion-chart .fusion-chart-legend-wrap li {
  display: inline-block;
  margin: 0;
}
.fusion-chart .fusion-chart-legend-wrap li span {
  position: relative;
  padding: 3px 12px;
  z-index: 2;
  color: #fff;
  display: inline-block;
  margin: 4px;
}
.fusion-chart .fusion-chart-title {
  text-align: center;
}
.fusion-checklist,
ul.fusion-checklist {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0;
}
.fusion-checklist .fusion-li-item,
.fusion-checklist.fusion-404-checklist > li,
ul.fusion-checklist .fusion-li-item,
ul.fusion-checklist.fusion-404-checklist > li {
  padding-top: var(--checklist_item_padding-top, 0.35em);
  padding-right: var(--checklist_item_padding-right, 0);
  padding-bottom: var(--checklist_item_padding-bottom, 0.35em);
  padding-left: var(--checklist_item_padding-left, 0);
  margin-left: 0;
}
.fusion-checklist .fusion-li-item:first-child,
.fusion-checklist.fusion-404-checklist > li:first-child,
ul.fusion-checklist .fusion-li-item:first-child,
ul.fusion-checklist.fusion-404-checklist > li:first-child {
  padding-top: 0;
}
.fusion-checklist .fusion-li-item p:first-of-type,
.fusion-checklist.fusion-404-checklist > li p:first-of-type,
ul.fusion-checklist .fusion-li-item p:first-of-type,
ul.fusion-checklist.fusion-404-checklist > li p:first-of-type {
  margin-top: 0;
}
.fusion-checklist .fusion-li-item p:last-of-type,
.fusion-checklist.fusion-404-checklist > li p:last-of-type,
ul.fusion-checklist .fusion-li-item p:last-of-type,
ul.fusion-checklist.fusion-404-checklist > li p:last-of-type {
  margin-bottom: 0;
}
.fusion-checklist.fusion-checklist-divider .fusion-li-item,
.fusion-checklist.fusion-checklist-divider.fusion-404-checklist > li,
ul.fusion-checklist.fusion-checklist-divider .fusion-li-item,
ul.fusion-checklist.fusion-checklist-divider.fusion-404-checklist > li {
  padding-top: calc(var(--checklist_item_padding-top, 0.35em) * 2);
  padding-bottom: calc(var(--checklist_item_padding-bottom, 0.35em) * 2);
  border-bottom: 1px solid;
}
.fusion-checklist .icon-wrapper,
ul.fusion-checklist .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
}
.fusion-checklist .fusion-li-icon,
ul.fusion-checklist .fusion-li-icon {
  display: inline-block;
}
.fusion-checklist .circle-yes,
ul.fusion-checklist .circle-yes {
  border-radius: 50%;
  background-clip: padding-box;
  background-color: #333;
  color: #fff;
  text-align: center;
}
.fusion-content-boxes {
  margin-bottom: 60px;
}
.fusion-content-boxes .col {
  position: relative;
}
.fusion-content-boxes .fusion-column {
  margin-bottom: 20px;
  box-sizing: border-box;
}
.fusion-content-boxes.fusion-columns-1 .fusion-column {
  margin-bottom: 40px;
}
.fusion-content-boxes .link-area-box {
  cursor: pointer;
}
.fusion-content-boxes .link-area-box .fusion-read-more,
.fusion-content-boxes .link-area-box .fusion-read-more-button {
  pointer-events: none;
}
.fusion-content-boxes .icon .fontawesome-icon {
  margin-right: 0;
  margin-left: 0;
}
.fusion-content-boxes .icon > span {
  display: inline-block;
}
.fusion-content-boxes .icon * {
  box-sizing: border-box;
}
.fusion-content-boxes .heading {
  overflow: hidden;
  margin-bottom: 15px;
}
.fusion-content-boxes .heading .heading-link {
  text-decoration: none;
  box-shadow: none;
}
.fusion-content-boxes .heading .heading-link:hover .content-box-heading {
  color: #65bc7b;
}
.fusion-content-boxes .heading .heading-link:hover .fontawesome-icon {
  background-color: #65bc7b;
  border-color: #65bc7b;
}
.fusion-content-boxes .heading .heading-link .icon {
  height: auto;
  width: auto;
  display: block;
}
.fusion-content-boxes .heading .content-box-heading,
.fusion-content-boxes .heading .fontawesome-icon,
.fusion-content-boxes .heading a {
  transition: all 0.3s;
}
.fusion-content-boxes .heading .content-box-heading {
  margin: 0;
  padding: 0;
}
.fusion-content-boxes .heading img {
  float: none;
  margin: 0;
}
.fusion-content-boxes .fontawesome-icon.circle-yes {
  margin-top: 0;
}
.fusion-content-boxes .fusion-read-more:after,
.fusion-content-boxes .fusion-read-more:before {
  transition: all 0.3s;
}
.fusion-content-boxes .fusion-read-more-button {
  margin-top: 18px;
}
.fusion-content-boxes .link-type-button-bar .content-container {
  padding-bottom: 50px;
  position: relative;
}
.fusion-content-boxes .link-type-button-bar .fusion-read-more {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 0;
  text-align: center;
}
.fusion-content-boxes .link-type-button-bar .fusion-read-more:after {
  display: none;
}
.fusion-content-boxes .content-container {
  margin-bottom: 20px;
}
.fusion-content-boxes .content-container > p:last-child {
  margin-bottom: 0;
}
.fusion-content-boxes .content-container .fusion-read-more {
  display: block;
  margin-top: 18px;
}
.fusion-content-boxes .content-wrapper-background {
  padding: 35px;
}
.fusion-content-boxes .content-box-shortcode-timeline {
  display: block;
  height: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  margin-right: 10px;
}
.fusion-content-boxes .content-box-shortcode-timeline-vertical {
  display: block;
  width: 1px;
  border-left-style: solid;
  border-left-width: 1px;
  position: absolute;
  top: 0;
  z-index: 1;
}
.fusion-content-boxes.content-boxes
  .content-box-column-last
  .content-box-shortcode-timeline,
.fusion-content-boxes.content-boxes
  .content-box-column-last-in-row
  .content-box-shortcode-timeline {
  display: none;
}
.fusion-content-boxes.content-boxes-clean-horizontal.content-boxes
  .content-box-column-last,
.fusion-content-boxes.content-boxes-clean-horizontal.content-boxes
  .content-box-column-last-in-row,
.fusion-content-boxes.content-boxes-clean-vertical.content-boxes
  .content-box-column-last,
.fusion-content-boxes.content-boxes-clean-vertical.content-boxes
  .content-box-column-last-in-row {
  border-right-width: 1px;
}
.fusion-content-boxes.content-boxes-timeline-horizontal
  .content-box-column
  .icon,
.fusion-content-boxes.content-boxes-timeline-vertical
  .content-box-column
  .icon {
  opacity: 0;
}
.fusion-content-boxes.content-boxes-timeline-horizontal
  .content-box-column.fusion-appear
  .icon {
  animation: fusionExpandAndShow 1s 1 cubic-bezier(0.17, 0.67, 0.83, 0.67);
  opacity: 1;
  transform: scale(1);
}
.fusion-content-boxes.content-boxes-timeline-horizontal
  .content-box-column.fusion-appear
  .content-box-shortcode-timeline {
  animation: fusionExpandWidth 1s 1 cubic-bezier(0.17, 0.67, 0.83, 0.67);
  width: 100%;
}
.fusion-content-boxes.content-boxes-timeline-vertical
  .content-box-column.fusion-appear
  .icon {
  animation: fusionExpandAndShow 1s 1 cubic-bezier(0.17, 0.67, 0.83, 0.67);
  opacity: 1;
  transform: scale(1);
}
.fusion-content-boxes.content-boxes-timeline-vertical
  .content-box-column.fusion-appear
  .content-box-shortcode-timeline-vertical {
  animation: fusionExpandHeight 1s 1 cubic-bezier(0.17, 0.67, 0.83, 0.67);
  height: calc(100% + 40px);
}
.fusion-content-boxes.content-boxes-icon-with-title .heading-with-icon .icon,
.fusion-content-boxes.content-boxes-icon-with-title .heading-with-icon .image {
  display: table-cell;
  vertical-align: middle;
  float: none;
}
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon
  .content-box-heading {
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
}
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon.icon-right
  .content-box-heading {
  float: none !important;
}
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon.icon-right
  i,
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon.icon-right
  img {
  margin-right: 0;
  margin-left: 20px;
}
.fusion-content-boxes.content-boxes-icon-on-side .heading {
  margin-bottom: 15px;
}
.fusion-content-boxes.content-boxes-icon-on-side .heading-with-icon {
  position: relative;
  overflow: visible;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon
  .content-box-heading {
  vertical-align: top;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon.icon-right
  .content-box-heading {
  float: right;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon.icon-right
  .icon,
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon.icon-right
  .image {
  right: 0;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon.icon-right
  .icon
  i,
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon.icon-right
  .icon
  img,
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon.icon-right
  .image
  i,
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon.icon-right
  .image
  img {
  margin-left: 20px;
  margin-right: 0;
}
.fusion-content-boxes.content-boxes-icon-on-side .icon,
.fusion-content-boxes.content-boxes-icon-on-side .image {
  position: absolute;
}
.fusion-content-boxes.content-boxes-icon-on-side .content-container.icon-small {
  padding-left: 55px;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .content-container.icon-medium {
  padding-left: 70px;
}
.fusion-content-boxes.content-boxes-icon-on-side .content-container.icon-large {
  padding-left: 85px;
}
.fusion-content-boxes.content-boxes-icon-on-side.content-right
  .content-container {
  padding-left: 0 !important;
}
.fusion-content-boxes.content-boxes-icon-on-side.content-right
  .content-container.icon-small {
  padding-right: 45px;
}
.fusion-content-boxes.content-boxes-icon-on-side.content-right
  .content-container.icon-medium {
  padding-right: 60px;
}
.fusion-content-boxes.content-boxes-icon-on-side.content-right
  .content-container.icon-large {
  padding-right: 75px;
}
.fusion-content-boxes.content-boxes-icon-on-side .heading-with-icon,
.fusion-content-boxes.content-boxes-icon-with-title .heading-with-icon {
  display: table;
  width: auto;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon
  .heading-link,
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon
  .heading-link {
  display: block;
}
.fusion-content-boxes.content-boxes-icon-on-side .heading-with-icon img,
.fusion-content-boxes.content-boxes-icon-with-title .heading-with-icon img {
  margin-right: 20px;
  max-width: none;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon
  .fontawesome-icon,
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon
  .fontawesome-icon {
  display: block;
  float: none;
  margin-right: 20px;
}
.fusion-content-boxes.content-boxes-icon-on-side .heading-with-icon i.small,
.fusion-content-boxes.content-boxes-icon-with-title .heading-with-icon i.small {
  height: 35px;
  width: 35px;
  line-height: 35px;
  font-size: 18px;
}
.fusion-content-boxes.content-boxes-icon-on-side .heading-with-icon i.medium,
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon
  i.medium {
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 21px;
}
.fusion-content-boxes.content-boxes-icon-on-side .heading-with-icon i.large,
.fusion-content-boxes.content-boxes-icon-with-title .heading-with-icon i.large {
  width: 65px;
  height: 65px;
  line-height: 65px;
  font-size: 24px;
}
.fusion-content-boxes.content-boxes-icon-on-side .heading-with-icon.icon-right,
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon.icon-right {
  float: right !important;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon.icon-right
  .icon,
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon.icon-right
  .image,
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon.icon-right
  .icon,
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon.icon-right
  .image {
  float: none;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon.icon-right
  .icon
  i,
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon.icon-right
  .icon
  i {
  margin-right: 0 !important;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .heading-with-icon.icon-right
  .content-box-heading,
.fusion-content-boxes.content-boxes-icon-with-title
  .heading-with-icon.icon-right
  .content-box-heading {
  text-align: right;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .content-icon-wrapper-yes
  .heading-with-icon
  .icon
  span,
.fusion-content-boxes.content-boxes-icon-with-title
  .content-icon-wrapper-yes
  .heading-with-icon
  .icon
  span {
  margin-right: 20px;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .content-icon-wrapper-yes
  .heading-with-icon.icon-right
  .icon
  span,
.fusion-content-boxes.content-boxes-icon-with-title
  .content-icon-wrapper-yes
  .heading-with-icon.icon-right
  .icon
  span {
  margin-left: 20px;
  margin-right: 0;
}
.fusion-content-boxes.content-boxes-icon-on-side
  .content-icon-wrapper-yes
  .heading-with-icon
  .fontawesome-icon,
.fusion-content-boxes.content-boxes-icon-with-title
  .content-icon-wrapper-yes
  .heading-with-icon
  .fontawesome-icon {
  margin-right: 0;
}
.fusion-content-boxes.content-boxes-icon-on-top .heading {
  text-align: center;
}
.fusion-content-boxes.content-boxes-icon-on-top .heading .icon {
  margin-bottom: 15px;
}
.fusion-content-boxes.content-boxes-icon-on-top .heading .fontawesome-icon {
  display: block;
  float: none;
  margin: 0 auto;
  height: 64px;
  width: 64px;
  line-height: 64px;
  font-size: 24px;
}
.fusion-content-boxes.content-boxes-icon-on-top .heading i.small {
  height: 35px;
  width: 35px;
  line-height: 35px;
  font-size: 18px;
}
.fusion-content-boxes.content-boxes-icon-on-top .heading i.medium {
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 21px;
}
.fusion-content-boxes.content-boxes-icon-on-top .heading i.large {
  width: 65px;
  height: 65px;
  line-height: 65px;
  font-size: 24px;
}
.fusion-content-boxes.content-boxes-icon-on-top .heading img {
  margin-bottom: 20px;
}
.fusion-content-boxes.content-boxes-icon-on-top .content-container,
.fusion-content-boxes.content-boxes-icon-on-top .fusion-read-more {
  text-align: center;
}
.fusion-content-boxes.content-boxes-icon-boxed {
  overflow: visible;
}
.fusion-content-boxes.content-boxes-icon-boxed .content-wrapper-boxed {
  overflow: visible;
  padding: 50px 35px 35px;
  text-align: center;
}
.fusion-content-boxes.content-boxes-icon-boxed
  .link-type-button-bar
  .content-container {
  padding-bottom: 50px;
}
.fusion-content-boxes.content-boxes-icon-boxed .heading {
  overflow: visible;
  position: relative;
  text-align: center;
}
.fusion-content-boxes.content-boxes-icon-boxed .heading .fontawesome-icon {
  display: block;
  position: absolute;
  left: 50%;
  top: -80px;
  float: none;
  height: 64px;
  width: 64px;
  line-height: 64px;
  font-size: 24px;
}
.fusion-content-boxes.content-boxes-icon-boxed .heading i.small {
  height: 35px;
  width: 35px;
  line-height: 35px;
  font-size: 18px;
  top: -67px;
  margin-left: -17px;
}
.fusion-content-boxes.content-boxes-icon-boxed .heading i.medium {
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 21px;
  top: -76px;
  margin-left: -25px;
}
.fusion-content-boxes.content-boxes-icon-boxed .heading i.large {
  width: 65px;
  height: 65px;
  line-height: 65px;
  font-size: 24px;
  top: -85px;
}
.fusion-content-boxes.content-boxes-icon-boxed .heading .image {
  display: block;
  position: absolute;
  left: 50%;
}
.fusion-content-boxes.content-boxes-icon-boxed .content-container,
.fusion-content-boxes.content-boxes-icon-boxed .fusion-read-more {
  text-align: center;
}
.fusion-content-boxes.content-boxes-timeline-horizontal .icon {
  position: relative;
  z-index: 2;
}
.fusion-content-boxes.content-boxes-timeline-horizontal
  .content-box-column
  .content-box-wrapper {
  position: static;
}
.fusion-content-boxes.content-boxes-timeline-vertical .heading-with-icon,
.fusion-content-boxes.content-boxes-timeline-vertical
  .heading-with-icon
  .heading-link {
  display: flex;
  align-items: center;
  overflow: visible;
}
.fusion-content-boxes.content-boxes-timeline-vertical
  .content-box-column
  > .col {
  position: static;
}
.fusion-content-boxes.content-boxes-timeline-vertical .icon {
  position: relative;
  z-index: 2;
}
.fusion-content-boxes.content-boxes-timeline-vertical
  .content-box-column-last
  .content-box-shortcode-timeline-vertical {
  display: none;
}
.do-animate .fusion-content-boxes .fusion-animated {
  animation-fill-mode: none;
  position: relative;
}
.fusion-content-boxes.content-boxes-clean-vertical {
  margin-left: 0;
  margin-right: 0;
}
.fusion-content-boxes.content-boxes-clean-vertical .content-box-column {
  border-style: solid;
  border-width: 1px;
  border-right-width: 0;
  padding: 0;
}
.fusion-content-boxes.content-boxes-clean-vertical .col {
  padding: 30px;
}
.fusion-content-boxes.content-boxes-clean-vertical .link-type-button {
  padding-bottom: 0;
}
.fusion-content-boxes.content-boxes-clean-vertical
  .link-type-button-bar
  .content-container {
  padding-bottom: 60px;
}
.fusion-content-boxes.content-boxes-clean-horizontal {
  margin-left: 0;
  margin-right: 0;
}
.fusion-content-boxes.content-boxes-clean-horizontal .content-box-column {
  border-style: solid;
  border-width: 1px;
  border-right-width: 0;
  padding: 0;
}
.fusion-content-boxes.content-boxes-clean-horizontal .col {
  padding: 30px;
}
.fusion-content-boxes.content-boxes-clean-horizontal
  .link-type-button-bar
  .content-container {
  padding-bottom: 60px;
}
.fusion-content-boxes a {
  text-decoration: none;
  box-shadow: none;
}
.content-boxes-icon-on-top .col {
  text-align: center;
}
.content-boxes-icon-on-top .col .more a {
  float: none;
}
.content-boxes-icon-on-top .col .heading-and-icon .fontawesome-icon {
  float: none;
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 24px;
  margin: 0;
  display: inline-block;
}
.content-boxes-icon-on-top .col .heading-and-icon img {
  float: none;
  margin-right: 0;
}
.content-boxes-icon-on-top .col .heading-and-icon h2 {
  padding-left: 0;
}
.content-boxes-icon-on-side .col-content-container {
  padding-left: 45px;
}
.content-boxes-icon-boxed {
  overflow: visible;
}
.content-boxes-icon-boxed .col {
  overflow: visible !important;
  text-align: center;
  padding: 50px 2% 2%;
}
.content-boxes-icon-boxed .col .more a {
  float: none;
}
.content-boxes-icon-boxed .col .heading-and-icon {
  overflow: visible;
  position: relative;
}
.content-boxes-icon-boxed .col .heading-and-icon .fontawesome-icon,
.content-boxes-icon-boxed .col .heading-and-icon img {
  float: none;
  position: absolute;
  top: -80px;
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 24px;
  margin: 0 0 0 -32px;
  left: 50%;
}
.content-boxes-icon-boxed .col .heading-and-icon img {
  float: none;
}
.content-boxes-icon-boxed .col .heading-and-icon h2 {
  padding-left: 0;
}
.fusion-countdown {
  padding: 30px;
}
.fusion-countdown.fusion-no-bg {
  padding: 0;
}
.fusion-countdown .fusion-countdown-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 -7.5px;
}
.fusion-countdown .fusion-countdown-counter-wrapper,
.fusion-countdown .fusion-countdown-heading-wrapper,
.fusion-countdown .fusion-countdown-link-wrapper {
  margin: 0 7.5px;
}
.fusion-countdown .fusion-countdown-heading {
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1.2em;
}
.fusion-countdown .fusion-countdown-subheading {
  letter-spacing: 1px;
  line-height: 1.2em;
}
.fusion-countdown .fusion-countdown-counter-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  text-align: center;
}
.fusion-countdown .fusion-dash-wrapper {
  display: inline-block;
}
.fusion-countdown .fusion-dash {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.fusion-countdown .fusion-digit-wrapper {
  display: flex;
  justify-content: center;
}
.fusion-countdown .fusion-digit > div {
  margin: 0 -0.23em;
  width: 1em;
}
.fusion-countdown .fusion-hundred-digit,
.fusion-countdown .fusion-thousand-digit {
  display: none;
}
.fusion-countdown .fusion-dash-title {
  min-width: 46px;
}
.ltr .fusion-countdown .fusion-dash-title {
  padding-left: 0.25em;
}
.rtl .fusion-countdown .fusion-dash-title {
  padding-right: 0.25em;
}
.fusion-countdown .fusion-countdown-link {
  letter-spacing: 2px;
}
.fusion-countdown .fusion-countdown-link:hover {
  opacity: 0.7;
}
.fusion-countdown.fusion-countdown-has-heading .fusion-countdown-subheading {
  margin-bottom: 0.4em;
}
.fusion-countdown.fusion-countdown-stacked .fusion-countdown-wrapper {
  flex-direction: column;
}
.fusion-countdown.fusion-countdown-stacked .fusion-countdown-heading-wrapper {
  text-align: center;
}
.fusion-countdown.fusion-countdown-stacked.fusion-countdown-has-heading
  .fusion-countdown-counter-wrapper {
  margin-top: 1em;
}
.fusion-countdown.fusion-countdown-stacked.fusion-countdown-has-link
  .fusion-countdown-counter-wrapper {
  margin-bottom: 1em;
}
.fusion-countdown.fusion-countdown-label-bottom .fusion-dash,
.fusion-countdown.fusion-countdown-label-top .fusion-dash {
  flex-direction: column;
}
.fusion-countdown.fusion-countdown-label-bottom .fusion-dash-title,
.fusion-countdown.fusion-countdown-label-top .fusion-dash-title {
  width: 100%;
}
.fusion-countdown.fusion-countdown-label-top .fusion-digit-wrapper {
  order: 2;
}
.fusion-countdown.fusion-countdown-label-top .fusion-dash-title {
  order: 1;
  padding: 0 0 0.25em 0;
}
.fusion-countdown.fusion-countdown-label-bottom .fusion-dash-title {
  padding: 0.25em 0 0 0;
}
.fusion-counters-box.row {
  margin-left: -7px;
  margin-right: -7px;
}
.fusion-counters-box .fusion-counter-box {
  padding: 0 7px;
  margin-bottom: 14px;
  text-align: center;
  box-sizing: border-box;
}
.fusion-counters-box .fusion-counter-box .counter-box-container {
  padding: 20px;
}
.fusion-counters-box .fusion-counter-box .counter-box-border {
  border: 1px solid #e5e4e3;
}
.fusion-counters-box .content-box-counter {
  color: #65bc7b;
  font-size: 50px;
  line-height: 50px;
}
.fusion-counters-box .counter-box-icon {
  float: none;
  margin-top: -8px;
  vertical-align: middle;
  color: inherit;
}
.fusion-counters-box .fusion-counter-box-icon-top .counter-box-icon {
  display: block;
  float: none;
  margin: 0;
}
.fusion-counters-circle {
  margin-bottom: 20px;
  text-align: center;
}
.fusion-counters-circle .counter-circle-wrapper {
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  margin-right: 15px;
  overflow: hidden;
  text-align: center;
}
.fusion-counters-circle .counter-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: 220px;
  width: 220px;
  font-size: 50px;
}
.fusion-counters-circle .counter-circle .fusion-counter-circle-content-inner {
  line-height: 1;
  width: 80%;
}
.fusion-counters-circle .counter-circle .fontawesome-icon {
  float: none;
  margin: 0;
  font-size: inherit;
}
.fusion-counters-circle .counter-circle canvas {
  position: absolute;
  left: 0;
  top: 0;
}
.fusion-counters-circle:last-child {
  margin-right: 0;
}
.fusion-body .fusion-dropcap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 4px;
  float: left;
  margin-right: 10px;
  padding: 0;
  height: 52px;
  line-height: 52px;
  color: var(--dropcap_color);
  font-size: 60px;
  text-transform: uppercase;
}
.fusion-body .fusion-dropcap.dropcap-boxed {
  height: 51px;
  width: 51px;
  line-height: 51px;
  border-radius: 8px;
  background-clip: padding-box;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  color: var(--dropcap_text_color, #fff);
  font-size: 45px;
  background-color: var(--dropcap_color);
}
.fusion-flip-boxes {
  margin-bottom: 25px;
}
.fusion-flip-boxes .fusion-column {
  box-sizing: border-box;
}
.fusion-flip-boxes .fusion-flip-box {
  position: relative;
  z-index: 1;
  padding: 0;
  margin: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.fusion-flip-boxes .fusion-flip-box .flip-box-inner-wrapper {
  position: relative;
  margin-bottom: 15px;
  perspective: 1000px;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-back,
.fusion-flip-boxes .fusion-flip-box .flip-box-front {
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  perspective: 1000px;
  padding: 27px 20px;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-clip: padding-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-front {
  position: relative;
  bottom: 0;
  z-index: 10;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-back {
  position: absolute;
  z-index: -1;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-back .fusion-button {
  display: table;
  margin: 20px auto 0;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-back-inner,
.fusion-flip-boxes .fusion-flip-box .flip-box-front-inner {
  width: 100%;
}
.fusion-flip-boxes .fusion-flip-box.hover .flip-box-back,
.fusion-flip-boxes .fusion-flip-box:focus .flip-box-back,
.fusion-flip-boxes .fusion-flip-box:focus-within .flip-box-back,
.fusion-flip-boxes .fusion-flip-box:hover .flip-box-back {
  z-index: 1;
}
.fusion-flip-boxes .fusion-flip-box.hover .flip-box-front,
.fusion-flip-boxes .fusion-flip-box:focus .flip-box-front,
.fusion-flip-boxes .fusion-flip-box:focus-within .flip-box-front,
.fusion-flip-boxes .fusion-flip-box:hover .flip-box-front {
  z-index: -1;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-grafix {
  display: table;
  margin: 0 auto 10px;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-image {
  table-layout: fixed;
  width: 100%;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-circle {
  height: 64px;
  width: 64px;
  border: 1px solid;
  border-radius: 50%;
  background-clip: padding-box;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-circle i {
  display: table-cell;
  vertical-align: middle;
  font-size: 24px;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-no-circle i {
  font-size: 60px;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-heading {
  margin-top: 0;
  margin-bottom: 9px;
  padding-top: 0;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-heading.without-text {
  margin-bottom: 0;
}
.fusion-flip-boxes .fusion-flip-box .flip-box-heading-back {
  margin-bottom: 9px;
  margin-top: 0;
  text-transform: uppercase;
}
.fusion-flip-boxes .fusion-flip-box .finished-animating {
  transition: none !important;
  animation: none !important;
}
.fusion-flip-boxes .fusion-flip-box.flip-right .flip-box-front {
  transform: rotateY(0);
}
.fusion-flip-boxes .fusion-flip-box.flip-right .flip-box-back {
  transform: rotateY(-180deg);
}
.fusion-flip-boxes .fusion-flip-box.flip-right.hover .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-right:focus .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-right:focus-within .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-right:hover .flip-box-back {
  transform: rotateY(0);
}
.fusion-flip-boxes .fusion-flip-box.flip-right.hover .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-right:focus .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-right:focus-within .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-right:hover .flip-box-front {
  transform: rotateY(180deg);
}
.fusion-flip-boxes .fusion-flip-box.flip-left .flip-box-front {
  transform: rotateY(0);
}
.fusion-flip-boxes .fusion-flip-box.flip-left .flip-box-back {
  transform: rotateY(180deg);
}
.fusion-flip-boxes .fusion-flip-box.flip-left.hover .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-left:focus .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-left:focus-within .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-left:hover .flip-box-back {
  transform: rotateY(0);
}
.fusion-flip-boxes .fusion-flip-box.flip-left.hover .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-left:focus .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-left:focus-within .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-left:hover .flip-box-front {
  transform: rotateY(-180deg);
}
.fusion-flip-boxes .fusion-flip-box.flip-up .flip-box-front {
  transform: rotateX(0);
}
.fusion-flip-boxes .fusion-flip-box.flip-up .flip-box-back {
  transform: rotateX(-180deg);
}
.fusion-flip-boxes .fusion-flip-box.flip-up.hover .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-up:focus .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-up:focus-within .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-up:hover .flip-box-back {
  transform: rotateX(0);
}
.fusion-flip-boxes .fusion-flip-box.flip-up.hover .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-up:focus .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-up:focus-within .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-up:hover .flip-box-front {
  transform: rotateX(180deg);
}
.fusion-flip-boxes .fusion-flip-box.flip-down .flip-box-front {
  transform: rotateX(0);
}
.fusion-flip-boxes .fusion-flip-box.flip-down .flip-box-back {
  transform: rotateX(180deg);
}
.fusion-flip-boxes .fusion-flip-box.flip-down.hover .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-down:focus .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-down:focus-within .flip-box-back,
.fusion-flip-boxes .fusion-flip-box.flip-down:hover .flip-box-back {
  transform: rotateX(0);
}
.fusion-flip-boxes .fusion-flip-box.flip-down.hover .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-down:focus .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-down:focus-within .flip-box-front,
.fusion-flip-boxes .fusion-flip-box.flip-down:hover .flip-box-front {
  transform: rotateX(-180deg);
}
.fusion-flip-boxes.flip-effect-classic .flip-box-back,
.fusion-flip-boxes.flip-effect-classic .flip-box-front {
  transition: transform 0.4s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}
.fusion-flip-boxes.flip-effect-classic
  .fusion-flip-box.hover
  .flip-box-grafix
  i:before,
.fusion-flip-boxes.flip-effect-classic
  .fusion-flip-box:focus
  .flip-box-grafix
  i:before,
.fusion-flip-boxes.flip-effect-classic
  .fusion-flip-box:focus-within
  .flip-box-grafix
  i:before,
.fusion-flip-boxes.flip-effect-classic
  .fusion-flip-box:hover
  .flip-box-grafix
  i:before {
  display: none;
}
.fusion-flip-boxes.flip-effect-3d .flip-box-inner-wrapper {
  transform-style: preserve-3d;
}
.fusion-flip-boxes.flip-effect-3d .flip-box-back,
.fusion-flip-boxes.flip-effect-3d .flip-box-front {
  transform-style: preserve-3d;
  transition: transform 0.75s ease-in-out;
}
.fusion-flip-boxes.flip-effect-3d .flip-box-back-inner,
.fusion-flip-boxes.flip-effect-3d .flip-box-front-inner {
  transform: translateZ(50px) scale(0.9);
}
.ua-edge .fusion-flip-boxes .fusion-flip-box.hover .flip-box-back,
.ua-edge .fusion-flip-boxes .fusion-flip-box:focus .flip-box-back,
.ua-edge .fusion-flip-boxes .fusion-flip-box:focus-within .flip-box-back,
.ua-edge .fusion-flip-boxes .fusion-flip-box:hover .flip-box-back {
  transform: rotateY(0);
  z-index: 1;
}
.ua-edge .fusion-flip-boxes .fusion-flip-box.hover .flip-box-front,
.ua-edge .fusion-flip-boxes .fusion-flip-box:focus .flip-box-front,
.ua-edge .fusion-flip-boxes .fusion-flip-box:focus-within .flip-box-front,
.ua-edge .fusion-flip-boxes .fusion-flip-box:hover .flip-box-front {
  transform: rotateY(180deg);
  z-index: -1;
}
.fontawesome-icon {
  display: inline-block;
  float: left;
  margin-right: 10px;
}
.fontawesome-icon:before {
  transition: none;
}
.fontawesome-icon.circle-yes {
  border-radius: 50%;
  background-clip: inherit;
  border-width: 1px;
  border-style: solid;
  background-color: #333;
  color: #fff;
  text-align: center;
  margin-top: 2px;
}
.fontawesome-icon.fusion-link:before {
  color: inherit;
}
.fontawesome-icon.fusion-text-flow {
  float: none;
}
.fontawesome-icon.circle-no.size-large {
  margin-top: 2px;
}
.fontawesome-icon.circle-no.size-medium {
  margin-top: 3px;
}
.fontawesome-icon.circle-no.size-small {
  margin-top: 5px;
}
.fontawesome-icon.icon-hover-animation-pulsate {
  position: relative;
}
.fontawesome-icon.icon-hover-animation-pulsate.hover,
.fontawesome-icon.icon-hover-animation-pulsate:hover {
  transform: scale(0.93);
  transition: all 0.3s;
}
.fontawesome-icon.icon-hover-animation-pulsate.hover:after,
.fontawesome-icon.icon-hover-animation-pulsate:hover:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  content: "";
  box-sizing: content-box;
  top: 0;
  left: 0;
  padding: 0;
  z-index: -1;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  opacity: 0;
  transform: scale(0.9);
  display: inline-block;
  animation: fusionSonarEffect 1.3s ease-out 75ms;
}
.fontawesome-icon.icon-hover-animation-slide.circle-yes {
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
}
.fontawesome-icon.icon-hover-animation-slide.hover:before,
.fontawesome-icon.icon-hover-animation-slide:hover:before {
  animation: fusionToRightFromLeft 0.3s forwards;
}
.fontawesome-icon.icon-hover-animation-slide:before {
  display: block;
}
.fusion-fa-align-left {
  display: inline;
  float: left;
  margin: 0;
}
.fusion-fa-align-left .fontawesome-icon {
  margin: 0;
}
.fusion-fa-align-right {
  display: inline;
  float: right;
  margin: 0;
}
.fusion-fa-align-right .fontawesome-icon {
  margin: 0;
}
.fusion-fa-align-center {
  display: block;
  clear: both;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.fusion-fa-align-center .fontawesome-icon {
  margin: 0;
}
.fusion-body .fusion-fa-align-center .fontawesome-icon {
  float: none;
}
.fusion-grid-1 > .fusion-grid-column,
.fusion-grid-one > .fusion-grid-column {
  width: 100%;
}
.fusion-grid-2 > .fusion-grid-column,
.fusion-grid-two > .fusion-grid-column {
  width: 50%;
}
.fusion-grid-3 > .fusion-grid-column,
.fusion-grid-three > .fusion-grid-column {
  width: calc(100% / 3);
}
.fusion-grid-4 > .fusion-grid-column,
.fusion-grid-four > .fusion-grid-column {
  width: 25%;
}
.fusion-grid-5 > .fusion-grid-column,
.fusion-grid-five > .fusion-grid-column {
  width: 20%;
}
.fusion-grid-6 > .fusion-grid-column,
.fusion-grid-six > .fusion-grid-column {
  width: calc(100% / 6);
}
.fusion-gallery.fusion-gallery-layout-masonry.fusion-grid-2.fusion-masonry-has-vertical
  .fusion-element-landscape,
.fusion-gallery.fusion-gallery-layout-masonry.fusion-grid-two.fusion-masonry-has-vertical
  .fusion-element-landscape {
  width: 100%;
}
.fusion-gallery.fusion-gallery-layout-masonry.fusion-grid-3.fusion-masonry-has-vertical
  .fusion-element-landscape,
.fusion-gallery.fusion-gallery-layout-masonry.fusion-grid-three.fusion-masonry-has-vertical
  .fusion-element-landscape {
  width: calc(100% / 3 * 2);
}
.fusion-gallery.fusion-gallery-layout-masonry.fusion-grid-4.fusion-masonry-has-vertical
  .fusion-element-landscape,
.fusion-gallery.fusion-gallery-layout-masonry.fusion-grid-four.fusion-masonry-has-vertical
  .fusion-element-landscape {
  width: 50%;
}
.fusion-gallery.fusion-gallery-layout-masonry.fusion-grid-5.fusion-masonry-has-vertical
  .fusion-element-landscape,
.fusion-gallery.fusion-gallery-layout-masonry.fusion-grid-five.fusion-masonry-has-vertical
  .fusion-element-landscape {
  width: 40%;
}
.fusion-gallery.fusion-gallery-layout-masonry.fusion-grid-6.fusion-masonry-has-vertical
  .fusion-element-landscape,
.fusion-gallery.fusion-gallery-layout-masonry.fusion-grid-six.fusion-masonry-has-vertical
  .fusion-element-landscape {
  width: calc(100% / 3);
}
.fusion-gallery.fusion-gallery-layout-masonry
  .fusion-gallery-column
  .fusion-gallery-image.fusion-gallery-image-liftup
  img {
  display: none;
}
.fusion-gallery .fusion-gallery-column {
  padding: 0;
  display: none;
}
.fusion-gallery .fusion-gallery-column .fusion-gallery-image {
  overflow: hidden;
  opacity: 1;
}
.fusion-gallery
  .fusion-gallery-column
  .fusion-gallery-image.fusion-gallery-image-liftup {
  display: block;
  position: relative;
  max-width: 100%;
  transform: perspective(1000px) scale(1);
  transition: transform 0.35s;
}
.fusion-gallery
  .fusion-gallery-column
  .fusion-gallery-image.fusion-gallery-image-liftup.hover,
.fusion-gallery
  .fusion-gallery-column
  .fusion-gallery-image.fusion-gallery-image-liftup:hover {
  z-index: 1;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.4);
  transform: perspective(1000px) scale(1.03);
}
.fusion-gallery
  .fusion-gallery-column
  .fusion-gallery-image.fusion-gallery-image-liftup
  img {
  display: block;
}
.fusion-gallery .fusion-gallery-column .fusion-gallery-caption {
  line-height: 2.87;
}
@media only screen and (max-width: 800px) {
  .fusion-gallery
    .fusion-column.fusion-gallery-column.fusion-element-landscape {
    width: 100%;
  }
}
.fusion-google-map img {
  max-width: none;
}
.fusion-google-map .fusion-info-box {
  padding: 5px;
  border-radius: 5px;
  background-clip: padding-box;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background-color: #65bc7b;
  color: #fff;
  font-size: 13px;
}
.fusion-maps-static-type img {
  max-width: 100%;
}
.fusion-highlight.awb-highlight-background {
  padding: 0.1em 0.3em;
  line-height: 1.7em;
}
.fusion-highlight.awb-highlight-background.rounded {
  border-radius: 4px;
  background-clip: padding-box;
}
.fusion-highlight.dark {
  color: #ccc;
}
.fusion-image-before-after-horizontal .fusion-image-before-after-handle:before {
  content: " ";
  display: block;
  background: #fff;
  position: absolute;
  z-index: 30;
  width: 3px;
  height: 9999px;
  left: 50%;
  margin-left: -1.5px;
  bottom: 50%;
  margin-bottom: 22px;
  box-shadow: 0 3px 0 #fff, 0 0 12px rgba(51, 51, 51, 0.5);
}
.fusion-image-before-after-horizontal .fusion-image-before-after-handle:after {
  content: " ";
  display: block;
  background: #fff;
  position: absolute;
  z-index: 30;
  width: 3px;
  height: 9999px;
  left: 50%;
  margin-left: -1.5px;
  top: 50%;
  margin-top: 22px;
  box-shadow: 0 -3px 0 #fff, 0 0 12px rgba(51, 51, 51, 0.5);
}
.fusion-image-before-after-horizontal
  .fusion-image-before-after-handle.fusion-image-before-after-handle-rectangle {
  border-radius: 6px;
  width: 15px;
  height: 65px;
}
.fusion-image-before-after-horizontal
  .fusion-image-before-after-handle.fusion-image-before-after-handle-rectangle:before {
  margin-bottom: 36px;
}
.fusion-image-before-after-horizontal
  .fusion-image-before-after-handle.fusion-image-before-after-handle-rectangle:after {
  margin-top: 35px;
}
.fusion-image-before-after-horizontal
  .fusion-image-before-after-handle.fusion-image-before-after-handle-rectangle
  .fusion-image-before-after-left-arrow {
  margin: 0;
  transform: translate(-230%, -50%);
}
